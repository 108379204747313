import { SelectField } from '../../../../default/Form/Form';
import { Loading } from '../../../../default/States/States';
import { SelectGoalProps } from './types';

const SelectGoal = ({ goalData, loadingGoals }: SelectGoalProps): JSX.Element => {
    if (loadingGoals) return <Loading centralised={true} />;

    return (
        <SelectField title='goalId' type='select' alias='goal'>
            <option value=''>-- Select --</option>
            {goalData?.goals?.map(({ id, name }: any) => (
                <option key={id} value={id}>
                    {name}
                </option>
            ))}
        </SelectField>
    );
};

export default SelectGoal;
