import React, { useEffect, useState } from 'react';
import styles from '../../../../../css/default.module.scss';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useActivateTestMutation } from '../../../../../graphql/generated/schema';
import { useParams, Params } from 'react-router-dom';
import { Success, Error, Loading } from '../../../../default/States/States';
import { ActivateTestProps } from './types';

const ActivateTest = ({ isActive, refetchTest, testFinished }: ActivateTestProps): JSX.Element => {
    const [modalIsOpen, setIsOpen] = useState<boolean>(false);
    const [{ data, fetching: loading, error }, activateTestFn] = useActivateTestMutation();
    const { testId } = useParams<Params<string>>();
    const testIsEnableable = !isActive && !testFinished;

    const openModal = (condition: boolean): void => {
        if (!condition) return;
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const activateTest = async () => {
        await activateTestFn({
            testId: testId as string,
        });
        refetchTest();
    };

    useEffect(() => {
        if (data?.activateTest?.success) {
            const timeoutId = setTimeout(closeModal, 3000);
            return () => clearTimeout(timeoutId);
        }
    }, [data?.activateTest?.success, modalIsOpen]);

    return (
        <>
            <button
                onClick={() => openModal(testIsEnableable)}
                className={`${styles.btnGreen} ${testIsEnableable ? '' : styles.disabled}`}
            >
                Activate
            </button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className={styles.modal}
                contentLabel='Activate Test'
                portalClassName={styles.modalOverlayWrap} // cannot use overlayClassName
            >
                <button onClick={closeModal} className={styles.close} aria-label='close'>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                {testIsEnableable ? (
                    <div>
                        <h2>Activate Test</h2>
                        <p>Please confirm that you would like to activate this test.</p>
                        {loading ? (
                            <Loading centralised={true} />
                        ) : (
                            <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                                <button onClick={activateTest} className={styles.btnGreen}>
                                    Activate
                                </button>
                                <button onClick={closeModal} className={styles.btnPrimary}>
                                    Cancel
                                </button>
                            </div>
                        )}
                    </div>
                ) : (
                    <>
                        <Success msg='Test activated successfully' marginTop={true} />
                        <Loading centralised={true} marginTop={true} />
                    </>
                )}
                {error && <Error msg={error.message} marginTop={true} />}
            </Modal>
        </>
    );
};

export default ActivateTest;
