import React from 'react';
import styles from '../../../css/default.module.scss';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { SignupModalProps } from './types';
import { SignupForm } from '../SignupForm/SignupForm';

export const SignupModal = ({ modalIsOpen, setIsOpen }: SignupModalProps): JSX.Element => {
    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className={styles.modal}
            contentLabel='Download Guide'
            portalClassName={styles.modalOverlayWrap} // cannot use overlayClassName
        >
            <button onClick={closeModal} className={styles.close} aria-label='close'>
                <FontAwesomeIcon icon={faTimes} />
            </button>
            <div>
                <h2>Sign Up to Download</h2>
                <p>Complete the form below:</p>
                <SignupForm onCancel={closeModal} />
            </div>
        </Modal>
    );
};
