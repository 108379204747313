import React from 'react';
import styles from './styles.module.scss';
import { ListBoxesProps } from './types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';

const ListBoxes = ({ items, cls = '', design = 1 }: ListBoxesProps): JSX.Element => (
    <div className={`${styles.outer} ${cls} ${design === 2 ? styles.two : ''}`}>
        {items.map(({ icon, label, title, list }) => (
            <article key={label} className={styles.box}>
                <div className={styles.iconAndTitle}>
                    <FontAwesomeIcon icon={icon} aria-label={label} className={styles.icon} />
                    <h3>{title}</h3>
                </div>
                <ul className={styles.list}>
                    {list.map((item) => (
                        <li className={styles.item} key={item}>
                            <FontAwesomeIcon
                                icon={faCheck}
                                aria-label='tick'
                                className={styles.check}
                            />{' '}
                            {item}
                        </li>
                    ))}
                </ul>
            </article>
        ))}
    </div>
);

export default ListBoxes;
