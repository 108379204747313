import React from 'react';
import { withPage } from '../../../components/layout/Page/Page';
import { servicesMeta } from '../../misc/MetaTags';
import { HeroArea, Section } from '../../../components/default/Areas/Areas';
import styles from './styles.module.scss';
import { StandardCards } from '../../../components/default/Cards/PlanCards/PlanCards';
import { SERVICE_CARDS } from './variables';

const Services = () => (
    <>
        <HeroArea
            title='Custom A/B Testing Services'
            description='Want technical assistance with A/B testing? Explore our professional A/B testing services, from basic setup to custom test development.'
            tall={false}
        />
        <Section id={styles.planCardsSectionContainer} clsInner={styles.inner} patterned={true}>
            <StandardCards cards={SERVICE_CARDS} />
        </Section>
    </>
);

export default withPage(Services)({ meta: servicesMeta, fullWidth: true });
