import { useCallback, useEffect, useState } from 'react';
import { UseCreateTestStage } from './types';
import { FINAL_STAGE, STAGE_FIELDS, STAGE_TITLES } from './variables';
import { useSubmitTestCreation } from '../useSubmitTestCreation/useSubmitTestCreation';

export const useCreateTestStage: UseCreateTestStage = (methods) => {
    const [currentStage, setCurrentStage] = useState<number>(1);
    const [currentStageComplete, setCurrentStageComplete] = useState<boolean>(false);
    const [title, setTitle] = useState<string>(STAGE_TITLES[currentStage]);
    const formValues = methods.watch(); // use useWatch instead? (when inside provider)
    const {
        createTest,
        loading: loadingSubmission,
        error: submissionError,
    } = useSubmitTestCreation();

    const areFieldsValid = useCallback(
        (fieldNames: string[]): boolean => {
            const hasFieldErrors = fieldNames.some((fieldName) =>
                methods.formState.errors.hasOwnProperty(fieldName)
            );
            const hasEmptyValues = fieldNames.some((fieldName) => {
                const fieldValue = methods.getValues()[fieldName];
                return Array.isArray(fieldValue) ? fieldValue.length === 0 : !fieldValue;
            });

            return !hasFieldErrors && !hasEmptyValues;
        },
        [methods]
    );

    const proceed = useCallback(() => {
        if (currentStageComplete && currentStage === FINAL_STAGE) {
            createTest(formValues);
            return;
        }

        for (let stage = 1; stage <= 4; stage++) {
            const fields = STAGE_FIELDS.get(stage) ?? [];
            if (areFieldsValid(fields) && currentStage === stage) {
                setCurrentStage((curStage) => curStage + 1);
                break;
            }
        }
    }, [areFieldsValid, currentStage, currentStageComplete, createTest, formValues]);

    const showPrevStep = useCallback(() => {
        setCurrentStage((curStage) => curStage - 1);
    }, []);

    useEffect(() => {
        // @todo: debounce?
        setCurrentStageComplete(areFieldsValid(STAGE_FIELDS.get(currentStage) ?? []));
    }, [formValues, areFieldsValid, currentStage]);

    useEffect(() => {
        setTitle(STAGE_TITLES[currentStage]);
    }, [currentStage]);

    return {
        currentStage,
        currentStageComplete,
        proceed,
        showPrevStep,
        title,
        loadingSubmission,
        submissionError,
    };
};
