import React from 'react';
import styles from './styles.module.scss';
import BenefitBox from '../../../../../../components/default/Informational/BenefitBox/BenefitBox';

const ProPlanPersuasionMessage = () => (
    <div className={styles.container}>
        <h2>Upgrade Your Testing with the Pro Plan</h2>
        <p>
            Consider moving up to the Pro Plan and get ready for some serious testing power. As you
            think about going beyond the Beginner Plan, let's talk about the good stuff that comes
            with the Pro Plan.
        </p>

        <BenefitBox
            title='More Ways to Try: Almost Limitless Variations'
            description='The Pro Plan lets you try out different variants with hardly any limits. You can make up to 10 variants per test, which is more than you should create per test. This helps you understand what works best and make smart choices that will increase conversions.'
        />

        <BenefitBox
            title='Make More Tests: Unlimited Tests'
            description='With the Pro Plan, you can run several tests at once, saving you time and improving coverage. You can test different ideas together and find out what makes people happier or more interested. This means quicker results and smarter, profitable decisions.'
        />

        <BenefitBox
            title='Improved Test Result Accuracy: More People Seeing Your Tests'
            description='With the Pro Plan, your tests and their variants can be seen by many more people – up to 10,000 visitors per test. This makes your test results more accurate, helping you understand what your audience really likes.'
        />

        <BenefitBox
            title='Many Websites'
            description="The Pro Plan lets you try your experiments on different websites, not just one. This is great if you have or manage different projects or websites you're working on."
        />

        <h3>Ready to Step Up? Choose the Pro Plan</h3>
        <p>
            Give your testing a boost by going Pro today. It's like upgrading to a better toolbox
            for your experiments. You can always revert back later.
        </p>
    </div>
);

export default ProPlanPersuasionMessage;
