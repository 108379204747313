import React from 'react';
import defaultStyles from '../../../../css/default.module.scss';
import { Link, Params, useParams } from 'react-router-dom';
import { TestActionProps } from './types';
import PauseTest from './PauseTest/PauseTest';
import DeleteTest from './DeleteTest/DeleteTest';
import ActivateTest from './ActivateTest/ActivateTest';
import { InfoAreaWrap } from '../../../default/Cards/Cards';
import styles from './style.module.scss';

const TestActions = ({ isActive, refetchTest, testFinished }: TestActionProps): JSX.Element => {
    const { testId } = useParams<Params<string>>();

    // @todo later: add remaining functionality (e.g. 'edit')

    return (
        <InfoAreaWrap>
            <h2>Actions:</h2>
            <div className={`${defaultStyles.buttonsContainer} ${defaultStyles.spaced}`}>
                <ActivateTest
                    isActive={isActive}
                    refetchTest={refetchTest}
                    testFinished={testFinished}
                />
                <PauseTest isActive={isActive} refetchTest={refetchTest} />
                {/* <button className={defaultStyles.btnDark}>Edit</button> */}
                <DeleteTest />
                <Link to={`/user/tests/${testId}/results`} className={defaultStyles.btnPrimary}>
                    View Results
                </Link>
            </div>
            <p className={styles.trackingScriptInstallation}>
                Installing the <Link to='/support/script-setup'>tracking script</Link>.
            </p>
        </InfoAreaWrap>
    );
};

export default TestActions;
