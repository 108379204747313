import React, { FC, useState } from 'react';
import styles from '../../../../css/default.module.scss';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import ReferralLinkGeneratorForm from '../ReferralLinkGeneratorForm/ReferralLinkGeneratorForm';
import { ReferralGeneratorProps } from './types';

export const ReferralLinkGenerator: FC<ReferralGeneratorProps> = ({ referralCode }) => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div>
            <button onClick={openModal} className={styles.btnPrimary}>
                Custom Link Generator
            </button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className={styles.modal}
                contentLabel='Referral Link Generator Modal'
                portalClassName={styles.modalOverlayWrap} // cannot use overlayClassName
            >
                <button onClick={closeModal} className={styles.close} aria-label='close'>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <div>
                    <h2>Referral Link Generator</h2>
                    <p>
                        You may want to send prospects to custom pages on our website and still get
                        credited as the referrer. You can generate a custom referral link to any
                        page on our website that will credit you as the referrer by using the form
                        below:
                    </p>
                    <ReferralLinkGeneratorForm
                        referralCode={referralCode}
                        closeModal={closeModal}
                    />
                </div>
            </Modal>
        </div>
    );
};
