import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import defaultStyles from '../../../../css/default.module.scss';
import styles from './styles.module.scss';
import { CodeField, InputField } from '../../../default/Form/Form';
import { ErrorText, InfoWithChildren } from '../../../default/States/States';
import { SelectPageInput, SelectWebsitePrompt } from '../StageOne/SelectPage/SelectPage';
import { useFieldArray, useFormContext } from 'react-hook-form';
import { CreateTestStageTwoProps } from './types';
import { Variant } from '../../../../pages/account/private/Tests/CreateTest/types';
import useVariantsValidation from '../../../../hooks/tests/useVariantsValidation/useVariantsValidation';
import { Link } from 'react-router-dom';
import { UserContext } from '../../../../contexts/UserContext/UserContext';
import { UserContextType } from '../../../../contexts/UserContext/types';
import { MAX_VARIANTS } from '../../../../utils/global-variables';

const CreateTestStageTwo = ({ pagesData }: CreateTestStageTwoProps): JSX.Element => {
    const { user } = useContext<UserContextType>(UserContext);
    const userPlan = useMemo(() => user?.userPlan, [user]);
    const [maxVariantsBreached, setMaxVariantsBreached] = useState<boolean>(true);
    const { control, setValue, formState, watch } = useFormContext();
    const {
        fields: variantFields,
        append,
        remove,
    } = useFieldArray({
        control,
        name: 'variants',
    });
    const variantFieldsRef = useRef(variantFields);
    const noOfVariants = variantFields.length;
    const variantsWithoutControl = variantFields.slice(1);
    const variantValues = watch('variants');

    const checkDisableVariantsButton = useCallback(() => {
        if (userPlan === 'FREE' && noOfVariants > MAX_VARIANTS.FREE) {
            setMaxVariantsBreached(true);
            return;
        }

        if (userPlan === 'BEGINNER' && noOfVariants > MAX_VARIANTS.BEGINNER) {
            setMaxVariantsBreached(true);
            return;
        }

        if (userPlan === 'PRO' && noOfVariants > MAX_VARIANTS.PRO) {
            setMaxVariantsBreached(true);
            return;
        }

        setMaxVariantsBreached(false);
    }, [noOfVariants, userPlan]);

    useEffect(() => {
        variantFieldsRef.current = variantFields;
    }, [variantFields]);

    useEffect(() => {
        const suggestedValue = Math.round(100 / noOfVariants);
        const controlValue = 100 - suggestedValue * noOfVariants + suggestedValue;

        variantFieldsRef.current.forEach(({ isControl }: Partial<Variant>, index: number) => {
            const valueToSet = isControl ? controlValue : suggestedValue;
            setValue(`variants[${index}].trafficAllocation`, valueToSet);
        });

        checkDisableVariantsButton();
    }, [variantFieldsRef, noOfVariants, setValue, checkDisableVariantsButton]);

    useVariantsValidation();

    return (
        <div>
            <div>
                {variantsWithoutControl.length ? (
                    variantsWithoutControl.map((field: { id: React.Key }, index: number) => {
                        const incrementedIndex = index + 1;
                        const manualError =
                            // @ts-ignore
                            formState.errors?.variants?.[incrementedIndex]?.whatItShouldShow
                                ?.message ||
                            // @ts-ignore
                            formState.errors.variants?.custom?.message;

                        const handleCssEditorChange = (value: string): void => {
                            setValue(`variants[${incrementedIndex}].cssCode`, value);
                        };

                        const handleJsEditorChange = (value: string): void => {
                            setValue(`variants[${incrementedIndex}].jsCode`, value);
                        };

                        const cssValue = variantValues[incrementedIndex]?.cssCode;
                        const jsValue = variantValues[incrementedIndex]?.jsCode;

                        return (
                            <div className={defaultStyles.formInner} key={field.id}>
                                <h3>Variant {incrementedIndex}</h3>
                                <InputField
                                    type='text'
                                    title={`variants[${incrementedIndex}].name`}
                                    placeholder='Enter a name'
                                    alias='name'
                                />
                                <InputField
                                    type='text'
                                    title={`variants[${incrementedIndex}].description`}
                                    placeholder='Enter a description'
                                    alias='description'
                                />
                                {pagesData ? (
                                    <SelectPageInput
                                        pagesData={pagesData}
                                        title={`variants[${incrementedIndex}].pageRedirectId`}
                                        alias='Page redirect'
                                    />
                                ) : (
                                    <SelectWebsitePrompt />
                                )}
                                <CodeField
                                    alias='Enter CSS'
                                    title={`variants[${incrementedIndex}].cssCode`}
                                    onChange={handleCssEditorChange}
                                    language='css'
                                    defaultValue={cssValue}
                                />
                                <CodeField
                                    alias='Enter JavaScript'
                                    title={`variants[${incrementedIndex}].jsCode`}
                                    onChange={handleJsEditorChange}
                                    language='javascript'
                                    defaultValue={jsValue}
                                />
                                {manualError && <ErrorText msg={manualError} />}
                                <button
                                    type='button'
                                    onClick={() => remove(incrementedIndex)}
                                    className={`${defaultStyles.btnRed} ${defaultStyles.left} ${defaultStyles.marginBottom}`}
                                >
                                    Remove Variant
                                </button>
                            </div>
                        );
                    })
                ) : (
                    <p className={`${styles.emptyVariantsMsg} ${styles.specificity}`}>
                        Your test needs at least one variant.
                    </p>
                )}
                <InfoWithChildren marginBottom={true}>
                    If you want to track a specific element (e.g. a button, link or form), you
                    should read the{' '}
                    <Link to='/support/test-creation/guide' target='_blank'>
                        test creation guide
                    </Link>
                    .
                </InfoWithChildren>
                {maxVariantsBreached && (
                    <InfoWithChildren marginBottom={true}>
                        You have reached the maximum number of variants that your account plan can
                        create.{' '}
                        {!(userPlan === 'PRO') && (
                            <>
                                To increase this limit, you can{' '}
                                <Link to='/pricing' target='_blank'>
                                    upgrade your account plan
                                </Link>
                            </>
                        )}
                        .
                    </InfoWithChildren>
                )}
            </div>

            <button
                onClick={() =>
                    append({
                        name: '',
                        description: '',
                        pageRedirectId: '',
                        cssCode: '',
                        jsCode: '',
                        trafficAllocation: 0,
                    })
                }
                disabled={maxVariantsBreached}
                className={`${defaultStyles.btnPrimary} ${defaultStyles.marginBottom} ${
                    maxVariantsBreached ? defaultStyles.disabled : ''
                }`}
                type='button'
            >
                New Variant
            </button>
        </div>
    );
};

export default CreateTestStageTwo;
