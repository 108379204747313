import React from 'react';
import { CheckboxFields, InputField } from '../../../default/Form/Form';

const CreateTestStageFour = () => (
    <>
        <CheckboxFields
            title='targeting'
            values={['mobiles', 'desktops', 'tablets']}
            alias='Device targeting options'
            caption='Select the devices that should show the experiment'
        />
        <InputField
            type='number'
            title='maxSessions'
            alias='Maximum visitor sessions'
            placeholder='Enter a number'
            caption='Set the maximum number of page views until the experiment stops'
        />
    </>
);

export default CreateTestStageFour;
