import { useEffect, useState } from 'react';
import { useAffiliateBalancesQuery } from '../../../graphql/generated/schema';
import { UseAffiliateBalances } from './types';

export const useAffiliateBalances: UseAffiliateBalances = () => {
    const [{ data, fetching: loading, error: queryError }] = useAffiliateBalancesQuery();
    const [error, setError] = useState<string>(''); // empty '' coerces to false
    const pendingBalance: number = data?.affiliateBalancePending || 0;
    const qualifiedBalance: number = data?.affiliateBalanceQualified || 0;
    const unpaidBalance: number = pendingBalance + qualifiedBalance;

    useEffect(() => {
        if (queryError || !data) {
            setError(queryError?.message || 'Could not find any balances.');
            return;
        }
        setError('');
    }, [queryError, data]);

    return { pendingBalance, qualifiedBalance, unpaidBalance, loading, error };
};
