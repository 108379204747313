import React, { useContext } from 'react';
import { CodeBlock } from '../../../default/Cards/Cards';
import { TRACKING_SCRIPT_PATH } from '../../../../utils/global-variables';
import { Loading, Warning } from '../../../default/States/States';
import defaultStyles from '../../../../css/default.module.scss';
import { Link } from 'react-router-dom';
import { UserContextType } from '../../../../contexts/UserContext/types';
import { UserContext } from '../../../../contexts/UserContext/UserContext';
import { useSelectWebsites } from '../../../../hooks/pages/useSelectWebsites/useSelectWebsites';
import { SelectWebsiteDropdownForm } from '../../Pages/SelectWebsiteDropdown/SelectWebsiteDropdown';
import { AccountContext } from '../../../../contexts/AccountContext/AccountContext';
import { AccountContextType } from '../../../../contexts/AccountContext/types';
import { SCRIPT_INSTRUCTIONS_LOGGED_IN, SCRIPT_INSTRUCTIONS_LOGGED_OUT } from './variables';
import PageError from '../../../../pages/other/errors/PageError.tsx/PageError';
import { Back } from '../../../default/Navigation/Navigation';
import styles from './styles.module.scss';

const ScriptInstallation = () => {
    const {
        loading: loadingWebsites,
        error: websiteError,
        handleWebsiteChange,
    } = useSelectWebsites();
    const { user } = useContext<UserContextType>(UserContext);
    const { selectedWebsite, websites } = useContext<AccountContextType>(AccountContext);
    const wid = selectedWebsite?.id || 'XXXXXX';
    const code = `<script src="${TRACKING_SCRIPT_PATH}?wid=${wid}"></script>`;

    if (loadingWebsites) return <Loading entirePage={true} />;

    if (user && websiteError) {
        return <PageError msg={websiteError} />;
    }

    if (!websites?.length) {
        return (
            <div className={styles['align-centrally']}>
                <p>
                    In order to install your tracking script, you just need to{' '}
                    <Link to='/user/websites'>add your website</Link> first.
                </p>
                <Link to='/user/websites' className={defaultStyles.btnPrimary}>
                    Add Website
                </Link>
            </div>
        );
    }

    return (
        <div>
            <h2>Installing the Tracking Script:</h2>
            <SelectWebsiteDropdownForm handleWebsiteChange={handleWebsiteChange} />
            {(!user || selectedWebsite) && (
                <>
                    <p>{user ? SCRIPT_INSTRUCTIONS_LOGGED_IN : SCRIPT_INSTRUCTIONS_LOGGED_OUT}</p>
                    <CodeBlock
                        title='Installation Script'
                        code={code}
                        marginBottom={true}
                        marginTop={true}
                    />
                    <p>
                        You could place the script anywhere in the head, but it will be
                        significantly faster if placed closer to the opening head tag.
                    </p>
                    <Warning
                        marginBottom={true}
                        msg="Note: if you have multiple websites, each website will have a different script (the 'wid' in the src attribute will differ)"
                    />
                    <p>
                        After you have done this, you may want to verify that it has installed
                        correctly.
                    </p>
                    <Link to='/support/script-verification' className={defaultStyles.btnPrimary}>
                        How to Verify
                    </Link>
                    <Back to='/support' text='Support Hub' block={true} />
                </>
            )}
        </div>
    );
};

export default ScriptInstallation;
