import { PlanCard } from '../../../components/default/Cards/PlanCards/types';
import { MAX_TEST_SESSIONS, MAX_VARIANTS, Plan } from '../../../utils/global-variables';

// n.b.: beware of changing the title, because the refinedPlans in <PlanCards/> uses it programatically
// also do not change the order of items just in case
export const PLANS: PlanCard[] = [
    {
        title: 'FREE',
        description: 'Everything needed to get started.',
        price: { dollars: 0, cents: 0 },
        button: {
            buttonLink: '/create-account',
            buttonText: 'Create Free Account',
        },
        benefits: [
            'Unlimited tests',
            '1 active test at a time',
            '1 website only',
            'A small backlink to our website',
            `Max. ${MAX_TEST_SESSIONS[Plan.FREE]} sessions per test`,
            `Max. ${MAX_VARIANTS.FREE} variant per test`,
        ],
    },
    {
        title: 'BEGINNER',
        description: 'For those who want to see more results.',
        price: { dollars: 29, cents: 99 },
        button: { buttonLink: '/create-account', buttonText: 'Buy Now' },
        benefits: [
            'Unlimited tests',
            '2 active tests at a time',
            '1 website only',
            `Max. ${MAX_TEST_SESSIONS[Plan.BEGINNER]} sessions per test`,
            `Max. ${MAX_VARIANTS.BEGINNER} variants per test`,
            'Customer support',
            'No backlink to our website',
        ],
    },
    {
        title: 'PRO',
        description: 'For the best testers who want to take testing to the next level.',
        price: { dollars: 79, cents: 99 },
        button: { buttonLink: '/create-account', buttonText: 'Buy Now' },
        benefits: [
            'All beginner features',
            `Max. ${MAX_VARIANTS.PRO} variants per test`,
            '6 active tests at a time',
            'Unlimited websites',
            `Max. ${MAX_TEST_SESSIONS[Plan.PRO]} sessions per test`,
            // 'Scheduling', - later
        ],
    },
];
