import gql from 'graphql-tag';
import * as Urql from 'urql';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
};

export type AffiliatePerformanceMetricsFree = {
  __typename?: 'AffiliatePerformanceMetricsFree';
  freeLastMonth: Scalars['Int'];
  freeLastQuarter: Scalars['Int'];
  freeLastWeek: Scalars['Int'];
  freeLastYear: Scalars['Int'];
};

export type AffiliatePerformanceMetricsPro = {
  __typename?: 'AffiliatePerformanceMetricsPro';
  payingLastMonth: Scalars['Int'];
  payingLastQuarter: Scalars['Int'];
  payingLastWeek: Scalars['Int'];
  payingLastYear: Scalars['Int'];
};

export type AffiliatePerformanceMetricsResponse = {
  __typename?: 'AffiliatePerformanceMetricsResponse';
  free: AffiliatePerformanceMetricsFree;
  paying: AffiliatePerformanceMetricsPro;
  totals: AffiliatePerformanceMetricsTotals;
};

export type AffiliatePerformanceMetricsTotals = {
  __typename?: 'AffiliatePerformanceMetricsTotals';
  freeReferrals: Scalars['Int'];
  payingReferrals: Scalars['Int'];
  totalReferrals: Scalars['Int'];
};

export type AffiliateSession = {
  __typename?: 'AffiliateSession';
  dateTime: Scalars['String'];
  id: Scalars['Int'];
  referrerId: Scalars['String'];
};

export type AffiliateSessionMetricsResponse = {
  __typename?: 'AffiliateSessionMetricsResponse';
  lastMonth: Scalars['Int'];
  lastQuarter: Scalars['Int'];
  lastWeek: Scalars['Int'];
  lastYear: Scalars['Int'];
  total: Scalars['Int'];
};

export type AffiliateTransaction = {
  __typename?: 'AffiliateTransaction';
  amount: Scalars['Float'];
  dateTime: Scalars['String'];
  id: Scalars['String'];
  qualified?: Maybe<Scalars['Boolean']>;
  status?: Maybe<Scalars['String']>;
};

export type AuthenticateUserInput = {
  email: Scalars['String'];
  password: Scalars['String'];
};

export type AvailableAvatarGoal = {
  __typename?: 'AvailableAvatarGoal';
  goalName: Scalars['String'];
  id: Scalars['String'];
};

export type AvatarBusinessWithoutUserId = {
  __typename?: 'AvatarBusinessWithoutUserId';
  businessName: Scalars['String'];
  industryId: Scalars['String'];
  monthlyWebsiteTrafficId: Scalars['String'];
  numEmployees: Scalars['Int'];
  otherIndustry?: Maybe<Scalars['String']>;
  otherPrimaryObjective?: Maybe<Scalars['String']>;
  primaryObjectiveId: Scalars['String'];
};

export type AvatarDemographicsWithoutUserId = {
  __typename?: 'AvatarDemographicsWithoutUserId';
  businessPosition: BusinessPosition;
  countryId: Scalars['String'];
  dob: Scalars['String'];
  educationLevelId: Scalars['String'];
  gender: Gender;
  jobCategoryId: Scalars['String'];
  nickname: Scalars['String'];
  otherJobCategory?: Maybe<Scalars['String']>;
  techSavvy: Scalars['Boolean'];
};

export type AvatarGoalWithoutUserId = {
  __typename?: 'AvatarGoalWithoutUserId';
  goalCategoryId: Scalars['String'];
  goalComment: Scalars['String'];
  improvements: Scalars['String'];
  otherGoalCategory?: Maybe<Scalars['String']>;
  siteConcerns: Scalars['String'];
  useCase: Scalars['String'];
};

export type BankDetails = {
  accountNumber?: InputMaybe<Scalars['String']>;
  bankName: Scalars['String'];
  country: Scalars['String'];
  firstName: Scalars['String'];
  iban?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  sortCode?: InputMaybe<Scalars['String']>;
  swiftBic?: InputMaybe<Scalars['String']>;
};

export type BankDetailsOutput = {
  __typename?: 'BankDetailsOutput';
  accountNumber?: Maybe<Scalars['String']>;
  bankName: Scalars['String'];
  country: Scalars['String'];
  firstName: Scalars['String'];
  iban?: Maybe<Scalars['String']>;
  lastName: Scalars['String'];
  sortCode?: Maybe<Scalars['String']>;
  swiftBic?: Maybe<Scalars['String']>;
};

export enum BusinessPosition {
  Employee = 'EMPLOYEE',
  Owner = 'OWNER'
}

export type ButtonData = {
  __typename?: 'ButtonData';
  avgClicks?: Maybe<Scalars['Float']>;
  avgHovers?: Maybe<Scalars['Float']>;
};

export type ChangeUserPlanInput = {
  userPlan: Scalars['Int'];
};

export type CreateAvatarBusinessInput = {
  businessName: Scalars['String'];
  industryId: Scalars['String'];
  monthlyWebsiteTrafficId: Scalars['String'];
  numEmployees: Scalars['Int'];
  otherIndustry?: InputMaybe<Scalars['String']>;
  otherPrimaryObjective?: InputMaybe<Scalars['String']>;
  primaryObjectiveId: Scalars['String'];
};

export type CreateAvatarDemographicsInput = {
  businessPosition: BusinessPosition;
  countryId: Scalars['String'];
  dob: Scalars['String'];
  educationLevelId: Scalars['String'];
  gender: Gender;
  jobCategoryId: Scalars['String'];
  nickname: Scalars['String'];
  otherJobCategory?: InputMaybe<Scalars['String']>;
  techSavvy: Scalars['Boolean'];
};

export type CreateAvatarGoalInput = {
  goalCategoryId: Scalars['String'];
  goalComment: Scalars['String'];
  improvements: Scalars['String'];
  otherGoalCategory?: InputMaybe<Scalars['String']>;
  siteConcerns: Scalars['String'];
  useCase: UseCase;
};

export type CreateAvatarPreviousExperienceInput = {
  conductedTestsBefore: Scalars['Boolean'];
  movedPlatform: Scalars['Boolean'];
  platformLeaveReason?: InputMaybe<Scalars['String']>;
  platformOther?: InputMaybe<Scalars['String']>;
  previousPlatformId?: InputMaybe<Scalars['String']>;
  previousTestProblems?: InputMaybe<Scalars['String']>;
};

export type CreateNewsletterSubscriberInput = {
  email: Scalars['String'];
  firstName: Scalars['String'];
};

export type CreateTestDataInstanceInput = {
  dataSession: DataSessionInput;
};

export type CreateTestInput = {
  active: Scalars['Boolean'];
  description: Scalars['String'];
  endDate?: InputMaybe<Scalars['String']>;
  goalId?: InputMaybe<Scalars['Int']>;
  maxCases?: InputMaybe<Scalars['Int']>;
  name: Scalars['String'];
  pageId: Scalars['String'];
  startDate?: InputMaybe<Scalars['String']>;
  websiteId: Scalars['String'];
};

export type CreateTestTargetDevicesInput = {
  targetDeviceIds: Array<Scalars['Int']>;
  testId: Scalars['String'];
};

export type CreateTestVariantInput = {
  cssCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  isControl: Scalars['Boolean'];
  jsCode?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  pageRedirectId?: InputMaybe<Scalars['String']>;
  testId: Scalars['String'];
  trafficAllocation: Scalars['Int'];
  variantNo: Scalars['Int'];
};

export type CreateUserFeedbackInput = {
  comment: Scalars['String'];
  rating: Rating;
};

export type CreateUserInput = {
  directAffiliateSignup?: InputMaybe<Scalars['Boolean']>;
  email: Scalars['String'];
  password: Scalars['String'];
  referrer?: InputMaybe<Scalars['String']>;
  trackingParams?: InputMaybe<TrackingParams>;
  userPlan: Scalars['Int'];
};

export type DataSessionInput = {
  bounced: Scalars['Boolean'];
  clicks: Scalars['Int'];
  deviceTypeId: Scalars['Int'];
  formSubmits: Scalars['Int'];
  id: Scalars['String'];
  scrolledDownPercentage: Scalars['Int'];
  sessionEnd: Scalars['String'];
  sessionStart: Scalars['String'];
  timeOnPage: Scalars['Int'];
  variantId: Scalars['String'];
};

export type DbIdInsertSuccess = {
  __typename?: 'DbIdInsertSuccess';
  acknowledged: Scalars['Boolean'];
  insertedId: Scalars['Int'];
};

export type DbUuidInsertSuccess = {
  __typename?: 'DbUuidInsertSuccess';
  acknowledged: Scalars['Boolean'];
  insertedId: Scalars['String'];
};

export type DbUuidUpdateSuccess = {
  __typename?: 'DbUuidUpdateSuccess';
  acknowledged: Scalars['Boolean'];
  updatedId: Scalars['String'];
};

export type EmailInput = {
  email: Scalars['String'];
};

export type FormData = {
  __typename?: 'FormData';
  avgClicks?: Maybe<Scalars['Float']>;
  avgHovers?: Maybe<Scalars['Float']>;
  medianTimeSpent?: Maybe<Scalars['Float']>;
  submissionRate?: Maybe<Scalars['Float']>;
};

export enum Gender {
  F = 'F',
  M = 'M'
}

export type GetAffiliateSessionsResponse = {
  __typename?: 'GetAffiliateSessionsResponse';
  sessions: Array<AffiliateSession>;
  total: Scalars['Int'];
};

export type GetAffiliateTransactionsResponse = {
  __typename?: 'GetAffiliateTransactionsResponse';
  total: Scalars['Int'];
  transactions: Array<AffiliateTransaction>;
};

export type GetPagesResponse = {
  __typename?: 'GetPagesResponse';
  pages: Array<Page>;
  total: Scalars['Int'];
};

export type GetReferralsResponse = {
  __typename?: 'GetReferralsResponse';
  total: Scalars['Int'];
  users: Array<ReferralUserData>;
};

export type GetTestResponse = {
  __typename?: 'GetTestResponse';
  active: Scalars['Boolean'];
  createdAt: Scalars['String'];
  description: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  goalName?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['String']>;
  maxCases: Scalars['Int'];
  name: Scalars['String'];
  pageUrl: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  targetDeviceNames: Scalars['String'];
  testSessionsCount: Scalars['Int'];
  userId: Scalars['String'];
  variantRedirectUrls?: Maybe<Scalars['String']>;
  websiteUrl: Scalars['String'];
};

export type GetTestsForUserInput = {
  limit?: InputMaybe<Scalars['Int']>;
  userId: Scalars['String'];
};

export type GetTestsForUserResponse = {
  __typename?: 'GetTestsForUserResponse';
  tests: Array<TestForUser>;
  total: Scalars['Int'];
};

export type GetTestsForWebsiteInput = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  websiteId: Scalars['String'];
};

export type GetTestsForWebsiteResponse = {
  __typename?: 'GetTestsForWebsiteResponse';
  tests: Array<Test>;
  total: Scalars['Int'];
};

export type GetWebsitesResponse = {
  __typename?: 'GetWebsitesResponse';
  total: Scalars['Int'];
  websites: Array<Website>;
};

export type Goal = {
  __typename?: 'Goal';
  id: Scalars['Int'];
  name: Scalars['String'];
};

export type MultipleDbInsertSuccess = {
  __typename?: 'MultipleDbInsertSuccess';
  acknowledged: Scalars['Boolean'];
  insertedIds: Array<Scalars['Int']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  activateTest: SuccessResponse;
  addInitialAffiliatePaymentDetails: Scalars['String'];
  adjustMaxTestCasesToBeginner: SuccessResponse;
  authenticateUser: UserLoginResponse;
  cancelSubscription: SuccessResponse;
  changeUserEmail: SuccessResponse;
  changeUserPassword: SuccessResponse;
  changeUserPlan: SuccessResponse;
  createAffiliateSession: SuccessResponse;
  createAvatarBusiness: SuccessResponse;
  createAvatarDemographics: SuccessResponse;
  createAvatarGoal: SuccessResponse;
  createAvatarPreviousExperience: SuccessResponse;
  createNewsletterSubscriber: SuccessResponse;
  createPage: DbUuidInsertSuccess;
  createTest: DbUuidInsertSuccess;
  createTestDataInstance: SuccessResponse;
  createTestTargetDevices: MultipleDbInsertSuccess;
  createTestVariant: DbUuidInsertSuccess;
  createUser: UserResponse;
  createUserFeedback: SuccessResponse;
  createWebsite: DbUuidInsertSuccess;
  deleteAllVariantsForTest: SuccessResponse;
  deletePage: SuccessResponse;
  deleteTest: SuccessResponse;
  deleteTestDataForTest: SuccessResponse;
  deleteTestTargetDevices: SuccessResponse;
  deleteTestVariant: SuccessResponse;
  deleteUser: SuccessResponse;
  deleteWebsite: SuccessResponse;
  makeTestInactive: SuccessResponse;
  processCardUpdateSession: Scalars['String'];
  processCheckoutCompleted: SuccessResponse;
  processCheckoutSession: Scalars['String'];
  resetPassword: SuccessResponse;
  saveUserBankDetails: DbUuidInsertSuccess;
  sendPasswordResetEmail: SuccessResponse;
  updateAvatarBusiness: SuccessResponse;
  updateAvatarDemographics: SuccessResponse;
  updateAvatarGoal: SuccessResponse;
  updatePage: SuccessResponse;
  updateSubscription: SuccessResponse;
  updateTest: Test;
  updateTestTargetDevices: MultipleDbInsertSuccess;
  updateTestVariant: SuccessResponse;
  updateUserBankDetails: DbUuidUpdateSuccess;
  updateWebsite: SuccessResponse;
};


export type MutationActivateTestArgs = {
  id: Scalars['String'];
};


export type MutationAuthenticateUserArgs = {
  input: AuthenticateUserInput;
};


export type MutationChangeUserEmailArgs = {
  input: EmailInput;
};


export type MutationChangeUserPasswordArgs = {
  input: PasswordInput;
};


export type MutationChangeUserPlanArgs = {
  input: ChangeUserPlanInput;
};


export type MutationCreateAffiliateSessionArgs = {
  referrerId: Scalars['String'];
};


export type MutationCreateAvatarBusinessArgs = {
  input: CreateAvatarBusinessInput;
};


export type MutationCreateAvatarDemographicsArgs = {
  input: CreateAvatarDemographicsInput;
};


export type MutationCreateAvatarGoalArgs = {
  input: CreateAvatarGoalInput;
};


export type MutationCreateAvatarPreviousExperienceArgs = {
  input: CreateAvatarPreviousExperienceInput;
};


export type MutationCreateNewsletterSubscriberArgs = {
  input: CreateNewsletterSubscriberInput;
};


export type MutationCreatePageArgs = {
  name: Scalars['String'];
  url: Scalars['String'];
  websiteId: Scalars['String'];
};


export type MutationCreateTestArgs = {
  input: CreateTestInput;
};


export type MutationCreateTestDataInstanceArgs = {
  input: CreateTestDataInstanceInput;
};


export type MutationCreateTestTargetDevicesArgs = {
  input: CreateTestTargetDevicesInput;
};


export type MutationCreateTestVariantArgs = {
  input: CreateTestVariantInput;
};


export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


export type MutationCreateUserFeedbackArgs = {
  input: CreateUserFeedbackInput;
};


export type MutationCreateWebsiteArgs = {
  name: Scalars['String'];
  url: Scalars['String'];
};


export type MutationDeleteAllVariantsForTestArgs = {
  id: Scalars['String'];
};


export type MutationDeletePageArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTestArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTestDataForTestArgs = {
  id: Scalars['String'];
};


export type MutationDeleteTestTargetDevicesArgs = {
  testId: Scalars['String'];
};


export type MutationDeleteTestVariantArgs = {
  id: Scalars['String'];
};


export type MutationDeleteWebsiteArgs = {
  id: Scalars['String'];
};


export type MutationMakeTestInactiveArgs = {
  id: Scalars['String'];
};


export type MutationProcessCardUpdateSessionArgs = {
  customerId: Scalars['String'];
};


export type MutationProcessCheckoutCompletedArgs = {
  userId: Scalars['String'];
};


export type MutationProcessCheckoutSessionArgs = {
  email: Scalars['String'];
  planId: Scalars['String'];
};


export type MutationResetPasswordArgs = {
  input: ResetPasswordInput;
};


export type MutationSaveUserBankDetailsArgs = {
  input: BankDetails;
};


export type MutationSendPasswordResetEmailArgs = {
  input: EmailInput;
};


export type MutationUpdateAvatarBusinessArgs = {
  input: CreateAvatarBusinessInput;
};


export type MutationUpdateAvatarDemographicsArgs = {
  input: CreateAvatarDemographicsInput;
};


export type MutationUpdateAvatarGoalArgs = {
  input: CreateAvatarGoalInput;
};


export type MutationUpdatePageArgs = {
  input: UpdatePageInput;
};


export type MutationUpdateSubscriptionArgs = {
  planId: Scalars['String'];
  userId: Scalars['String'];
};


export type MutationUpdateTestArgs = {
  input: UpdateTestInput;
};


export type MutationUpdateTestTargetDevicesArgs = {
  input: CreateTestTargetDevicesInput;
};


export type MutationUpdateTestVariantArgs = {
  input: UpdateTestVariantInput;
};


export type MutationUpdateUserBankDetailsArgs = {
  input: BankDetails;
};


export type MutationUpdateWebsiteArgs = {
  input: UpdateWebsiteInput;
};

export type Page = {
  __typename?: 'Page';
  id: Scalars['String'];
  lastModified?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  url: Scalars['String'];
  websiteId?: Maybe<Scalars['String']>;
};

export type PagesPaginatedInput = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  websiteId: Scalars['String'];
};

export type PaginationInput = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export type PasswordInput = {
  password: Scalars['String'];
};

export type Query = {
  __typename?: 'Query';
  activeTestPages: Array<Scalars['String']>;
  affiliateBalancePending: Scalars['Float'];
  affiliateBalanceQualified: Scalars['Float'];
  affiliatePayoutsEnabled: Scalars['Boolean'];
  affiliatePerformanceMetrics: AffiliatePerformanceMetricsResponse;
  affiliateSessionMetrics: AffiliateSessionMetricsResponse;
  affiliateSessions: GetAffiliateSessionsResponse;
  affiliateTransactions: GetAffiliateTransactionsResponse;
  avatarBusiness: AvatarBusinessWithoutUserId;
  avatarDemographics: AvatarDemographicsWithoutUserId;
  avatarGoal: AvatarGoalWithoutUserId;
  checkUserPreviousExperienceExists: Scalars['Boolean'];
  competitors: Array<SelectItem>;
  countries: Array<SelectItem>;
  educationLevels: Array<SelectItem>;
  getAvailableAvatarGoals: Array<AvailableAvatarGoal>;
  getAvatarBusinessIndustries: Array<SelectItem>;
  getAvatarBusinessTrafficOptions: Array<SelectItem>;
  getAvatarBusinessWebsiteObjectives: Array<SelectItem>;
  getUserPlan: Scalars['Int'];
  goals: Array<Goal>;
  hasEnteredPaymentDetails: Scalars['Boolean'];
  jobCategories: Array<SelectItem>;
  me: User;
  page: Page;
  pages: GetPagesResponse;
  referrals: GetReferralsResponse;
  test: GetTestResponse;
  testPerformanceData: Array<TestVariantPerformanceDataWithVariantNo>;
  testTargetDevices: Array<TestTargetDevice>;
  testVariant: TestVariantWithPageRedirectUrl;
  testVariantPerformanceData: Array<TestVariantPerformanceData>;
  testVariants: Array<TestVariantsResponse>;
  tests: GetTestsForWebsiteResponse;
  testsForUser: GetTestsForUserResponse;
  user: User;
  userBankDetails?: Maybe<BankDetailsOutput>;
  userHasEnteredBankingDetails: Scalars['Boolean'];
  users: Array<User>;
  website: Website;
  websites: GetWebsitesResponse;
};


export type QueryActiveTestPagesArgs = {
  websiteId: Scalars['String'];
};


export type QueryAffiliateSessionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};


export type QueryAffiliateTransactionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<TransactionType>;
};


export type QueryGetUserPlanArgs = {
  id: Scalars['String'];
};


export type QueryPageArgs = {
  id: Scalars['String'];
};


export type QueryPagesArgs = {
  input?: InputMaybe<PagesPaginatedInput>;
};


export type QueryReferralsArgs = {
  input?: InputMaybe<PaginationInput>;
};


export type QueryTestArgs = {
  id: Scalars['String'];
};


export type QueryTestPerformanceDataArgs = {
  testId: Scalars['String'];
};


export type QueryTestTargetDevicesArgs = {
  testId: Scalars['String'];
};


export type QueryTestVariantArgs = {
  id: Scalars['String'];
};


export type QueryTestVariantPerformanceDataArgs = {
  variantIds: Array<Scalars['String']>;
};


export type QueryTestVariantsArgs = {
  id: Scalars['String'];
};


export type QueryTestsArgs = {
  input?: InputMaybe<GetTestsForWebsiteInput>;
};


export type QueryTestsForUserArgs = {
  input?: InputMaybe<GetTestsForUserInput>;
};


export type QueryUserArgs = {
  id: Scalars['String'];
};


export type QueryUsersArgs = {
  input?: InputMaybe<PaginationInput>;
};


export type QueryWebsiteArgs = {
  id: Scalars['String'];
};


export type QueryWebsitesArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
};

export enum Rating {
  Good = 'good',
  Poor = 'poor',
  Satisfactory = 'satisfactory'
}

export type ReferralUserData = {
  __typename?: 'ReferralUserData';
  conversionDate?: Maybe<Scalars['String']>;
  dateCreated: Scalars['String'];
  id: Scalars['String'];
  totalCommissionsValue: Scalars['Float'];
  totalMonthsPaid: Scalars['Int'];
  userPlan: Scalars['Int'];
};

export type ResetPasswordInput = {
  id: Scalars['String'];
  password: Scalars['String'];
  token: Scalars['String'];
};

export type SelectItem = {
  __typename?: 'SelectItem';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type SuccessResponse = {
  __typename?: 'SuccessResponse';
  success: Scalars['Boolean'];
};

export type Test = {
  __typename?: 'Test';
  active: Scalars['Boolean'];
  createdAt: Scalars['String'];
  description: Scalars['String'];
  endDate?: Maybe<Scalars['String']>;
  goalId?: Maybe<Scalars['Int']>;
  id: Scalars['String'];
  lastModified?: Maybe<Scalars['String']>;
  maxCases?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  pageId: Scalars['String'];
  startDate?: Maybe<Scalars['String']>;
  userId: Scalars['String'];
  websiteId: Scalars['String'];
};

export type TestDataToUpdate = {
  description?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['String']>;
  goalId?: InputMaybe<Scalars['Int']>;
  maxCases?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['String']>;
};

export type TestForUser = {
  __typename?: 'TestForUser';
  id: Scalars['String'];
  name: Scalars['String'];
};

export type TestTargetDevice = {
  __typename?: 'TestTargetDevice';
  id: Scalars['Int'];
  targetDeviceId: Scalars['Int'];
  testId: Scalars['String'];
};

export type TestVariantPerformanceData = {
  __typename?: 'TestVariantPerformanceData';
  avgClicks: Scalars['Float'];
  avgFormSubmits?: Maybe<Scalars['Float']>;
  avgScrolledDownPercentage: Scalars['Int'];
  bounceRate: Scalars['Float'];
  buttonData?: Maybe<ButtonData>;
  formData?: Maybe<FormData>;
  medianTimeOnPage: Scalars['Int'];
  sessions: Scalars['Int'];
  variantId: Scalars['String'];
};

export type TestVariantPerformanceDataWithVariantNo = {
  __typename?: 'TestVariantPerformanceDataWithVariantNo';
  avgClicks: Scalars['Float'];
  avgFormSubmits?: Maybe<Scalars['Float']>;
  avgScrolledDownPercentage: Scalars['Int'];
  bounceRate: Scalars['Float'];
  buttonData?: Maybe<ButtonData>;
  formData?: Maybe<FormData>;
  medianTimeOnPage: Scalars['Int'];
  sessions: Scalars['Int'];
  variantId: Scalars['String'];
  variantNo: Scalars['Int'];
};

export type TestVariantWithPageRedirectUrl = {
  __typename?: 'TestVariantWithPageRedirectUrl';
  createdAt: Scalars['String'];
  cssCode?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  isControl: Scalars['Boolean'];
  jsCode?: Maybe<Scalars['String']>;
  lastModified?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  pageRedirectId?: Maybe<Scalars['String']>;
  pageRedirectUrl?: Maybe<Scalars['String']>;
  testId: Scalars['String'];
  trafficAllocation: Scalars['Int'];
  userId?: Maybe<Scalars['String']>;
  variantNo: Scalars['Int'];
};

export type TestVariantsResponse = {
  __typename?: 'TestVariantsResponse';
  hasRedirect: Scalars['Boolean'];
  id: Scalars['String'];
  isControl: Scalars['Boolean'];
  name: Scalars['String'];
  trafficAllocation: Scalars['Int'];
  userId: Scalars['String'];
  variantNo: Scalars['Int'];
};

export type TrackingParams = {
  adSet?: InputMaybe<Scalars['String']>;
  device: Scalars['String'];
  isPaid?: InputMaybe<Scalars['Boolean']>;
  landingPageUrl: Scalars['String'];
  utmCampaign?: InputMaybe<Scalars['String']>;
  utmContent?: InputMaybe<Scalars['String']>;
  utmMedium?: InputMaybe<Scalars['String']>;
  utmSource?: InputMaybe<Scalars['String']>;
  utmTerm?: InputMaybe<Scalars['String']>;
};

export enum TransactionType {
  Commission = 'COMMISSION',
  Payment = 'PAYMENT'
}

export type UpdatePageInput = {
  name: Scalars['String'];
  pageId: Scalars['String'];
  url: Scalars['String'];
};

export type UpdateTestInput = {
  dataToUpdate: TestDataToUpdate;
  testId: Scalars['String'];
};

export type UpdateTestVariantInput = {
  dataToUpdate: VariantDataToUpdate;
  variantId: Scalars['String'];
};

export type UpdateWebsiteInput = {
  name: Scalars['String'];
  url: Scalars['String'];
  websiteId: Scalars['String'];
};

export enum UseCase {
  ClientSites = 'CLIENT_SITES',
  OwnSite = 'OWN_SITE'
}

export type User = {
  __typename?: 'User';
  affiliateRate: Scalars['Float'];
  cardNearingExpiry?: Maybe<Scalars['Boolean']>;
  conversionDate?: Maybe<Scalars['String']>;
  dateCreated: Scalars['String'];
  dateModified?: Maybe<Scalars['String']>;
  directAffiliateSignup: Scalars['Boolean'];
  email: Scalars['String'];
  hashedPassword: Scalars['String'];
  id: Scalars['String'];
  lastLogin?: Maybe<Scalars['String']>;
  referredBy?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  subscriptionStatusId?: Maybe<Scalars['String']>;
  userPlan: Scalars['Int'];
};

export type UserLoginResponse = {
  __typename?: 'UserLoginResponse';
  affiliateRate: Scalars['Float'];
  cardNearingExpiry: Scalars['Boolean'];
  conversionDate?: Maybe<Scalars['String']>;
  dateCreated: Scalars['String'];
  dateModified?: Maybe<Scalars['String']>;
  directAffiliateSignup: Scalars['Boolean'];
  email: Scalars['String'];
  hashedPassword: Scalars['String'];
  id: Scalars['String'];
  lastLogin?: Maybe<Scalars['String']>;
  referredBy?: Maybe<Scalars['String']>;
  stripeCustomerId?: Maybe<Scalars['String']>;
  subscriptionStatusId: Scalars['Int'];
  token: Scalars['String'];
  userPlan: Scalars['Int'];
};

export type UserResponse = {
  __typename?: 'UserResponse';
  cardNearingExpiry: Scalars['Boolean'];
  id: Scalars['String'];
  subscriptionStatusId: Scalars['Int'];
  token: Scalars['String'];
  userPlan: Scalars['Int'];
};

export type VariantDataToUpdate = {
  cssCode?: InputMaybe<Scalars['String']>;
  description?: InputMaybe<Scalars['String']>;
  jsCode?: InputMaybe<Scalars['String']>;
  name?: InputMaybe<Scalars['String']>;
  pageRedirectId?: InputMaybe<Scalars['String']>;
  trafficAllocation?: InputMaybe<Scalars['Int']>;
  variantNo?: InputMaybe<Scalars['Boolean']>;
};

export type Website = {
  __typename?: 'Website';
  id: Scalars['String'];
  lastModified?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  url: Scalars['String'];
};

export type IsDirectAffiliateSignupQueryVariables = Exact<{ [key: string]: never; }>;


export type IsDirectAffiliateSignupQuery = { __typename?: 'Query', me: { __typename?: 'User', directAffiliateSignup: boolean } };

export type AffiliateBalancesQueryVariables = Exact<{ [key: string]: never; }>;


export type AffiliateBalancesQuery = { __typename?: 'Query', affiliateBalancePending: number, affiliateBalanceQualified: number };

export type GetReferralsQueryVariables = Exact<{
  input: PaginationInput;
}>;


export type GetReferralsQuery = { __typename?: 'Query', referrals: { __typename?: 'GetReferralsResponse', total: number, users: Array<{ __typename?: 'ReferralUserData', dateCreated: string, userPlan: number, conversionDate?: string | null, totalMonthsPaid: number, totalCommissionsValue: number, id: string }> } };

export type AffiliatePerformanceMetricTotalsQueryVariables = Exact<{ [key: string]: never; }>;


export type AffiliatePerformanceMetricTotalsQuery = { __typename?: 'Query', affiliatePerformanceMetrics: { __typename?: 'AffiliatePerformanceMetricsResponse', totals: { __typename?: 'AffiliatePerformanceMetricsTotals', freeReferrals: number, payingReferrals: number, totalReferrals: number } } };

export type AffiliatePerformanceMetricsDetailedQueryVariables = Exact<{ [key: string]: never; }>;


export type AffiliatePerformanceMetricsDetailedQuery = { __typename?: 'Query', affiliatePerformanceMetrics: { __typename?: 'AffiliatePerformanceMetricsResponse', free: { __typename?: 'AffiliatePerformanceMetricsFree', freeLastWeek: number, freeLastMonth: number, freeLastQuarter: number, freeLastYear: number }, paying: { __typename?: 'AffiliatePerformanceMetricsPro', payingLastWeek: number, payingLastMonth: number, payingLastQuarter: number, payingLastYear: number } } };

export type AffiliateSessionMetricsQueryVariables = Exact<{ [key: string]: never; }>;


export type AffiliateSessionMetricsQuery = { __typename?: 'Query', affiliateSessionMetrics: { __typename?: 'AffiliateSessionMetricsResponse', lastMonth: number, lastQuarter: number, lastWeek: number, lastYear: number, total: number } };

export type AffiliateSessionMutationVariables = Exact<{
  referrerId: Scalars['String'];
}>;


export type AffiliateSessionMutation = { __typename?: 'Mutation', createAffiliateSession: { __typename?: 'SuccessResponse', success: boolean } };

export type AffiliateSessionsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type AffiliateSessionsQuery = { __typename?: 'Query', affiliateSessions: { __typename?: 'GetAffiliateSessionsResponse', total: number, sessions: Array<{ __typename?: 'AffiliateSession', dateTime: string, id: number }> } };

export type GetAffiliateTransactionsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<TransactionType>;
}>;


export type GetAffiliateTransactionsQuery = { __typename?: 'Query', affiliateTransactions: { __typename?: 'GetAffiliateTransactionsResponse', total: number, transactions: Array<{ __typename?: 'AffiliateTransaction', dateTime: string, amount: number, id: string, qualified?: boolean | null }> } };

export type GetAffiliatePaymentsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type GetAffiliatePaymentsQuery = { __typename?: 'Query', affiliateTransactions: { __typename?: 'GetAffiliateTransactionsResponse', total: number, transactions: Array<{ __typename?: 'AffiliateTransaction', amount: number, dateTime: string, id: string }> } };

export type GetAffiliateCommissionsQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type GetAffiliateCommissionsQuery = { __typename?: 'Query', affiliateTransactions: { __typename?: 'GetAffiliateTransactionsResponse', total: number, transactions: Array<{ __typename?: 'AffiliateTransaction', amount: number, dateTime: string, id: string, qualified?: boolean | null }> } };

export type CreateUserFeedbackMutationVariables = Exact<{
  input: CreateUserFeedbackInput;
}>;


export type CreateUserFeedbackMutation = { __typename?: 'Mutation', createUserFeedback: { __typename?: 'SuccessResponse', success: boolean } };

export type CreateNewsletterSubscriberMutationVariables = Exact<{
  input: CreateNewsletterSubscriberInput;
}>;


export type CreateNewsletterSubscriberMutation = { __typename?: 'Mutation', createNewsletterSubscriber: { __typename?: 'SuccessResponse', success: boolean } };

export type PagesQueryVariables = Exact<{
  input?: InputMaybe<PagesPaginatedInput>;
}>;


export type PagesQuery = { __typename?: 'Query', pages: { __typename?: 'GetPagesResponse', total: number, pages: Array<{ __typename?: 'Page', id: string, name: string, url: string, lastModified?: string | null }> } };

export type UpdatePageMutationVariables = Exact<{
  input: UpdatePageInput;
}>;


export type UpdatePageMutation = { __typename?: 'Mutation', updatePage: { __typename: 'SuccessResponse', success: boolean } };

export type CreatePageMutationVariables = Exact<{
  url: Scalars['String'];
  name: Scalars['String'];
  websiteId: Scalars['String'];
}>;


export type CreatePageMutation = { __typename?: 'Mutation', createPage: { __typename?: 'DbUuidInsertSuccess', acknowledged: boolean, insertedId: string } };

export type DeletePageMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeletePageMutation = { __typename?: 'Mutation', deletePage: { __typename?: 'SuccessResponse', success: boolean } };

export type ProcessCheckoutSessionMutationVariables = Exact<{
  email: Scalars['String'];
  planId: Scalars['String'];
}>;


export type ProcessCheckoutSessionMutation = { __typename?: 'Mutation', processCheckoutSession: string };

export type ProcessCheckoutCompletedMutationVariables = Exact<{
  userId: Scalars['String'];
}>;


export type ProcessCheckoutCompletedMutation = { __typename?: 'Mutation', processCheckoutCompleted: { __typename?: 'SuccessResponse', success: boolean } };

export type GetStripeCustomerIdQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStripeCustomerIdQuery = { __typename?: 'Query', me: { __typename?: 'User', stripeCustomerId?: string | null } };

export type ProcessCardUpdateSessionMutationVariables = Exact<{
  customerId: Scalars['String'];
}>;


export type ProcessCardUpdateSessionMutation = { __typename?: 'Mutation', processCardUpdateSession: string };

export type CancelSubscriptionMutationVariables = Exact<{ [key: string]: never; }>;


export type CancelSubscriptionMutation = { __typename?: 'Mutation', cancelSubscription: { __typename?: 'SuccessResponse', success: boolean } };

export type UpdateSubscriptionMutationVariables = Exact<{
  userId: Scalars['String'];
  planId: Scalars['String'];
}>;


export type UpdateSubscriptionMutation = { __typename?: 'Mutation', updateSubscription: { __typename?: 'SuccessResponse', success: boolean } };

export type CreateTestMutationVariables = Exact<{
  input: CreateTestInput;
}>;


export type CreateTestMutation = { __typename?: 'Mutation', createTest: { __typename: 'DbUuidInsertSuccess', acknowledged: boolean, insertedId: string } };

export type TestsForWebsiteQueryVariables = Exact<{
  input?: InputMaybe<GetTestsForWebsiteInput>;
}>;


export type TestsForWebsiteQuery = { __typename?: 'Query', tests: { __typename?: 'GetTestsForWebsiteResponse', total: number, tests: Array<{ __typename?: 'Test', id: string, name: string, active: boolean, startDate?: string | null }> } };

export type TestQueryVariables = Exact<{
  testId: Scalars['String'];
}>;


export type TestQuery = { __typename?: 'Query', test: { __typename?: 'GetTestResponse', name: string, description: string, active: boolean, createdAt: string, startDate?: string | null, endDate?: string | null, lastModified?: string | null, goalName?: string | null, maxCases: number, pageUrl: string, websiteUrl: string, targetDeviceNames: string, testSessionsCount: number, variantRedirectUrls?: string | null } };

export type RecentTestsQueryVariables = Exact<{
  input?: InputMaybe<GetTestsForUserInput>;
}>;


export type RecentTestsQuery = { __typename?: 'Query', testsForUser: { __typename?: 'GetTestsForUserResponse', tests: Array<{ __typename?: 'TestForUser', id: string, name: string }> } };

export type MakeTestInactiveMutationVariables = Exact<{
  testId: Scalars['String'];
}>;


export type MakeTestInactiveMutation = { __typename?: 'Mutation', makeTestInactive: { __typename?: 'SuccessResponse', success: boolean } };

export type ActivateTestMutationVariables = Exact<{
  testId: Scalars['String'];
}>;


export type ActivateTestMutation = { __typename?: 'Mutation', activateTest: { __typename?: 'SuccessResponse', success: boolean } };

export type DeleteTestMutationVariables = Exact<{
  testId: Scalars['String'];
}>;


export type DeleteTestMutation = { __typename?: 'Mutation', deleteTest: { __typename?: 'SuccessResponse', success: boolean } };

export type DeleteTestDataForTestMutationVariables = Exact<{
  testId: Scalars['String'];
}>;


export type DeleteTestDataForTestMutation = { __typename?: 'Mutation', deleteTestDataForTest: { __typename?: 'SuccessResponse', success: boolean } };

export type TestPerformanceDataQueryVariables = Exact<{
  testId: Scalars['String'];
}>;


export type TestPerformanceDataQuery = { __typename?: 'Query', testPerformanceData: Array<{ __typename?: 'TestVariantPerformanceDataWithVariantNo', variantNo: number, variantId: string, avgClicks: number, avgFormSubmits?: number | null, avgScrolledDownPercentage: number, medianTimeOnPage: number, bounceRate: number, sessions: number, buttonData?: { __typename?: 'ButtonData', avgClicks?: number | null, avgHovers?: number | null } | null, formData?: { __typename?: 'FormData', avgClicks?: number | null, avgHovers?: number | null, submissionRate?: number | null, medianTimeSpent?: number | null } | null }> };

export type GoalsQueryVariables = Exact<{ [key: string]: never; }>;


export type GoalsQuery = { __typename?: 'Query', goals: Array<{ __typename?: 'Goal', id: number, name: string }> };

export type CreateTestTargetDevicesMutationVariables = Exact<{
  input: CreateTestTargetDevicesInput;
}>;


export type CreateTestTargetDevicesMutation = { __typename?: 'Mutation', createTestTargetDevices: { __typename: 'MultipleDbInsertSuccess', acknowledged: boolean, insertedIds: Array<number> } };

export type AdjustMaxTestCasesToBeginnerMutationVariables = Exact<{ [key: string]: never; }>;


export type AdjustMaxTestCasesToBeginnerMutation = { __typename?: 'Mutation', adjustMaxTestCasesToBeginner: { __typename?: 'SuccessResponse', success: boolean } };

export type CreateTestVariantMutationVariables = Exact<{
  input: CreateTestVariantInput;
}>;


export type CreateTestVariantMutation = { __typename?: 'Mutation', createTestVariant: { __typename: 'DbUuidInsertSuccess', acknowledged: boolean, insertedId: string } };

export type TestVariantQueryVariables = Exact<{
  testVariantId: Scalars['String'];
}>;


export type TestVariantQuery = { __typename?: 'Query', testVariant: { __typename?: 'TestVariantWithPageRedirectUrl', createdAt: string, cssCode?: string | null, description?: string | null, jsCode?: string | null, lastModified?: string | null, name: string, pageRedirectUrl?: string | null, trafficAllocation: number, variantNo: number } };

export type TestVariantsQueryVariables = Exact<{
  testId: Scalars['String'];
}>;


export type TestVariantsQuery = { __typename?: 'Query', testVariants: Array<{ __typename?: 'TestVariantsResponse', id: string, name: string, trafficAllocation: number, variantNo: number, hasRedirect: boolean, isControl: boolean }> };

export type DeleteTestVariantMutationVariables = Exact<{
  variantId: Scalars['String'];
}>;


export type DeleteTestVariantMutation = { __typename?: 'Mutation', deleteTestVariant: { __typename: 'SuccessResponse', success: boolean } };

export type AffiliatePayoutsEnabledQueryVariables = Exact<{ [key: string]: never; }>;


export type AffiliatePayoutsEnabledQuery = { __typename?: 'Query', affiliatePayoutsEnabled: boolean };

export type AuthenticateUserMutationVariables = Exact<{
  input: AuthenticateUserInput;
}>;


export type AuthenticateUserMutation = { __typename?: 'Mutation', authenticateUser: { __typename?: 'UserLoginResponse', id: string, token: string, userPlan: number, subscriptionStatusId: number, cardNearingExpiry: boolean } };

export type HasEnteredBankingDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type HasEnteredBankingDetailsQuery = { __typename?: 'Query', userHasEnteredBankingDetails: boolean };

export type GetBankDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBankDetailsQuery = { __typename?: 'Query', userBankDetails?: { __typename?: 'BankDetailsOutput', accountNumber?: string | null, bankName: string, country: string, firstName: string, iban?: string | null, lastName: string, sortCode?: string | null, swiftBic?: string | null } | null };

export type UpdateBankingDetailsMutationVariables = Exact<{
  updateUserBankDetailsInput: BankDetails;
}>;


export type UpdateBankingDetailsMutation = { __typename?: 'Mutation', updateUserBankDetails: { __typename?: 'DbUuidUpdateSuccess', acknowledged: boolean, updatedId: string } };

export type SaveBankingDetailsToDbMutationVariables = Exact<{
  saveUserBankDetailsInput: BankDetails;
}>;


export type SaveBankingDetailsToDbMutation = { __typename?: 'Mutation', saveUserBankDetails: { __typename?: 'DbUuidInsertSuccess', acknowledged: boolean, insertedId: string } };

export type ChangeUserEmailMutationVariables = Exact<{
  input: EmailInput;
}>;


export type ChangeUserEmailMutation = { __typename?: 'Mutation', changeUserEmail: { __typename?: 'SuccessResponse', success: boolean } };

export type CreateUserMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutation = { __typename?: 'Mutation', createUser: { __typename?: 'UserResponse', id: string, token: string, subscriptionStatusId: number, userPlan: number, cardNearingExpiry: boolean } };

export type DeleteUserMutationVariables = Exact<{ [key: string]: never; }>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteUser: { __typename?: 'SuccessResponse', success: boolean } };

export type GenerateAddInitialAffiliatePaymentDetailsUrlMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateAddInitialAffiliatePaymentDetailsUrlMutation = { __typename?: 'Mutation', addInitialAffiliatePaymentDetails: string };

export type HasEnteredPaymentDetailsQueryVariables = Exact<{ [key: string]: never; }>;


export type HasEnteredPaymentDetailsQuery = { __typename?: 'Query', hasEnteredPaymentDetails: boolean };

export type UpdateAvatarGoalMutationVariables = Exact<{
  input: CreateAvatarGoalInput;
}>;


export type UpdateAvatarGoalMutation = { __typename?: 'Mutation', updateAvatarGoal: { __typename?: 'SuccessResponse', success: boolean } };

export type UpdateAvatarBusinessMutationVariables = Exact<{
  input: CreateAvatarBusinessInput;
}>;


export type UpdateAvatarBusinessMutation = { __typename?: 'Mutation', updateAvatarBusiness: { __typename?: 'SuccessResponse', success: boolean } };

export type UpdateAvatarDemographicsMutationVariables = Exact<{
  input: CreateAvatarDemographicsInput;
}>;


export type UpdateAvatarDemographicsMutation = { __typename?: 'Mutation', updateAvatarDemographics: { __typename?: 'SuccessResponse', success: boolean } };

export type AvatarGoalQueryVariables = Exact<{ [key: string]: never; }>;


export type AvatarGoalQuery = { __typename?: 'Query', avatarGoal: { __typename?: 'AvatarGoalWithoutUserId', goalCategoryId: string, otherGoalCategory?: string | null, goalComment: string, improvements: string, useCase: string, siteConcerns: string } };

export type AvatarBusinessQueryVariables = Exact<{ [key: string]: never; }>;


export type AvatarBusinessQuery = { __typename?: 'Query', avatarBusiness: { __typename?: 'AvatarBusinessWithoutUserId', businessName: string, industryId: string, monthlyWebsiteTrafficId: string, numEmployees: number, otherIndustry?: string | null, otherPrimaryObjective?: string | null, primaryObjectiveId: string } };

export type AvatarDemographicsQueryVariables = Exact<{ [key: string]: never; }>;


export type AvatarDemographicsQuery = { __typename?: 'Query', avatarDemographics: { __typename?: 'AvatarDemographicsWithoutUserId', businessPosition: BusinessPosition, countryId: string, dob: string, educationLevelId: string, gender: Gender, jobCategoryId: string, nickname: string, otherJobCategory?: string | null, techSavvy: boolean } };

export type CreateAvatarGoalMutationVariables = Exact<{
  input: CreateAvatarGoalInput;
}>;


export type CreateAvatarGoalMutation = { __typename?: 'Mutation', createAvatarGoal: { __typename?: 'SuccessResponse', success: boolean } };

export type CreateAvatarDemographicsMutationVariables = Exact<{
  input: CreateAvatarDemographicsInput;
}>;


export type CreateAvatarDemographicsMutation = { __typename?: 'Mutation', createAvatarDemographics: { __typename?: 'SuccessResponse', success: boolean } };

export type CreateAvatarBusinessMutationVariables = Exact<{
  input: CreateAvatarBusinessInput;
}>;


export type CreateAvatarBusinessMutation = { __typename?: 'Mutation', createAvatarBusiness: { __typename?: 'SuccessResponse', success: boolean } };

export type CreateAvatarPreviousExperienceMutationVariables = Exact<{
  input: CreateAvatarPreviousExperienceInput;
}>;


export type CreateAvatarPreviousExperienceMutation = { __typename?: 'Mutation', createAvatarPreviousExperience: { __typename?: 'SuccessResponse', success: boolean } };

export type GetAvailableAvatarGoalsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAvailableAvatarGoalsQuery = { __typename?: 'Query', getAvailableAvatarGoals: Array<{ __typename?: 'AvailableAvatarGoal', id: string, goalName: string }> };

export type GetAvatarBusinessWebsiteObjectivesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAvatarBusinessWebsiteObjectivesQuery = { __typename?: 'Query', getAvatarBusinessWebsiteObjectives: Array<{ __typename?: 'SelectItem', id: string, name: string }> };

export type GetAvatarBusinessTrafficOptionsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAvatarBusinessTrafficOptionsQuery = { __typename?: 'Query', getAvatarBusinessTrafficOptions: Array<{ __typename?: 'SelectItem', id: string, name: string }> };

export type GetAvatarBusinessIndustriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAvatarBusinessIndustriesQuery = { __typename?: 'Query', getAvatarBusinessIndustries: Array<{ __typename?: 'SelectItem', id: string, name: string }> };

export type JobCategoriesQueryVariables = Exact<{ [key: string]: never; }>;


export type JobCategoriesQuery = { __typename?: 'Query', jobCategories: Array<{ __typename?: 'SelectItem', id: string, name: string }> };

export type CountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type CountriesQuery = { __typename?: 'Query', countries: Array<{ __typename?: 'SelectItem', id: string, name: string }> };

export type EducationLevelsQueryVariables = Exact<{ [key: string]: never; }>;


export type EducationLevelsQuery = { __typename?: 'Query', educationLevels: Array<{ __typename?: 'SelectItem', id: string, name: string }> };

export type CompetitorsQueryVariables = Exact<{ [key: string]: never; }>;


export type CompetitorsQuery = { __typename?: 'Query', competitors: Array<{ __typename?: 'SelectItem', id: string, name: string }> };

export type CheckUserPreviousExperienceExistsQueryVariables = Exact<{ [key: string]: never; }>;


export type CheckUserPreviousExperienceExistsQuery = { __typename?: 'Query', checkUserPreviousExperienceExists: boolean };

export type ResetPwMutationVariables = Exact<{
  input: ResetPasswordInput;
}>;


export type ResetPwMutation = { __typename?: 'Mutation', resetPassword: { __typename?: 'SuccessResponse', success: boolean } };

export type SendEmMutationVariables = Exact<{
  input: EmailInput;
}>;


export type SendEmMutation = { __typename?: 'Mutation', sendPasswordResetEmail: { __typename?: 'SuccessResponse', success: boolean } };

export type WebsitesQueryVariables = Exact<{
  limit?: InputMaybe<Scalars['Int']>;
  skip?: InputMaybe<Scalars['Int']>;
}>;


export type WebsitesQuery = { __typename?: 'Query', websites: { __typename?: 'GetWebsitesResponse', total: number, websites: Array<{ __typename?: 'Website', id: string, url: string, name: string, lastModified?: string | null }> } };

export type AllWebsitesForUserQueryVariables = Exact<{ [key: string]: never; }>;


export type AllWebsitesForUserQuery = { __typename?: 'Query', websites: { __typename?: 'GetWebsitesResponse', websites: Array<{ __typename?: 'Website', id: string, url: string, lastModified?: string | null }> } };

export type UpdateWebsiteMutationVariables = Exact<{
  input: UpdateWebsiteInput;
}>;


export type UpdateWebsiteMutation = { __typename?: 'Mutation', updateWebsite: { __typename: 'SuccessResponse', success: boolean } };

export type CreateWebsiteMutationVariables = Exact<{
  url: Scalars['String'];
  name: Scalars['String'];
}>;


export type CreateWebsiteMutation = { __typename?: 'Mutation', createWebsite: { __typename?: 'DbUuidInsertSuccess', acknowledged: boolean, insertedId: string } };

export type DeleteWebsiteMutationVariables = Exact<{
  id: Scalars['String'];
}>;


export type DeleteWebsiteMutation = { __typename?: 'Mutation', deleteWebsite: { __typename?: 'SuccessResponse', success: boolean } };


export const IsDirectAffiliateSignupDocument = gql`
    query isDirectAffiliateSignup {
  me {
    directAffiliateSignup
  }
}
    `;

export function useIsDirectAffiliateSignupQuery(options?: Omit<Urql.UseQueryArgs<IsDirectAffiliateSignupQueryVariables>, 'query'>) {
  return Urql.useQuery<IsDirectAffiliateSignupQuery, IsDirectAffiliateSignupQueryVariables>({ query: IsDirectAffiliateSignupDocument, ...options });
};
export const AffiliateBalancesDocument = gql`
    query affiliateBalances {
  affiliateBalancePending
  affiliateBalanceQualified
}
    `;

export function useAffiliateBalancesQuery(options?: Omit<Urql.UseQueryArgs<AffiliateBalancesQueryVariables>, 'query'>) {
  return Urql.useQuery<AffiliateBalancesQuery, AffiliateBalancesQueryVariables>({ query: AffiliateBalancesDocument, ...options });
};
export const GetReferralsDocument = gql`
    query getReferrals($input: PaginationInput!) {
  referrals(input: $input) {
    total
    users {
      dateCreated
      userPlan
      conversionDate
      totalMonthsPaid
      totalCommissionsValue
      id
    }
  }
}
    `;

export function useGetReferralsQuery(options: Omit<Urql.UseQueryArgs<GetReferralsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetReferralsQuery, GetReferralsQueryVariables>({ query: GetReferralsDocument, ...options });
};
export const AffiliatePerformanceMetricTotalsDocument = gql`
    query affiliatePerformanceMetricTotals {
  affiliatePerformanceMetrics {
    totals {
      freeReferrals
      payingReferrals
      totalReferrals
    }
  }
}
    `;

export function useAffiliatePerformanceMetricTotalsQuery(options?: Omit<Urql.UseQueryArgs<AffiliatePerformanceMetricTotalsQueryVariables>, 'query'>) {
  return Urql.useQuery<AffiliatePerformanceMetricTotalsQuery, AffiliatePerformanceMetricTotalsQueryVariables>({ query: AffiliatePerformanceMetricTotalsDocument, ...options });
};
export const AffiliatePerformanceMetricsDetailedDocument = gql`
    query affiliatePerformanceMetricsDetailed {
  affiliatePerformanceMetrics {
    free {
      freeLastWeek
      freeLastMonth
      freeLastQuarter
      freeLastYear
    }
    paying {
      payingLastWeek
      payingLastMonth
      payingLastQuarter
      payingLastYear
    }
  }
}
    `;

export function useAffiliatePerformanceMetricsDetailedQuery(options?: Omit<Urql.UseQueryArgs<AffiliatePerformanceMetricsDetailedQueryVariables>, 'query'>) {
  return Urql.useQuery<AffiliatePerformanceMetricsDetailedQuery, AffiliatePerformanceMetricsDetailedQueryVariables>({ query: AffiliatePerformanceMetricsDetailedDocument, ...options });
};
export const AffiliateSessionMetricsDocument = gql`
    query AffiliateSessionMetrics {
  affiliateSessionMetrics {
    lastMonth
    lastQuarter
    lastWeek
    lastYear
    total
  }
}
    `;

export function useAffiliateSessionMetricsQuery(options?: Omit<Urql.UseQueryArgs<AffiliateSessionMetricsQueryVariables>, 'query'>) {
  return Urql.useQuery<AffiliateSessionMetricsQuery, AffiliateSessionMetricsQueryVariables>({ query: AffiliateSessionMetricsDocument, ...options });
};
export const AffiliateSessionDocument = gql`
    mutation AffiliateSession($referrerId: String!) {
  createAffiliateSession(referrerId: $referrerId) {
    success
  }
}
    `;

export function useAffiliateSessionMutation() {
  return Urql.useMutation<AffiliateSessionMutation, AffiliateSessionMutationVariables>(AffiliateSessionDocument);
};
export const AffiliateSessionsDocument = gql`
    query AffiliateSessions($limit: Int, $skip: Int) {
  affiliateSessions(limit: $limit, skip: $skip) {
    sessions {
      dateTime
      id
    }
    total
  }
}
    `;

export function useAffiliateSessionsQuery(options?: Omit<Urql.UseQueryArgs<AffiliateSessionsQueryVariables>, 'query'>) {
  return Urql.useQuery<AffiliateSessionsQuery, AffiliateSessionsQueryVariables>({ query: AffiliateSessionsDocument, ...options });
};
export const GetAffiliateTransactionsDocument = gql`
    query getAffiliateTransactions($limit: Int, $skip: Int, $type: TransactionType) {
  affiliateTransactions(limit: $limit, skip: $skip, type: $type) {
    total
    transactions {
      dateTime
      amount
      id
      qualified
    }
  }
}
    `;

export function useGetAffiliateTransactionsQuery(options?: Omit<Urql.UseQueryArgs<GetAffiliateTransactionsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAffiliateTransactionsQuery, GetAffiliateTransactionsQueryVariables>({ query: GetAffiliateTransactionsDocument, ...options });
};
export const GetAffiliatePaymentsDocument = gql`
    query getAffiliatePayments($limit: Int, $skip: Int) {
  affiliateTransactions(limit: $limit, skip: $skip, type: PAYMENT) {
    total
    transactions {
      amount
      dateTime
      id
    }
  }
}
    `;

export function useGetAffiliatePaymentsQuery(options?: Omit<Urql.UseQueryArgs<GetAffiliatePaymentsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAffiliatePaymentsQuery, GetAffiliatePaymentsQueryVariables>({ query: GetAffiliatePaymentsDocument, ...options });
};
export const GetAffiliateCommissionsDocument = gql`
    query getAffiliateCommissions($limit: Int, $skip: Int) {
  affiliateTransactions(limit: $limit, skip: $skip, type: COMMISSION) {
    total
    transactions {
      amount
      dateTime
      id
      qualified
    }
  }
}
    `;

export function useGetAffiliateCommissionsQuery(options?: Omit<Urql.UseQueryArgs<GetAffiliateCommissionsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAffiliateCommissionsQuery, GetAffiliateCommissionsQueryVariables>({ query: GetAffiliateCommissionsDocument, ...options });
};
export const CreateUserFeedbackDocument = gql`
    mutation CreateUserFeedback($input: CreateUserFeedbackInput!) {
  createUserFeedback(input: $input) {
    success
  }
}
    `;

export function useCreateUserFeedbackMutation() {
  return Urql.useMutation<CreateUserFeedbackMutation, CreateUserFeedbackMutationVariables>(CreateUserFeedbackDocument);
};
export const CreateNewsletterSubscriberDocument = gql`
    mutation CreateNewsletterSubscriber($input: CreateNewsletterSubscriberInput!) {
  createNewsletterSubscriber(input: $input) {
    success
  }
}
    `;

export function useCreateNewsletterSubscriberMutation() {
  return Urql.useMutation<CreateNewsletterSubscriberMutation, CreateNewsletterSubscriberMutationVariables>(CreateNewsletterSubscriberDocument);
};
export const PagesDocument = gql`
    query pages($input: PagesPaginatedInput) {
  pages(input: $input) {
    total
    pages {
      id
      name
      url
      lastModified
    }
  }
}
    `;

export function usePagesQuery(options?: Omit<Urql.UseQueryArgs<PagesQueryVariables>, 'query'>) {
  return Urql.useQuery<PagesQuery, PagesQueryVariables>({ query: PagesDocument, ...options });
};
export const UpdatePageDocument = gql`
    mutation updatePage($input: UpdatePageInput!) {
  updatePage(input: $input) {
    success
    __typename
  }
}
    `;

export function useUpdatePageMutation() {
  return Urql.useMutation<UpdatePageMutation, UpdatePageMutationVariables>(UpdatePageDocument);
};
export const CreatePageDocument = gql`
    mutation createPage($url: String!, $name: String!, $websiteId: String!) {
  createPage(url: $url, name: $name, websiteId: $websiteId) {
    acknowledged
    insertedId
  }
}
    `;

export function useCreatePageMutation() {
  return Urql.useMutation<CreatePageMutation, CreatePageMutationVariables>(CreatePageDocument);
};
export const DeletePageDocument = gql`
    mutation deletePage($id: String!) {
  deletePage(id: $id) {
    success
  }
}
    `;

export function useDeletePageMutation() {
  return Urql.useMutation<DeletePageMutation, DeletePageMutationVariables>(DeletePageDocument);
};
export const ProcessCheckoutSessionDocument = gql`
    mutation processCheckoutSession($email: String!, $planId: String!) {
  processCheckoutSession(email: $email, planId: $planId)
}
    `;

export function useProcessCheckoutSessionMutation() {
  return Urql.useMutation<ProcessCheckoutSessionMutation, ProcessCheckoutSessionMutationVariables>(ProcessCheckoutSessionDocument);
};
export const ProcessCheckoutCompletedDocument = gql`
    mutation processCheckoutCompleted($userId: String!) {
  processCheckoutCompleted(userId: $userId) {
    success
  }
}
    `;

export function useProcessCheckoutCompletedMutation() {
  return Urql.useMutation<ProcessCheckoutCompletedMutation, ProcessCheckoutCompletedMutationVariables>(ProcessCheckoutCompletedDocument);
};
export const GetStripeCustomerIdDocument = gql`
    query getStripeCustomerId {
  me {
    stripeCustomerId
  }
}
    `;

export function useGetStripeCustomerIdQuery(options?: Omit<Urql.UseQueryArgs<GetStripeCustomerIdQueryVariables>, 'query'>) {
  return Urql.useQuery<GetStripeCustomerIdQuery, GetStripeCustomerIdQueryVariables>({ query: GetStripeCustomerIdDocument, ...options });
};
export const ProcessCardUpdateSessionDocument = gql`
    mutation processCardUpdateSession($customerId: String!) {
  processCardUpdateSession(customerId: $customerId)
}
    `;

export function useProcessCardUpdateSessionMutation() {
  return Urql.useMutation<ProcessCardUpdateSessionMutation, ProcessCardUpdateSessionMutationVariables>(ProcessCardUpdateSessionDocument);
};
export const CancelSubscriptionDocument = gql`
    mutation cancelSubscription {
  cancelSubscription {
    success
  }
}
    `;

export function useCancelSubscriptionMutation() {
  return Urql.useMutation<CancelSubscriptionMutation, CancelSubscriptionMutationVariables>(CancelSubscriptionDocument);
};
export const UpdateSubscriptionDocument = gql`
    mutation updateSubscription($userId: String!, $planId: String!) {
  updateSubscription(userId: $userId, planId: $planId) {
    success
  }
}
    `;

export function useUpdateSubscriptionMutation() {
  return Urql.useMutation<UpdateSubscriptionMutation, UpdateSubscriptionMutationVariables>(UpdateSubscriptionDocument);
};
export const CreateTestDocument = gql`
    mutation createTest($input: CreateTestInput!) {
  createTest(input: $input) {
    acknowledged
    insertedId
    __typename
  }
}
    `;

export function useCreateTestMutation() {
  return Urql.useMutation<CreateTestMutation, CreateTestMutationVariables>(CreateTestDocument);
};
export const TestsForWebsiteDocument = gql`
    query testsForWebsite($input: GetTestsForWebsiteInput) {
  tests(input: $input) {
    total
    tests {
      id
      name
      active
      startDate
    }
  }
}
    `;

export function useTestsForWebsiteQuery(options?: Omit<Urql.UseQueryArgs<TestsForWebsiteQueryVariables>, 'query'>) {
  return Urql.useQuery<TestsForWebsiteQuery, TestsForWebsiteQueryVariables>({ query: TestsForWebsiteDocument, ...options });
};
export const TestDocument = gql`
    query test($testId: String!) {
  test(id: $testId) {
    name
    description
    active
    createdAt
    startDate
    endDate
    lastModified
    goalName
    maxCases
    pageUrl
    websiteUrl
    targetDeviceNames
    testSessionsCount
    variantRedirectUrls
  }
}
    `;

export function useTestQuery(options: Omit<Urql.UseQueryArgs<TestQueryVariables>, 'query'>) {
  return Urql.useQuery<TestQuery, TestQueryVariables>({ query: TestDocument, ...options });
};
export const RecentTestsDocument = gql`
    query recentTests($input: GetTestsForUserInput) {
  testsForUser(input: $input) {
    tests {
      id
      name
    }
  }
}
    `;

export function useRecentTestsQuery(options?: Omit<Urql.UseQueryArgs<RecentTestsQueryVariables>, 'query'>) {
  return Urql.useQuery<RecentTestsQuery, RecentTestsQueryVariables>({ query: RecentTestsDocument, ...options });
};
export const MakeTestInactiveDocument = gql`
    mutation makeTestInactive($testId: String!) {
  makeTestInactive(id: $testId) {
    success
  }
}
    `;

export function useMakeTestInactiveMutation() {
  return Urql.useMutation<MakeTestInactiveMutation, MakeTestInactiveMutationVariables>(MakeTestInactiveDocument);
};
export const ActivateTestDocument = gql`
    mutation activateTest($testId: String!) {
  activateTest(id: $testId) {
    success
  }
}
    `;

export function useActivateTestMutation() {
  return Urql.useMutation<ActivateTestMutation, ActivateTestMutationVariables>(ActivateTestDocument);
};
export const DeleteTestDocument = gql`
    mutation deleteTest($testId: String!) {
  deleteTest(id: $testId) {
    success
  }
}
    `;

export function useDeleteTestMutation() {
  return Urql.useMutation<DeleteTestMutation, DeleteTestMutationVariables>(DeleteTestDocument);
};
export const DeleteTestDataForTestDocument = gql`
    mutation deleteTestDataForTest($testId: String!) {
  deleteTestDataForTest(id: $testId) {
    success
  }
}
    `;

export function useDeleteTestDataForTestMutation() {
  return Urql.useMutation<DeleteTestDataForTestMutation, DeleteTestDataForTestMutationVariables>(DeleteTestDataForTestDocument);
};
export const TestPerformanceDataDocument = gql`
    query TestPerformanceData($testId: String!) {
  testPerformanceData(testId: $testId) {
    variantNo
    variantId
    avgClicks
    avgFormSubmits
    avgScrolledDownPercentage
    medianTimeOnPage
    bounceRate
    sessions
    buttonData {
      avgClicks
      avgHovers
    }
    formData {
      avgClicks
      avgHovers
      submissionRate
      medianTimeSpent
    }
  }
}
    `;

export function useTestPerformanceDataQuery(options: Omit<Urql.UseQueryArgs<TestPerformanceDataQueryVariables>, 'query'>) {
  return Urql.useQuery<TestPerformanceDataQuery, TestPerformanceDataQueryVariables>({ query: TestPerformanceDataDocument, ...options });
};
export const GoalsDocument = gql`
    query goals {
  goals {
    id
    name
  }
}
    `;

export function useGoalsQuery(options?: Omit<Urql.UseQueryArgs<GoalsQueryVariables>, 'query'>) {
  return Urql.useQuery<GoalsQuery, GoalsQueryVariables>({ query: GoalsDocument, ...options });
};
export const CreateTestTargetDevicesDocument = gql`
    mutation createTestTargetDevices($input: CreateTestTargetDevicesInput!) {
  createTestTargetDevices(input: $input) {
    acknowledged
    insertedIds
    __typename
  }
}
    `;

export function useCreateTestTargetDevicesMutation() {
  return Urql.useMutation<CreateTestTargetDevicesMutation, CreateTestTargetDevicesMutationVariables>(CreateTestTargetDevicesDocument);
};
export const AdjustMaxTestCasesToBeginnerDocument = gql`
    mutation adjustMaxTestCasesToBeginner {
  adjustMaxTestCasesToBeginner {
    success
  }
}
    `;

export function useAdjustMaxTestCasesToBeginnerMutation() {
  return Urql.useMutation<AdjustMaxTestCasesToBeginnerMutation, AdjustMaxTestCasesToBeginnerMutationVariables>(AdjustMaxTestCasesToBeginnerDocument);
};
export const CreateTestVariantDocument = gql`
    mutation createTestVariant($input: CreateTestVariantInput!) {
  createTestVariant(input: $input) {
    acknowledged
    insertedId
    __typename
  }
}
    `;

export function useCreateTestVariantMutation() {
  return Urql.useMutation<CreateTestVariantMutation, CreateTestVariantMutationVariables>(CreateTestVariantDocument);
};
export const TestVariantDocument = gql`
    query testVariant($testVariantId: String!) {
  testVariant(id: $testVariantId) {
    createdAt
    cssCode
    description
    jsCode
    lastModified
    name
    pageRedirectUrl
    trafficAllocation
    variantNo
  }
}
    `;

export function useTestVariantQuery(options: Omit<Urql.UseQueryArgs<TestVariantQueryVariables>, 'query'>) {
  return Urql.useQuery<TestVariantQuery, TestVariantQueryVariables>({ query: TestVariantDocument, ...options });
};
export const TestVariantsDocument = gql`
    query testVariants($testId: String!) {
  testVariants(id: $testId) {
    id
    name
    trafficAllocation
    variantNo
    hasRedirect
    isControl
  }
}
    `;

export function useTestVariantsQuery(options: Omit<Urql.UseQueryArgs<TestVariantsQueryVariables>, 'query'>) {
  return Urql.useQuery<TestVariantsQuery, TestVariantsQueryVariables>({ query: TestVariantsDocument, ...options });
};
export const DeleteTestVariantDocument = gql`
    mutation deleteTestVariant($variantId: String!) {
  deleteTestVariant(id: $variantId) {
    success
    __typename
  }
}
    `;

export function useDeleteTestVariantMutation() {
  return Urql.useMutation<DeleteTestVariantMutation, DeleteTestVariantMutationVariables>(DeleteTestVariantDocument);
};
export const AffiliatePayoutsEnabledDocument = gql`
    query AffiliatePayoutsEnabled {
  affiliatePayoutsEnabled
}
    `;

export function useAffiliatePayoutsEnabledQuery(options?: Omit<Urql.UseQueryArgs<AffiliatePayoutsEnabledQueryVariables>, 'query'>) {
  return Urql.useQuery<AffiliatePayoutsEnabledQuery, AffiliatePayoutsEnabledQueryVariables>({ query: AffiliatePayoutsEnabledDocument, ...options });
};
export const AuthenticateUserDocument = gql`
    mutation authenticateUser($input: AuthenticateUserInput!) {
  authenticateUser(input: $input) {
    id
    token
    userPlan
    subscriptionStatusId
    cardNearingExpiry
  }
}
    `;

export function useAuthenticateUserMutation() {
  return Urql.useMutation<AuthenticateUserMutation, AuthenticateUserMutationVariables>(AuthenticateUserDocument);
};
export const HasEnteredBankingDetailsDocument = gql`
    query HasEnteredBankingDetails {
  userHasEnteredBankingDetails
}
    `;

export function useHasEnteredBankingDetailsQuery(options?: Omit<Urql.UseQueryArgs<HasEnteredBankingDetailsQueryVariables>, 'query'>) {
  return Urql.useQuery<HasEnteredBankingDetailsQuery, HasEnteredBankingDetailsQueryVariables>({ query: HasEnteredBankingDetailsDocument, ...options });
};
export const GetBankDetailsDocument = gql`
    query GetBankDetails {
  userBankDetails {
    accountNumber
    bankName
    country
    firstName
    iban
    lastName
    sortCode
    swiftBic
  }
}
    `;

export function useGetBankDetailsQuery(options?: Omit<Urql.UseQueryArgs<GetBankDetailsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetBankDetailsQuery, GetBankDetailsQueryVariables>({ query: GetBankDetailsDocument, ...options });
};
export const UpdateBankingDetailsDocument = gql`
    mutation UpdateBankingDetails($updateUserBankDetailsInput: BankDetails!) {
  updateUserBankDetails(input: $updateUserBankDetailsInput) {
    acknowledged
    updatedId
  }
}
    `;

export function useUpdateBankingDetailsMutation() {
  return Urql.useMutation<UpdateBankingDetailsMutation, UpdateBankingDetailsMutationVariables>(UpdateBankingDetailsDocument);
};
export const SaveBankingDetailsToDbDocument = gql`
    mutation SaveBankingDetailsToDb($saveUserBankDetailsInput: BankDetails!) {
  saveUserBankDetails(input: $saveUserBankDetailsInput) {
    acknowledged
    insertedId
  }
}
    `;

export function useSaveBankingDetailsToDbMutation() {
  return Urql.useMutation<SaveBankingDetailsToDbMutation, SaveBankingDetailsToDbMutationVariables>(SaveBankingDetailsToDbDocument);
};
export const ChangeUserEmailDocument = gql`
    mutation changeUserEmail($input: EmailInput!) {
  changeUserEmail(input: $input) {
    success
  }
}
    `;

export function useChangeUserEmailMutation() {
  return Urql.useMutation<ChangeUserEmailMutation, ChangeUserEmailMutationVariables>(ChangeUserEmailDocument);
};
export const CreateUserDocument = gql`
    mutation createUser($input: CreateUserInput!) {
  createUser(input: $input) {
    id
    token
    subscriptionStatusId
    userPlan
    cardNearingExpiry
  }
}
    `;

export function useCreateUserMutation() {
  return Urql.useMutation<CreateUserMutation, CreateUserMutationVariables>(CreateUserDocument);
};
export const DeleteUserDocument = gql`
    mutation deleteUser {
  deleteUser {
    success
  }
}
    `;

export function useDeleteUserMutation() {
  return Urql.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument);
};
export const GenerateAddInitialAffiliatePaymentDetailsUrlDocument = gql`
    mutation GenerateAddInitialAffiliatePaymentDetailsUrl {
  addInitialAffiliatePaymentDetails
}
    `;

export function useGenerateAddInitialAffiliatePaymentDetailsUrlMutation() {
  return Urql.useMutation<GenerateAddInitialAffiliatePaymentDetailsUrlMutation, GenerateAddInitialAffiliatePaymentDetailsUrlMutationVariables>(GenerateAddInitialAffiliatePaymentDetailsUrlDocument);
};
export const HasEnteredPaymentDetailsDocument = gql`
    query HasEnteredPaymentDetails {
  hasEnteredPaymentDetails
}
    `;

export function useHasEnteredPaymentDetailsQuery(options?: Omit<Urql.UseQueryArgs<HasEnteredPaymentDetailsQueryVariables>, 'query'>) {
  return Urql.useQuery<HasEnteredPaymentDetailsQuery, HasEnteredPaymentDetailsQueryVariables>({ query: HasEnteredPaymentDetailsDocument, ...options });
};
export const UpdateAvatarGoalDocument = gql`
    mutation UpdateAvatarGoal($input: CreateAvatarGoalInput!) {
  updateAvatarGoal(input: $input) {
    success
  }
}
    `;

export function useUpdateAvatarGoalMutation() {
  return Urql.useMutation<UpdateAvatarGoalMutation, UpdateAvatarGoalMutationVariables>(UpdateAvatarGoalDocument);
};
export const UpdateAvatarBusinessDocument = gql`
    mutation UpdateAvatarBusiness($input: CreateAvatarBusinessInput!) {
  updateAvatarBusiness(input: $input) {
    success
  }
}
    `;

export function useUpdateAvatarBusinessMutation() {
  return Urql.useMutation<UpdateAvatarBusinessMutation, UpdateAvatarBusinessMutationVariables>(UpdateAvatarBusinessDocument);
};
export const UpdateAvatarDemographicsDocument = gql`
    mutation UpdateAvatarDemographics($input: CreateAvatarDemographicsInput!) {
  updateAvatarDemographics(input: $input) {
    success
  }
}
    `;

export function useUpdateAvatarDemographicsMutation() {
  return Urql.useMutation<UpdateAvatarDemographicsMutation, UpdateAvatarDemographicsMutationVariables>(UpdateAvatarDemographicsDocument);
};
export const AvatarGoalDocument = gql`
    query AvatarGoal {
  avatarGoal {
    goalCategoryId
    otherGoalCategory
    goalComment
    improvements
    useCase
    siteConcerns
  }
}
    `;

export function useAvatarGoalQuery(options?: Omit<Urql.UseQueryArgs<AvatarGoalQueryVariables>, 'query'>) {
  return Urql.useQuery<AvatarGoalQuery, AvatarGoalQueryVariables>({ query: AvatarGoalDocument, ...options });
};
export const AvatarBusinessDocument = gql`
    query AvatarBusiness {
  avatarBusiness {
    businessName
    industryId
    monthlyWebsiteTrafficId
    numEmployees
    otherIndustry
    otherPrimaryObjective
    primaryObjectiveId
  }
}
    `;

export function useAvatarBusinessQuery(options?: Omit<Urql.UseQueryArgs<AvatarBusinessQueryVariables>, 'query'>) {
  return Urql.useQuery<AvatarBusinessQuery, AvatarBusinessQueryVariables>({ query: AvatarBusinessDocument, ...options });
};
export const AvatarDemographicsDocument = gql`
    query AvatarDemographics {
  avatarDemographics {
    businessPosition
    countryId
    dob
    educationLevelId
    gender
    jobCategoryId
    nickname
    otherJobCategory
    techSavvy
  }
}
    `;

export function useAvatarDemographicsQuery(options?: Omit<Urql.UseQueryArgs<AvatarDemographicsQueryVariables>, 'query'>) {
  return Urql.useQuery<AvatarDemographicsQuery, AvatarDemographicsQueryVariables>({ query: AvatarDemographicsDocument, ...options });
};
export const CreateAvatarGoalDocument = gql`
    mutation CreateAvatarGoal($input: CreateAvatarGoalInput!) {
  createAvatarGoal(input: $input) {
    success
  }
}
    `;

export function useCreateAvatarGoalMutation() {
  return Urql.useMutation<CreateAvatarGoalMutation, CreateAvatarGoalMutationVariables>(CreateAvatarGoalDocument);
};
export const CreateAvatarDemographicsDocument = gql`
    mutation CreateAvatarDemographics($input: CreateAvatarDemographicsInput!) {
  createAvatarDemographics(input: $input) {
    success
  }
}
    `;

export function useCreateAvatarDemographicsMutation() {
  return Urql.useMutation<CreateAvatarDemographicsMutation, CreateAvatarDemographicsMutationVariables>(CreateAvatarDemographicsDocument);
};
export const CreateAvatarBusinessDocument = gql`
    mutation CreateAvatarBusiness($input: CreateAvatarBusinessInput!) {
  createAvatarBusiness(input: $input) {
    success
  }
}
    `;

export function useCreateAvatarBusinessMutation() {
  return Urql.useMutation<CreateAvatarBusinessMutation, CreateAvatarBusinessMutationVariables>(CreateAvatarBusinessDocument);
};
export const CreateAvatarPreviousExperienceDocument = gql`
    mutation CreateAvatarPreviousExperience($input: CreateAvatarPreviousExperienceInput!) {
  createAvatarPreviousExperience(input: $input) {
    success
  }
}
    `;

export function useCreateAvatarPreviousExperienceMutation() {
  return Urql.useMutation<CreateAvatarPreviousExperienceMutation, CreateAvatarPreviousExperienceMutationVariables>(CreateAvatarPreviousExperienceDocument);
};
export const GetAvailableAvatarGoalsDocument = gql`
    query GetAvailableAvatarGoals {
  getAvailableAvatarGoals {
    id
    goalName
  }
}
    `;

export function useGetAvailableAvatarGoalsQuery(options?: Omit<Urql.UseQueryArgs<GetAvailableAvatarGoalsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAvailableAvatarGoalsQuery, GetAvailableAvatarGoalsQueryVariables>({ query: GetAvailableAvatarGoalsDocument, ...options });
};
export const GetAvatarBusinessWebsiteObjectivesDocument = gql`
    query GetAvatarBusinessWebsiteObjectives {
  getAvatarBusinessWebsiteObjectives {
    id
    name
  }
}
    `;

export function useGetAvatarBusinessWebsiteObjectivesQuery(options?: Omit<Urql.UseQueryArgs<GetAvatarBusinessWebsiteObjectivesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAvatarBusinessWebsiteObjectivesQuery, GetAvatarBusinessWebsiteObjectivesQueryVariables>({ query: GetAvatarBusinessWebsiteObjectivesDocument, ...options });
};
export const GetAvatarBusinessTrafficOptionsDocument = gql`
    query GetAvatarBusinessTrafficOptions {
  getAvatarBusinessTrafficOptions {
    id
    name
  }
}
    `;

export function useGetAvatarBusinessTrafficOptionsQuery(options?: Omit<Urql.UseQueryArgs<GetAvatarBusinessTrafficOptionsQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAvatarBusinessTrafficOptionsQuery, GetAvatarBusinessTrafficOptionsQueryVariables>({ query: GetAvatarBusinessTrafficOptionsDocument, ...options });
};
export const GetAvatarBusinessIndustriesDocument = gql`
    query GetAvatarBusinessIndustries {
  getAvatarBusinessIndustries {
    id
    name
  }
}
    `;

export function useGetAvatarBusinessIndustriesQuery(options?: Omit<Urql.UseQueryArgs<GetAvatarBusinessIndustriesQueryVariables>, 'query'>) {
  return Urql.useQuery<GetAvatarBusinessIndustriesQuery, GetAvatarBusinessIndustriesQueryVariables>({ query: GetAvatarBusinessIndustriesDocument, ...options });
};
export const JobCategoriesDocument = gql`
    query JobCategories {
  jobCategories {
    id
    name
  }
}
    `;

export function useJobCategoriesQuery(options?: Omit<Urql.UseQueryArgs<JobCategoriesQueryVariables>, 'query'>) {
  return Urql.useQuery<JobCategoriesQuery, JobCategoriesQueryVariables>({ query: JobCategoriesDocument, ...options });
};
export const CountriesDocument = gql`
    query Countries {
  countries {
    id
    name
  }
}
    `;

export function useCountriesQuery(options?: Omit<Urql.UseQueryArgs<CountriesQueryVariables>, 'query'>) {
  return Urql.useQuery<CountriesQuery, CountriesQueryVariables>({ query: CountriesDocument, ...options });
};
export const EducationLevelsDocument = gql`
    query EducationLevels {
  educationLevels {
    id
    name
  }
}
    `;

export function useEducationLevelsQuery(options?: Omit<Urql.UseQueryArgs<EducationLevelsQueryVariables>, 'query'>) {
  return Urql.useQuery<EducationLevelsQuery, EducationLevelsQueryVariables>({ query: EducationLevelsDocument, ...options });
};
export const CompetitorsDocument = gql`
    query Competitors {
  competitors {
    id
    name
  }
}
    `;

export function useCompetitorsQuery(options?: Omit<Urql.UseQueryArgs<CompetitorsQueryVariables>, 'query'>) {
  return Urql.useQuery<CompetitorsQuery, CompetitorsQueryVariables>({ query: CompetitorsDocument, ...options });
};
export const CheckUserPreviousExperienceExistsDocument = gql`
    query CheckUserPreviousExperienceExists {
  checkUserPreviousExperienceExists
}
    `;

export function useCheckUserPreviousExperienceExistsQuery(options?: Omit<Urql.UseQueryArgs<CheckUserPreviousExperienceExistsQueryVariables>, 'query'>) {
  return Urql.useQuery<CheckUserPreviousExperienceExistsQuery, CheckUserPreviousExperienceExistsQueryVariables>({ query: CheckUserPreviousExperienceExistsDocument, ...options });
};
export const ResetPwDocument = gql`
    mutation resetPw($input: ResetPasswordInput!) {
  resetPassword(input: $input) {
    success
  }
}
    `;

export function useResetPwMutation() {
  return Urql.useMutation<ResetPwMutation, ResetPwMutationVariables>(ResetPwDocument);
};
export const SendEmDocument = gql`
    mutation sendEm($input: EmailInput!) {
  sendPasswordResetEmail(input: $input) {
    success
  }
}
    `;

export function useSendEmMutation() {
  return Urql.useMutation<SendEmMutation, SendEmMutationVariables>(SendEmDocument);
};
export const WebsitesDocument = gql`
    query websites($limit: Int, $skip: Int) {
  websites(limit: $limit, skip: $skip) {
    websites {
      id
      url
      name
      lastModified
    }
    total
  }
}
    `;

export function useWebsitesQuery(options?: Omit<Urql.UseQueryArgs<WebsitesQueryVariables>, 'query'>) {
  return Urql.useQuery<WebsitesQuery, WebsitesQueryVariables>({ query: WebsitesDocument, ...options });
};
export const AllWebsitesForUserDocument = gql`
    query allWebsitesForUser {
  websites(limit: 99999, skip: 0) {
    websites {
      id
      url
      lastModified
    }
  }
}
    `;

export function useAllWebsitesForUserQuery(options?: Omit<Urql.UseQueryArgs<AllWebsitesForUserQueryVariables>, 'query'>) {
  return Urql.useQuery<AllWebsitesForUserQuery, AllWebsitesForUserQueryVariables>({ query: AllWebsitesForUserDocument, ...options });
};
export const UpdateWebsiteDocument = gql`
    mutation updateWebsite($input: UpdateWebsiteInput!) {
  updateWebsite(input: $input) {
    success
    __typename
  }
}
    `;

export function useUpdateWebsiteMutation() {
  return Urql.useMutation<UpdateWebsiteMutation, UpdateWebsiteMutationVariables>(UpdateWebsiteDocument);
};
export const CreateWebsiteDocument = gql`
    mutation createWebsite($url: String!, $name: String!) {
  createWebsite(url: $url, name: $name) {
    acknowledged
    insertedId
  }
}
    `;

export function useCreateWebsiteMutation() {
  return Urql.useMutation<CreateWebsiteMutation, CreateWebsiteMutationVariables>(CreateWebsiteDocument);
};
export const DeleteWebsiteDocument = gql`
    mutation deleteWebsite($id: String!) {
  deleteWebsite(id: $id) {
    success
  }
}
    `;

export function useDeleteWebsiteMutation() {
  return Urql.useMutation<DeleteWebsiteMutation, DeleteWebsiteMutationVariables>(DeleteWebsiteDocument);
};