import { useEffect } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';

const useVariantsValidation = () => {
    const { trigger } = useFormContext();
    const variantValues = useWatch({
        name: 'variants', // watches all... variants[0].description would watch that only, for example,
    });

    useEffect(() => {
        trigger('variants');
    }, [variantValues, trigger]);
};

export default useVariantsValidation;
