import React from 'react';
import { Section } from '../../default/Areas/Areas';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContentsProps } from './types';
import styles from './styles.module.scss';

const Contents = ({ title, sections }: ContentsProps): JSX.Element => (
    <Section patterned={true}>
        {title && <h1>{title}</h1>}
        <h2>Contents:</h2>
        <ul className={styles.outerList}>
            {sections.map((section, index) => (
                <li key={index}>
                    <a href={`#${section.id}`}>
                        <FontAwesomeIcon
                            icon={faAngleRight}
                            aria-label='right arrow'
                            className={styles.icon}
                        />{' '}
                        <span className={styles.label}>{section.label}</span>
                    </a>
                    {section.subsections && section.subsections.length > 0 && (
                        <ul>
                            {section.subsections.map((subsection, subIndex) => (
                                <li key={subIndex}>
                                    <a href={`#${subsection.id}`}>
                                        <FontAwesomeIcon
                                            icon={faAngleRight}
                                            aria-label='right arrow'
                                            className={styles.icon}
                                        />{' '}
                                        <span className={styles.label}>{subsection.label}</span>
                                    </a>
                                </li>
                            ))}
                        </ul>
                    )}
                </li>
            ))}
        </ul>
    </Section>
);

export default Contents;
