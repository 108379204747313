import React, { useEffect, useRef, useState } from 'react';
import { withPage } from '../../../components/layout/Page/Page';
import { landingPageTemplateMeta } from '../../misc/MetaTags';
import styles from './styles.module.scss';
import LaptopImage from './laptop-landing-page-code.webp';
import { SignupModal } from '../../../components/default/SignupFormModal/SignupFormModal';

const props = {
    image: LaptopImage,
    imageAlt: 'A/B Test Easy Wins Guide',
};

const LandingPageTemplate = () => {
    const [modalIsOpen, setIsOpen] = useState<boolean>(false);
    const containerRef = useRef<HTMLDivElement>(null);
    const imageRef = useRef<HTMLImageElement>(null);

    useEffect(() => {
        const timer = setTimeout(() => {
            document.body.classList.add(styles.loaded, 'hewwo');
        }, 1000);

        if (imageRef.current) {
            imageRef.current.classList.add(styles.visible);
        }

        if (containerRef.current) {
            containerRef.current.classList.add(styles.visible);
        }

        return () => {
            clearTimeout(timer);
            document.body.classList.remove(styles.loaded);
        };
    }, []);

    return (
        <>
            <div className={styles['container-outer']}>
                <div
                    ref={containerRef}
                    className={`${styles['text']} ${styles['slide-in']} ${styles['left']}`}
                >
                    <h1>
                        <span className={styles['emphasised-text']}>Landing Page</span> Code
                        Template
                    </h1>
                    <p>Create a simple landing page with ease.</p>
                    <button onClick={() => setIsOpen(true)}>Download</button>
                </div>
                <img
                    ref={imageRef}
                    className={`${styles['slide-in']} ${styles['right']}`}
                    src={props.image}
                    alt={props.imageAlt}
                />
            </div>
            <SignupModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
        </>
    );
};

export default withPage(LandingPageTemplate)({
    meta: landingPageTemplateMeta,
    fullWidth: true,
    clsPage: `${styles['landing-page-custom']} ${styles['specificity']}`,
    clsOuter: styles['outer'],
    clsInner: styles['inner'],
    showHeader: false,
    showFooter: false,
});
