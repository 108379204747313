import { Links } from '../Header/types';

export const footerLinks: Links = [
    {
        title: 'Contact',
        url: '/contact',
    },
    {
        title: 'Support',
        url: '/support',
    },
    {
        title: 'Affiliates',
        url: '/affiliates',
    },
    {
        title: 'Partnerships',
        url: '/partnerships',
    },
    {
        title: 'Free Guide',
        url: '/quickwins',
    },
    {
        title: 'Blog',
        url: '/blog',
    },
    {
        title: 'Terms',
        url: '/terms',
    },
    {
        title: 'Privacy',
        url: '/privacy',
    },
    {
        title: 'Cookies',
        url: '/cookie-policy',
    },
];
