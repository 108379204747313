import { useContext, useEffect, useMemo } from 'react';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { usePagesQuery } from '../../../graphql/generated/schema';
import { RefinedPage, UseGetPages } from './types';
import { AccountContext } from '../../../contexts/AccountContext/AccountContext';
import { AccountContextType } from '../../../contexts/AccountContext/types';

// keep as .tsx as it needs the icon (in JSX)
export const useGetPages: UseGetPages = (options) => {
    const { selectedWebsite } = useContext<AccountContextType>(AccountContext);
    const { limit = 10, skip = 0 } = options || {};
    const [{ data, fetching: loading, error }, fetchPages] = usePagesQuery({
        pause: true,
        variables: {
            input: {
                websiteId: selectedWebsite?.id || '',
                limit,
                skip,
            },
        },
    });
    const refinedPages = useMemo<RefinedPage[] | undefined>(
        () =>
            data?.pages?.pages.map(({ id, name, url }) => ({
                id,
                name,
                url,
                icon: <FontAwesomeIcon icon={faPen} aria-label='edit' />,
            })),
        [data]
    );

    useEffect(() => {
        if (selectedWebsite) {
            fetchPages();
        }
    }, [selectedWebsite, fetchPages]);

    return {
        pageCount: data?.pages?.total || 0,
        pages: refinedPages,
        loading,
        error: selectedWebsite ? error : false,
        refetchPages: fetchPages,
    };
};
