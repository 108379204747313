import { useAffiliateSessionMetricsQuery } from '../../../graphql/generated/schema';
import { UseAffiliateSessionMetrics } from './types';

export const useAffiliateSessionMetrics: UseAffiliateSessionMetrics = () => {
    const [{ data, fetching: loading, error }] = useAffiliateSessionMetricsQuery();
    const { total, lastWeek, lastMonth, lastQuarter, lastYear } =
        data?.affiliateSessionMetrics || {};
    const hasData: boolean = [total, lastWeek, lastMonth, lastQuarter, lastYear].every(
        (item: number | undefined): boolean => Boolean(item)
    );

    return {
        hasData,
        total: total || 0,
        lastWeek: lastWeek || 0,
        lastMonth: lastMonth || 0,
        lastQuarter: lastQuarter || 0,
        lastYear: lastYear || 0,
        loading,
        error,
    };
};
