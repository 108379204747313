export const BLOG_CONTENTS = [
    {
        id: 'overview',
        label: 'Overview',
    },
    {
        id: 'form-improvement-ideas',
        label: 'Testable Form Improvement Ideas',
    },
    {
        id: 'common-form-mistakes',
        label: 'Common Form Mistakes',
    },
    {
        id: 'why-test-forms',
        label: 'Why You Should Test Your Forms',
    },
    {
        id: 'form-ab-test-creation',
        label: 'How to Create a Form A/B Test',
    },
    {
        id: 'conclusion',
        label: 'Conclusion',
    },
];
