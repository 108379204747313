import React from 'react';
import { Link } from 'react-router-dom';
import {
    AffiliateBalanceFigures,
    // AffiliateBalanceTable,
} from '../../../../../components/account/affiliates/AffiliateBalance/AffiliateBalance';
import { withPage } from '../../../../../components/layout/Page/Page';
import { MINIMUM_QUALIFICATION_TIME_PLURAL } from '../../../../../utils/global-variables';
import { affiliateBalanceMeta } from '../../../../misc/MetaTags';
import defaultStyles from '../../../../../css/default.module.scss';
import { Back } from '../../../../../components/default/Navigation/Navigation';

// this is wasting time
export const AffiliateBalance = () => (
    <>
        <h1 className={defaultStyles.centralisedText}>Balance</h1>
        <h2>Current Balance</h2>
        <p>
            Your qualified balance includes commissions that are at least{' '}
            {MINIMUM_QUALIFICATION_TIME_PLURAL} old while your pending balance includes commissions
            that have not yet surpassed the minimum qualification time (
            <Link to='/affiliates/faq'>learn more</Link>).
        </p>
        <AffiliateBalanceFigures />
        {/* <h2>History</h2>
        <p>
            View <em>qualified</em> affiliate commissions credited to your qualified balance and payments made to you.
        </p>
        <p>
            @todo: change the below table to QUALIFIED only - move PENDING to a separate table. The below table won't
            need a qualified column.
        </p>
        <AffiliateBalanceTable />
        <p>
            Note: you may also have <em>pending</em> commissions that may soon automatically become qualified and added
            to your qualified balance - <Link to='/'>view pending commissions</Link>.
        </p>
        <p>@todo: correct 'view pending commissions' link</p>
        <p>@todo: add pending commissions table on separate page, without a balance column</p>
        <p>
            @todo: consider adding if statements - e.g. only show pending commissions link if pending commissions exist,
            and/or show the qualified commissions table if qualified/paid commissions exist, OR might be better to just
            show a message like 'no qualified commissions exist yet' or similar.
        </p> */}
        <Back to='/user/profile/affiliate' text='Back to Affiliate Profile' />
    </>
);

export default withPage(AffiliateBalance)({ meta: affiliateBalanceMeta, innerSpacing: true });
