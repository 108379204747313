import { useState } from 'react';
import { useCreatePageMutation, useCreateWebsiteMutation } from '../../../graphql/generated/schema';
import { CreateWebsiteFn, UseCreateWebsite } from './types';
import trimTrailingSlash from '../../../utils/HelperFunctions/trimTrailingSlash';

export const useCreateWebsite: UseCreateWebsite = () => {
    const [error, setError] = useState<string | boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [, makeWebsite] = useCreateWebsiteMutation();
    const [, makePage] = useCreatePageMutation();

    const createWebsite: CreateWebsiteFn = async ({ name, url }) => {
        const cleanedUrl = trimTrailingSlash(url);

        try {
            setLoading(true);
            const { data: websiteData, error: websiteError } = await makeWebsite({
                name,
                url: cleanedUrl,
            });

            const websiteId = websiteData?.createWebsite.insertedId;

            const { data: pageData, error: pageError } = await makePage({
                name: 'Homepage',
                url: cleanedUrl,
                websiteId: websiteId as string,
            });

            if (websiteError || !websiteData || pageError || !pageData) {
                const errorText =
                    websiteError?.message || pageError?.message || 'Could not create website';
                setSuccess(false);
                setError(`HTTP error: ${errorText}`);
                return;
            }

            setError(false);
            setSuccess(true);
        } catch (err: any) {
            setSuccess(false);
            setError(err.message);
        } finally {
            setLoading(false);
        }
    };

    return { loading, error, createWebsite, success };
};
