import { useMemo } from 'react';
import { useGetAffiliatePaymentsQuery } from '../../../graphql/generated/schema';
import { refineAffiliatePayments } from '../helpers/helpers';
import { UseAffiliatePayments } from './types';
import { RefinedAffiliatePayments } from '../helpers/types';

export const useAffiliatePayments: UseAffiliatePayments = (options) => {
    const [limit, skip] = [options?.limit || 0, options?.skip || 0];
    const [{ data, fetching: loading, error }] = useGetAffiliatePaymentsQuery({
        variables: {
            limit,
            skip,
        },
    });
    const refinedData = useMemo<RefinedAffiliatePayments | undefined>(
        () => refineAffiliatePayments(data?.affiliateTransactions?.transactions),
        [data]
    );

    return {
        paymentsCount: data?.affiliateTransactions?.total || 0,
        payments: refinedData,
        loading,
        error,
    };
};
