export const BLOG_CONTENTS = [
    {
        id: 'overview',
        label: 'Overview',
    },
    {
        id: 'benefits',
        label: 'Benefits of Multivariate Tests',
    },
    {
        id: 'good-test-elements',
        label: 'Key Elements of an Effective Multivariate Test',
    },
    {
        id: 'common-multivariate-test-mistakes',
        label: 'Common Multivariate Test Mistakes',
    },
    {
        id: 'creating-a-multivariate-test',
        label: 'How to Create a Multivariate Test',
    },
    {
        id: 'conclusion',
        label: 'Conclusion',
    },
];
