export const SERVICE_CARDS = [
    {
        title: 'New Starter Package',
        description:
            'A value bundle for those who are serious about A/B testing and want a hand getting started.',
        price: { dollars: 699, cents: 50 },
        button: {
            buttonLink: '/contact',
            buttonText: 'Get in Touch',
        },
        benefits: [
            'Custom Site Conversion Audit',
            'Account Setup',
            'Tracking Script Installation',
            'Pro User Account (for 3 months)',
            'First Test Setup',
        ],
        monthly: false,
    },
    {
        title: 'Custom Test Development',
        description: 'For those who want customised technical tests.',
        price: 'POA' as 'POA',
        button: {
            buttonLink: '/contact',
            buttonText: 'Get in Touch',
        },
        benefits: [
            'We can create custom A/B tests for you',
            'Ranges from simple heading changes to amending or creating complex pages or elements for you',
            'Get in touch and tell us your requirements',
        ],
        monthly: false,
    },
];
