import React from 'react';
import styles from './styles.module.scss';

const RotatingDollar = () => (
    <div className={styles.container}>
        <div className={styles.inner}>$</div>
    </div>
);

export default RotatingDollar;
