import * as Yup from 'yup';

const emptyOrWithinLengthRange = (min: number, max: number, value?: string | null): boolean => {
    // If value is null or an empty string, it's considered valid
    if (!value || !value?.length) {
        return true;
    }
    // Check if value length is within the specified range
    return value.length >= min && value.length <= max;
};

export const affiliateBankingDetailsFormSchema = Yup.object().shape({
    firstName: Yup.string().required('Enter your first name'),
    lastName: Yup.string().required('Enter your last name'),
    bankName: Yup.string().required("Enter your bank's name"),
    country: Yup.string().required('Enter your country'),
    accountNumber: Yup.string()
        .test('is-empty-or-length', 'Must be exactly 8 digits', (value) =>
            emptyOrWithinLengthRange(8, 8, value)
        )
        .nullable(),
    sortCode: Yup.string()
        .test('is-empty-or-length', 'Must be exactly 6 digits', (value) =>
            emptyOrWithinLengthRange(6, 6, value)
        )
        .nullable(),
    iban: Yup.string()
        .test('is-empty-or-length', 'Must be 5-34 digits', (value) =>
            emptyOrWithinLengthRange(5, 34, value)
        )
        .nullable(),
    swiftBic: Yup.string()
        .test('is-empty-or-length', 'Must be 8-11 digits', (value) =>
            emptyOrWithinLengthRange(8, 11, value)
        )
        .nullable(),
});
