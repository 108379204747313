import React, { useContext, useMemo } from 'react';
import { useParams, Params } from 'react-router-dom';
import { DEFAULT_ITEMS_PER_PAGE } from '../../../../utils/global-variables';
import { Paginator } from '../../../default/Paginator/Paginator';
import { Loading, Error, NoneFound } from '../../../default/States/States';
import { DynamicTable } from '../../../default/Table/Table';
import { useGetTests } from '../../../../hooks/tests/useGetTests/useGetTests';
import { AccountContext } from '../../../../contexts/AccountContext/AccountContext';
import { AccountContextType } from '../../../../contexts/AccountContext/types';
// import { useNavigate } from 'react-router-dom';
// import styles from './styles.module.scss';

export const TestListTable = () => {
    const itemsPerPage: number = DEFAULT_ITEMS_PER_PAGE;
    const params = useParams<Params<string>>();
    const currentPage: number = Number(params.currentPage) || 1;
    const skip: number = (currentPage - 1) * itemsPerPage;
    const { testCount, tests, loading, error } = useGetTests({
        limit: itemsPerPage,
        skip,
    });
    const headers: string[] = useMemo<string[]>(() => ['name', 'active', 'start date', 'view'], []);
    const { selectedWebsite } = useContext<AccountContextType>(AccountContext);

    // if want to make entire row clickable, use this code and pass onClick to DynamicTable:
    // const navigate = useNavigate();
    // const onClick = React.useCallback<(e: any) => void>(
    //     (e) => {
    //         navigate(`/user/tests/${e.id}`);
    //     },
    //     [navigate]
    // );
    // note that if the user clicks the button then they will need to click back twice to get back again
    // current way is more accessible as the user can access the button using a keyboard if necessary

    if (loading) return <Loading />;
    if (error) return <Error msg={error.message} />;
    if (!tests?.length)
        return (
            <NoneFound
                msg={`No tests found for website ${selectedWebsite?.url}`}
                centralised={true}
            />
        );

    return (
        <>
            <DynamicTable data={tests} headers={headers} />
            {/* <DynamicTable data={tests} headers={headers} onClick={onClick} clsOuter={styles.table} /> */}
            <Paginator
                currentPage={currentPage}
                itemCount={testCount}
                itemsPerPage={itemsPerPage}
                path='/user/tests'
            />
        </>
    );
};

export default TestListTable;
