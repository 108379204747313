import React from 'react';
import { useGetBankDetailsQuery } from '../../../../graphql/generated/schema';
import { Loading, Error } from '../../../default/States/States';
import { faUniversity } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from './styles.module.scss';
import { AffiliateBankingDetailsModal } from '../AffiliateBankingDetailsForm/AffiliateBankingDetailsForm';

const AffiliatePaymentDetails = () => {
    const [
        { data: fetchedBankDetails, fetching: fetchingBankDetails, error },
        refetchBankingDetails,
    ] = useGetBankDetailsQuery();

    if (fetchingBankDetails) return <Loading />;
    if (error) return <Error msg={error.message} />;

    const bankDetails = fetchedBankDetails?.userBankDetails;

    const sortedData = bankDetails
        ? [
              {
                  title: 'Bank',
                  value: bankDetails.bankName,
              },
              {
                  title: 'Full name',
                  value: `${bankDetails.firstName} ${bankDetails.lastName}`,
              },
              {
                  title: 'Account Number',
                  value: bankDetails.accountNumber,
              },
              {
                  title: 'Sort Code',
                  value: bankDetails.sortCode,
              },
              {
                  title: 'IBAN',
                  value: bankDetails.iban,
              },
              {
                  title: 'SWIFTBIC',
                  value: bankDetails.swiftBic,
              },
              {
                  title: 'Country',
                  value: bankDetails.country,
              },
          ]
        : null;

    return (
        <div className={styles.container}>
            <h2 className={styles.title}>
                <FontAwesomeIcon
                    icon={faUniversity}
                    aria-label='banking'
                    className={styles.bankIcon}
                />{' '}
                Your Payment Information
            </h2>
            {bankDetails ? (
                <ul>
                    {sortedData?.map((item, index) => (
                        <li key={index}>
                            <strong>{item.title}:</strong> {item.value}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>You haven't entered any payment details yet.</p>
            )}

            <AffiliateBankingDetailsModal
                fetchedBankDetails={bankDetails}
                fetchingBankDetails={fetchingBankDetails}
                refetchBankingDetails={refetchBankingDetails}
            />
        </div>
    );
};

export default AffiliatePaymentDetails;
