export const BLOG_CONTENTS = [
    {
        id: 'overview',
        label: 'Overview',
    },
    {
        id: 'ecommerce-ab-test-ideas',
        label: 'Ecommerce A/B Test Ideas',
    },
    {
        id: 'common-product-page-mistakes',
        label: 'Common Product Page Mistakes',
    },
    {
        id: 'why-ab-test-ecommerce-pages',
        label: 'The Consequences of Not Testing your Product Pages',
    },
    {
        id: 'creating-product-page-ab-test',
        label: 'How to A/B Test your Product Pages',
    },
    {
        id: 'conclusion',
        label: 'Conclusion',
    },
];
