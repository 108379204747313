import React, { useContext, useEffect } from 'react';
import defaultStyles from '../../../../../css/default.module.scss';
import { withPage } from '../../../../../components/layout/Page/Page';
import { createTestMeta } from '../../../../misc/MetaTags';
import { FormProvider, useForm } from 'react-hook-form';
import { CreateTestFormInputs } from './types';
import { yupResolver } from '@hookform/resolvers/yup';
import { createTestSchema } from '../../../../../schemas/TestSchemas';
import { PagesQuery, useGoalsQuery, usePagesQuery } from '../../../../../graphql/generated/schema';
import PageError from '../../../../other/errors/PageError.tsx/PageError';
import { AccountContext } from '../../../../../contexts/AccountContext/AccountContext';
import { AccountContextType } from '../../../../../contexts/AccountContext/types';
import { useSelectWebsites } from '../../../../../hooks/pages/useSelectWebsites/useSelectWebsites';
import { ErrorText, Loading } from '../../../../../components/default/States/States';
import { WebsiteId } from '../../../../../utils/global-types';
import { NextStage } from '../../../../../components/account/CreateTest/Shared/Shared';
import { useCreateTestStage } from '../../../../../hooks/tests/useCreateTestStage/useCreateTestStage';
import { Back } from '../../../../../components/default/Navigation/Navigation';
import { InfoAreaWrap } from '../../../../../components/default/Cards/Cards';
import { FINAL_STAGE } from '../../../../../hooks/tests/useCreateTestStage/variables';
import { User, UserContextType } from '../../../../../contexts/UserContext/types';
import { UserContext } from '../../../../../contexts/UserContext/UserContext';
import {
    getMaxTestSessions,
    getPlanValue,
} from '../../../../../utils/HelperFunctions/HelperFunctions';
import CreateTestStageOne from '../../../../../components/account/CreateTest/StageOne/CreateTestStageOne';
import CreateTestStageFour from '../../../../../components/account/CreateTest/StageFour/CreateTestStageFour';
import CreateTestStageThree from '../../../../../components/account/CreateTest/StageThree/CreateTestStageThree';
import CreateTestStageTwo from '../../../../../components/account/CreateTest/StageTwo/CreateTestStageTwo';
import usePreventPageRefresh from '../../../../../hooks/tests/usePreventPageRefresh/usePreventPageRefresh';

const CreateTest = () => {
    const { selectedWebsite, websites } = useContext<AccountContextType>(AccountContext);
    const {
        loading: loadingWebsites,
        error: websiteError,
        handleWebsiteChange,
    } = useSelectWebsites();
    const { user } = useContext<UserContextType>(UserContext);
    const planValue = getPlanValue(user?.userPlan);
    const maxSessions = getMaxTestSessions(planValue);
    const methods = useForm<CreateTestFormInputs>({
        resolver: yupResolver(createTestSchema(user as User)),
        mode: 'onTouched',
        defaultValues: {
            name: '',
            description: '',
            goalId: '',
            websiteId: selectedWebsite?.id || '',
            pageId: '',
            variants: [
                {
                    name: 'Control',
                    description: 'The original unchanged page',
                    trafficAllocation: 0,
                    isControl: true,
                },
            ],
            maxSessions,
            targeting: ['mobiles', 'desktops', 'tablets'],
        },
    });
    const [{ data: goalData, fetching: loadingGoals, error: goalsError }] = useGoalsQuery();
    const [{ data: pagesData, fetching: loadingPages, error: pagesError }, fetchPages] =
        usePagesQuery({
            pause: true,
            variables: {
                input: {
                    websiteId: selectedWebsite?.id as WebsiteId,
                },
            },
        });
    const {
        currentStage,
        currentStageComplete,
        proceed,
        showPrevStep,
        title,
        loadingSubmission,
        submissionError,
    } = useCreateTestStage(methods);

    useEffect(() => {
        if (pagesData?.pages?.total === undefined) return;

        const selectedWebsiteHasPages = pagesData.pages.total > 0;

        if (selectedWebsite && !selectedWebsiteHasPages) {
            methods.setError('pageId', {
                message: `No pages found for website ${selectedWebsite.url}. You must create a page before you can proceed`,
            });
            return;
        }

        methods.clearErrors('pageId');
    }, [pagesData, selectedWebsite, methods]);

    useEffect(() => {
        if (selectedWebsite) {
            methods.resetField('pageId');
            methods.resetField('variants');
            methods.setValue('websiteId', selectedWebsite?.id);
            fetchPages();
        }
    }, [selectedWebsite, fetchPages, methods]);

    usePreventPageRefresh();

    if (loadingWebsites || loadingGoals) return <Loading entirePage={true} />;

    if (goalsError || pagesError || websiteError) {
        const msg = goalsError?.message || pagesError?.message || websiteError;
        return <PageError msg={msg} />;
    }

    if (!websites?.length) {
        return (
            <PageError
                title='You Cannot Create a Test Yet'
                msg="You haven't created a website yet. In order to create a test, you must create a website."
                customBtnPrimary={{
                    text: 'Go To Websites',
                    url: '/user/websites',
                }}
            />
        );
    }

    return (
        <>
            <h1 className={defaultStyles.centralisedText}>Create Test</h1>
            <FormProvider {...methods}>
                <InfoAreaWrap>
                    <form>
                        <div className={defaultStyles.formInner}>
                            <h2>{title}</h2>
                            {currentStage === 1 && (
                                <CreateTestStageOne
                                    handleWebsiteChange={handleWebsiteChange}
                                    goalData={goalData}
                                    loadingGoals={loadingGoals}
                                    pagesData={pagesData}
                                    loadingPages={loadingPages}
                                />
                            )}
                            {currentStage === 2 && (
                                <CreateTestStageTwo pagesData={pagesData as PagesQuery} />
                            )}
                            {currentStage === 3 && <CreateTestStageThree />}
                            {currentStage === FINAL_STAGE && <CreateTestStageFour />}
                            <NextStage
                                disabled={!currentStageComplete}
                                onClick={proceed}
                                type='button'
                                loading={loadingSubmission}
                            />
                            {currentStage > 1 && <Back onClick={showPrevStep} />}
                            {submissionError && (
                                <ErrorText msg={submissionError} marginTop={true} />
                            )}
                        </div>
                    </form>
                </InfoAreaWrap>
            </FormProvider>
            <Back to='/user/tests' text='Back to Tests' />
        </>
    );
};

export default withPage(CreateTest)({ meta: createTestMeta, innerSpacing: true });
