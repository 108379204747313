import React from 'react';
import { withPage } from '../../../components/layout/Page/Page';
import { scriptVerificationMeta } from '../../misc/MetaTags';
import sitewideStyles from '../../../css/sitewide.module.scss';
import { Section } from '../../../components/default/Areas/Areas';
import { Link } from 'react-router-dom';
import { Back } from '../../../components/default/Navigation/Navigation';

const ScriptVerification = () => (
    <>
        <Intro />
        <JsConsoleInstructions />
        <PreviewingAVariant />
        <AnyIssues />
    </>
);

const Intro = () => (
    <Section patterned={true}>
        <h1>Verifying you've installed your script correctly</h1>
        <p>
            You can check whether your script has been installed successfully by using the
            JavaScript console or previewing a variant.
        </p>
    </Section>
);

const AnyIssues = () => (
    <Section patterned={true}>
        <h2>Any Issues?</h2>
        <p>
            If you encounter any issues or need further assistance, feel free to{' '}
            <Link to='/contact'>reach out</Link> to us.
        </p>
        <Back to='/support' text='Support Hub' />
    </Section>
);

const PreviewingAVariant = () => (
    <Section patterned={true}>
        <h2>Previewing a Variant</h2>
        <p>
            Another way to confirm your tracking script's installation is to preview a variant
            whenever you create a new test, since this will only work if the tracking script has
            been installed successfully. Check that it displays how it's supposed to display, which
            should be different than the original (control).
        </p>
    </Section>
);

const JsConsoleInstructions = () => (
    <Section patterned={true}>
        <h2>Checking the JavaScript Console</h2>
        <p>
            To check if the A/B testing software tracking has started successfully, follow these
            steps:
        </p>
        <ol className={sitewideStyles.spaced}>
            <li>
                Open your website in Google Chrome, Mozilla Firefox, or any modern web browser. Some
                browsers (e.g. Safari) may need you to enable developer tools (e.g. open Safari,
                select Preferences, Advanced and click show developer tools or similar). If this has
                changed, just ask AI.
            </li>
            <li>
                Right-click anywhere on the page and select "Inspect" or "Inspect Element" from the
                context menu. Alternatively, you can use the keyboard shortcut:
            </li>
            <ul>
                <li>Google Chrome: Ctrl + Shift + J (or Cmd + Option + J on Mac)</li>
                <li>Mozilla Firefox: Ctrl + Shift + K (or Cmd + Option + K on Mac)</li>
            </ul>
            <li>
                This will open the Developer Tools. In the Developer Tools window, find the
                "Console" tab and click on it to switch to the console view.
            </li>
            <li>
                Now, you should see the JavaScript console. It may contain some messages or errors
                depending on your website's content and scripts.
            </li>
            <li>
                To verify whether the tracking script is running, look for a message stating that
                our tracking script has started successfully - it should show the following:
                <br />
                <br />
                <code>CRO SIMPLE: A/B testing tracking script loaded successfully.</code>
            </li>
            <li>
                If you see the above message in the console, it means the tracking script is
                installed and running.
            </li>
        </ol>
    </Section>
);

export default withPage(ScriptVerification)({ meta: scriptVerificationMeta, fullWidth: true });
