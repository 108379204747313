export const FORM_TEST_CREATION_CONTENTS = [
    {
        id: 'overview',
        label: 'Overview',
    },
    {
        id: 'create-test-page',
        label: 'Steps',
        subsections: [
            {
                id: 'create-test-page',
                label: 'Step 1: Create a Page for Testing',
            },
            {
                id: 'initial-test-details',
                label: 'Step 2: Enter Initial Test Details',
            },
            {
                id: 'tracking-approach',
                label: 'Step 3: Choose Tracking Approach',
            },
            {
                id: 'variant-approach',
                label: 'Step 4: Create Variants',
            },
            {
                id: 'traffic-allocation',
                label: 'Step 5: Set Traffic Allocation',
            },
            {
                id: 'targeting',
                label: 'Step 6: Set Targeting Options',
            },
        ],
    },
    {
        id: 'completion',
        label: "That's Everything",
    },
    { id: 'ideas', label: 'Form Testing Ideas' },
];
