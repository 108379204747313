import React from 'react';
import { withPage } from '../../../components/layout/Page/Page';
import { termsMeta } from '../../misc/MetaTags';
import { Section } from '../../../components/default/Areas/Areas';
import { Link } from 'react-router-dom';

const Terms = () => (
    <>
        <Section patterned={true}>
            <h1>Our Terms &amp; Conditions of Service</h1>
            <h2>Acceptance</h2>
            <p>
                Thank you for your interest in our services! By accessing and using our platform,
                you agree to comply with these terms and conditions. If you do not agree with any
                part of these terms, please refrain from using our services. Your use of our
                platform signifies your acceptance of these terms.
            </p>
            <p>
                When you utilize our service, whether presently or in the future, you are consenting
                to adhere to the most current Terms of Service. This agreement applies to all our
                present and forthcoming products, as well as any new features incorporated into our
                service over time. There may be occasions when we choose not to exercise or enforce
                a specific right or provision within the Terms of Service, and such a decision does
                not constitute a waiver of that right or provision. It's important to note that
                these terms include provisions limiting our liability.
            </p>
            <p>Please be aware that we may make updates to our terms at a later date.</p>
            <p>
                Please also read our <Link to='/privacy'>privacy</Link> and{' '}
                <Link to='/cookie-policy'>cookie</Link> policies. By using our services, you are
                also agreeing to these policies.
            </p>
        </Section>

        <Section patterned={true}>
            <h2>Accounts</h2>
            <p>
                It is your responsibility to ensure the security of your account and password. CRO
                Simple cannot and will not be held accountable for any losses or damages resulting
                from your failure to meet these security requirements.
            </p>
            <p>
                You are accountable for all activities conducted under your account, even if they
                are performed by individuals with their own login credentials within your account.
            </p>
            <p>
                Our service may not be used for any unlawful purposes or to violate any laws
                applicable in your jurisdiction.
            </p>
            <p>
                To complete the signup process, you are required to furnish a valid email address.
            </p>
            <p>
                Only human users are permitted to register accounts; accounts created by bots or
                other automated means are not allowed
            </p>
        </Section>

        <Section patterned={true}>
            <h2>Payments, upgrades, downgrades &amp; refunds</h2>
            <h3>Payments</h3>
            <h4>Payment Failures:</h4>
            <p>
                If a payment for your subscription fails, we will make automatic re-attempts to
                collect overdue payments. If we are still unable to successfully collect payment,
                your subscription will be cancelled, and your account status will be reset to the
                free plan, which may disrupt any tests or data on your account.
            </p>
            <h4>Chargebacks and Disputes:</h4>
            <p>
                In the event of a chargeback or dispute being opened, your subscription will be
                automatically cancelled.
            </p>
            <h4>Billing Cycle Changes:</h4>
            <p>
                You can upgrade, downgrade, or cancel your plan at any time. When you make changes
                to your subscription, your payments will be adjusted to the specified payments of
                the new plan. Please note that the first month's payment may vary based on your
                current billing cycle, as it will be prorated.
            </p>

            <h3>Refunds</h3>
            <h4>30-Day Refund Policy:</h4>
            <p>
                You are entitled to a full refund within 30 days of your initial payment for any
                reason, such as if you are unsatisfied with our product. Simply inform us, and we
                will process the refund and cancel your subscription.
            </p>
            <h4>Free Trial:</h4>
            <p>
                For our free trial, we provide clear information about the trial's duration when you
                sign up. We do not require your credit card information for the trial, and, just
                like our paying customers, we do not sell your data. Once the trial period ends, you
                must make an advance payment to continue using our services. If you choose to
                upgrade from a free trial to a paid plan, we will charge you immediately, and your
                billing cycle begins on the day of the upgrade.
            </p>

            <h3>Upgrades &amp; downgrades</h3>
            <h4>Transition from Free Trial:</h4>
            <p>
                When upgrading from a free trial to a paid plan, you will be charged immediately,
                and your billing cycle starts on the day of the upgrade.
            </p>
            <h4>Feature Loss with Downgrades:</h4>
            <p>
                Please be aware that downgrading your plan may result in the loss of certain
                features or account capacity. CRO Simple does not accept any liability for such
                loss.
            </p>
        </Section>

        <Section patterned={true}>
            <h2>Cancellations and terminations</h2>
            {/* <h3>Account Cancellation:</h3> */}
            <p>
                You are solely responsible for properly canceling your account. The cancellation
                process can be easily initiated within the app by following the provided
                instructions.
            </p>

            {/* <h3>Immediate Cancellation:</h3> */}
            <p>
                If you choose to cancel the service before the conclusion of your current paid-up
                period, your cancellation may take effect immediately, and you will not incur any
                further charges. Please be aware that after cancellation, all of your data may
                become inaccessible from the service, and there is a possibility that the data may
                be permanently deleted. Once data has been permanently deleted, it cannot be
                recovered.
            </p>

            {/* <h3>Account Deletion:</h3> */}
            <p>
                You have the option to delete your account and all associated data at any time. When
                you opt to delete your CRO Simple account, all of your data may be permanently and
                immediately removed.
            </p>

            {/* <h3>Service Termination by CRO Simple:</h3> */}
            <p>
                CRO Simple reserves the right to suspend or terminate your account and deny any
                current or future use of the service at our discretion, for any reason, and at any
                time. Such termination may result in the deactivation or deletion of your account,
                as well as the loss of access to your account and statistical data.
            </p>

            {/* <h3>Right to Refuse Service:</h3> */}
            <p>
                We retain the right to refuse service to any individual or entity for any reason, at
                any time. This clause is included as a precaution, as among the numerous users of
                our service, there may be instances of individuals engaging in inappropriate
                activities.
            </p>

            {/* <h3>Prohibited Conduct:</h3> */}
            <p>
                Engaging in verbal, physical, written, or other forms of abuse, including threats of
                abuse or retribution, against any service customer, company employee, or officer may
                result in immediate account termination.
            </p>
        </Section>

        <Section patterned={true}>
            <h2>Service and price changes</h2>
            {/* <h3>Service Modification:</h3> */}
            <p>
                CRO Simple reserves the right, at its sole discretion, at any time and without prior
                notice, to modify, discontinue, temporarily or permanently, any part of the service.
                This includes, but is not limited to, features, functionality, or any aspect of the
                service.
            </p>

            {/* <h3>Pricing Structure Alteration:</h3> */}
            <p>
                Periodically, CRO Simple may make changes to the pricing structure for its products
                and services. This may also entail modifications to the prices applicable to
                existing customers. In the event of such changes, we are committed to providing at
                least 30 days' notice to affected customers, and will offer an option for customers
                to cancel if they do not like the new changes. Notification of such changes will be
                delivered to the email address on record associated with your account.
            </p>

            {/* <h3>Limitation of Liability:</h3> */}
            <p>
                CRO Simple shall not be liable to you or any third party for any modification, price
                change, suspension, or discontinuance of the service. You understand and acknowledge
                that CRO Simple retains the discretion to make such changes as outlined in this
                section, and this shall not result in any liability on the part of CRO Simple to you
                or any other party.
            </p>
        </Section>

        <Section patterned={true}>
            <h2>General</h2>
            {/* <h3>Service Availability:</h3> */}
            <p>
                Your use of CRO Simple is at your sole risk. The service is provided on an "as is"
                and "as available" basis. While we prioritize maintaining the uptime of our
                application, like any website, we cannot guarantee 100% uptime.
            </p>

            {/* <h3>Service Design and Satisfaction:</h3> */}
            <p>
                We design our services with care, drawing from our own experience and the feedback
                of our customers. However, it is important to note that no service can cater to the
                needs and expectations of every individual. We do not make any guarantees that our
                services will meet your specific requirements or expectations.
            </p>

            {/* <h3>Software Testing and Bugs:</h3> */}
            <p>
                We conduct extensive testing of all our features before deploying them. Nonetheless,
                like any software, our services may contain occasional bugs. We diligently track and
                address reported bugs, prioritizing those related to security and privacy. It is
                important to understand that not all reported bugs will be fixed, and we do not
                guarantee completely error-free services.
            </p>

            {/* <h3>Technical Support:</h3> */}
            <p>
                Technical support is available via email. We make reasonable efforts to respond to
                emails but do not provide a guaranteed response time.
            </p>

            {/* <h3>Data Access and Security:</h3> */}
            <p>
                As a part of providing support, we, as humans, may need access to your data to
                assist you with support requests. We also access and maintain CRO Simple to ensure
                the security of your data and the service as a whole.
            </p>

            {/* <h3>Third-Party Vendors:</h3> */}
            <p>To deliver our services, we may engage the services of third-party vendors.</p>
        </Section>

        <Section patterned={true}>
            <h2>Liability</h2>
            <p>
                You expressly understand and agree that CRO Simple shall not be liable, in law or in
                equity, to you or to any third party for any direct, indirect, incidental, lost
                profits, special, consequential, punitive or exemplary damages, including, but not
                limited to, damages for loss of profits, goodwill, use, data or other intangible
                losses (even if the company has been advised of the possibility of such damages),
                resulting from: (i) the use or the inability to use the services; (ii) the cost of
                procurement of substitute goods and services resulting from any goods, data,
                information or services purchased or obtained or messages received or transactions
                entered into through or from the services; (iii) unauthorized access to or
                alteration of your transmissions or data; (iv) statements or conduct of any third
                party on the service; (v) or any other matter relating to this Terms of Service or
                the services, whether as a breach of contract, tort (including negligence whether
                active or passive), or any other theory of liability.
            </p>
        </Section>

        <Section patterned={true}>
            <h2>Contact</h2>
            <p>
                Feel free to <Link to='/contact'>get in touch</Link> if you have any questions about
                our terms and conditions.
            </p>
            <p>Last updated: November 4, 2023</p>
        </Section>
    </>
);

export default withPage(Terms)({ meta: termsMeta, fullWidth: true });
