// import PlaceholderImage from './images/placeholder.png';
import AbHeadlinesImage from './images/ab-headlines.webp';
import AbTestingMultipleVariantsImage from './AbTestingMultipleVariants/peacock-1.webp';
import AbTestingToolFreeImage from './AbTestingToolFree/ab-testing-tool.webp';
import AbTestingFormImageOne from './ImproveFormConversions/form-ab-test-1.webp';
import ProductPageImage from './images/modern-optimized-product-page-small.webp';
import WooCommerceImage from './WordPressAndWooCommerceAbTesting/wordpress-ab-test-small.webp';

export const articles = [
    {
        title: 'A/B Testing Headlines',
        description: 'Unlocking the Power of A/B Testing Headlines: A Comprehensive Guide.',
        image: {
            src: AbHeadlinesImage,
            alt: 'AB Headlines',
        },
        link: '/blog/ab-testing-headlines',
    },
    {
        title: 'A/B Testing Multiple Variants',
        description:
            'Discover how A/B testing multiple variants can unlock deeper insights, faster optimizations, and significant improvements in your digital strategies.',
        image: {
            src: AbTestingMultipleVariantsImage,
            alt: 'Peacock variations',
        },
        link: '/blog/ab-testing-multiple-variants',
    },
    {
        title: 'Free A/B Testing Tool for Websites',
        description: `The CRO Simple A/B testing platform can be used for free to make simple A/B tests for websites. It's based on simplicity and ease of use to all.`,
        image: {
            src: AbTestingToolFreeImage,
            alt: 'Testing Tool',
        },
        link: '/blog/free-ab-testing-tool',
    },
    {
        title: 'Improve Form Conversion Rates with A/B Testing',
        description: `Discover simple changes you can make to your forms to increase conversion rates, then test which changes perform best with A/B testing to boost submissions and reduce abandonment.`,
        image: {
            src: AbTestingFormImageOne,
            alt: 'Form A/B Test',
        },
        link: '/blog/improve-form-conversions-with-ab-testing',
    },
    {
        title: 'Product Page Conversion Rate Optimization Ideas for A/B Testing',
        description: `Discover simple changes you can make to your product pages to improve conversion rates, then test which changes perform best with A/B testing to increase sales.`,
        image: {
            src: ProductPageImage,
            alt: 'Product Page',
        },
        link: '/blog/improve-product-page-conversions-with-ab-testing',
    },
    {
        title: 'Pluginless WordPress and WooCommerce A/B Testing',
        description: `Ditch the plugin and just use a simple A/B testing platform to create tests for your WordPress with WooCommerce store.`,
        image: {
            src: WooCommerceImage,
            alt: 'WordPress A/B Test',
        },
        link: '/blog/wordpress-and-woocommerce-pluginless-ab-testing',
    },
];
