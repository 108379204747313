import React, { useContext } from 'react';
import { withPage } from '../../../../components/layout/Page/Page';
import { userPagesMeta } from '../../../misc/MetaTags';
import defaultStyles from '../../../../css/default.module.scss';
import { AccountContext } from '../../../../contexts/AccountContext/AccountContext';
import { AccountContextType } from '../../../../contexts/AccountContext/types';
import PagesTableWithModal from '../../../../components/account/Pages/PagesTableWithModal/PagesTableWithModal';
import { CreatePageModal } from '../../../../components/account/Pages/CreatePageModal/CreatePageModal';
import { useParams, Params } from 'react-router-dom';
import { useGetPages } from '../../../../hooks/pages/useGetPages/useGetPages';
import { DEFAULT_ITEMS_PER_PAGE } from '../../../../utils/global-variables';
import { SelectWebsiteDropdownForm } from '../../../../components/account/Pages/SelectWebsiteDropdown/SelectWebsiteDropdown';
import PageError from '../../../other/errors/PageError.tsx/PageError';
import { useSelectWebsites } from '../../../../hooks/pages/useSelectWebsites/useSelectWebsites';
import { Loading } from '../../../../components/default/States/States';
import { PagesContentProps } from './types';
import { Back } from '../../../../components/default/Navigation/Navigation';

const Pages = () => {
    const { selectedWebsite, websites } = useContext<AccountContextType>(AccountContext); // @todo: investigate if this is causing children to re-render too often
    const {
        loading: loadingWebsites,
        error: websiteError,
        handleWebsiteChange,
    } = useSelectWebsites();
    const itemsPerPage: number = DEFAULT_ITEMS_PER_PAGE;
    const params = useParams<Params<string>>();
    const currentPage: number = Number(params.currentPage) || 1;
    const skip: number = (currentPage - 1) * itemsPerPage;
    const {
        pageCount,
        pages,
        loading: loadingPages,
        error: pagesError,
        refetchPages,
    } = useGetPages({
        limit: itemsPerPage,
        skip,
    });

    if (loadingWebsites || loadingPages) return <Loading entirePage={true} />;

    if (websiteError || pagesError) {
        return <PageError msg={websiteError || pagesError?.message} />;
    }

    if (!websites?.length) {
        return (
            <PageError
                title="You Haven't Created a Website Yet"
                msg='In order to create pages, you must create a website.'
                customBtnPrimary={{
                    text: 'Go To Websites',
                    url: '/user/websites',
                }}
            />
        );
    }

    return (
        <>
            <h1 className={defaultStyles.centralisedText}>Pages</h1>
            <SelectWebsiteDropdownForm handleWebsiteChange={handleWebsiteChange} />
            {selectedWebsite && (
                <PagesContent
                    pageCount={pageCount}
                    pages={pages}
                    refetchPages={refetchPages}
                    itemsPerPage={itemsPerPage}
                    currentPage={currentPage}
                />
            )}
            <Back to='/user' text='Back to Dashboard' />
        </>
    );
};

const PagesContent = ({
    pageCount,
    pages,
    refetchPages,
    itemsPerPage,
    currentPage,
}: PagesContentProps): JSX.Element => (
    <>
        <PagesTableWithModal
            pageCount={pageCount}
            pages={pages}
            refetchPages={refetchPages}
            itemsPerPage={itemsPerPage}
            currentPage={currentPage}
        />
        <CreatePageModal refetchPages={refetchPages} />
    </>
);

export default withPage(Pages)({ meta: userPagesMeta, innerSpacing: true });
