// import { Params, useParams } from 'react-router-dom';
import { useAffiliateBalances } from '../../../../hooks/affiliates/useAffiliateBalances/useAffiliateBalances';
// import { useAffiliateTransactions } from '../../../../hooks/affiliates/useAffiliateTransactions/useAffiliateTransactions';
import { convertToCurrency } from '../../../../utils/HelperFunctions/HelperFunctions';
// import { Paginator } from '../../../default/Paginator/Paginator';
import { Loading, Error } from '../../../default/States/States';
// import { DynamicTable } from '../../../default/Table/Table';
// import styles from './styles.module.scss';
// import { DEFAULT_ITEMS_PER_PAGE } from '../../../../utils/global-variables';

// --------------------------- FEATURE ABANDONED ---------------------------
// wastes time, so don't bother developing this
// export const AffiliateBalanceTable = () => {
//     return null;
//     const itemsPerPage: number = DEFAULT_ITEMS_PER_PAGE;
//     const params: Params<string> = useParams();
//     const currentPage: number = Number(params.currentPage) || 1;
//     const skip: number = (currentPage - 1) * itemsPerPage;
//     const { transactionCount, transactions, loading, error } = useAffiliateTransactions({ limit: itemsPerPage, skip });
//     const headers: string[] = ['date', 'amount', 'type', 'qualified', 'balance'];

//     if (loading) return <Loading />;
//     if (error) return <Error msg={error.message} />;
//     if (!transactions) return <p>No transactions found.</p>;

//     return null;

//     // return (
//     //     <>
//     //         <DynamicTable data={transactions} headers={headers} clsOuter={styles.affiliateBalanceTable} />
//     //         <Paginator
//     //             currentPage={currentPage}
//     //             itemCount={transactionCount}
//     //             itemsPerPage={itemsPerPage}
//     //             path='/user/profile/affiliate/balance'
//     //         />
//     //     </>
//     // );
// };

export const AffiliateBalanceFigures = () => {
    const { pendingBalance, qualifiedBalance, unpaidBalance, loading, error } =
        useAffiliateBalances();

    if (loading) return <Loading />;
    if (error) return <Error msg={error} />;

    return (
        <>
            <p>Pending Balance: {convertToCurrency(pendingBalance)}</p>
            <p>
                <b>Qualified Balance: {convertToCurrency(qualifiedBalance)}</b>
            </p>
            <p>Unpaid Balance: {convertToCurrency(unpaidBalance)}</p>
        </>
    );
};
