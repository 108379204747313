import React, { useCallback, useMemo, useState } from 'react';
import { RefinedPage } from '../../../../hooks/pages/useGetPages/types';
import { Paginator } from '../../../default/Paginator/Paginator';
import { Loading, Error, NoneFound } from '../../../default/States/States';
import { DynamicTable } from '../../../default/Table/Table';
import EditPageModal from '../EditPageModal/EditPageModal';
import styles from './styles.module.scss';
import { PagesTableWithModalProps } from './types';

const PagesTableWithModal = ({
    pageCount,
    pages,
    loading,
    error,
    refetchPages,
    itemsPerPage,
    currentPage,
}: PagesTableWithModalProps): JSX.Element => {
    const headers: string[] = useMemo<string[]>(() => ['name', 'url', ''], []);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<RefinedPage | null>(null);

    const onClick = useCallback<(newRow: RefinedPage) => void>((newRow) => {
        setModalIsOpen(true);
        setSelectedRow(newRow);
    }, []);

    if (loading) return <Loading centralised={true} />;
    if (error) return <Error msg={error.message} />;
    if (!pages?.length) return <NoneFound msg='No pages found' centralised={true} />;

    return (
        <>
            <DynamicTable
                clsInner={styles.pagesTable}
                data={pages}
                headers={headers}
                onClick={onClick}
            />
            <Paginator
                currentPage={currentPage}
                itemCount={pageCount}
                itemsPerPage={itemsPerPage}
                path='/user/pages'
            />
            <EditPageModal
                selectedRow={selectedRow}
                modalIsOpen={modalIsOpen}
                setModalIsOpen={setModalIsOpen}
                refetchPages={refetchPages}
            />
        </>
    );
};

export default PagesTableWithModal;
