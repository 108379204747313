import React, { useState } from 'react';
import { withPage } from '../../../components/layout/Page/Page';
import { quickWinsMeta } from '../../misc/MetaTags';
import styles from './styles.module.scss';
import { Section } from '../../../components/default/Areas/Areas';
import { BenefitsProps, QuickWinsProps } from './types';
import QuickWinsImage from '../../../images/ebook-ab-sm.png';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { SignupModal } from '../../../components/default/SignupFormModal/SignupFormModal';

const props: QuickWinsProps = {
    image: QuickWinsImage,
    imageAlt: 'A/B Test Easy Wins Guide',
    benefits: [
        'Easy to Implement',
        'Takes Little Time',
        'Low Effort',
        'Yields Surprisingly Good Results',
        'Applies to Numerous Places',
    ],
};

const QuickWinsV2 = () => {
    const [modalIsOpen, setIsOpen] = useState<boolean>(false);

    return (
        <>
            <Section
                clsOuter={styles.landingPageOuter}
                clsInner={`${styles.landingPageInner} ${styles.specificity}`}
                tag='section'
            >
                <img src={props.image} alt={props.imageAlt} />
                <Benefits benefits={props.benefits} setIsOpen={setIsOpen} />
            </Section>
            <SignupModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
        </>
    );
};

const Benefits = ({ benefits, setIsOpen }: BenefitsProps): JSX.Element => (
    <div className={`${styles.benefitsContainer} ${styles.infoContainer}`}>
        <h2>Quick and simple ways to benefit from A/B tests</h2>
        <h1>Free Guide: Simple &amp; Effective A/B Test Ideas</h1>
        <ul>
            {benefits.map((benefit: string) => (
                <li key={benefit}>
                    <FontAwesomeIcon icon={faCheck} aria-label='tick' className={styles.check} />{' '}
                    {benefit}
                </li>
            ))}
        </ul>
        <button className={styles.btnPrimary} onClick={() => setIsOpen(true)}>
            Download
        </button>
    </div>
);

export default withPage(QuickWinsV2)({ meta: quickWinsMeta, fullWidth: true, noindex: true });
// @todo: remove noindex: true if using this page, or just implement the change in the other file
