import { useState } from 'react';
import { EditPageFn, UseEditPage } from './types';
import { useUpdatePageMutation } from '../../../graphql/generated/schema';
import trimTrailingSlash from '../../../utils/HelperFunctions/trimTrailingSlash';

export const useEditPage: UseEditPage = (selectedRowId) => {
    const [error, setError] = useState<string | boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [{ fetching: loading }, changePage] = useUpdatePageMutation();

    const editPage: EditPageFn = async ({ name, url }) => {
        const cleanedUrl = trimTrailingSlash(url);

        try {
            const { data, error } = await changePage({
                input: { name, url: cleanedUrl, pageId: selectedRowId },
            });

            if (error || !data) {
                const errorText = error?.message || 'Could not update page';
                setSuccess(false);
                setError(`HTTP error: ${errorText}`);
                return;
            }

            setError(false);
            setSuccess(true);
        } catch (err: any) {
            setSuccess(false);
            setError(err.message);
        }
    };

    return { loading, error, editPage, success };
};
