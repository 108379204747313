import { useCallback, useEffect, useState } from 'react';
import { useClient } from 'urql';
import { GetReferralsDocument } from '../../../graphql/generated/schema';
import {
    convertToCurrency,
    formatDate,
    mappedPlan,
} from '../../../utils/HelperFunctions/HelperFunctions';
import { UseGetReferralList, UseGetReferrals } from './types';

export const useGetReferralList: UseGetReferralList = (options) => {
    const [limit, skip] = [options?.limit || 0, options?.skip || 0];
    const [error, setError] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(true);
    const [referrals, setReferrals] = useState<any[]>([]); // nearly ReferralUserData. Not ideal to use any
    const [referralsCount, setReferralsCount] = useState<number>(0);
    const getReferrals = useGetReferrals();

    const handleReferrals = useCallback(async () => {
        try {
            setLoading(true);
            // get referrals:
            const getReferralsRes = await getReferrals(limit, skip);
            const referralData = getReferralsRes?.data?.referrals;
            if (!referralData) throw new Error('Could not get referral list');
            const refinedUsers = referralData.users.map(
                ({
                    __typename,
                    dateCreated,
                    conversionDate,
                    userPlan,
                    totalCommissionsValue: totalCommissionsValueUnrefined,
                    ...userData
                }) => {
                    const refinedPlan = mappedPlan(userPlan);
                    const formattedDateCreated = formatDate(Number(dateCreated));
                    const formattedConversionDate =
                        conversionDate && formatDate(Number(conversionDate));
                    const totalCommissionsValue = convertToCurrency(totalCommissionsValueUnrefined);
                    return {
                        dateCreated: formattedDateCreated,
                        userPlan: refinedPlan,
                        conversionDate: formattedConversionDate,
                        ...userData,
                        totalCommissionsValue,
                    };
                }
            );
            setReferrals(refinedUsers);
            setReferralsCount(referralData.total);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, [getReferrals, limit, skip]);

    useEffect(() => {
        handleReferrals();
    }, [handleReferrals]);

    return { referralsCount, referrals, loading, error };
};

export const useGetReferrals: UseGetReferrals = () => {
    const client = useClient();
    return useCallback(
        (limit, skip) => client.query(GetReferralsDocument, { input: { limit, skip } }).toPromise(),
        [client]
    );
};
