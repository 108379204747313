export const BLOG_CONTENTS = [
    {
        id: 'what-is-ab-testing',
        label: 'What is A/B Testing',
    },
    {
        id: 'ab-testing-tool-challenges',
        label: 'A/B Testing Tool Challenges',
    },
    {
        id: 'our-ab-testing-tool',
        label: 'Our A/B Testing Tool',
    },
    {
        id: 'ab-testing-tool-features',
        label: 'Key Features of Our Tool',
    },
    {
        id: 'getting-started',
        label: 'Getting Started',
    },
    {
        id: 'why-use-our-tool',
        label: 'Why Use Our Tool',
    },
    {
        id: 'conclusion',
        label: 'Conclusion',
    },
];
