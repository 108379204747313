import { AnyObject } from '../../global-types';
import { BTN_FIELDS, FORM_FIELDS, TrackingType } from './variables';

const getTrackingType = (results: AnyObject[]): TrackingType => {
    if (isBtnTrackingType(results)) return TrackingType.BUTTON;
    if (isFormTrackingType(results)) return TrackingType.FORM;
    if (trackingHasError(results)) return TrackingType.HAS_ERROR;
    return TrackingType.STANDARD;
};

const isBtnTrackingType = (arr: AnyObject[]): boolean =>
    arr.every((item) => BTN_FIELDS.every((key) => item.hasOwnProperty(key)));

const isFormTrackingType = (arr: AnyObject[]): boolean =>
    arr.every((item) => FORM_FIELDS.every((key) => item.hasOwnProperty(key)));

const trackingHasError = (arr: AnyObject[]): boolean => {
    const requiredKeys = [...FORM_FIELDS, ...BTN_FIELDS];
    return arr.some((item) => requiredKeys.some((key) => item.hasOwnProperty(key)));
};

export default getTrackingType;
