import React, { useContext, useState } from 'react';
import styles from '../../../css/default.module.scss';
import customStyles from './styles.module.scss';
import { FreeGuideProps } from './types';
import EbookImage from '../../../images/ebook-ab-sm.png';
import { Section } from '../Areas/Areas';
import { UserContext } from '../../../contexts/UserContext/UserContext';
// @ts-ignore
import quickWinsPdf from '../../../downloads/cro-simple-quick-wins.pdf';
import { CreateAccountModal } from '../CreateAccountModal/CreateAccountModal';

export const FreeGuide = ({
    title = 'Free Guide',
    description = 'Want some simple and easy-to-implement A/B test ideas? Get a free copy of the Quick Wins guide.',
    buttonText = 'Download',
    inverse = false,
}: FreeGuideProps): JSX.Element => {
    const { user: loggedInUser } = useContext(UserContext);
    const [modalIsOpen, setIsOpen] = useState<boolean>(false);

    const handleOnClick = () => {
        if (loggedInUser) {
            window.open(quickWinsPdf, '_blank', 'noreferrer');
            return;
        }

        setIsOpen(true);
    };

    return (
        <>
            <Section inverse={inverse} patterned={true} clsInner={customStyles.containerInner}>
                <img src={EbookImage} alt='A/B Testing Guide' onClick={handleOnClick} />
                <div className={customStyles.content}>
                    <h2>{title}</h2>
                    <p>{description}</p>
                    <button className={styles.btnPrimary} onClick={handleOnClick}>
                        {buttonText}
                    </button>
                </div>
            </Section>

            <CreateAccountModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
        </>
    );
};
