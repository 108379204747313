import { ChangeEvent, useCallback, useContext, useEffect, useState } from 'react';
import { AllWebsitesForUserDocument } from '../../../graphql/generated/schema';
import { AccountContext } from '../../../contexts/AccountContext/AccountContext';
import { AccountContextType } from '../../../contexts/AccountContext/types';
import useLazyQuery from '../../other/useLazyQuery/useLazyQuery';
import { LastModified, SortedWebsite, UseSelectWebsites } from './types';

export const useSelectWebsites: UseSelectWebsites = () => {
    const { setSelectedWebsite, setWebsites } = useContext<AccountContextType>(AccountContext);
    const fetchWebsites = useLazyQuery(AllWebsitesForUserDocument);
    const [loadingWebsites, setLoadingWebsites] = useState<boolean>(false);
    const [paused, setPaused] = useState<boolean>(true);
    const [error, setError] = useState<string>('');

    const handleWebsiteChange = useCallback(
        (event: ChangeEvent<HTMLSelectElement>): void => {
            if (!event.target.value) return;
            const selectedOption = event.target.selectedOptions[0];
            const selectedWebsiteId = selectedOption.getAttribute('data-id');
            setSelectedWebsite({
                url: event.target.value,
                id: selectedWebsiteId as string,
            });
        },
        [setSelectedWebsite]
    );

    useEffect(() => {
        (async () => {
            try {
                setLoadingWebsites(true);
                setPaused(false);
                const res = await fetchWebsites();
                if (!res || res?.error)
                    throw new Error(res?.error?.message || 'Could not fetch websites');
                const sortedWebsites = res.data?.websites?.websites
                    .map(({ id, url, lastModified }: SortedWebsite & LastModified) => ({
                        id,
                        url,
                        lastModified: parseInt(lastModified ?? '0').toString(),
                    }))
                    .sort(
                        (a: LastModified, b: LastModified) =>
                            parseInt(b.lastModified) - parseInt(a.lastModified)
                    )
                    .map(({ id, url }: SortedWebsite) => ({
                        id,
                        url,
                    }));
                setWebsites(sortedWebsites);
            } catch (error) {
                setError((error as Error).message);
            } finally {
                setLoadingWebsites(false);
            }
        })();
    }, [fetchWebsites, setWebsites]);

    return { loading: loadingWebsites || paused, error, handleWebsiteChange };
};
