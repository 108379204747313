import React from 'react';
import { formatDate } from '../../../../utils/HelperFunctions/HelperFunctions';
import { VariantInfoProps } from './types';
import { InfoAreaWrap } from '../../../default/Cards/Cards';

const VariantInfo = ({
    name,
    description,
    createdAt,
    lastModified,
}: VariantInfoProps): JSX.Element => (
    <InfoAreaWrap>
        <h2>Main Information:</h2>
        <p>
            <strong>Name:</strong> {name}
        </p>
        <p>
            <strong>Description:</strong> {description}
        </p>
        <p>
            <strong>Created:</strong> {formatDate(Number(createdAt))}
        </p>
        {lastModified && (
            <p>
                <strong>Last Modified:</strong> {formatDate(lastModified)}
            </p>
        )}
    </InfoAreaWrap>
);

export default VariantInfo;
