export const RADIO_PROPS = {
    label: 'How would you rate your experience?',
    group: 'rating',
    options: [
        {
            title: 'Good',
            value: 'good',
        },
        {
            title: 'Satisfactory',
            value: 'satisfactory',
        },
        {
            title: 'Poor',
            value: 'poor',
        },
    ],
};
