import React from 'react';
// import StyleGuide from './pages/other/StyleGuide/StyleGuide';
// import BlogArticleTemplate from './pages/blog/BlogArticleTemplate/BlogArticleTemplate';
import Homepage from './pages/other/Homepage/Homepage';
import NotFound from './pages/other/errors/404/404';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Login from './pages/account/public/Login/Login';
import Terms from './pages/legal/Terms/Terms';
import Privacy from './pages/legal/Privacy/Privacy';
import CreateAccount from './pages/account/public/CreateAccount/CreateAccount';
import EditAccount from './pages/account/private/EditAccount/EditAccount';
import { UserProvider } from './contexts/UserContext/UserContext';
import { PrivateRoute } from './components/account/PrivateRoute/PrivateRoute';
import NewPassword from './pages/account/public/NewPassword/NewPassword';
import NewPasswordSuccess from './pages/account/public/NewPasswordSuccess/NewPasswordSuccess';
import ResetPassword from './pages/account/public/ResetPassword/ResetPassword';
import { HelmetProvider } from 'react-helmet-async';
import AffiliateProgram from './pages/affiliates/AffiliateProgram/AffiliateProgram';
import AffiliateDashboard from './pages/account/private/affiliate/AffiliateDashboard/AffiliateDashboard';
import AffiliateRegistry from './pages/affiliates/AffiliateRegistry/AffiliateRegistry';
import AffiliateLogin from './pages/affiliates/AffiliateLogin/AffiliateLogin';
import { Provider as UrqlProvider } from 'urql';
import { client } from './graphql/gateway-client';
import About from './pages/other/About/About';
import ReferralListPage from './pages/account/private/affiliate/ReferralListPage/ReferralListPage';
import AffiliatePayments from './pages/account/private/affiliate/AffiliatePayments/AffiliatePayments';
import './css/sitewide.module.scss';
import AffiliateBalance from './pages/account/private/affiliate/AffiliateBalance/AffiliateBalance';
import AffiliateCommissions from './pages/account/private/affiliate/AffiliateCommissions/AffiliateCommissions';
import AffiliateFaq from './pages/affiliates/AffiliateFaq/AffiliateFaq';
import AffiliateSessions from './pages/account/private/affiliate/AffiliateSessions/AffiliateSessions';
import Cookies from './pages/legal/Cookies/Cookies';
import { CookieProvider } from './contexts/CookieContext/CookieContext';
import UserProfile from './pages/account/private/UserProfile/UserProfile';
import Pricing from './pages/other/Pricing/Pricing';
import Dashboard from './pages/account/private/Dashboard/Dashboard';
import Websites from './pages/account/private/Websites/Websites';
import Pages from './pages/account/private/Pages/Pages';
import Tests from './pages/account/private/Tests/Tests';
import Test from './pages/account/private/Tests/Test/Test';
import CreateTest from './pages/account/private/Tests/CreateTest/CreateTest';
import TestResults from './pages/account/private/Tests/Test/TestResults/TestResults';
import TestVariant from './pages/account/private/Tests/Test/TestVariant/TestVariant';
import CreateTestSuccess from './pages/account/private/Tests/CreateTest/CreateTestSuccess/CreateTestSuccess';
import Plan from './pages/account/private/Plan/Plan';
import PaymentSuccessful from './pages/account/private/payments/PaymentSuccessful/PaymentSuccessful';
import PaymentCancelled from './pages/account/private/payments/PaymentCancelled/PaymentCancelled';
import {
    PAYMENT_CANCEL_REDIRECT_PATH,
    PAYMENT_METHOD_UPDATE_CANCEL_REDIRECT_URL,
    PAYMENT_METHOD_UPDATE_SUCCESS_REDIRECT_URL,
    PAYMENT_SUCCESS_REDIRECT_PATH,
} from './utils/global-variables';
import ScriptSetup from './pages/support/ScriptSetup/ScriptSetup';
import Support from './pages/support/Support';
import ScriptVerification from './pages/support/ScriptVerification/ScriptVerification';
import Contact from './pages/other/Contact/Contact';
import CancelPlan from './pages/account/private/Plan/CancelPlan/CancelPlan';
import CancelPlanSuccess from './pages/account/private/Plan/CancelPlanSuccess/CancelPlanSuccess';
import ChangePlan from './pages/account/private/Plan/ChangePlan/ChangePlan';
import ChangePlanSuccess from './pages/account/private/Plan/ChangePlanSuccess/ChangePlanSuccess';
import PaymentMethodUpdateCancelled from './pages/account/private/payments/PaymentMethodUpdateCancelled/PaymentMethodUpdateCancelled';
import PaymentMethodUpdateSuccess from './pages/account/private/payments/PaymentMethodUpdateSuccess/PaymentMethodUpdateSuccess';
import Goals from './pages/account/private/UserProfile/Avatar/Goals/Goals';
import Business from './pages/account/private/UserProfile/Avatar/Business/Business';
import Demographics from './pages/account/private/UserProfile/Avatar/Demographics/Demographics';
import PreviousExperience from './pages/account/private/UserProfile/Avatar/PreviousExperience/PreviousExperience';
import AvatarSuccess from './pages/account/private/UserProfile/Avatar/AvatarSuccess/AvatarSuccess';
import TestCreationGuide from './pages/support/Testing/TestCreationGuide/TestCreationGuide';
import ProductImageEffectiveness from './pages/support/Testing/Examples/ProductImageEffectiveness/ProductImageEffectiveness';
import FormTestCreation from './pages/support/Testing/Examples/FormTestCreation/FormTestCreation';
import NewNewsletterSubscriber from './pages/subscribers/NewNewsletterSubscriber/NewNewsletterSubscriber';
import Partnerships from './pages/affiliates/Partnerships/Partnerships';
import AbTestingHeadlines from './pages/blog/AbTestingHeadlines/AbTestingHeadlines';
import Blog from './pages/blog/Blog';
import AbTestingMultipleVariants from './pages/blog/AbTestingMultipleVariants/AbTestingMultipleVariants';
import AbTestingToolFree from './pages/blog/AbTestingToolFree/AbTestingToolFree';
import ImproveFormConversions from './pages/blog/ImproveFormConversions/ImproveFormConversions';
import QuickWinsV2 from './pages/landing/QuickWinsSuperseded/QuickWinsV2';
import ImproveProductPageConversions from './pages/blog/ImproveProductPageConversions/ImproveProductPageConversions';
import WordPressAndWooCommerceAbTesting from './pages/blog/WordPressAndWooCommerceAbTesting/WordPressAndWooCommerceAbTesting';
import LandingPageTemplate from './pages/landing/LandingPageTemplate/LandingPageTemplate';
import PaidAdLandingPageOne from './pages/landing/paid-ads/segment/ab-testers/PaidAdLandingPageOne/PaidAdLandingPageOne';
import { Hooks } from './components/default/Hooks/Hooks';
// import NewAccountCreated from './pages/account/public/NewAccountCreated/NewAccountCreated';
import PaidAdAdvertisersLandingPageOne from './pages/landing/paid-ads/segment/paid-advertisers/PaidAdAdvertisersLandingPageOne/PaidAdAdvertisersLandingPageOne';
import PaidAdEcommerceLandingPageOne from './pages/landing/paid-ads/segment/ecommerce-store-owners/PaidAdEcommerceLandingPageOne/PaidAdEcommerceLandingPageOne';
import AccountCreationSuccess from './pages/account/private/AccountCreationSuccess/AccountCreationSuccess';
import PaidAdEcommerceLandingPageTwo from './pages/landing/paid-ads/segment/ecommerce-store-owners/PaidAdEcommerceLandingPageTwo/PaidAdEcommerceLandingPageTwo';
import PaidAdLandingPageTwo from './pages/landing/paid-ads/segment/ab-testers/PaidAdLandingPageTwo/PaidAdLandingPageTwo';
import PaidAdAdvertisersLandingPageTwo from './pages/landing/paid-ads/segment/paid-advertisers/PaidAdAdvertisersLandingPageTwo/PaidAdAdvertisersLandingPageTwo';
import QuickWins from './pages/landing/QuickWins/QuickWins';
import Services from './pages/other/Services/Services';
import ScrollToTop from './components/default/ScrollToTop/ScrollToTop';

const App = () => (
    <HelmetProvider>
        <UrqlProvider value={client}>
            <CookieProvider>
                <Hooks />
                <UserProvider>
                    <BrowserRouter>
                        <ScrollToTop />
                        <Routes>
                            <Route path='/' element={<Homepage />} />
                            {/* <Route path='/styles' element={<StyleGuide />} /> */}
                            {/* <Route path='/blog/template' element={<BlogArticleTemplate />} /> */}
                            <Route
                                path='/campaign/ab-testing-tool'
                                element={<PaidAdLandingPageOne />}
                            />
                            <Route
                                path='/campaign/ab-testing-tool-v2'
                                element={<PaidAdLandingPageTwo />}
                            />
                            <Route
                                path='/campaign/paid-advertiser-tool'
                                element={<PaidAdAdvertisersLandingPageOne />}
                            />
                            <Route
                                path='/campaign/paid-advertiser-tool-v2'
                                element={<PaidAdAdvertisersLandingPageTwo />}
                            />
                            <Route
                                path='/campaign/ecommerce-conversion-optimizer'
                                element={<PaidAdEcommerceLandingPageOne />}
                            />
                            <Route
                                path='/campaign/ecommerce-conversion-optimizer-v2'
                                element={<PaidAdEcommerceLandingPageTwo />}
                            />
                            <Route
                                path='/blog/ab-testing-headlines'
                                element={<AbTestingHeadlines />}
                            />
                            <Route
                                path='/blog/ab-testing-multiple-variants'
                                element={<AbTestingMultipleVariants />}
                            />
                            <Route
                                path='/blog/free-ab-testing-tool'
                                element={<AbTestingToolFree />}
                            />
                            <Route
                                path='/blog/improve-form-conversions-with-ab-testing'
                                element={<ImproveFormConversions />}
                            />
                            <Route
                                path='/blog/improve-product-page-conversions-with-ab-testing'
                                element={<ImproveProductPageConversions />}
                            />
                            <Route
                                path='/blog/wordpress-and-woocommerce-pluginless-ab-testing'
                                element={<WordPressAndWooCommerceAbTesting />}
                            />
                            <Route path='/about' element={<About />} />
                            <Route path='/blog' element={<Blog />} />
                            <Route path='/pricing' element={<Pricing />} />
                            <Route path='/services' element={<Services />} />
                            <Route path='/login' element={<Login />} />
                            {/* <Route path='/new-account-created' element={<NewAccountCreated />} /> superseded */}
                            <Route path='/contact' element={<Contact />} />
                            <Route path='/create-account' element={<CreateAccount />} />
                            <Route path='/new-password' element={<NewPassword />} />
                            <Route path='/new-password-success' element={<NewPasswordSuccess />} />
                            <Route path='/reset-password' element={<ResetPassword />} />
                            <Route
                                path='/user'
                                element={
                                    <PrivateRoute>
                                        <Dashboard />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path='/user/new-account-success'
                                element={
                                    <PrivateRoute>
                                        <AccountCreationSuccess />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path='/user/websites'
                                element={
                                    <PrivateRoute>
                                        <Websites />
                                    </PrivateRoute>
                                }
                            >
                                <Route
                                    path='/user/websites/page/:currentPage'
                                    element={
                                        <PrivateRoute>
                                            <Websites />
                                        </PrivateRoute>
                                    }
                                />
                            </Route>
                            <Route
                                path='/user/tests'
                                element={
                                    <PrivateRoute>
                                        <Tests />
                                    </PrivateRoute>
                                }
                            >
                                <Route
                                    path='/user/tests/page/:currentPage'
                                    element={
                                        <PrivateRoute>
                                            <Tests />
                                        </PrivateRoute>
                                    }
                                />
                            </Route>
                            <Route
                                path='/user/tests/:testId'
                                element={
                                    <PrivateRoute>
                                        <Test />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path='/user/tests/:testId/results'
                                element={
                                    <PrivateRoute>
                                        <TestResults />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path='/user/tests/:testId/variant/:variantId'
                                element={
                                    <PrivateRoute>
                                        <TestVariant />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path='/user/tests/new'
                                element={
                                    <PrivateRoute>
                                        <CreateTest />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path='/user/tests/new/success/:testId'
                                element={
                                    <PrivateRoute>
                                        <CreateTestSuccess />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path='/user/pages'
                                element={
                                    <PrivateRoute>
                                        <Pages />
                                    </PrivateRoute>
                                }
                            >
                                <Route
                                    path='/user/pages/page/:currentPage'
                                    element={
                                        <PrivateRoute>
                                            <Pages />
                                        </PrivateRoute>
                                    }
                                />
                            </Route>
                            <Route
                                path='/user/plan'
                                element={
                                    <PrivateRoute>
                                        <Plan />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path='/user/plan/cancel'
                                element={
                                    <PrivateRoute>
                                        <CancelPlan />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path='/user/plan/change'
                                element={
                                    <PrivateRoute>
                                        <ChangePlan />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path='/user/plan/cancellation-success'
                                element={<CancelPlanSuccess />}
                            />
                            <Route
                                path='/user/plan/change-success'
                                element={<ChangePlanSuccess />}
                            />
                            <Route
                                path={PAYMENT_CANCEL_REDIRECT_PATH}
                                element={<PaymentCancelled />}
                            />
                            <Route
                                path={PAYMENT_SUCCESS_REDIRECT_PATH}
                                element={<PaymentSuccessful />}
                            />
                            <Route
                                path={PAYMENT_METHOD_UPDATE_SUCCESS_REDIRECT_URL}
                                element={<PaymentMethodUpdateSuccess />}
                            />
                            <Route
                                path={PAYMENT_METHOD_UPDATE_CANCEL_REDIRECT_URL}
                                element={<PaymentMethodUpdateCancelled />}
                            />
                            <Route
                                path='/user/profile'
                                element={
                                    <PrivateRoute>
                                        <UserProfile />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path='/user/profile/edit'
                                element={
                                    <PrivateRoute>
                                        <Goals />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path='/user/profile/edit/2'
                                element={
                                    <PrivateRoute>
                                        <Business />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path='/user/profile/edit/3'
                                element={
                                    <PrivateRoute>
                                        <Demographics />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path='/user/profile/edit/4'
                                element={
                                    <PrivateRoute>
                                        <PreviousExperience />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path='/user/profile/edit/success'
                                element={
                                    <PrivateRoute>
                                        <AvatarSuccess />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path='/user/profile/affiliate'
                                element={
                                    <PrivateRoute>
                                        <AffiliateDashboard />
                                    </PrivateRoute>
                                }
                            />
                            <Route
                                path='/user/profile/affiliate/referral-list'
                                element={
                                    <PrivateRoute>
                                        <ReferralListPage />
                                    </PrivateRoute>
                                }
                            >
                                <Route
                                    path='/user/profile/affiliate/referral-list/page/:currentPage'
                                    element={
                                        <PrivateRoute>
                                            <ReferralListPage />
                                        </PrivateRoute>
                                    }
                                />
                            </Route>
                            <Route
                                path='/user/profile/affiliate/payments'
                                element={
                                    <PrivateRoute>
                                        <AffiliatePayments />
                                    </PrivateRoute>
                                }
                            >
                                <Route
                                    path='/user/profile/affiliate/payments/page/:currentPage'
                                    element={
                                        <PrivateRoute>
                                            <AffiliatePayments />
                                        </PrivateRoute>
                                    }
                                />
                            </Route>
                            <Route
                                path='/user/profile/affiliate/commissions'
                                element={
                                    <PrivateRoute>
                                        <AffiliateCommissions />
                                    </PrivateRoute>
                                }
                            >
                                <Route
                                    path='/user/profile/affiliate/commissions/page/:currentPage'
                                    element={
                                        <PrivateRoute>
                                            <AffiliateCommissions />
                                        </PrivateRoute>
                                    }
                                />
                            </Route>
                            <Route
                                path='/user/profile/affiliate/sessions'
                                element={
                                    <PrivateRoute>
                                        <AffiliateSessions />
                                    </PrivateRoute>
                                }
                            >
                                <Route
                                    path='/user/profile/affiliate/sessions/page/:currentPage'
                                    element={
                                        <PrivateRoute>
                                            <AffiliateSessions />
                                        </PrivateRoute>
                                    }
                                />
                            </Route>
                            <Route
                                path='/user/profile/affiliate/balance'
                                element={
                                    <PrivateRoute>
                                        <AffiliateBalance />
                                    </PrivateRoute>
                                }
                            >
                                <Route
                                    path='/user/profile/affiliate/balance/page/:currentPage'
                                    element={
                                        <PrivateRoute>
                                            <AffiliateBalance />
                                        </PrivateRoute>
                                    }
                                />
                            </Route>
                            <Route
                                path='/user/edit-account'
                                element={
                                    <PrivateRoute>
                                        <EditAccount />
                                    </PrivateRoute>
                                }
                            />
                            <Route path='/support' element={<Support />} />
                            <Route path='/support/script-setup' element={<ScriptSetup />} />
                            <Route
                                path='/support/script-verification'
                                element={<ScriptVerification />}
                            />
                            <Route
                                path='/support/test-creation/guide'
                                element={<TestCreationGuide />}
                            />
                            <Route
                                path='/support/test-examples/images'
                                element={<ProductImageEffectiveness />}
                            />
                            <Route
                                path='/support/test-examples/forms'
                                element={<FormTestCreation />}
                            />
                            <Route path='/terms' element={<Terms />} />
                            <Route path='/privacy' element={<Privacy />} />
                            <Route path='/cookie-policy' element={<Cookies />} />
                            <Route path='/affiliates' element={<AffiliateProgram />} />
                            <Route path='/partnerships' element={<Partnerships />} />
                            <Route path='/affiliates/register' element={<AffiliateRegistry />} />
                            <Route path='/affiliates/login' element={<AffiliateLogin />} />
                            <Route path='/affiliates/faq' element={<AffiliateFaq />} />
                            <Route path='/quickwins' element={<QuickWins />} />
                            <Route path='/quickwins-v2' element={<QuickWinsV2 />} />
                            <Route
                                path='/landing-page-template'
                                element={<LandingPageTemplate />}
                            />
                            <Route
                                path='/newsletter-signup/success'
                                element={<NewNewsletterSubscriber />}
                            />
                            <Route path='*' element={<NotFound />} />
                        </Routes>
                    </BrowserRouter>
                </UserProvider>
            </CookieProvider>
        </UrqlProvider>
    </HelmetProvider>
);

export default App;
