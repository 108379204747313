import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { WarningBoxProps } from './types';
import styles from './styles.module.scss';
import defaultStyles from '../../../../css/default.module.scss';

const WarningBox = ({ title, description }: WarningBoxProps): JSX.Element => (
    <>
        <h4>
            <FontAwesomeIcon
                icon={faExclamationTriangle}
                className={`${defaultStyles.warningText} ${styles.warningIcon}`}
                aria-label='warning'
            />
            {title}
        </h4>
        <p>{description}</p>
    </>
);

export default WarningBox;
