import React from 'react';
import defaultStyles from '../../css/default.module.scss';
import { withPage } from '../../components/layout/Page/Page';
import { supportMeta } from '../misc/MetaTags';
import { Link } from 'react-router-dom';
import ListWithArrows from '../../components/default/ListWithArrows/ListWithArrows';
import {
    AFFILIATE_SUPPORT_LINK_ITEMS,
    TEST_CREATION_LINK_ITEMS,
    TEST_EXAMPLE_LINK_ITEMS,
    TRACKING_SCRIPT_SUPPORT_LINK_ITEMS,
} from './variables';

const Support = () => (
    <>
        <h1 className={defaultStyles.centralisedText}>Support Hub</h1>
        <p>
            Welcome to the support hub. We're here to help you get the most out of our A/B testing
            tools. Whether you're new to A/B testing or experienced, you'll find useful resources
            here to guide you in optimizing your website.
        </p>

        <h3>The Tracking Script:</h3>
        <ListWithArrows items={TRACKING_SCRIPT_SUPPORT_LINK_ITEMS} />

        <h3>Affiliates:</h3>
        <ListWithArrows items={AFFILIATE_SUPPORT_LINK_ITEMS} />

        <h3>Creating Tests:</h3>
        <ListWithArrows items={TEST_CREATION_LINK_ITEMS} />

        <h3>Test Examples:</h3>
        <ListWithArrows items={TEST_EXAMPLE_LINK_ITEMS} />

        <h3>Want More Ideas?</h3>
        <p>
            Visit our <Link to='/blog'>blog</Link> for more ideas of what you can test and what else
            to consider.
        </p>

        <h3>Contact Us:</h3>
        <p>
            If you can't find the answer you're looking for or need further assistance, don't
            hesitate to <Link to='/contact'>reach out</Link> to us. We're here to help.
        </p>
    </>
);

export default withPage(Support)({ meta: supportMeta, innerSpacing: true });
