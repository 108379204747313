import React from 'react';
import styles from './styles.module.scss';
import { StatusIconProps, StatusProps } from './types';

const Status = ({ active, finished, started }: StatusProps): JSX.Element => {
    if (!finished) return <StatusIcon active={active} started={started} />;
    return <Completed />;
};

const StatusIcon = ({ active, started }: StatusIconProps): JSX.Element => {
    const status = active ? 'Live' : started ? 'Paused' : 'Not yet started';
    const style = active ? styles.pulse : styles.subtleGreyCircle;

    return (
        <p className={styles.container}>
            <span className={style}></span> <span>{status}</span>
        </p>
    );
};

const Completed = () => <p className={`${styles.container} ${styles.completed}`}>Completed</p>;

export default Status;
