import {
    useCreateTestMutation,
    useCreateTestVariantMutation,
    useCreateTestTargetDevicesMutation,
} from '../../../graphql/generated/schema';
import { UseSubmitTestCreation } from './types';
import {
    CreateTestFormInputs,
    Variants,
} from '../../../pages/account/private/Tests/CreateTest/types';
import { getTargetDeviceNumbers } from '../../../utils/HelperFunctions/HelperFunctions';
import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { minify as minifyCss } from 'csso';
import { minify as minifyJs } from 'terser';

export const useSubmitTestCreation: UseSubmitTestCreation = () => {
    const [, createTestFn] = useCreateTestMutation();
    const [, createVariant] = useCreateTestVariantMutation();
    const [, createTargetDevices] = useCreateTestTargetDevicesMutation();
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const navigate = useNavigate();

    const createTest = useCallback(
        async ({
            goalId,
            maxSessions,
            name,
            pageId,
            websiteId,
            description,
            targeting,
            variants,
        }: CreateTestFormInputs): Promise<void> => {
            const createTestInputs = {
                active: false,
                goalId: Number(goalId),
                maxCases: Number(maxSessions),
                name,
                pageId,
                websiteId,
                description,
            };

            try {
                setLoading(true);
                const createTestResponse = await createTestFn({
                    input: createTestInputs,
                });
                const insertedTestId = createTestResponse.data?.createTest?.insertedId;

                if (!insertedTestId || createTestResponse.error) {
                    throw new Error(
                        createTestResponse.error?.message ||
                            'Could not insert test into the database'
                    );
                }

                const createTargetDevicesResponse = await createTargetDevices({
                    input: {
                        testId: insertedTestId,
                        targetDeviceIds: getTargetDeviceNumbers(targeting),
                    },
                });

                if (
                    !createTargetDevicesResponse?.data?.createTestTargetDevices?.acknowledged ||
                    createTargetDevicesResponse.error
                ) {
                    throw new Error(
                        createTargetDevicesResponse.error?.message ||
                            'Could not insert test target devices into the database'
                    );
                }

                const variantsRes = await Promise.all(
                    variants.map(async (variant: Variants, index: number) => {
                        // async actually needed?
                        const { trafficAllocation, pageRedirectId, ...rest } = variant;

                        if (rest.hasOwnProperty('cssCode')) {
                            rest.cssCode = minifyCss(rest.cssCode).css;
                        }

                        if (rest.hasOwnProperty('jsCode')) {
                            rest.jsCode = (await minifyJs(rest.jsCode)).code;
                        }

                        const variantInput = {
                            ...rest,
                            trafficAllocation: Number(trafficAllocation),
                            testId: insertedTestId,
                            variantNo: index,
                            isControl: variant.hasOwnProperty('isControl')
                                ? variant.isControl
                                : false,
                            pageRedirectId: pageRedirectId || null,
                        };

                        return await createVariant({ input: variantInput }); // await actually needed?
                    })
                );

                const errorMessages = variantsRes
                    .filter(
                        (variant) => variant.error || !variant.data?.createTestVariant?.acknowledged
                    )
                    .map((variant) => variant.error?.message)
                    .filter(Boolean);

                if (errorMessages.length > 0) {
                    throw new Error(
                        `Errors occurred while creating test variants: ${errorMessages.join(', ')}`
                    );
                }

                navigate(`/user/tests/new/success/${insertedTestId}`);
            } catch (error: any) {
                setError(`Error submitting test: ${error.message}`);
            } finally {
                setLoading(false);
            }
        },
        [createTestFn, createVariant, createTargetDevices, navigate]
    );

    return { createTest, loading, error };
};
