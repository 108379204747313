import React, { useContext } from 'react';
import styles from './styles.module.scss';
import { AccountContext } from '../../../../contexts/AccountContext/AccountContext';
import { AccountContextType } from '../../../../contexts/AccountContext/types';
import { SelectWebsiteDropdownProps } from './types';

export const SelectWebsiteDropdown = ({
    handleWebsiteChange,
}: SelectWebsiteDropdownProps): JSX.Element | null => {
    const { selectedWebsite, websites } = useContext<AccountContextType>(AccountContext);

    if (!websites?.length) return null;

    return (
        <>
            <label htmlFor='website'>Select a Website:</label>
            <select id='website' value={selectedWebsite?.url} onChange={handleWebsiteChange}>
                <option value=''>-- Select --</option>
                {websites.map((website) => (
                    <option key={website.id} value={website.url} data-id={website.id}>
                        {website.url}
                    </option>
                ))}
            </select>
        </>
    );
};

export const SelectWebsiteDropdownForm = ({
    handleWebsiteChange,
}: SelectWebsiteDropdownProps): JSX.Element => (
    <form className={styles.websitesDropdownContainer}>
        <SelectWebsiteDropdown handleWebsiteChange={handleWebsiteChange} />
    </form>
);
