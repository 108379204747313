export const DEFAULT_AFFILIATE_RATE: string = '25%'; // must match same variable from server
export const MINIMUM_PAYMENT_THRESHOLD: string = '$10 USD';
export const MINIMUM_QUALIFICATION_TIME_QTY: number = 3;
export const MINIMUM_QUALIFICATION_TIME_UNIT: string = 'month';
export const MINIMUM_QUALIFICATION_TIME_SINGULAR: string = `${MINIMUM_QUALIFICATION_TIME_QTY} ${MINIMUM_QUALIFICATION_TIME_UNIT}`;
export const MINIMUM_QUALIFICATION_TIME_PLURAL: string = `${MINIMUM_QUALIFICATION_TIME_QTY} ${MINIMUM_QUALIFICATION_TIME_UNIT}s`;
export const COOKIE_DURATION: string = '1 year';
export const DEFAULT_CURRENCY_SYMBOL: string = '£';
export const HOSTNAME: string = 'crosimple.com'; // @todo: change with new projects
export const PROTOCOL: string = 'https';
// export const SITE_URL: string = 'http://localhost:3000'; // @todo: change back to `${PROTOCOL}://www.${HOSTNAME}`;
export const SITE_URL: string = `${PROTOCOL}://www.${HOSTNAME}`; // for local dev convenience, could use 'http://localhost:3000'
export const NO_MODAL_OVERLAY: { [key: string]: unknown } = {
    overlay: {
        background: 'unset',
        top: 'unset',
    },
};
export const DEFAULT_ITEMS_PER_PAGE = 10;
export enum Plan {
    FREE = 1,
    BEGINNER = 2,
    PRO = 3,
}
export type PlanKey = keyof typeof Plan;
export enum SubscriptionStatus {
    INITIAL = 1,
    ACTIVE = 2,
    IN_ARREARS = 3,
    RESTRICTED = 4,
    MANUALLY_CANCELLED = 5,
}
export type SubscriptionStatusKey = keyof typeof SubscriptionStatus;
export type Plans = 'FREE' | 'BEGINNER' | 'PRO'; // same as PlanKey? not actually sure
export type PaidPlans = 'BEGINNER' | 'PRO';
export const MAX_TEST_SESSIONS: Record<Plan, number> = {
    [Plan.FREE]: 400,
    [Plan.BEGINNER]: 4000,
    [Plan.PRO]: 10000,
    // @todo: perhaps add platinum option later - ref PLAT2523
};
export enum TARGET_DEVICES {
    MOBILES = 1,
    TABLETS = 2,
    DESKTOPS = 3,
}
export const TRACKING_SCRIPT_PATH = `${window.location.origin}/scripts/tracking/track.js`;
export enum PLAN_PRICE_IDS {
    BEGINNER = 'price_1PaluHCemj7XfGvctF3puwPP', // test GBP 'price_1PaPSPCemj7XfGvctYAbPeoh' USD 'price_1ONBtlCemj7XfGvc8RDQdMDa', // real (GBP) = 'price_1PaluHCemj7XfGvctF3puwPP', USD = price_1ONBqOCemj7XfGvc437Iz9Nv
    PRO = 'price_1PaluWCemj7XfGvc2t6z4m06', // test GBP 'price_1PaPSrCemj7XfGvcg97COlE7' or USD 'price_1ONBtQCemj7XfGvcOyV8iAcO', // real (GBP) = 'price_1PaluWCemj7XfGvc2t6z4m06', USD = price_1ONBqrCemj7XfGvczD8mYG6c
}
export const PAYMENT_SUCCESS_REDIRECT_PATH: string = '/payment-success';
export const PAYMENT_CANCEL_REDIRECT_PATH: string = '/payment-cancelled';
// note: ensure PAYMENT_SUCCESS_REDIRECT_PATH & PAYMENT_CANCEL_REDIRECT_PATH correspond with BE global variables
export enum PRICES_MONTHLY {
    BEGINNER = '$29.99',
    PRO = '$79.99',
}
export const PAYMENT_METHOD_UPDATE_SUCCESS_REDIRECT_URL: string = '/payment-method-update-success';
export const PAYMENT_METHOD_UPDATE_CANCEL_REDIRECT_URL: string = '/payment-method-update-cancelled';
export enum AVATAR_VALUES { // @todo: check when going to prod as these values may be WRONG
    OTHER_GOAL = '6e4972c3-0f98-4e10-830e-c97b6436a20d', // ab_testing_goals table
    OTHER_INDUSTRY = 'c3202e8b-03df-4b52-b5d0-51b3d72cb4b8', // industries table
    OTHER_PRIMARY_OBJECTIVE = 'd847f478-4998-4a29-8f76-6746db31a200', // website_objectives table
    OTHER_JOB_CATEGORY = '37d4c20a-75f3-40d7-8b36-4a1b5d45f9c8', // job_categories table
    OTHER_COMPETITOR = '008f5156-12a3-449d-a611-a27a8f50ff58', // previous_platforms table
}
export enum MAX_VARIANTS {
    FREE = 1,
    BEGINNER = 2,
    PRO = 4,
    // @todo: perhaps add a platinum plan or something later - ref PLAT2523
}
