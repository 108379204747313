import React from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { editWebsiteSchema } from '../../../../schemas/WebsiteSchema';
import { InputField } from '../../../default/Form/Form';
import { Loading, Error, Success } from '../../../default/States/States';
import { EditPageFormInputs, EditPageFormProps } from './types';
import { useEditPage } from '../../../../hooks/pages/useEditPage/useEditPage';
import styles from '../../../../css/default.module.scss';

const EditPageForm = ({
    selectedRow,
    refetchPages,
    setModalIsOpen,
}: EditPageFormProps): JSX.Element => {
    const { loading, error, editPage, success } = useEditPage(selectedRow?.id);
    const handleSubmit: SubmitHandler<EditPageFormInputs> = async (formData) => {
        await editPage(formData);
        refetchPages();
    };
    const methods = useForm<EditPageFormInputs>({
        resolver: yupResolver(editWebsiteSchema),
        mode: 'onTouched',
        defaultValues: {
            name: selectedRow?.name,
            url: selectedRow?.url,
        },
    });

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                    <div className={styles.formInner}>
                        <InputField type='text' title='name' placeholder='e.g. Homepage' />
                        <InputField
                            type='text'
                            title='url'
                            placeholder='e.g. https://www.example.com'
                        />
                    </div>
                    {loading ? (
                        <Loading />
                    ) : (
                        <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                            <button type='submit' className={styles.btnPrimary}>
                                Submit
                            </button>
                            <button
                                onClick={() => setModalIsOpen(false)}
                                className={styles.btnDark}
                            >
                                Cancel
                            </button>
                        </div>
                    )}
                </form>
            </FormProvider>
            {error && <Error msg={error as string} marginTop={true} />}
            {success && <Success msg='Page changed successfully' marginTop={true} />}
        </>
    );
};

export default EditPageForm;
