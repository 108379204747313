import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { CheckboxFields, InputField } from '../Form/Form';
import { Loading, Error } from '../States/States';
import styles from '../../../css/default.module.scss';
import { SignupFormProps } from './types';
import { signupFormSchema } from '../../../schemas/MarketingSchemas';
import customStyles from './styles.module.scss';
import { useCreateNewsletterSubscriberMutation } from '../../../graphql/generated/schema';
import { useNavigate } from 'react-router-dom';
import { useEffect } from 'react';

type FormInputs = {
    firstName: string;
    email: string;
    newsletter: string[];
};

export const SignupForm = ({ onCancel }: SignupFormProps): JSX.Element => {
    const [{ data, fetching: loading, error }, createSubscriber] =
        useCreateNewsletterSubscriberMutation();
    const navigate = useNavigate();

    const handleSubmitFn: SubmitHandler<FormInputs> = async ({ firstName, email }) => {
        const refinedInputs = {
            input: {
                firstName,
                email,
            },
        };

        await createSubscriber(refinedInputs);
    };

    const methods = useForm<FormInputs>({
        resolver: yupResolver(signupFormSchema),
        mode: 'onTouched',
        defaultValues: {
            firstName: '',
            email: '',
            newsletter: [],
        },
    });

    useEffect(() => {
        if (data?.createNewsletterSubscriber?.success) {
            navigate('/newsletter-signup/success');
        }
    }, [data, navigate]);

    return (
        <>
            <FormProvider {...methods}>
                <form data-ab='cro-simple' onSubmit={methods.handleSubmit(handleSubmitFn)}>
                    <div className={styles.formInner}>
                        <InputField
                            type='text'
                            title='firstName'
                            alias='first name'
                            placeholder='e.g. Homepage'
                        />
                        <InputField type='text' title='email' placeholder='e.g. joe@bloggs.com' />
                        <CheckboxFields
                            title='newsletter'
                            values={['*Includes newsletter signup']}
                            cls={customStyles.checkbox}
                        />
                    </div>
                    {loading ? (
                        <Loading />
                    ) : (
                        <div
                            className={`${styles.buttonsContainer} ${styles.spaced} ${customStyles.buttonsContainer}`}
                        >
                            <button type='submit' className={styles.btnPrimary}>
                                Download
                            </button>
                            <button onClick={onCancel} className={styles.btnRed}>
                                Cancel
                            </button>
                        </div>
                    )}
                </form>
            </FormProvider>
            {error && <Error msg={error.message} marginTop={true} />}
        </>
    );
};
