import React, { memo } from 'react';
import styles from '../../../css/default.module.scss';
import { DynamicTableProps, DynamicTableRowProps, DynamicTableHeaderProps } from './types';

// note: DynamicTableProps type should only accept keyField if data excludes _id field & vice versa
// beware: expensive component & children re-render with prop changes, so use useMemo & useCallback wherever possible
export const DynamicTable = memo(
    ({
        data,
        headers,
        clsOuter = '',
        clsInner = '',
        maxWidth = 'none',
        keyField,
        onClick,
    }: DynamicTableProps): JSX.Element => (
        <div
            className={`${styles.dynamicTableOuter} ${clsOuter} ${
                onClick ? styles.interactive : ''
            }`}
            style={{ maxWidth }}
        >
            <table className={`${styles.dynamicTableInner} ${clsInner}`}>
                <DynamicTableHeader headers={headers} />
                <tbody>
                    {data.map((props: DynamicTableRowProps) => {
                        const { id, ...rowProps } = props;
                        const { icon, ...onSelectedProps } = props;
                        return (
                            <DynamicTableRow
                                {...rowProps}
                                key={id || props[`${keyField}`]}
                                {...(onClick
                                    ? {
                                          onClick: () => onClick(onSelectedProps),
                                      }
                                    : {})}
                            />
                        );
                    })}
                </tbody>
            </table>
        </div>
    )
);

const DynamicTableHeader = memo(
    ({ headers }: DynamicTableHeaderProps): JSX.Element => (
        <thead className={styles.head}>
            <tr className={`${styles.row} ${styles.headerRow}`}>
                {headers.map((name: string) => (
                    <th className={styles.header} key={name}>
                        {name}
                    </th>
                ))}
            </tr>
        </thead>
    )
);

const DynamicTableRow = memo(({ onClick, ...data }: DynamicTableRowProps): JSX.Element => {
    const rowValues = Object.values(data);
    const rowEntries = Object.entries(rowValues);

    return (
        <tr className={styles.row} onClick={onClick}>
            {rowEntries.map(([key, value]) => (
                <td className={styles.cell} key={key}>
                    {value}
                </td>
            ))}
        </tr>
    );
});
