import React from 'react';
import { Section } from '../../../components/default/Areas/Areas';
import { withPage } from '../../../components/layout/Page/Page';
import {
    COOKIE_DURATION,
    DEFAULT_AFFILIATE_RATE,
    // DEFAULT_AFFILIATE_RATE,
    MINIMUM_PAYMENT_THRESHOLD,
    MINIMUM_QUALIFICATION_TIME_PLURAL,
    MINIMUM_QUALIFICATION_TIME_SINGULAR,
} from '../../../utils/global-variables';
import { affiliateFaqMeta } from '../../misc/MetaTags';
import { Back } from '../../../components/default/Navigation/Navigation';

const AffiliateFaq = () => (
    <>
        <Section patterned={true}>
            <h1>Affiliate FAQ</h1>
            <p>Learn more about our affiliate program by reading commonly-asked questions.</p>
        </Section>

        <Section patterned={true}>
            <h2>How it works</h2>
            <h3>What happens when someone I refer signs up?</h3>
            <p>
                You as the referrer will be credited to their account, however, you will only earn
                commissions if they convert to a paying customer.
            </p>
            <h3>
                What happens if a prospect who I have referred is then referred by someone else?
            </h3>
            <p>
                The <em>first</em> referrer receives the commission. For example: if someone lands
                on our website through your referral link but then returns later through a different
                referral link, <em>you</em> will be credited since they were referred by you first.
            </p>
        </Section>

        <Section patterned={true}>
            <h2>Terms</h2>
            <h3>What is your commission rate?</h3>
            <p>
                {DEFAULT_AFFILIATE_RATE}, meaning you will simply receive {DEFAULT_AFFILIATE_RATE}{' '}
                of the total revenue from that customer.
            </p>
            <h3>What is the cookie duration?</h3>
            <p>
                Prospects you send to our website have {COOKIE_DURATION} to <em>sign up</em> in
                order for you to be credited as their referrer (before the cookie expires). Once
                they sign up, there is no deadline for them to convert into a paying customer in
                order for you to receive commissions.
            </p>
            <h3>How long do I receive commissions for?</h3>
            <p>
                Forever, throughout the customer lifetime. For example: if a customer you send us
                continues to pay for our product for 10 years, you will continue to receive
                commissions for every payment they have made for all 10 years, and at{' '}
                {DEFAULT_AFFILIATE_RATE}. If they switch back to the free plan and later switch back
                to a paid plan again, you will continue to receive commissions again automatically.
            </p>
        </Section>

        <Section patterned={true}>
            <h2>Payments</h2>
            <h3>What is the minimum qualification time?</h3>
            <p>
                The minimum amount of time that has to elapse before a pending commission becomes
                qualified is {MINIMUM_QUALIFICATION_TIME_PLURAL}. We only pay our affiliates for
                qualified commissions.
            </p>
            <p>
                Note that whenever a referred customer becomes a paying customer, the commission for
                each payment is initially classified as pending until a further{' '}
                {MINIMUM_QUALIFICATION_TIME_PLURAL} have passed, then it will be classified as
                qualified. There is essentially a {MINIMUM_QUALIFICATION_TIME_SINGULAR} delay for
                every commission throughout the lifetime of the customer.
            </p>
            <h3>Why is there a minimum qualification time?</h3>
            <p>
                We need to ensure that commissions are not paid for sales that get refunded or
                disputed. Sales cannot get refunded after the minimum qualification time has
                elapsed, but they could get refunded if the customer requests a refund or opens a
                chargeback before this time has elapsed.
            </p>
            <h3>When will my balance get paid?</h3>
            <p>
                We only pay the <em>qualified</em> balance at the end of the month provided it has
                reached the minimum payment threshold - if this has not yet been reached, it will be
                rolled over to the next month. Payments will be made to you automatically.
            </p>
            <h3>What is the minimum payment threshold?</h3>
            <p>
                The minimum qualified balance for a payment to be made to you is{' '}
                {MINIMUM_PAYMENT_THRESHOLD}.
            </p>
            <h3>My account shows different balances - what are they?</h3>
            <p>There are 3 different types of balances:</p>
            <ul>
                <li>pending balance: the total of all pending commissions</li>
                <li>
                    qualified balance: the total of all unpaid qualified commissions; the balance
                    due to be paid to you
                </li>
                <li>unpaid balance: the total of the pending and qualified balances</li>
            </ul>
            <h3>What happens if my payment details expire?</h3>
            <p>
                You will get prompted to renew your payment details if they are nearing expiry. If
                they expire and we cannot pay you, you will be paid in the following payment cycle
                (including any amounts missed for expired payment details).
            </p>
            <Back to='/affiliates' text='Affiliates' />
        </Section>
    </>
);

export default withPage(AffiliateFaq)({
    meta: affiliateFaqMeta,
    fullWidth: true,
});
