import React from 'react';
import { withPage } from '../../../../components/layout/Page/Page';
import { userPlanMeta } from '../../../misc/MetaTags';
import styles from '../../../../css/default.module.scss';
import { Section } from '../../../../components/default/Areas/Areas';
import { PlanCards } from '../../../../components/default/Cards/PlanCards/PlanCards';
import { Loading, Error } from '../../../../components/default/States/States';
import { useChangePlanCheckout } from '../../../../hooks/payments/useChangePlanCheckout/useChangePlanCheckout';
import { PlanTermsSnippet } from './PlanTermsSnippet/PlanTermsSnippet';
import { Back } from '../../../../components/default/Navigation/Navigation';

const Plan = () => {
    const { changePlan, loading, error } = useChangePlanCheckout();

    return (
        <>
            <h1 className={styles.centralisedText}>Manage Plan</h1>

            <Section id={styles.planCardsSectionContainer} clsInner={styles.inner}>
                <PlanCards changePlan={changePlan} />
                <PlanTermsSnippet />
            </Section>

            {loading && <Loading centralised={true} />}
            {error && <Error msg={error} />}

            <Back to='/user' text='View Dashboard' />
        </>
    );
};

export default withPage(Plan)({ meta: userPlanMeta, innerSpacing: true });
