import React from 'react';
import { withPage } from '../../../components/layout/Page/Page';
import { contactMeta } from '../../misc/MetaTags';
import { HeroArea } from '../../../components/default/Areas/Areas';
import { Link } from 'react-router-dom';

const Description = () => (
    <p>
        Whether you have questions, need assistance, want to{' '}
        <Link to='/services'>buy one of our services</Link>, or simply want to get in touch, we're
        here to help. You can email us or view our support hub which may already have an answer to
        your question.
    </p>
);

const Contact = () => (
    <HeroArea
        title='Contact Us'
        descriptionJsx={<Description />}
        buttonText='Email Us'
        buttonLink='mailto:support@crosimple.com'
        buttonLinkExternal={true}
        buttonTwoText='Support Hub'
        buttonTwoLink='/support'
    />
);

export default withPage(Contact)({
    meta: contactMeta,
    fullWidth: true,
});
