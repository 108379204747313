import React from 'react';
import { ProgressBarProps } from './types';
import styles from './styles.module.scss';

const ProgressBar = ({ percentage, caption, marginBottom }: ProgressBarProps): JSX.Element => {
    const integerPercentage = Math.round(percentage);
    const getColor = (percentage: number): string => {
        const red = Math.min(255, (100 - percentage) * 2.55);
        const green = Math.min(200, percentage * 2.0);
        return `rgb(${red}, ${green}, 0)`;
    };
    const barColor = getColor(integerPercentage);

    return (
        <div className={`${styles.wrap} ${marginBottom ? styles.marginBottom : ''}`}>
            <div className={styles.progressBar}>
                <div className={styles.progressContainer}>
                    <div
                        className={styles.progress}
                        style={{ width: `${integerPercentage}%`, background: barColor }}
                    ></div>
                </div>
            </div>
            <div
                className={`${styles.captionWrap} ${
                    integerPercentage > 50 ? styles.overHalfWay : ''
                }`}
            >
                <span className={styles.caption}>
                    {integerPercentage}% {caption}
                </span>
            </div>
        </div>
    );
};

export default ProgressBar;
