import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faVial } from '@fortawesome/free-solid-svg-icons';
import styles from '../../../../components/default/StackedList/styles.module.scss';
import { useRecentTestsQuery } from '../../../../graphql/generated/schema';
import { Loading, Error } from '../../../default/States/States';
import { NoneFound } from '../../../default/States/States';
import { UserContextType } from '../../../../contexts/UserContext/types';
import { UserContext } from '../../../../contexts/UserContext/UserContext';

const RecentTests = () => {
    const { user } = useContext<UserContextType>(UserContext);
    const [{ data, fetching: loading, error }] = useRecentTestsQuery({
        variables: {
            input: {
                userId: user?.id as string,
                limit: 3,
            },
        },
    });

    if (loading) return <Loading centralised={true} />;
    if (error) return <Error msg={error?.message as string} />;

    return (
        <article className={styles.container}>
            <h2>Recent Tests</h2>
            {!data?.testsForUser?.tests.length ? (
                <NoneFound msg='No tests found' centralised={true} />
            ) : (
                <nav>
                    {data.testsForUser.tests.map((test) => (
                        <Link key={test.id} to={`/user/tests/${test.id}`}>
                            <FontAwesomeIcon icon={faVial} aria-label={test.name} /> {test.name}
                        </Link>
                    ))}
                </nav>
            )}
        </article>
    );
};

export default RecentTests;
