import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { WarningWithChildren } from '../../../default/States/States';
import { useCheckUserPreviousExperienceExistsQuery } from '../../../../graphql/generated/schema';

const ProfilePrompt = () => {
    const [{ data }, checkExperience] = useCheckUserPreviousExperienceExistsQuery({
        pause: true,
    });
    const completedProfile = data?.checkUserPreviousExperienceExists;

    useEffect(() => {
        checkExperience();
    }, [checkExperience]);

    if (completedProfile === false)
        return (
            <WarningWithChildren marginBottom={true}>
                Your profile is incomplete. <Link to='/user/profile/edit'>Complete Profile</Link>.
            </WarningWithChildren>
        );

    return null;
};

export default ProfilePrompt;
