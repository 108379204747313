import React, { useContext } from 'react';
import { DEFAULT_AFFILIATE_RATE, SITE_URL } from '../../../../utils/global-variables';
import { ReferralLinkGenerator } from '../ReferralLinkGenerator/ReferralLinkGenerator';
import { User, UserContextType } from '../../../../contexts/UserContext/types';
import { UserContext } from '../../../../contexts/UserContext/UserContext';

const ReferralPromoCode = () => {
    const { user } = useContext<UserContextType>(UserContext);
    const { id: referralId } = user as User;
    const referralLink: string = `${SITE_URL}/?referrer=${referralId}`;

    return (
        <div>
            <p>Your referral id: {referralId}</p>
            <p>Your referral link: {referralLink}</p>
            <p>
                Promote this link to get credited as the referrer and receive{' '}
                {DEFAULT_AFFILIATE_RATE} lifetime recurring commissions from users that visit that
                link and become paying customers.
            </p>
            <ReferralLinkGenerator referralCode={referralId} />
        </div>
    );
};

export default ReferralPromoCode;
