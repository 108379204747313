import React, { useEffect, useState } from 'react';
import styles from '../../../../../css/default.module.scss';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useMakeTestInactiveMutation } from '../../../../../graphql/generated/schema';
import { useParams, Params } from 'react-router-dom';
import { Success, Error, Loading } from '../../../../default/States/States';
import { PauseTestProps } from './types';

const PauseTest = ({ isActive, refetchTest }: PauseTestProps): JSX.Element => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [{ data, fetching: loading, error }, makeInactiveFn] = useMakeTestInactiveMutation();
    const { testId } = useParams<Params<string>>();

    const openModal = (condition: boolean): void => {
        if (!condition) return;
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const makeInactive = async () => {
        await makeInactiveFn({
            testId: testId as string,
        });
        refetchTest();
    };

    useEffect(() => {
        if (data?.makeTestInactive?.success) {
            const timeout = setTimeout(closeModal, 3000);
            return () => clearTimeout(timeout);
        }
    }, [data?.makeTestInactive?.success, modalIsOpen]);

    return (
        <>
            <button
                onClick={() => openModal(isActive)}
                className={`${styles.btnGrey} ${!isActive ? styles.disabled : ''}`}
            >
                Pause
            </button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className={styles.modal}
                contentLabel='Pause Test'
                portalClassName={styles.modalOverlayWrap} // cannot use overlayClassName
            >
                <button onClick={closeModal} className={styles.close} aria-label='close'>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                {isActive ? (
                    <div>
                        <h2>Pause Test</h2>
                        <p>Please confirm that you would like to pause this test.</p>
                        {loading ? (
                            <Loading centralised={true} />
                        ) : (
                            <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                                <button onClick={makeInactive} className={styles.btnPrimary}>
                                    Pause
                                </button>
                                <button onClick={closeModal} className={styles.btnGrey}>
                                    Cancel
                                </button>
                            </div>
                        )}
                    </div>
                ) : (
                    <>
                        <Success msg='Test paused successfully' marginTop={true} />
                        <Loading centralised={true} marginTop={true} />
                    </>
                )}
                {error && <Error msg={error.message} marginTop={true} />}
            </Modal>
        </>
    );
};

export default PauseTest;
