import React, { useContext, useEffect, useState } from 'react';
import defaultStyles from '../../../../../css/default.module.scss';
import { withPage } from '../../../../../components/layout/Page/Page';
import { changePlanMeta } from '../../../../misc/MetaTags';
import { Link, useNavigate } from 'react-router-dom';
import { UserContextType } from '../../../../../contexts/UserContext/types';
import { UserContext } from '../../../../../contexts/UserContext/UserContext';
import { Loading } from '../../../../../components/default/States/States';
import PageError from '../../../../other/errors/PageError.tsx/PageError';
import ProPlanPersuasionMessage from './ProPlanPersuasionMessage/ProPlanPersuasionMessage';
import BeginnerPlanDissuasionMessage from './BeginnerPlanDissuasionMessage/BeginnerPlanDissuasionMessage';
import ConfirmChangePlanModal from './ConfirmChangePlanModal/ConfirmChangePlanModal';

const ChangePlan = () => {
    const [modalIsOpen, setIsOpen] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const { user } = useContext<UserContextType>(UserContext);
    const navigate = useNavigate();
    const userPlan = user?.userPlan;
    const isFreePlan = userPlan === 'FREE';
    const upgradingToPro = userPlan === 'BEGINNER';
    const prefix = upgradingToPro ? 'Upgrade' : 'Downgrade';
    const newPlan = upgradingToPro ? 'Pro' : 'Beginner';
    const title = `${prefix} to the ${newPlan} Plan`;

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    useEffect(() => {
        if (isFreePlan) {
            navigate('/user/plan');
        }
    }, [navigate, isFreePlan]);

    if (!userPlan || isFreePlan) {
        return <Loading entirePage={true} centralised={true} />;
    }

    if (error) {
        return (
            <PageError
                title='Something Went Wrong'
                msg={error}
                customBtnPrimary={{
                    text: 'Contact Us',
                    url: '/contact',
                }}
            />
        );
    }

    return (
        <>
            <h1 className={defaultStyles.centralisedText}>{title}</h1>
            {upgradingToPro ? <ProPlanPersuasionMessage /> : <BeginnerPlanDissuasionMessage />}
            <div
                className={`${defaultStyles.buttonsContainer} ${defaultStyles.spaced} ${defaultStyles.marginTop} ${defaultStyles.marginBottom}`}
            >
                <button onClick={openModal} className={defaultStyles.btnPrimary}>
                    Continue {prefix}
                </button>
                <Link to='/user/plan' className={defaultStyles.btnGrey}>
                    View Plans
                </Link>
            </div>
            <ConfirmChangePlanModal
                closeModal={closeModal}
                modalIsOpen={modalIsOpen}
                setError={setError}
                upgradingToPro={upgradingToPro}
            />
        </>
    );
};

export default withPage(ChangePlan)({ meta: changePlanMeta, innerSpacing: true });
