import React, { memo } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { editWebsiteSchema } from '../../../../../schemas/WebsiteSchema';
import { InputField } from '../../../../default/Form/Form';
import { Loading, Error, Success, Warning } from '../../../../default/States/States';
import styles from '../../../../../css/default.module.scss';
import { CreateWebsiteFormProps, CreateWebsiteFormInputs } from './types';
import { useCreateWebsite } from '../../../../../hooks/pages/useCreateWebsite/useCreateWebsite';

const CreateWebsiteForm = memo(
    ({ refetchWebsites, setModalIsOpen }: CreateWebsiteFormProps): JSX.Element => {
        const { loading, error, createWebsite, success } = useCreateWebsite();
        const handleSubmit: SubmitHandler<CreateWebsiteFormInputs> = async (formData) => {
            await createWebsite(formData);
            refetchWebsites();
        };
        const methods = useForm<CreateWebsiteFormInputs>({
            resolver: yupResolver(editWebsiteSchema),
            mode: 'onTouched',
            defaultValues: {
                name: '',
                url: '',
            },
        });

        return (
            <>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(handleSubmit)}>
                        <div className={styles.formInner}>
                            <InputField type='text' title='name' placeholder='e.g. Homepage' />
                            <InputField
                                type='text'
                                title='url'
                                placeholder='e.g. https://www.example.com'
                            />
                        </div>
                        <Warning
                            msg='Beware: you can only use a website with or without a subdomain (e.g. www.example.com vs example.com) - A/B testing will only work on one and not the other.'
                            marginBottom={true}
                        />
                        {loading ? (
                            <Loading />
                        ) : (
                            <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                                <button type='submit' className={styles.btnPrimary}>
                                    Submit
                                </button>
                                <button
                                    onClick={() => setModalIsOpen(false)}
                                    className={styles.btnDark}
                                >
                                    Cancel
                                </button>
                            </div>
                        )}
                    </form>
                </FormProvider>
                {error && <Error msg={error as string} marginTop={true} />}
                {success && <Success msg='Website created successfully' marginTop={true} />}
            </>
        );
    }
);

export default CreateWebsiteForm;
