export const BLOG_CONTENTS = [
    {
        id: 'overview',
        label: 'Overview',
    },
    {
        id: 'product-page-testing',
        label: 'You must test your product pages!',
    },
    {
        id: 'plugin-information',
        label: `Why you shouldn't just default to a plugin`,
    },
    {
        id: 'pluginless-ab-testing-alternative',
        label: 'A pluginless alternative',
    },
    {
        id: 'how-to-create-an-ab-test',
        label: 'Creating an A/B test for Wordpress with WooCommerce',
    },
    {
        id: 'product-page-ab-test-video-demo',
        label: 'In-depth Video Demo',
    },
    {
        id: 'suggestions',
        label: 'Our suggestions',
    },
    {
        id: 'conclusion',
        label: 'Conclusion',
    },
];
