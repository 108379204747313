import React, { useEffect, useState } from 'react';
import styles from '../../../../../css/default.module.scss';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDeleteTestDataForTestMutation } from '../../../../../graphql/generated/schema';
import { useParams, Params, useNavigate } from 'react-router-dom';
import { Success, Error, Loading } from '../../../../default/States/States';

const DeleteTestResults = () => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [{ data, fetching: loading, error }, DeleteTestResultsFn] =
        useDeleteTestDataForTestMutation();
    const { testId } = useParams<Params<string>>();
    const navigate = useNavigate();

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const DeleteTestResults = () =>
        DeleteTestResultsFn({
            testId: testId as string,
        });

    useEffect(() => {
        if (data?.deleteTestDataForTest?.success) {
            const timeoutId = setTimeout(() => {
                navigate(`/user/tests/${testId}`);
            }, 3000);

            // Cleanup function to clear the timeout if the component unmounts before the delay
            return () => clearTimeout(timeoutId);
        }
    }, [data?.deleteTestDataForTest?.success, navigate, testId]);

    return (
        <>
            <button onClick={openModal} className={styles.btnRed}>
                Delete Test Results
            </button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className={styles.modal}
                contentLabel='Delete Test Results'
                portalClassName={styles.modalOverlayWrap} // cannot use overlayClassName
            >
                <button onClick={closeModal} className={styles.close} aria-label='close'>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                {!data?.deleteTestDataForTest?.success ? (
                    <div>
                        <h2>Delete Test Results</h2>
                        <p>
                            Are you sure you want to delete this test's data? This action cannot be
                            undone.
                        </p>
                        <p>
                            This test will continue to run as usual and will generate new data
                            results, unless paused.
                        </p>
                        {loading ? (
                            <Loading centralised={true} />
                        ) : (
                            <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                                <button onClick={DeleteTestResults} className={styles.btnRed}>
                                    Delete
                                </button>
                                <button onClick={closeModal} className={styles.btnPrimary}>
                                    Cancel
                                </button>
                            </div>
                        )}
                    </div>
                ) : (
                    <>
                        <Success
                            msg='Test results deleted successfully. Now redirecting...'
                            marginTop={true}
                        />
                        <Loading centralised={true} marginTop={true} />
                    </>
                )}
                {error && <Error msg={error.message} marginTop={true} />}
            </Modal>
        </>
    );
};

export default DeleteTestResults;
