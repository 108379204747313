import React from 'react';
import defaultStyles from '../../../../css/default.module.scss';
import { formatDate } from '../../../../utils/HelperFunctions/HelperFunctions';
import { TestInformationProps } from './types';
import { InfoAreaWrap } from '../../../default/Cards/Cards';
import Status from './Status/Status';
import styles from './styles.module.scss';
import ProgressBar from '../../../default/ProgressBar/ProgressBar';

const TestInformation = ({
    testData,
    percentageCompleted,
    testFinished,
}: TestInformationProps): JSX.Element => {
    const {
        active,
        createdAt,
        description,
        endDate,
        goalName,
        lastModified,
        maxCases,
        name,
        pageUrl,
        startDate,
        targetDeviceNames,
        testSessionsCount,
        variantRedirectUrls,
        websiteUrl,
    } = testData || {};

    return (
        <div className={styles.container}>
            <h1 className={defaultStyles.centralisedText}>{name}</h1>
            <Status
                active={active as boolean}
                finished={testFinished}
                started={Boolean(startDate)}
            />
            <ProgressBar
                percentage={percentageCompleted}
                caption='Test Completion'
                marginBottom={true}
            />
            <InfoAreaWrap>
                <h2>Main Information:</h2>
                <p>
                    <strong>Website:</strong> {websiteUrl}
                </p>
                <p>
                    <strong>About:</strong> {description}
                </p>
                <p>
                    <strong>Created:</strong> {formatDate(Number(createdAt))}
                </p>
                <p>
                    <strong>Started:</strong> {startDate ? formatDate(Number(startDate)) : 'No'}
                </p>
                {startDate && (
                    <p>
                        <strong>Ended:</strong>{' '}
                        {endDate ? formatDate(Number(endDate)) : 'Not ended'}
                    </p>
                )}
                {lastModified && (
                    <p>
                        <strong>Last Modified:</strong> {formatDate(Number(lastModified))}
                    </p>
                )}
                <p>
                    <strong>Current Sessions:</strong> {testSessionsCount}
                </p>
                <p>
                    <strong>Max. Sessions:</strong> {maxCases}
                </p>
                <p>
                    <strong>Target Devices:</strong> {targetDeviceNames}
                </p>
                <p>
                    <strong>Goal:</strong> {goalName}
                </p>
            </InfoAreaWrap>
            <InfoAreaWrap>
                <h2>Pages:</h2>
                <p>
                    <strong>Main Page:</strong> {pageUrl}
                </p>
                {variantRedirectUrls && (
                    <div>
                        <p>
                            <strong>Redirects:</strong>
                        </p>
                        <ul>
                            {variantRedirectUrls.split(', ').map((url: string) => (
                                <li key={url}>{url}</li>
                            ))}
                        </ul>
                    </div>
                )}
            </InfoAreaWrap>
        </div>
    );
};

export default TestInformation;
