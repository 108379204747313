import React from 'react';
import { AffiliateBalanceFigures } from '../../../../../components/account/affiliates/AffiliateBalance/AffiliateBalance';
import { AffiliatePaymentsTable } from '../../../../../components/account/affiliates/AffiliatePayments/AffiliatePayments';
import { withPage } from '../../../../../components/layout/Page/Page';
import { affiliatePaymentsMeta } from '../../../../misc/MetaTags';
import defaultStyles from '../../../../../css/default.module.scss';
import { Back } from '../../../../../components/default/Navigation/Navigation';
import AffiliatePaymentDetails from '../../../../../components/account/affiliates/AffiliatePaymentDetails/AffiliatePaymentDetails';
import { InfoAreaWrap } from '../../../../../components/default/Cards/Cards';
// import AffiliatePaymentDetailsNearExpiry from '../../../../../components/account/payments/statuses/AffiliatePaymentDetailsNearExpiry/AffiliatePaymentDetailsNearExpiry';

// note: apparently stripe payment details for receiving payments don't expire
export const AffiliatePayments = () => (
    <>
        {/* <AffiliatePaymentDetailsNearExpiry /> */}
        <h1 className={defaultStyles.centralisedText}>Payments</h1>
        <InfoAreaWrap>
            <h2>History</h2>
            <p>View all payments made to your account.</p>
            <AffiliatePaymentsTable />
            <AffiliateBalanceFigures />
        </InfoAreaWrap>
        <AffiliatePaymentDetails />
        <Back to='/user/profile/affiliate' text='Back to Affiliate Profile' />
    </>
);

export default withPage(AffiliatePayments)({ meta: affiliatePaymentsMeta, innerSpacing: true });
