import { useContext, useMemo } from 'react';
import { useTestsForWebsiteQuery } from '../../../graphql/generated/schema';
import { AccountContext } from '../../../contexts/AccountContext/AccountContext';
import { AccountContextType } from '../../../contexts/AccountContext/types';
import { WebsiteId } from '../../../utils/global-types';
import { RefinedTest, UseGetTests } from './types';
import { faCheckCircle, faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatDate } from '../../../utils/HelperFunctions/HelperFunctions';
import { Link } from 'react-router-dom';
import styles from '../../../css/default.module.scss';

// keep as .tsx as it needs the icon (in JSX)
export const useGetTests: UseGetTests = (options) => {
    const { selectedWebsite } = useContext<AccountContextType>(AccountContext);
    const { limit = 10, skip = 0 } = options || {};
    const [{ data, fetching: loading, error }, refetchPages] = useTestsForWebsiteQuery({
        variables: {
            input: {
                websiteId: selectedWebsite?.id as WebsiteId,
                limit,
                skip,
            },
        },
    });
    const refinedTests = useMemo<RefinedTest[] | undefined>(
        () =>
            data?.tests?.tests.map(({ id, name, active, startDate }) => ({
                id,
                name,
                active: active ? (
                    <FontAwesomeIcon
                        icon={faCheckCircle}
                        aria-label='tick'
                        className={styles.greenText}
                    />
                ) : (
                    <FontAwesomeIcon
                        icon={faTimesCircle}
                        aria-label='cross'
                        className={styles.redText}
                    />
                ),
                startDate: startDate ? formatDate(Number(startDate)) : '',
                view: (
                    <Link to={`/user/tests/${id}`} className={styles.btnPrimary}>
                        View
                    </Link>
                ),
            })),
        [data]
    );

    return {
        testCount: data?.tests?.total || 0,
        tests: refinedTests,
        loading,
        error,
        refetchPages,
    };
};
