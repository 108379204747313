import { useContext } from 'react';
import { SelectField } from '../../../../default/Form/Form';
import { ErrorText, Loading } from '../../../../default/States/States';
import { Link } from 'react-router-dom';
import { AccountContextType, WebsiteWithId } from '../../../../../contexts/AccountContext/types';
import { AccountContext } from '../../../../../contexts/AccountContext/AccountContext';
import { RefinedPage, SelectPageInputProps, SelectPageProps } from './types';

export const SelectPage = ({
    pagesData,
    loadingPages,
    selectPageAlias = 'Page',
}: SelectPageProps): JSX.Element | null => {
    const { selectedWebsite } = useContext<AccountContextType>(AccountContext);

    if (!selectedWebsite) return null;
    if (loadingPages) return <Loading centralised={true} />;
    if (!pagesData) return null;

    return (
        <>
            {pagesData.pages?.total ? (
                <SelectPageInput pagesData={pagesData} title='pageId' alias={selectPageAlias} />
            ) : (
                <SelectWebsitePrompt />
            )}
            <Link to='/user/pages' className='testy'>
                Create page
            </Link>
        </>
    );
};

export const SelectPageInput = ({
    pagesData,
    title,
    alias = 'page',
}: SelectPageInputProps): JSX.Element => (
    <SelectField title={title} type='select' alias={alias}>
        <option value=''>-- Select --</option>
        {pagesData.pages.pages.map(({ id, url }: RefinedPage) => (
            <option key={id} value={id}>
                {url}
            </option>
        ))}
    </SelectField>
);

export const SelectWebsitePrompt = () => {
    const { selectedWebsite } = useContext<AccountContextType>(AccountContext);
    const msg = `No pages found for website ${
        (selectedWebsite as WebsiteWithId).url
    }. You must create a page before you can proceed.`;

    return <ErrorText msg={msg} />;
};
