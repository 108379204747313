import React, { useMemo } from 'react';
import { ErrorText } from '../../../default/States/States';
import { DynamicTable } from '../../../default/Table/Table';
import styles from './styles.module.scss';
import { InputFieldWithoutLabel } from '../../../default/Form/Form';
import { Variant } from '../../../../pages/account/private/Tests/CreateTest/types';
import { useFormContext, useWatch } from 'react-hook-form';
import useVariantsValidation from '../../../../hooks/tests/useVariantsValidation/useVariantsValidation';

const CreateTestStageThree = () => {
    const {
        formState: {
            errors: { variants: trafficAllocationError },
        },
    } = useFormContext();
    const variantValues = useWatch({
        name: 'variants',
    });

    const stageThreeAdjustedVariants = useMemo(
        () =>
            variantValues.map((variant: Variant, index: number) => ({
                id: `${variant.name}-${index}`,
                name: variant.name,
                description: variant.description,
                trafficAllocation: (
                    <InputFieldWithoutLabel
                        type='number'
                        title={`variants[${index}].trafficAllocation`}
                    />
                ),
            })),
        [variantValues]
    );

    useVariantsValidation();

    return (
        <div>
            <p>
                Traffic allocation determines the proportion of visitors who will see the original
                page (control) versus the different variants in your experiment. You can set the
                traffic allocation of each variant and the control below:
            </p>
            <DynamicTable
                data={stageThreeAdjustedVariants}
                headers={['name', 'description', 'traffic allocation (%)']}
                clsOuter={`${styles.stageThreeTable} ${styles.specificity}`}
            />

            {trafficAllocationError?.message && (
                <ErrorText msg={trafficAllocationError.message as string} marginBottom={true} />
            )}
        </div>
    );
};

export default CreateTestStageThree;
