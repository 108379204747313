import React, { memo, useContext } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { editWebsiteSchema } from '../../../../../schemas/WebsiteSchema';
import { InputField, SelectField } from '../../../../default/Form/Form';
import { Loading, Error, Success } from '../../../../default/States/States';
import { CreatePageFormInputs, CreatePageFormProps } from './types';
import { AccountContext } from '../../../../../contexts/AccountContext/AccountContext';
import { AccountContextType } from '../../../../../contexts/AccountContext/types';
import { useCreatePage } from '../../../../../hooks/pages/useCreatePage/useCreatePage';
import styles from '../../../../../css/default.module.scss';

const CreatePageForm = memo(
    ({ refetchPages, setModalIsOpen }: CreatePageFormProps): JSX.Element => {
        const { websites, selectedWebsite } = useContext<AccountContextType>(AccountContext);
        const { loading, error, createPage, success } = useCreatePage();
        const handleSubmit: SubmitHandler<CreatePageFormInputs> = async (formData) => {
            await createPage(formData);
            refetchPages();
        };
        const methods = useForm<CreatePageFormInputs>({
            resolver: yupResolver(editWebsiteSchema),
            mode: 'onTouched',
            defaultValues: {
                name: '',
                url: '',
                websiteId: selectedWebsite?.id,
            },
        });

        return (
            <>
                <FormProvider {...methods}>
                    <form onSubmit={methods.handleSubmit(handleSubmit)}>
                        <div className={styles.formInner}>
                            <InputField type='text' title='name' placeholder='e.g. Homepage' />
                            <InputField
                                type='text'
                                title='url'
                                placeholder='e.g. https://www.example.com'
                            />
                            <SelectField title='websiteId' type='select' alias='Website'>
                                {websites?.map((website) => (
                                    <option key={website.id} value={website.id}>
                                        {website.url}
                                    </option>
                                ))}
                            </SelectField>
                        </div>
                        {loading ? (
                            <Loading />
                        ) : (
                            <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                                <button type='submit' className={styles.btnPrimary}>
                                    Submit
                                </button>
                                <button
                                    onClick={() => setModalIsOpen(false)}
                                    className={styles.btnDark}
                                >
                                    Cancel
                                </button>
                            </div>
                        )}
                    </form>
                </FormProvider>
                {error && <Error msg={error as string} marginTop={true} />}
                {success && <Success msg='Page created successfully' marginTop={true} />}
            </>
        );
    }
);

export default CreatePageForm;
