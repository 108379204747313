import { useState } from 'react';
import { useCreatePageMutation } from '../../../graphql/generated/schema';
import { CreatePageFn, UseCreatePage } from './types';
import trimTrailingSlash from '../../../utils/HelperFunctions/trimTrailingSlash';

export const useCreatePage: UseCreatePage = () => {
    const [error, setError] = useState<string | boolean>(false);
    const [success, setSuccess] = useState<boolean>(false);
    const [{ fetching: loading }, makePage] = useCreatePageMutation();

    const createPage: CreatePageFn = async ({ name, url, websiteId }) => {
        const cleanedUrl = trimTrailingSlash(url);

        try {
            const { data, error } = await makePage({
                name,
                url: cleanedUrl,
                websiteId,
            });

            if (error || !data) {
                const errorText = error?.message || 'Could not create page';
                setSuccess(false);
                setError(`HTTP error: ${errorText}`);
                return;
            }

            setError(false);
            setSuccess(true);
        } catch (err: any) {
            setSuccess(false);
            setError(err.message);
        }
    };

    return { loading, error, createPage, success };
};
