import React, { useContext } from 'react';
import { withPage } from '../../../../components/layout/Page/Page';
import defaultStyles from '../../../../css/default.module.scss';
import { SelectWebsiteDropdownForm } from '../../../../components/account/Pages/SelectWebsiteDropdown/SelectWebsiteDropdown';
import { AccountContext } from '../../../../contexts/AccountContext/AccountContext';
import { AccountContextType } from '../../../../contexts/AccountContext/types';
import { userTestsMeta } from '../../../misc/MetaTags';
import TestListTable from '../../../../components/account/Tests/TestListTable/TestListTable';
import { Link } from 'react-router-dom';
import { useSelectWebsites } from '../../../../hooks/pages/useSelectWebsites/useSelectWebsites';
import { Loading } from '../../../../components/default/States/States';
import PageError from '../../../other/errors/PageError.tsx/PageError';
import { Back } from '../../../../components/default/Navigation/Navigation';

const Tests = () => {
    const { selectedWebsite, websites } = useContext<AccountContextType>(AccountContext); // @todo: investigate if this is causing children to re-render too often
    const {
        loading: loadingWebsites,
        error: websiteError,
        handleWebsiteChange,
    } = useSelectWebsites();

    if (loadingWebsites) return <Loading entirePage={true} />;

    if (websiteError) {
        return <PageError msg={websiteError} />;
    }

    if (!websites?.length) {
        return (
            <PageError
                title='You Cannot Create a Test Yet'
                msg="You haven't created a website yet. In order to create a test, you must create a website."
                customBtnPrimary={{
                    text: 'Go To Websites',
                    url: '/user/websites',
                }}
            />
        );
    }

    return (
        <>
            <h1 className={defaultStyles.centralisedText}>Tests</h1>
            <SelectWebsiteDropdownForm handleWebsiteChange={handleWebsiteChange} />
            {selectedWebsite && <TestListTable />}
            <div className={defaultStyles.buttonsContainer}>
                <Link to='/user/tests/new' className={defaultStyles.btnPrimary}>
                    Create Test
                </Link>
            </div>
            <Back to='/user' text='Back to Dashboard' />
        </>
    );
};

export default withPage(Tests)({ meta: userTestsMeta, innerSpacing: true });
