import React, { memo, useState } from 'react';
import styles from '../../../../css/default.module.scss';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import customStyles from './styles.module.scss';
import { CreateWebsiteModalProps } from './types';
import CreateWebsiteForm from './CreateWebsiteForm/CreateWebsiteForm';

const CreateWebsiteModal = ({ refetchWebsites }: CreateWebsiteModalProps): JSX.Element => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div className={customStyles.container}>
            <button onClick={openModal} className={styles.btnPrimary}>
                Create Website
            </button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className={styles.modal}
                contentLabel='Create Website'
                portalClassName={styles.modalOverlayWrap} // cannot use overlayClassName
            >
                <button onClick={closeModal} className={styles.close} aria-label='close'>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <div>
                    <h2>Create Website</h2>
                    <CreateWebsiteForm
                        refetchWebsites={refetchWebsites}
                        setModalIsOpen={setModalIsOpen}
                    />
                </div>
            </Modal>
        </div>
    );
};

export default memo(CreateWebsiteModal);
