import * as Yup from 'yup';
import { HOSTNAME, SITE_URL } from '../utils/global-variables';

const URL_REGEX_HTTPS: RegExp =
    /https:\/\/(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9_.//]*)/;
const HOSTNAME_REGEX: RegExp = new RegExp(HOSTNAME);

export const referralLinkGeneratorSchema = Yup.object().shape({
    url: Yup.string()
        .required('Please enter a URL')
        .matches(URL_REGEX_HTTPS, `Enter a valid URL with a HTTPS prefix, e.g. ${SITE_URL}/about`)
        .matches(HOSTNAME_REGEX, 'Links must be to our website'),
});
