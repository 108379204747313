const STD_BTN_SHARED_HEADERS = [
    'Variant',
    'Form Submits',
    'Time on Page (median)',
    'Scrolled Down %',
    'Page Clicks',
    'Bounce Rate',
]; // do not add sessions to STD_BTN_SHARED_HEADERS, otherwise it will affect the ordering

export const STANDARD_HEADERS = [...STD_BTN_SHARED_HEADERS, 'Sessions', ''];

export const BUTTON_HEADERS = [
    ...STD_BTN_SHARED_HEADERS,
    'Button Clicks',
    'Button Hovers',
    'Sessions',
    '',
];

export const FORM_HEADERS = [
    'Variant',
    'Time on Page (median)',
    'Scrolled Down %',
    'Page Clicks',
    'Bounce Rate',
    'Form Clicks',
    'Form Hovers',
    'Form Submission Rate',
    'Time Using Form (median)',
    'Sessions',
    '',
];
