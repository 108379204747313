import React from 'react';
import { withPage } from '../../../../../components/layout/Page/Page';
import { userTestMeta } from '../../../../misc/MetaTags';
import TestInformation from '../../../../../components/account/Test/TestInformation/TestInformation';
import TestVariantsTable from '../../../../../components/account/Test/TestVariantsTable/TestVariantsTable';
import TestActions from '../../../../../components/account/Test/TestActions/TestActions';
import { useParams, Params } from 'react-router-dom';
import { Loading } from '../../../../../components/default/States/States';
import { useTestQuery } from '../../../../../graphql/generated/schema';
import PageError from '../../../../other/errors/PageError.tsx/PageError';
import { Back } from '../../../../../components/default/Navigation/Navigation';
import { InfoAreaWrap } from '../../../../../components/default/Cards/Cards';

const Test = () => {
    const { testId } = useParams<Params<string>>();
    const [{ data, fetching: loading, error }, refetchTest] = useTestQuery({
        variables: {
            testId: testId as string,
        },
    });
    const percentageCompleted =
        data?.test && (data.test.testSessionsCount / data.test.maxCases) * 100;
    const testFinished = Boolean(percentageCompleted && percentageCompleted >= 100);

    if (loading) return <Loading centralised={true} entirePage={true} />;
    if (error || !data?.test || percentageCompleted === undefined || testFinished === undefined)
        return <PageError msg={error?.message} />;

    // @todo: display whether the test has finished or not, or even what percentage completed
    // When the test reaches the max cases, what happens? Does it automatically turn off, and display ‘finished’ or similar on the test page?

    return (
        <>
            <TestInformation
                testData={data.test}
                percentageCompleted={percentageCompleted}
                testFinished={testFinished}
            />
            <InfoAreaWrap>
                <h2>Variants:</h2>
                <TestVariantsTable pageUrl={data.test.pageUrl} />
            </InfoAreaWrap>
            <TestActions
                isActive={data.test.active}
                refetchTest={refetchTest}
                testFinished={testFinished}
            />
            <Back to='/user/tests/' text='Back to Tests' />
        </>
    );
};

export default withPage(Test)({ meta: userTestMeta, innerSpacing: true });
