import { useMemo } from 'react';
import { useGetAffiliateCommissionsQuery } from '../../../graphql/generated/schema';
import { refineAffiliateTransactions } from '../helpers/helpers';
import { UseAffiliateCommissions } from './types';
import { RefinedAffiliateTransactions } from '../useAffiliateTransactions/types';

export const useAffiliateCommissions: UseAffiliateCommissions = (options) => {
    const [limit, skip] = [options?.limit || 0, options?.skip || 0];
    const [{ data, fetching: loading, error }] = useGetAffiliateCommissionsQuery({
        variables: {
            limit,
            skip,
        },
    });
    const refinedData = useMemo<RefinedAffiliateTransactions | undefined>(
        () => refineAffiliateTransactions(data?.affiliateTransactions?.transactions),
        [data]
    );
    return {
        commissionsCount: data?.affiliateTransactions?.total || 0,
        commissions: refinedData,
        loading,
        error,
    };
};
