import React, { useState } from 'react';
import styles from '../../../../css/default.module.scss';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import customStyles from './styles.module.scss';
import CreatePageForm from './CreatePageForm/CreatePageForm';
import { CreatePageModalProps } from './types';

export const CreatePageModal = ({ refetchPages }: CreatePageModalProps): JSX.Element => {
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

    const openModal = () => {
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    return (
        <div className={customStyles.container}>
            <button onClick={openModal} className={styles.btnPrimary}>
                Create Page
            </button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className={styles.modal}
                contentLabel='Create Page'
                portalClassName={styles.modalOverlayWrap} // cannot use overlayClassName
            >
                <button onClick={closeModal} className={styles.close} aria-label='close'>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <div>
                    <h2>Create Page</h2>
                    <CreatePageForm refetchPages={refetchPages} setModalIsOpen={setModalIsOpen} />
                </div>
            </Modal>
        </div>
    );
};
