import React, { useState } from 'react';
import { HeroArea, Section } from '../../../../../../components/default/Areas/Areas';
import { withPage } from '../../../../../../components/layout/Page/Page';
import { paidAdLandingPage } from '../../../../../misc/MetaTags';
import styles from './styles.module.scss';
import { VideoModal } from '../../../../../../components/default/VideoModal/VideoModal';
import VideoPlaceholderImage from '../../../../../../components/default/VideoModal/video-placeholder.webp';
import BenefitBox from '../../../../../../components/default/Informational/BenefitBox/BenefitBox';
import GetStarted from '../../../../../../components/default/GetStarted/GetStarted';
import useQuoraCustomTrackingEvent from '../../../../../../hooks/tracking/useQuoraCustomTrackingEvent/useQuoraCustomTrackingEvent';
import { FreeGuide } from '../../../../../../components/default/FreeGuide/FreeGuide';

const PaidAdLandingPageOne = () => {
    const [videoModalOpen, setVideoModalOpen] = useState<boolean>(false);

    useQuoraCustomTrackingEvent('GenerateLead');

    return (
        <>
            <HeroArea
                title='DIY A/B Tests for Websites'
                description="Create different versions of your web pages and test which one's best. Simple setup and easy to get started for anyone."
                buttonText='Try Free Version'
                buttonLink='/pricing'
                buttonTwoText='Watch Video'
                buttonTwoOnClick={() => setVideoModalOpen(true)}
                leftText={true}
                image={VideoPlaceholderImage}
                imageAlt='A/B testing video demonstration'
                extraImagePadding={true}
                clsOuter={styles.heroOuter}
                clsInner={styles.heroInner}
                onImageClick={() => setVideoModalOpen(true)}
            />

            <FreeGuide inverse={true} />

            <Section patterned={true} clsInner={styles.benefitsContainer}>
                <h2>Our Platform in a Nutshell</h2>
                <div className={styles.benefits}>
                    <BenefitBox
                        description='No complex process for creating tests - just install the tracking script and get started.'
                        title='Simple'
                    />
                    <BenefitBox
                        description='Forever free version and affordable paid versions, with no expensive management costs.'
                        title='Affordable'
                    />
                    <BenefitBox
                        description='Non-technical users can just compare different page URLs for their tests, otherwise more advanced users can inject code (CSS and JavaScript).'
                        title='Non-technical'
                    />
                    <BenefitBox
                        description='Self-managed and non-reliant on third parties.'
                        title='Do it yourself'
                    />
                </div>
            </Section>

            <VideoModal
                customOpenState={{ isCustomOpen: videoModalOpen, setCustomOpen: setVideoModalOpen }}
            />

            <GetStarted />
        </>
    );
};

export default withPage(PaidAdLandingPageOne)({
    meta: paidAdLandingPage,
    fullWidth: true,
    clsPage: styles['landing-page-one'],
    hasFooterLinks: false,
    noIndex: true,
    lightBackground: true,
});
