import React, { useEffect, useState } from 'react';
import styles from '../../../../../css/default.module.scss';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useDeleteTestMutation } from '../../../../../graphql/generated/schema';
import { useParams, Params, useNavigate } from 'react-router-dom';
import { Success, Error, Loading } from '../../../../default/States/States';

const DeleteTest = () => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [{ data, fetching: loading, error }, deleteTestFn] = useDeleteTestMutation();
    const { testId } = useParams<Params<string>>();
    const navigate = useNavigate();

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    const deleteTest = () =>
        deleteTestFn({
            testId: testId as string,
        });

    useEffect(() => {
        if (data?.deleteTest?.success) {
            const timeoutId = setTimeout(() => {
                navigate('/user/tests');
            }, 3000);

            // Cleanup function to clear the timeout if the component unmounts before the delay
            return () => clearTimeout(timeoutId);
        }
    }, [data?.deleteTest?.success, navigate]);

    return (
        <>
            <button onClick={openModal} className={styles.btnRed}>
                Delete
            </button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className={styles.modal}
                contentLabel='Delete Test'
                portalClassName={styles.modalOverlayWrap} // cannot use overlayClassName
            >
                <button onClick={closeModal} className={styles.close} aria-label='close'>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                {!data?.deleteTest?.success ? (
                    <div>
                        <h2>Delete Test</h2>
                        <p>
                            Are you sure you want to delete this test? This action cannot be undone
                            and will delete any test data that may exist.
                        </p>
                        {loading ? (
                            <Loading centralised={true} />
                        ) : (
                            <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                                <button onClick={deleteTest} className={styles.btnRed}>
                                    Delete
                                </button>
                                <button onClick={closeModal} className={styles.btnPrimary}>
                                    Cancel
                                </button>
                            </div>
                        )}
                    </div>
                ) : (
                    <>
                        <Success
                            msg='Test deleted successfully. Now redirecting...'
                            marginTop={true}
                        />
                        <Loading centralised={true} marginTop={true} />
                    </>
                )}
                {error && <Error msg={error.message} marginTop={true} />}
            </Modal>
        </>
    );
};

export default DeleteTest;
