import {
    faVials,
    faUserCircle,
    faStickyNote,
    faLayerGroup,
    faDollarSign,
} from '@fortawesome/free-solid-svg-icons';

export const LINKS = [
    { to: '/user/tests', icon: faVials, label: 'Tests' },
    { to: '/user/profile', icon: faUserCircle, label: 'Profile' },
    { to: '/user/websites', icon: faLayerGroup, label: 'Websites' },
    { to: '/user/pages', icon: faStickyNote, label: 'Pages' },
    { to: '/user/profile/affiliate', icon: faDollarSign, label: 'Affiliates' },
];
