import React, { FC, useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { InputField } from '../../../default/Form/Form';
import styles from '../../../../css/default.module.scss';
import { referralLinkGeneratorSchema } from '../../../../schemas/AffiliateSchemas';
import { SITE_URL } from '../../../../utils/global-variables';
import { debounce } from '../../../../utils/HelperFunctions/HelperFunctions';
import { ReferralLinkGeneratorFormProps, ReferralLinkGeneratorFormInputs } from './types';

const ReferralLinkGeneratorForm: FC<ReferralLinkGeneratorFormProps> = ({
    referralCode,
    closeModal,
}) => {
    const methods = useForm<ReferralLinkGeneratorFormInputs>({
        resolver: yupResolver(referralLinkGeneratorSchema),
        mode: 'onTouched',
        defaultValues: {
            url: '',
        },
    });
    const { watch, handleSubmit } = methods;
    const [link, setLink] = useState<string>('');
    const [showLink, setShowLink] = useState<boolean>(false);
    const urlValue: string = watch('url');
    const safeDebounce: number = 500; // must be the same for debouncedSubmit and debouncedHideLink, otherwise the link may disappear if pressing submit too quickly after typing

    const debouncedSubmit: SubmitHandler<ReferralLinkGeneratorFormInputs> = useMemo(
        () =>
            debounce(({ url }: ReferralLinkGeneratorFormInputs) => {
                // small regex bug: why are https://example.com~";: and 'https://www.example.com/about woubih' valid?
                // also note: if site forwards traffic from non-www to www link, generated referral link could be wrong
                if (!url) {
                    setLink('');
                    return;
                }
                setLink(`${url}?referrer=${referralCode}`);
                setShowLink(true);
            }, safeDebounce),
        [referralCode]
    );

    const debouncedHideLink = useMemo(
        () =>
            debounce(() => {
                setShowLink(false);
                navigator.clipboard.writeText('');
            }, safeDebounce),
        []
    );

    useEffect(() => {
        navigator.clipboard.writeText(link);
    }, [link]);

    useEffect(() => {
        debouncedHideLink();
    }, [urlValue, debouncedHideLink]);

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={handleSubmit(debouncedSubmit)}>
                    <div className={styles.formInner}>
                        <InputField
                            alias='Enter a URL'
                            type='text'
                            title='url'
                            placeholder={`e.g. ${SITE_URL}/about`}
                        />
                    </div>
                    {link && showLink && (
                        <div className={styles.infoContainer}>
                            <p>Your referral link:</p>
                            <p>{link}</p>
                            <p>Copied to clipboard!</p>
                        </div>
                    )}
                    <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                        <button type='submit' className={styles.btnPrimary}>
                            Generate Link
                        </button>
                        <button onClick={closeModal} className={styles.btnRed}>
                            Close
                        </button>
                    </div>
                </form>
            </FormProvider>
        </>
    );
};

export default ReferralLinkGeneratorForm;
