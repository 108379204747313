import React, { useMemo } from 'react';
import { Params, useParams } from 'react-router-dom';
import { useAffiliateCommissions } from '../../../../hooks/affiliates/useAffiliateCommissions/useAffiliateCommissions';
import { DEFAULT_ITEMS_PER_PAGE } from '../../../../utils/global-variables';
import { Paginator } from '../../../default/Paginator/Paginator';
import { Loading, Error, NoneFound } from '../../../default/States/States';
import { DynamicTable } from '../../../default/Table/Table';
// import styles from './style.module.scss';

export const AffiliateCommissionsTable = () => {
    const itemsPerPage: number = DEFAULT_ITEMS_PER_PAGE;
    const params: Params<string> = useParams();
    const currentPage: number = Number(params.currentPage) || 1;
    const skip: number = (currentPage - 1) * itemsPerPage;
    const { commissionsCount, commissions, loading, error } = useAffiliateCommissions({
        limit: itemsPerPage,
        skip,
    });
    const headers: string[] = useMemo<string[]>(() => ['Date', 'Amount', 'Qualified'], []);
    const maxTableWidth: number = 500;

    if (loading) return <Loading />;
    if (error) return <Error msg={error.message} />;
    if (!commissions?.length) return <NoneFound msg='No commissions found' />;

    return (
        <>
            <DynamicTable
                // clsOuter={styles.hideQualifiedCol}
                data={commissions}
                headers={headers}
                maxWidth={maxTableWidth}
            />
            <Paginator
                currentPage={currentPage}
                itemCount={commissionsCount}
                itemsPerPage={itemsPerPage}
                path='/user/profile/affiliate/commissions'
                maxWidth={maxTableWidth}
            />
        </>
    );
};
