import React, { FC } from 'react';
import { Header } from '../Header/Header';
import { Footer } from '../Footer/Footer';
import styles from '../../../css/default.module.scss';
import { Helmet } from 'react-helmet-async';
import { CookiePopup } from '../../default/CookiePopup/CookiePopup';
import { PageProps, WithPage } from './types';
import pageStyles from './styles.module.scss';
import { footerLinks } from './variables';

export const Page: FC<PageProps> = ({
    meta,
    noindex = false,
    children,
    fullWidth,
    clsPage = '',
    clsOuter = '',
    clsInner = '',
    clsHeader = '',
    clsFooter = '',
    showHeader = true,
    showFooter = true,
    widerInnerWidth = false,
    innerSpacing = false,
    whiteBox = false,
    lightBackground = false,
    hasFooterLinks = true,
}) => {
    const widerInnerWidthClasses = widerInnerWidth ? styles.widerInnerWidth : '';
    const pageClasses = `${styles.pageContainer} ${clsPage} ${
        lightBackground ? pageStyles['light-background'] : ''
    }`;
    const outerClasses = `${styles.outer} ${styles.mainBody} ${clsOuter} ${
        whiteBox ? styles.smallWhiteBoxOuter : ''
    }`;
    const innerClasses = `${!fullWidth ? styles.inner : ''} ${clsInner} ${widerInnerWidthClasses} ${
        innerSpacing ? styles.innerSpacing : ''
    } ${whiteBox ? styles.smallWhiteBoxInner : ''}`;
    const headerClasses = `${clsHeader} ${widerInnerWidthClasses}`;
    const footerClasses = `${clsFooter} ${widerInnerWidthClasses}`;
    const { title, description } = meta;

    return (
        <>
            <Helmet>
                {/* n.b.: do not add tracking script here, otherwise loads page first, then shows loading spinner, and then shows potentially test-adjusted content (so would be snappy loading etc) */}
                <title>{title}</title>
                <meta name='description' content={description} />
                {noindex && <meta name='robots' content='noindex' />}
            </Helmet>
            <div className={pageClasses}>
                <CookiePopup />
                {showHeader && <Header cls={headerClasses} light={lightBackground} />}
                <div className={outerClasses}>
                    <div className={innerClasses}>{children}</div>
                </div>
                {showFooter && (
                    <Footer cls={footerClasses} links={hasFooterLinks ? footerLinks : null} />
                )}
            </div>
        </>
    );
};

export const withPage: WithPage = (Component) => (props) => () =>
    (
        <Page {...props}>
            <Component {...props} />
        </Page>
    );
