import * as Yup from 'yup';
import { User } from '../contexts/UserContext/types';
import { getMaxTestSessions, getPlanValue } from '../utils/HelperFunctions/HelperFunctions';
import { MAX_TEST_SESSIONS, Plan, Plans } from '../utils/global-variables';
import { Variant, Variants } from '../pages/account/private/Tests/CreateTest/types';

export const createTestSchema = (user: User) =>
    Yup.object().shape({
        name: Yup.string().required('Enter a name'),
        description: Yup.string().required('Enter a description'),
        goalId: Yup.string().required('Select a goal'),
        websiteId: Yup.string().required('Select a website'),
        pageId: Yup.string().required('Select a page'),
        variants: Yup.array()
            .of(
                Yup.object()
                    .shape({
                        name: Yup.string().required('Enter a variant name'),
                        description: Yup.string().required('Enter a variant description'),
                        pageRedirectId: Yup.string(),
                        cssCode: Yup.string().max(
                            10000,
                            'CSS code must not exceed 10000 characters'
                        ),
                        jsCode: Yup.string().max(
                            10000,
                            'JavaScript code must not exceed 10000 characters'
                        ),
                        trafficAllocation: Yup.number().required(
                            'Enter the traffic allocation for this entity'
                        ),
                        isControl: Yup.boolean(),
                    })
                    .test(
                        'redirects to the same page',
                        'You cannot redirect to the same page. Choose a different page to redirect to',
                        function ({ pageRedirectId }, ctx: any) {
                            const pageId = ctx.from[1].value.pageId;
                            const path = `variants[${ctx.options.index}].pageRedirectId`;

                            if (pageRedirectId === pageId) {
                                return this.createError({
                                    message:
                                        'You cannot redirect to the same page. Choose a different page to redirect to',
                                    path,
                                });
                            }

                            return true;
                        }
                    )
                    .test(
                        'has code or redirect, but not both',
                        'Enter at least one of CSS code or JS code, or enter a page redirect',
                        function (value, ctx) {
                            if (value.isControl) return true;

                            // @ts-ignore - unknown issue, but index DOES exist on options
                            const path = `variants[${ctx.options.index}].whatItShouldShow`; // i.e. how it shows in error object
                            const { pageRedirectId, cssCode, jsCode } = value;

                            if (!pageRedirectId && !(cssCode || jsCode)) {
                                return this.createError({
                                    message:
                                        'Enter at least one of CSS code or JS code, or enter a page redirect',
                                    path,
                                });
                            }

                            if (pageRedirectId && (cssCode || jsCode)) {
                                return this.createError({
                                    message:
                                        'You cannot have both a redirect and any code - either remove the redirect or the code.',
                                    path,
                                });
                            }

                            return true; // i.e. no errors
                        }
                    )
            )
            .min(2, 'At least one variant is required')
            .required('Add at least one variant')
            .test(
                'traffic allocation totals 100%',
                'Traffic allocation for all variants and control must total 100',
                function (value) {
                    const path = 'variants';
                    const isTrafficAllocationValid = checkTrafficAllocationValidity(value);

                    if (!isTrafficAllocationValid) {
                        return this.createError({
                            message:
                                'Traffic allocation for all variants and control must total 100',
                            path,
                        });
                    }

                    return true;
                }
            )
            .test('unique variant names', 'Variant names must be unique', function (value) {
                const path = 'variants.custom';
                const names = value?.map((variant) => variant.name);
                const uniqueNames = new Set(names);

                if (names?.length !== uniqueNames.size) {
                    return this.createError({
                        message: 'Variant names must be unique',
                        path,
                    });
                }

                return true;
            }),
        maxSessions: Yup.number()
            .required('Enter a number')
            .test('max-sessions', 'Exceeded maximum sessions', function (value) {
                if (value === undefined) return false;

                const userPlan = user.userPlan;
                const planValue = getPlanValue(userPlan);
                const maxSessions = getMaxTestSessions(planValue);
                const errorMsg = getMaxSessionsErrorMessageFromPlan(userPlan);

                if (!Number.isInteger(value) || value <= 9) {
                    return this.createError({
                        message: 'Max sessions must be a positive integer of 10 or higher',
                    });
                }

                return value <= (maxSessions as number) || this.createError({ message: errorMsg });
            }),
        targeting: Yup.array()
            .of(Yup.string().oneOf(['mobiles', 'desktops', 'tablets']))
            .min(1, 'At least one targeting option must be selected')
            .required('Targeting is required'),
    });

const checkTrafficAllocationValidity = (variants: Variants): boolean => {
    const totalTrafficAllocation = variants.reduce(
        (total: number, variant: Variant) => total + variant.trafficAllocation,
        0
    );
    return totalTrafficAllocation === 100;
};

const getMaxSessionsErrorMessageFromPlan = (userPlan: Plans): string => {
    switch (userPlan) {
        case 'FREE':
            return `Your ${userPlan} account allows a maximum of ${
                MAX_TEST_SESSIONS[Plan.FREE]
            } sessions. Upgrade to a paid account to increase this limit.`;
        case 'BEGINNER':
            return `Your ${userPlan} account allows a maximum of ${
                MAX_TEST_SESSIONS[Plan.BEGINNER]
            } sessions. Upgrade if you want to increase this amount.`;
        case 'PRO':
            return `The maximum number of sessions per test is 10,000 sessions`;
        default:
            return 'Max sessions error';
    }
};
