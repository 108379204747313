import React from 'react';
// import React, { useContext } from 'react';
import { withPage } from '../../../../components/layout/Page/Page';
import { userDashboardMeta } from '../../../misc/MetaTags';
// import { UserContextType } from '../../../../contexts/UserContext/types';
// import { UserContext } from '../../../../contexts/UserContext/UserContext';
// import QuickLinks from '../../../../components/account/Dashboard/QuickLinks/QuickLinks';
import RecentTests from '../../../../components/account/Dashboard/RecentTests/RecentTests';
import styles from '../../../../components/default/StackedList/styles.module.scss';
import defaultStyles from '../../../../css/default.module.scss';
import StackedList from '../../../../components/default/StackedList/StackedList';
import { LINKS } from './variables';
import ProfilePrompt from '../../../../components/account/profile/ProfilePrompt/ProfilePrompt';
import { UserStatus } from '../../../../components/layout/Page/UserStatus/UserStatus';

const Dashboard = () => {
    // const { user } = useContext<UserContextType>(UserContext);

    /*
        @todo:
            - DO AFTER THE TEST RESULTS PAGE IS DONE
            - displays most recent test or active test latest results/stats, inc. variants
            - try to promote the affiliate program on this page, perhaps below the fold or something
    */

    return (
        <>
            <h1 className={defaultStyles.centralisedText}>Dashboard</h1>
            <UserStatus />
            <ProfilePrompt />
            <div className={styles.panelsContainer}>
                {/* @todo later <LatestTest /> */}
                <StackedList items={LINKS} title='Quick Links' areLinks={true} />
                <RecentTests />
            </div>
        </>
    );
};

export default withPage(Dashboard)({ meta: userDashboardMeta, innerSpacing: true });
