import { faVial, faTrophy, faRedo, faTrafficLight } from '@fortawesome/free-solid-svg-icons';
import styles from './styles.module.scss';

export const PROCESS_STEPS = [
    {
        icon: { icon: faTrafficLight },
        title: (
            <>
                <span className={styles.primaryColour}>Send Traffic</span> to a landing page
            </>
        ),
        description: 'e.g. via paid ads',
        ariaLabel: 'test script',
    },
    {
        icon: { icon: faVial },
        title: (
            <>
                <span className={styles.primaryColour}>Create an A/B Test</span> on that page
            </>
        ),
        description: 'e.g. 50% see original, 50% see variant with changes',
        ariaLabel: 'test vial',
    },
    {
        icon: { icon: faTrophy },
        title: (
            <>
                <span className={styles.primaryColour}>Implement</span> the highest converting
                variant
            </>
        ),
        ariaLabel: 'test winner',
    },
    {
        icon: { icon: faRedo },
        title: <span className={styles.primaryColour}>Repeat</span>,
        ariaLabel: 'test repeat',
    },
];
