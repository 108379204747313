import React from 'react';
import { Link } from 'react-router-dom';
import { withPage } from '../../../../../components/layout/Page/Page';
import { affiliateDashboardMeta } from '../../../../misc/MetaTags';
import { IndirectAffiliateSignupMessage } from '../../../../../components/account/affiliates/IndirectAffiliateSignupMessage/IndirectAffiliateSignupMessage';
import ReferralPromoCode from '../../../../../components/account/affiliates/ReferralPromoCode/ReferralPromoCode';
import defaultStyles from '../../../../../css/default.module.scss';
import { ReferralPerformanceMetricTotals } from '../../../../../components/account/affiliates/ReferralList/ReferrralList';
import { AffiliateSessionMetrics } from '../../../../../components/account/affiliates/AffiliateSessions/AffiliateSessions';
import { Back } from '../../../../../components/default/Navigation/Navigation';
import { DEFAULT_AFFILIATE_RATE } from '../../../../../utils/global-variables';
import { InfoWithChildren } from '../../../../../components/default/States/States';
import AffiliatePaymentDetailsNeededManual from '../../../../../components/account/payments/statuses/AffiliatePaymentDetailsNeededManual/AffiliatePaymentDetailsNeededManual';
import { InfoAreaWrap } from '../../../../../components/default/Cards/Cards';
// import AffiliatePaymentDetailsNearExpiry from '../../../../../components/account/payments/statuses/AffiliatePaymentDetailsNearExpiry/AffiliatePaymentDetailsNearExpiry';
// import AffiliatePaymentDetailsNeeded from '../../../../../components/account/payments/statuses/AffiliatePaymentDetailsNeeded/AffiliatePaymentDetailsNeeded';

// note: apparently stripe payment details for receiving payments don't expire
const AffiliateDashboard = () => (
    <InfoAreaWrap>
        {/* <AffiliatePaymentDetailsNearExpiry /> */}
        {/* <AffiliatePaymentDetailsNeeded /> */}
        <AffiliatePaymentDetailsNeededManual />
        <h1 className={defaultStyles.centralisedText}>Affiliate Dashboard</h1>
        <p>Welcome affiliate. Promote our software and get paid.</p>
        <InfoWithChildren marginBottom={true}>
            For every customer you refer, you get a substantial <b>{DEFAULT_AFFILIATE_RATE}</b>{' '}
            commission for the <b>lifetime</b> of the customer for every payment they ever make.{' '}
            <Link to='/affiliates'>Learn more</Link>.
        </InfoWithChildren>
        <IndirectAffiliateSignupMessage />
        <ReferralPerformanceMetricTotals />
        <AffiliateSessionMetrics />
        <ReferralPromoCode />
        <div
            className={`${defaultStyles.buttonsContainer} ${defaultStyles.spaced} ${defaultStyles.marginTop} `}
        >
            <Link to='/user/profile/affiliate/sessions' className={defaultStyles.btnPrimary}>
                Sessions
            </Link>
            <Link to='/user/profile/affiliate/referral-list' className={defaultStyles.btnPrimary}>
                Referral List
            </Link>
            <Link to='/user/profile/affiliate/payments' className={defaultStyles.btnPrimary}>
                Payments
            </Link>
            <Link to='/user/profile/affiliate/commissions' className={defaultStyles.btnPrimary}>
                Commissions
            </Link>
            <Link to='/user/profile/affiliate/balance' className={defaultStyles.btnPrimary}>
                Balance
            </Link>
            <Link to='/affiliates/faq' className={defaultStyles.btnPrimary}>
                FAQs
            </Link>
        </div>
        <Back to='/user' text='Back to User Dashboard' />
    </InfoAreaWrap>
);

export default withPage(AffiliateDashboard)({ meta: affiliateDashboardMeta, innerSpacing: true });
