import React from 'react';
import { withPage } from '../../../components/layout/Page/Page';
import { pricingMeta } from '../../misc/MetaTags';
import { HeroArea, Section } from '../../../components/default/Areas/Areas';
import styles from './styles.module.scss';
import { PlanCards } from '../../../components/default/Cards/PlanCards/PlanCards';
import { useChangePlanCheckout } from '../../../hooks/payments/useChangePlanCheckout/useChangePlanCheckout';
import { Loading, Error } from '../../../components/default/States/States';
import { PlanTermsSnippet } from '../../account/private/Plan/PlanTermsSnippet/PlanTermsSnippet';
import defaultStyles from '../../../css/default.module.scss';
import { Link } from 'react-router-dom';

const Pricing = () => {
    const { changePlan, loading, error } = useChangePlanCheckout();

    return (
        <>
            <HeroArea
                title='Pricing'
                description='Get started with a free account today or select a paid plan. You can change plans any time.'
                buttonText='Create Free Account'
                buttonLink='/create-account'
                tall={false}
            />
            <Section id={styles.planCardsSectionContainer} clsInner={styles.inner} patterned={true}>
                <PlanCards changePlan={changePlan} />
                <PlanTermsSnippet />
            </Section>

            <Section patterned={true} centralised={true}>
                <h2>Custom Services</h2>
                <p>Want more customised or technical help? Explore our service options.</p>
                <Link to='/services' className={defaultStyles.btnPrimary}>
                    Services
                </Link>
            </Section>

            {loading && <Loading centralised={true} />}
            {error && <Error msg={error} />}
        </>
    );
};

export default withPage(Pricing)({ meta: pricingMeta, fullWidth: true });
