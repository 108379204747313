import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { BenefitBoxProps } from './types';
import styles from './styles.module.scss';
import defaultStyles from '../../../../css/default.module.scss';

const BenefitBox = ({ title, description }: BenefitBoxProps): JSX.Element => (
    <div className={styles.container}>
        <h4>
            <FontAwesomeIcon
                icon={faCheckCircle}
                className={`${defaultStyles.greenText} ${styles.benefitIcon}`}
                aria-label='benefit'
            />
            {title}
        </h4>
        <p>{description}</p>
    </div>
);

export default BenefitBox;
