import React from 'react';
import { Loading, WarningWithChildren } from '../../../../default/States/States';
import { useHasEnteredBankingDetailsQuery } from '../../../../../graphql/generated/schema';
import { Link } from 'react-router-dom';

const AffiliatePaymentDetailsNeededManual = () => {
    const [{ data, fetching }] = useHasEnteredBankingDetailsQuery();

    if (fetching) return <Loading centralised={true} />;
    if (data?.userHasEnteredBankingDetails) return null;

    return (
        <WarningWithChildren marginBottom={true}>
            You have not yet entered your payment details. If you are participating in the affiliate
            program, please{' '}
            <Link to='/user/profile/affiliate/payments'>enter your payment details</Link> to enable
            us to make any payments to you before the next payment cycle. If you miss the next
            payment cycle and you were due a payment, you will be paid in the following cycle,
            including any missed payments.
        </WarningWithChildren>
    );
};

export default AffiliatePaymentDetailsNeededManual;
