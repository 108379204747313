import React from 'react';
import { useIsDirectAffiliateSignup } from '../../../../hooks/affiliates/useIsDirectAffiliateSignup/useIsDirectAffiliateSignup';
import { Loading, Error } from '../../../default/States/States';

export const IndirectAffiliateSignupMessage = () => {
    const { isDirectAffiliateSignup, loading, error } = useIsDirectAffiliateSignup();

    if (loading) return <Loading />;
    if (error) return <Error msg={error.message} />;
    if (isDirectAffiliateSignup) return null;

    return (
        <p>
            Even though you did not directly sign up as an affiliate, we've made you an affiliate
            anyway in case you want to promote our products and earn money from it.
        </p>
    );
};
