export const BLOG_CONTENTS = [
    {
        id: 'overview',
        label: 'Overview of A/B Testing Headlines',
    },
    {
        id: 'benefits',
        label: 'Benefits of Getting the Best Headlines',
    },
    {
        id: 'creating-good-headlines',
        label: 'Key Elements of a Good Headline',
    },
    {
        id: 'pitfalls',
        label: 'Common Headline Mistakes',
    },
    {
        id: 'how-to-create-a-headline-test',
        label: 'How to Create a Headline A/B Test',
    },
    {
        id: 'conclusion',
        label: 'Conclusion',
    },
];
