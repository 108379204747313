import React from 'react';
import styles from '../../styles.module.scss';
import defaultStyles from '../../../../../css/default.module.scss';
import { Section } from '../../../../../components/default/Areas/Areas';
import { withPage } from '../../../../../components/layout/Page/Page';
import { testExampleProductImageEffectivenessMeta } from '../../../../misc/MetaTags';
// import InitialTestDetailsImage from './initial-test-details.png';
import Contents from '../../../../../components/layout/Contents/Contents';
import { PRODUCT_IMAGE_EFFECTIVENESS_CONTENTS } from './variables';
import { Back } from '../../../../../components/default/Navigation/Navigation';
import { Link } from 'react-router-dom';
import { Info, WarningWithChildren } from '../../../../../components/default/States/States';
import { CodeBlock } from '../../../../../components/default/Cards/Cards';
import controlImage from './control.png';
import variantOneImage from './variant-one.png';
import variantTwoImage from './variant-two.png';
import BrowserImage from '../../../../../components/default/ImageContainers/BrowserImage/BrowserImage';
import { VideoModal } from '../../../../../components/default/VideoModal/VideoModal';

const ProductImageEffectiveness = () => {
    const imagesShowingHtml = `
        <img id="original-image" src="path/to/your/image.jpg" />
        <img id="variant-one-image" src="path/to/your/image.jpg" />
        <img id="variant-two-image" src="path/to/your/image.jpg" />
    `;
    const imagesHidingHtml = `
        #variant-one-image,
        #variant-two-image {
            display: none;
        }
    `;
    const imagesHidingHtmlInStyleTag = `
        <style>
            ${imagesHidingHtml}
        </style>
    `;
    const imagesHidingHtmlInline = `
        <img id="original-image" src="path/to/your/image.jpg" />
        <img id="variant-one-image" src="path/to/your/image.jpg" style="display: none;" />
        <img id="variant-two-image" src="path/to/your/image.jpg" style="display: none;" />
    `;
    const showSecondImageCSS = `
        #variant-one-image {
            display: block !important;
        }
        #original-image, #variant-two-image {
            display: none !important;
        }
    `;
    const showThirdImageCSS = `
        #variant-two-image {
            display: block !important;
        }
        #original-image, #variant-one-image {
            display: none !important;
        }
    `;

    return (
        <>
            <Contents
                title='How to Create a Product Image A/B Test to Increase Sales'
                sections={PRODUCT_IMAGE_EFFECTIVENESS_CONTENTS}
            />

            <VideoModal
                title='Video Tutorial'
                description='Watch our in-depth video explaining how to create a product page A/B test.'
                videoEmbedUrl='https://www.youtube.com/embed/7RvmBiaZR5g?si=O5r5kQ6A-zUq_KZ-'
            />

            <Section patterned={true}>
                <h2 id='overview'>Overview</h2>
                <p>
                    In this test, we are going to test three different images on a product page to
                    discover which one has the highest conversion rate - and by conversion rate, we
                    will measure how many average times the <b>'Add to Cart'</b> button is clicked.
                </p>
                <div className={styles.variantsOverview}>
                    <BrowserImage
                        caption='Control (original)'
                        imageAlt='control'
                        imageSrc={controlImage}
                    />
                    <BrowserImage
                        caption='Variant A'
                        imageAlt='variant a'
                        imageSrc={variantOneImage}
                    />
                    <BrowserImage
                        caption='Variant B'
                        imageAlt='variant b'
                        imageSrc={variantTwoImage}
                    />
                </div>
                <p>
                    The goal: <b>increase sales of this product</b>. Without testing each image, we
                    won't know which one converts the best and will lose out on lost sales.
                </p>
            </Section>

            <Section patterned={true}>
                <p className={styles.step}>Step 1</p>
                <h2 id='create-test-page'>Create a Page for Testing</h2>
                <p>
                    Begin by choosing a webpage that you want to test. Just ensure it is exposed on
                    the internet via a web URL.
                </p>
                {/* <p>@todo: crete image here ______</p> */}
                {/* <img src={InitialTestDetailsImage} alt='initial test details' /> */}
                <p>In this example, our test page will be https://www.example.com/product-one.</p>
            </Section>

            <Section patterned={true}>
                <p className={styles.step}>Step 2</p>
                <h2 id='initial-test-details'>Enter Initial Test Details</h2>
                <p>
                    On our platform, create a new test and enter the initial test details: the test
                    name, description, goal, website and page.
                </p>
                {/* <img src={InitialTestDetailsImage} alt='initial test details' /> */}
            </Section>

            <Section patterned={true}>
                <p className={styles.step}>Step 3</p>
                <h2 id='tracking-approach'>Choose Tracking Approach</h2>
                <p>
                    While this step is optional, in some cases we're only interested in tracking a
                    specific element rather than generic metrics. Since we want to track the
                    conversion rate, tracking the number of 'Add to Cart' button clicks would be
                    more suitable than standard tracking. Therefore, in this scenario it would be
                    recommended to add the{' '}
                    <span className={defaultStyles.highlightedText}>data-ab='cro-simple'</span>{' '}
                    attribute to the 'Add to Cart' button to initiate custom tracking of that
                    element.
                </p>
                <p>
                    You can of course skip this step and standard tracking will be initiated, which
                    will still track average clicks but not specific to the 'Add to Cart' button.
                </p>
                <p>
                    For more information, read the{' '}
                    <Link to='/support/test-creation/guide#tracking'>tracking guide</Link>.
                </p>
            </Section>

            <Section patterned={true}>
                <p className={styles.step}>Step 4</p>
                <h2 id='variant-approach'>Choose Variant Creation Approach</h2>
                <p>For this test, we have two options when creating variants:</p>
                <ol>
                    <li>
                        Page redirects: create each variant by using a different page URL, where
                        each page will display a different image; or
                    </li>
                    <li>
                        Code (HTML &amp; CSS): edit the main test page by adding each image (i.e.
                        each image's HTML) but hiding them with CSS (except for the original one),
                        and use CSS to only show the variant's image and hide the others.
                    </li>
                </ol>
                <h3>Option 1: Page Redirects</h3>
                <p>
                    If you want to create a new page for each variant where each page shows a
                    different image, all you need to do is to create a redirect variant for each
                    page URL you want to test. For example, you could use pages like these:
                </p>
                <ul>
                    <li>
                        Control: https://www.example.com/product-one - the original unchanged page
                    </li>
                    <li>
                        Variant 1: https://www.example.com/product-one-a - the same page but with a
                        different image
                    </li>
                    <li>
                        Variant 2: https://www.example.com/product-one-b - the same page but with
                        another different image
                    </li>
                </ul>
                <p>You can create a redirect variant on the test creation form.</p>
                {/* <p>@todo: add the image here ________</p> */}
                <WarningWithChildren marginTop={true} marginBottom={true}>
                    If choosing custom tracking, you need to add the{' '}
                    <span className={defaultStyles.highlightedText}>data-ab='cro-simple'</span>{' '}
                    attribute to the 'Add to Cart' button on <em>every</em> redirect variant's page,
                    otherwise there will be a tracking data mismatch.
                </WarningWithChildren>
                <h3>Option 2: Code (HTML &amp; CSS)</h3>
                <Info
                    msg='A great option for those with basic HTML/CSS knowledge and access to change the code.'
                    marginBottom={true}
                />
                <p>
                    If you want to toggle showing and hiding each image using HTML and CSS, you'll
                    need to (1) add each image to the page, (2) hide each image with CSS (except for
                    the original control's image) and (3) use CSS to only show the variant's image.
                </p>
                <h4>1. Add each variant's image to the original page</h4>
                <p>Change the original test page's HTML to add each image:</p>
                <CodeBlock
                    title='Add HTML for all images'
                    code={imagesShowingHtml}
                    marginBottom={true}
                />
                <Info
                    msg='Replace "path/to/your/image.jpg" with the actual path or URL to the image you want to display.'
                    marginBottom={true}
                />
                <p>
                    You should also add an{' '}
                    <span className={defaultStyles.highlightedText}>id='original-image'</span>{' '}
                    attribute to the original image so that the CSS can target that element and hide
                    it.
                </p>
                <h4>2. Hide the additional images with CSS</h4>
                <p>
                    You need to add CSS to hide only the additional images that were added while
                    leaving the original image alone. Adding CSS can be done in a few different ways
                    according to how your site is built. You can either:
                </p>
                <ol>
                    <li>Add CSS to an external stylesheet;</li>
                    <li>Add a style tag to the top of the page and add the CSS there; or</li>
                    <li>Add inline styles.</li>
                </ol>
                <h5>Adding CSS to an External Stylesheet:</h5>
                <CodeBlock
                    title='CSS to hide additional images (and keep the original)'
                    code={imagesHidingHtml}
                    marginBottom={true}
                />
                <h5>Adding a Style Tag to the top of the page (before the closing head tag):</h5>
                <CodeBlock
                    title='Style tag containing the CSS'
                    code={imagesHidingHtmlInStyleTag}
                    marginBottom={true}
                />
                <h5>Adding inline styles as HTML only:</h5>
                <CodeBlock
                    title='HTML containing inline styles that hide the additional variant images'
                    code={imagesHidingHtmlInline}
                    marginBottom={true}
                />
                <h4>3. Show the relevant variant image and hide the others</h4>
                <p>
                    You now need provide the correct CSS for each variant. This CSS is very
                    straightforward and just needs to (1) show the correct image, and (2) hide the
                    other images.
                </p>
                <CodeBlock
                    title='CSS for Variant 1'
                    code={showSecondImageCSS}
                    marginBottom={true}
                />
                <CodeBlock title='CSS for Variant 2' code={showThirdImageCSS} marginBottom={true} />
                <p>
                    When creating the variants, just insert the CSS into the CSS code field so that
                    it will be injected onto the page. You can leave the JavaScript field empty.
                </p>
            </Section>

            <Section patterned={true}>
                <p className={styles.step}>Step 5</p>
                <h2 id='traffic-allocation'>Set Traffic Allocation</h2>
                <p>
                    Set the percentage of traffic that will see each variant. Setting up an equal
                    distribution is a common practice.
                </p>
            </Section>

            <Section patterned={true}>
                <p className={styles.step}>Step 6</p>
                <h2 id='targeting'>Set Targeting Options</h2>
                <p>
                    Set the target devices that the test will show on, along with the maximum
                    visitor sessions.
                </p>
            </Section>

            <Section patterned={true}>
                <h2 id='completion'>That's Everything</h2>
                <p>
                    You can start the test whenever you like, view the results upon completion and
                    then implement the winner.
                </p>
                <p>
                    The result:{' '}
                    <b>more of this product will be added to customers' shopping carts over time</b>
                    .
                </p>
                <Back to='/support' text='Support Hub' block={true} />
            </Section>

            <Section patterned={true}>
                <h2 id='ideas'>Ecommerce A/B Test Ideas</h2>
                <p>
                    Need some ideas of what you could test on a product page to increase sales? Head
                    over to our detailed{' '}
                    <Link to='/blog/improve-product-page-conversions-with-ab-testing'>
                        eCommerce A/B test ideas
                    </Link>{' '}
                    guide.
                </p>
                <Back to='/support' text='Support Hub' block={true} />
            </Section>
        </>
    );
};

export default withPage(ProductImageEffectiveness)({
    meta: testExampleProductImageEffectivenessMeta,
    fullWidth: true,
});
