import React from 'react';
import { withPage } from '../../../components/layout/Page/Page';
import { aboutMeta } from '../../misc/MetaTags';
import { Section } from '../../../components/default/Areas/Areas';
import { Link } from 'react-router-dom';
import styles from '../../../css/default.module.scss';

const About = () => (
    <>
        <Section patterned={true}>
            <h1>About Us - CRO Simple</h1>
            <h2>Our Mission</h2>
            <p>
                Welcome to CRO Simple. Founded in 2023, our aim is to make A/B testing easier so
                that anyone - including non-technical users - can create simple A/B tests for their
                business. We noticed a need for a simple, affordable, and self-service A/B testing
                platform, hence we made our platform. We wanted to give website owners a way to
                manage their own tests without complicated setups or costly agencies.
            </p>
        </Section>

        <Section patterned={true}>
            <h2>Our Unique Approach</h2>
            <p>Our approach focuses on four key principles:</p>
            <p>
                <b>1. Simplicity:</b> Our platform is designed to be easy and simple to use. Whether
                you're experienced or new to A/B testing, setting up tests and tracking is very
                straightforward. No more complexity or frustration. Why overcomplicate it?
            </p>
            <p>
                <b>2. Affordability:</b> A/B testing should be accessible to everyone, regardless of
                business size or budget. We offer sensible pricing options and a free version to
                demonstrate the benefits of A/B testing without any commitment.
            </p>
            <p>
                <b>3. Flexibility:</b> We know that website owners have different levels of
                technical expertise. For non-technical users, simply enter page URLs to compare
                tests. If you're tech-savvy, enter code (CSS and/or JavaScript) to create more
                advanced tests. Or both - the choice is yours.
            </p>
            <p>
                <b>4. Self-service:</b> We empower website owners to take control. With CRO Simple,
                you can install our simple, fast and lightweight tracking script, set up your tests,
                and view the results without needing to pay an agency to do it for you.
            </p>
        </Section>

        <Section patterned={true}>
            <h2>Try It for Free</h2>
            <p>
                Want to see how CRO Simple can improve your website? Try our free version - it's a
                risk-free way to start A/B testing and see how your business can benefit from it.
            </p>
            <Link to='/pricing' className={styles.btnPrimary}>
                Get Started for Free
            </Link>
        </Section>

        <Section patterned={true}>
            <h2>Support and Resources</h2>
            <p>
                If you're looking for guidance, check out our <Link to='/support'>Support Hub</Link>{' '}
                and <Link to='/quickwins'>A/B Testing Ideas Guide</Link>. We're here to help you
                make some quick wins and get the most out of A/B testing.
            </p>
        </Section>

        <Section patterned={true}>
            <h2>Get in Touch</h2>
            <p>
                If you have any questions or need assistance, don't hesitate to reach out. Visit our{' '}
                <Link to='/contact'>Contact Page</Link> to get in touch with us.
            </p>
        </Section>
    </>
);

export default withPage(About)({ meta: aboutMeta, fullWidth: true });
