import React, { useContext, useState } from 'react';
import { withPage } from '../../../components/layout/Page/Page';
import { homepageMeta } from '../../misc/MetaTags';
import styles from './styles.module.scss';
import { HeroArea, Section } from '../../../components/default/Areas/Areas';
import defaultStyles from '../../../css/default.module.scss';
import { Link } from 'react-router-dom';
import GreenTicksChecklist from '../../../components/default/GreenTicksChecklist/GreenTicksChecklist';
import ProcessSteps from '../../../components/default/ProcessSteps/ProcessSteps';
import { AB_BENEFITS, HOMEPAGE_LIST_BOXES, PROCESS_STEPS, TEST_CREATION_STEPS } from './variables';
import { UserContextType } from '../../../contexts/UserContext/types';
import { UserContext } from '../../../contexts/UserContext/UserContext';
import { faAngleDoubleLeft } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AnimatedAbTest from './AnimatedAbTest/AnimatedAbTest';
import ListBoxes from '../../../components/default/ListBoxes/ListBoxes';
import { VideoModal } from '../../../components/default/VideoModal/VideoModal';
import { FreeGuide } from '../../../components/default/FreeGuide/FreeGuide';
import GetStarted from '../../../components/default/GetStarted/GetStarted';

const Homepage = () => {
    const { user } = useContext<UserContextType>(UserContext);
    const [videoModalOpen, setVideoModalOpen] = useState<boolean>(false);
    const heroAreaProps = user
        ? {
              description:
                  "Thank you for choosing CRO Simple! We appreciate your trust in us. Explore new testing opportunities and keep improving your website's performance with ease.",
              buttonText: 'View Dashboard',
              buttonLink: '/user',
          }
        : {
              description:
                  "Create different versions of your web pages and test which one's best. Simple setup and easy to get started for anyone.",
              buttonText: 'Try Free Version',
              buttonLink: '/pricing',
          };

    return (
        <>
            <HeroArea
                title='DIY A/B Tests for Websites'
                imageAlt='landing page ab test results'
                clsOuter={styles.heroOuter}
                clsInner={styles.heroInner}
                {...heroAreaProps}
                leftText={true}
                additionalContent={<AnimatedAbTest />}
                clsBtnTwo={defaultStyles.btnDark}
                buttonTwoText='Watch Video'
                buttonTwoOnClick={() => setVideoModalOpen(true)}
                clsBtn={defaultStyles.btnWhite}
            />

            <Section patterned={true}>
                <h2>Our Testing Approach</h2>
                <p>
                    Frustrated with complex A/B testing tools? Our simple and affordable
                    DIY-approach has you covered: just enter another page URL to test against - or
                    code if you're more advanced - and wait for the results.
                </p>
            </Section>

            <Section patterned={true}>
                <h2>How it Works</h2>
                <p>
                    We emphasise simplicity throughout the A/B testing process. Why overcomplicate
                    it?
                </p>
                <ProcessSteps steps={PROCESS_STEPS} cls={styles.processSteps} />
                <p>
                    No complex setup, no agencies and no convolutions. Take full control by yourself
                    - do whatever you want.
                </p>
                <p>
                    <b>Create - wait - implement - repeat</b>.
                </p>
            </Section>

            <VideoModal
                customOpenState={{ isCustomOpen: videoModalOpen, setCustomOpen: setVideoModalOpen }}
            />

            <Section patterned={true}>
                <h2>Why A/B Test?</h2>
                <p>
                    Data-driven results are proven to work and improve decision making. A/B testing
                    can improve:
                </p>
                <GreenTicksChecklist items={AB_BENEFITS} primaryColour={true} />
                <p>How do you know what is optimal if you don’t test?</p>
            </Section>

            <Section patterned={true}>
                <h2>What You Should Test</h2>
                <p>
                    There are many significant parts of a website that should be optimised. Here are
                    a few examples:
                </p>
                <ListBoxes items={HOMEPAGE_LIST_BOXES} design={2} />
                <p>
                    If you don't optimise your website, you're <u>leaving money on the table</u>.
                </p>
            </Section>

            <Section patterned={true}>
                <h2>Creating an A/B Test</h2>
                <p>
                    Creating a split or multivariate test on our platform is very straightforward:
                </p>
                <ProcessSteps steps={TEST_CREATION_STEPS} />
                <p>
                    When creating variants, you can just enter another page URL to test against,
                    otherwise if you're more technical, you can enter code (CSS &amp;/or
                    JavaScript). Extra options are available if wanted.
                </p>
            </Section>

            <FreeGuide />

            <BottomArea />
        </>
    );
};

const BottomArea = () => {
    const { user } = useContext<UserContextType>(UserContext);

    if (user)
        return (
            <Section patterned={true} clsOuter={styles.ctaBottomSection}>
                <h2>Get Testing Now</h2>
                <p>
                    Make the most of your plan by continuing to improve your website with more
                    tests.
                    <br />
                    Remember: create - wait - implement - repeat.
                </p>
                <Link to='/user/tests' className={defaultStyles.btnPrimary}>
                    View Tests
                </Link>
                <FontAwesomeIcon
                    icon={faAngleDoubleLeft}
                    className={styles.arrowsIcon}
                    aria-label='double left arrows'
                />
            </Section>
        );

    return <GetStarted />;
};

export default withPage(Homepage)({
    meta: homepageMeta,
    clsPage: styles.homepage,
    fullWidth: true,
    widerInnerWidth: false,
});
