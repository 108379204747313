import React from 'react';
import {
    ReferralList,
    ReferralPerformanceMetricsDetailed,
    ReferralPerformanceMetricTotals,
} from '../../../../../components/account/affiliates/ReferralList/ReferrralList';
import { withPage } from '../../../../../components/layout/Page/Page';
import { referralListMeta } from '../../../../misc/MetaTags';
import defaultStyles from '../../../../../css/default.module.scss';
import { Back } from '../../../../../components/default/Navigation/Navigation';
import { Info } from '../../../../../components/default/States/States';

const ReferralListPage = () => {
    // @todo: bizarre bug detected
    // clicking any pagination re-renders the ReferralList & Page? (Header & Footer) components
    // BUT not this ReferralListPage component
    // why?
    // presumably happens elsewhere where the Pagination component is present

    /*
        Note: inaccuracy (not critical though as this is more of an edge case):
        - inaccuracy on /user/profile/affiliate/referral-list where, if a customer payment is
        refunded but they are still on a paid plan, it incorrectly says lifetime value of $20
        - paid months also says 1, but this should be changed to ‘non-refunded paid months’
        which should show as 0 - although TBF this is a rare (ish?) edge case because when would
        I just decide to refund one payment and let the customer still keep their subscription?

        maybe adding the SQL in the User.fetchReferrals to this MIGHT (but NEEDS TESTING) fix it:

        SELECT
            users.date_created,
            users.id,
            users.conversion_date,
            users.user_plan,
            COALESCE(COUNT(DISTINCT customer_payments.id)::DECIMAL(12, 2), 0) as "total_months_paid",
            COALESCE(SUM(affiliate_commissions.amount)::DECIMAL(12, 2), 0) as "total_commissions_value"
        FROM
            users
        LEFT JOIN
            customer_payments ON customer_payments.user_id = users.id
        LEFT JOIN
            affiliate_commissions ON affiliate_commissions.customer_payment_id = customer_payments.id
        WHERE
            users.referred_by = $1
            AND customer_payments.status = 'paid'  -- Add this condition
        GROUP BY
            users.id
        ORDER BY
            users.date_created DESC
        OFFSET
            $2
        LIMIT
            $3;

    */

    return (
        <>
            <h1 className={defaultStyles.centralisedText}>Referral List</h1>
            <Info
                marginBottom={true}
                msg='Note: all referrals initially joined under the free plan.'
            />
            <ReferralPerformanceMetricTotals />
            <ReferralPerformanceMetricsDetailed />
            <ReferralList />
            <Back to='/user/profile/affiliate' text='Back to Affiliate Profile' />
        </>
    );
};

export default withPage(ReferralListPage)({ meta: referralListMeta, innerSpacing: true });
