import React from 'react';
import { InputField } from '../../../default/Form/Form';
import { SelectWebsiteDropdown } from '../../Pages/SelectWebsiteDropdown/SelectWebsiteDropdown';
import SelectGoal from './SelectGoal/SelectGoal';
import { SelectPage } from './SelectPage/SelectPage';
import { CreateTestStageOneProps } from './types';

const CreateTestStageOne = ({
    goalData,
    pagesData,
    loadingGoals,
    loadingPages,
    handleWebsiteChange,
}: CreateTestStageOneProps): JSX.Element => (
    <>
        <InputField type='text' title='name' placeholder='name' />
        <InputField type='text' title='description' placeholder='description' />
        <SelectGoal goalData={goalData} loadingGoals={loadingGoals} />
        <SelectWebsiteDropdown handleWebsiteChange={handleWebsiteChange} />
        <SelectPage
            pagesData={pagesData}
            loadingPages={loadingPages}
            selectPageAlias='Main test page'
        />
    </>
);

export default CreateTestStageOne;
