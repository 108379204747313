import React from 'react';
import defaultStyles from '../../../../../../css/default.module.scss';
import { withPage } from '../../../../../../components/layout/Page/Page';
import { testVariantMeta } from '../../../../../misc/MetaTags';
import { useParams, Params } from 'react-router-dom';
import { Back } from '../../../../../../components/default/Navigation/Navigation';
import { useTestVariantQuery } from '../../../../../../graphql/generated/schema';
import { Loading } from '../../../../../../components/default/States/States';
import PageError from '../../../../../other/errors/PageError.tsx/PageError';
import VariantInfo from '../../../../../../components/account/TestVariant/VariantInfo/VariantInfo';
import VariantImplementation from '../../../../../../components/account/TestVariant/VariantImplementation/VariantImplementation';
// import VariantActions from '../../../../../../components/account/TestVariant/VariantActions/VariantActions';

const TestVariant = () => {
    const { testId } = useParams<Params<string>>();
    const { variantId } = useParams<Params<string>>();
    const [{ data, fetching: loading, error }] = useTestVariantQuery({
        variables: {
            testVariantId: variantId as string,
        },
    });

    if (loading) return <Loading centralised={true} entirePage={true} />;
    if (error || !data?.testVariant) return <PageError msg={error?.message} />;

    const {
        createdAt,
        cssCode,
        description,
        jsCode,
        lastModified,
        name,
        pageRedirectUrl,
        trafficAllocation,
        variantNo,
    } = data.testVariant;

    return (
        <>
            <h1 className={defaultStyles.centralisedText}>Test Variant {variantNo}</h1>
            <VariantInfo
                name={name}
                description={description}
                createdAt={createdAt}
                lastModified={lastModified}
            />
            <VariantImplementation
                trafficAllocation={trafficAllocation}
                cssCode={cssCode}
                jsCode={jsCode}
                pageRedirectUrl={pageRedirectUrl}
            />
            {/* <VariantActions /> */}
            <Back to={`/user/tests/${testId}`} text='Back to Test Details' />
        </>
    );
};

export default withPage(TestVariant)({ meta: testVariantMeta, innerSpacing: true });
