export const FORM_FIELDS = ['avgFormClicks', 'avgFormHovers', 'medianTimeSpent', 'submissionRate'];

export const BTN_FIELDS = ['avgBtnClicks', 'avgBtnHovers'];

export enum TrackingType {
    BUTTON = 'button',
    FORM = 'form',
    HAS_ERROR = 'hasError',
    STANDARD = 'standard',
}

export const FORM_DATA_EXAMPLE = [
    {
        id: '928c14ab-4f34-47aa-a9dc-e5c00d363d6a',
        variant: 'Control',
        medianTimeOnPage: '13.8 sec',
        avgScrolledDownPercentage: '45%',
        avgClicks: 3.3,
        bounceRate: '25%',
        avgFormClicks: 2.5,
        avgFormHovers: 5,
        submissionRate: '25%',
        medianTimeSpent: '18.8 sec',
    },
    {
        id: '27dea9c7-9b6f-455d-aecf-5da699c1bde0',
        variant: 1,
        medianTimeOnPage: '14.8 sec',
        avgScrolledDownPercentage: '50%',
        avgClicks: 2,
        bounceRate: '50%',
        avgFormClicks: 1,
        avgFormHovers: 3,
        submissionRate: '25%',
        medianTimeSpent: '20 sec',
    },
    {
        id: '56491107-44cf-4b38-8bc6-d8652aa53a6f',
        variant: 2,
        medianTimeOnPage: '15 sec',
        avgScrolledDownPercentage: '24%',
        avgClicks: 3.3,
        bounceRate: '25%',
        avgFormClicks: 3.75,
        avgFormHovers: 8,
        submissionRate: '75%',
        medianTimeSpent: '45.3 sec',
    },
];

export const STD_DATA_EXAMPLE = [
    {
        avgClicks: 1.7,
        avgFormSubmits: 0.3,
        avgScrolledDownPercentage: '40%',
        medianTimeOnPage: '10 sec',
        bounceRate: '33%',
        id: '0e696a6c-31ce-4ee6-9ed9-2938bc7b1a4a',
        variant: 'Control',
    },
    {
        avgClicks: 1.7,
        avgFormSubmits: 0.3,
        avgScrolledDownPercentage: '77%',
        medianTimeOnPage: '23 sec',
        bounceRate: '67%',
        id: '4e696a6c-31ce-4ee6-9ed9-2938bc7b1a4a',
        variant: 'Control',
    },
    {
        avgClicks: 1.7,
        avgFormSubmits: 0.3,
        avgScrolledDownPercentage: '33%',
        medianTimeOnPage: '11 sec',
        bounceRate: '55%',
        id: '7e696a6c-31ce-4ee6-9ed9-2938bc7b1a4a',
        variant: 'Control',
    },
];
