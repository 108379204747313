import React from 'react';
import styles from './styles.module.scss';
import { StatBoxesProps } from './types';

export const StatBoxes = ({ boxes, marginBottom }: StatBoxesProps): JSX.Element | null => {
    if (!boxes.length) return null;

    return (
        <div className={`${styles.statBoxes} ${marginBottom ? styles.marginBottom : ''}`}>
            {boxes.map(({ title, number }) => (
                <div key={title} className={styles.item}>
                    <span className={styles.number}>{number}</span>
                    <span className={styles.title}>{title}</span>
                </div>
            ))}
        </div>
    );
};
