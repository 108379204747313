import React, { useEffect, useMemo, useState } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { InputField } from '../../../default/Form/Form';
import { Loading, Success, Error } from '../../../default/States/States';
import styles from '../../../../css/default.module.scss';
import { affiliateBankingDetailsFormSchema } from '../../../../schemas/UserSchemas';
import {
    BankDetails,
    useSaveBankingDetailsToDbMutation,
    useUpdateBankingDetailsMutation,
} from '../../../../graphql/generated/schema';
import Modal from 'react-modal';
import customStyles from './styles.module.scss';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { AffiliateBankingDetailsFormProps, AffiliateBankingDetailsModalProps } from './types';

export const AffiliateBankingDetailsModal = ({
    fetchedBankDetails,
    fetchingBankDetails,
    refetchBankingDetails,
}: AffiliateBankingDetailsModalProps): JSX.Element => {
    const [modalIsOpen, setIsOpen] = useState<boolean>(false);

    const openModal = () => {
        setIsOpen(true);
    };

    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <div className={customStyles.container}>
            <button onClick={openModal} className={styles.btnAsText}>
                Edit payment details
            </button>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className={styles.modal}
                contentLabel='Payment Details Modal'
                portalClassName={styles.modalOverlayWrap} // cannot use overlayClassName
            >
                <button onClick={closeModal} className={styles.close} aria-label='close'>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <AffiliateBankingDetailsForm
                    closeModal={closeModal}
                    fetchedBankDetails={fetchedBankDetails}
                    fetchingBankDetails={fetchingBankDetails}
                    refetchBankingDetails={refetchBankingDetails}
                />
            </Modal>
        </div>
    );
};

const AffiliateBankingDetailsForm = ({
    closeModal,
    fetchedBankDetails,
    fetchingBankDetails,
    refetchBankingDetails,
}: AffiliateBankingDetailsFormProps): JSX.Element => {
    const [{ fetching: loadingUpdateBankDetails }, updateBankDetails] =
        useUpdateBankingDetailsMutation();
    const [{ fetching: loadingSaveBankDetails }, saveBankDetails] =
        useSaveBankingDetailsToDbMutation();
    const [success, setSuccess] = useState<boolean>(false);
    const [error, setError] = useState<string>('');

    const {
        bankName = '',
        country = '',
        firstName = '',
        lastName = '',
        accountNumber = null,
        sortCode = null,
        swiftBic = null,
        iban = null,
    } = fetchedBankDetails || {};

    const handleSubmitFn: SubmitHandler<BankDetails> = async (formData) => {
        let res;

        if (fetchedBankDetails) {
            res = await updateBankDetails({
                updateUserBankDetailsInput: formData,
            });
        } else {
            res = await saveBankDetails({
                saveUserBankDetailsInput: formData,
            });
        }

        if (res?.error) {
            setError(res.error?.message);
            return;
        }

        refetchBankingDetails();
        setSuccess(true);
    };

    const defaultValues = useMemo(
        () => ({
            firstName,
            lastName,
            bankName,
            country,
            accountNumber: accountNumber || null,
            sortCode: sortCode || null,
            iban: iban || null,
            swiftBic: swiftBic || null,
        }),
        [firstName, lastName, bankName, country, accountNumber, sortCode, iban, swiftBic]
    );

    const methods = useForm<BankDetails>({
        resolver: yupResolver(affiliateBankingDetailsFormSchema),
        mode: 'onTouched',
        defaultValues,
    });

    useEffect(() => {
        // required, because defaultValues does not work inside methods for some reason
        methods.reset(defaultValues);
    }, [
        methods,
        firstName,
        lastName,
        bankName,
        country,
        accountNumber,
        sortCode,
        iban,
        swiftBic,
        defaultValues,
    ]);

    if (fetchingBankDetails) return <Loading />;

    return (
        <>
            <h2>Edit your bank details</h2>
            <p>
                We'll use these details to pay you for your commissions earned. If we have an issue
                paying you then we will be in touch.
            </p>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmitFn)}>
                    <div className={styles.formInner}>
                        <InputField
                            type='text'
                            title='firstName'
                            alias='First Name'
                            placeholder='e.g. Joe'
                        />
                        <InputField
                            type='text'
                            title='lastName'
                            alias='Last Name'
                            placeholder='e.g. Bloggs'
                        />
                        <InputField
                            type='text'
                            title='bankName'
                            alias='Bank Name'
                            placeholder='e.g. HSBC'
                        />
                        <InputField type='text' title='country' placeholder='e.g. United States' />
                        <InputField
                            type='text'
                            title='accountNumber'
                            alias='Account Number'
                            placeholder='e.g. 01234567'
                        />
                        <InputField
                            type='text'
                            title='sortCode'
                            alias='Sort Code'
                            placeholder='e.g. 012345'
                        />
                        <InputField
                            type='text'
                            title='iban'
                            placeholder='e.g. GB29 NWBK 6016 1331 9268 19'
                        />
                        <InputField
                            type='text'
                            title='swiftBic'
                            alias='Swiftbic'
                            placeholder='e.g. BUKBGB22'
                        />
                    </div>
                    {loadingUpdateBankDetails || loadingSaveBankDetails ? (
                        <Loading />
                    ) : (
                        <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                            <button type='submit' className={styles.btnPrimary}>
                                Submit
                            </button>
                            <button type='button' onClick={closeModal} className={styles.btnDark}>
                                Cancel
                            </button>
                        </div>
                    )}
                </form>
            </FormProvider>
            {error && <Error msg={error as string} marginTop={true} />}
            {success && <Success msg='Bank details updated successfully' marginTop={true} />}
        </>
    );
};

export default AffiliateBankingDetailsForm;
