import React, { useContext, useState } from 'react';
import styles from '../../../../../../css/default.module.scss';
import Modal from 'react-modal';
import { ConfirmChangePlanModalProps } from './types';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import {
    useAdjustMaxTestCasesToBeginnerMutation,
    useUpdateSubscriptionMutation,
} from '../../../../../../graphql/generated/schema';
import { Loading } from '../../../../../../components/default/States/States';
import {
    BEGINNER_DOWNGRADE_CONFIRMATION_MESSAGE,
    CHANGE_PLAN_ERROR_MSG,
    PRO_UPGRADE_CONFIRMATION_MESSAGE,
} from './variables';
import { UserContextType } from '../../../../../../contexts/UserContext/types';
import { UserContext } from '../../../../../../contexts/UserContext/UserContext';
import { PLAN_PRICE_IDS } from '../../../../../../utils/global-variables';

const ConfirmChangePlanModal = ({
    closeModal,
    modalIsOpen,
    setError,
    upgradingToPro,
}: ConfirmChangePlanModalProps): JSX.Element => {
    const [, updateSubscription] = useUpdateSubscriptionMutation();
    const [, adjustMaxCases] = useAdjustMaxTestCasesToBeginnerMutation();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { user } = useContext<UserContextType>(UserContext);
    const { id: userId } = user || {};
    const message = upgradingToPro
        ? PRO_UPGRADE_CONFIRMATION_MESSAGE
        : BEGINNER_DOWNGRADE_CONFIRMATION_MESSAGE;

    const proceed = async () => {
        setLoading(true);

        try {
            const { data, error: processingError } = await updateSubscription({
                userId: userId as string,
                planId: upgradingToPro ? PLAN_PRICE_IDS.PRO : PLAN_PRICE_IDS.BEGINNER,
            });

            if (!data?.updateSubscription?.success || processingError) {
                setError(`${CHANGE_PLAN_ERROR_MSG} ${processingError?.message || ''}`);
                return;
            }

            if (!upgradingToPro) {
                // adjust max test cases if downgrading from pro to beginner plan
                const { data: adjustMaxCasesData, error: adjustMaxCasesError } =
                    await adjustMaxCases({});

                if (
                    !adjustMaxCasesData?.adjustMaxTestCasesToBeginner?.success ||
                    adjustMaxCasesError
                ) {
                    setError(
                        `Could not adjust max test cases: ${adjustMaxCasesError?.message}. Please contact customer support.`
                    );
                    return;
                }
            }

            navigate('/user/plan/change-success');
        } catch (err) {
            setError(`${CHANGE_PLAN_ERROR_MSG} ${(err as Error).message || ''}`);
        } finally {
            setLoading(false);
        }
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className={styles.modal}
            contentLabel='Confirm Plan Change'
            portalClassName={styles.modalOverlayWrap} // cannot use overlayClassName
        >
            <button onClick={closeModal} className={styles.close} aria-label='close'>
                <FontAwesomeIcon icon={faTimes} />
            </button>
            <div>
                <h2>Confirm Plan Change</h2>
                <p>{message}</p>
                <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                    {loading ? (
                        <Loading />
                    ) : (
                        <button onClick={proceed} className={styles.btnPrimary}>
                            Continue Change
                        </button>
                    )}
                    <button onClick={closeModal} className={styles.btnRed}>
                        Cancel
                    </button>
                </div>
            </div>
        </Modal>
    );
};

export default ConfirmChangePlanModal;
