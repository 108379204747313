import { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { gql, useMutation } from 'urql'; // leave old way commented out
import {
    CreateUserInput,
    TrackingParams,
    useAuthenticateUserMutation,
    useCreateUserMutation,
} from '../../../graphql/generated/schema';
import { Plan } from '../../../utils/global-variables';
import {
    getCookie,
    mappedPlan,
    mappedSubscriptionStatus,
} from '../../../utils/HelperFunctions/HelperFunctions';
import { UseCreateAccountOutputs, CreateAccountFn, Referrer } from './types';
import { UserContextType } from '../../../contexts/UserContext/types';
import { UserContext } from '../../../contexts/UserContext/UserContext';

// leave old way commented out
// const CREATE_USER_MUTATION = gql`
//     mutation createUser($input: CreateUserInput!) {
//         createUser(input: $input) {
//             id
//             token
//         }
//     }
// `;

export const useCreateAccount: UseCreateAccountOutputs = () => {
    const navigate = useNavigate();
    const [error, setError] = useState<string | null>(null);
    // const [{ fetching: loading }, createUser] = useMutation(CREATE_USER_MUTATION); // leave old way commented out
    const [{ fetching: loadingCreation }, createUser] = useCreateUserMutation();
    const [{ fetching: loadingAuth }, authenticateUser] = useAuthenticateUserMutation();
    const { setAccount } = useContext<UserContextType>(UserContext);

    const createAccount: CreateAccountFn = async (
        releventFormData,
        affiliateForm,
        customRedirect
    ) => {
        const referrer: Referrer = getCookie('referrer');
        const redirectUrl: string = customRedirect
            ? customRedirect
            : affiliateForm
            ? '/user/profile/affiliate'
            : '/user/new-account-success';
        const trackingParams: TrackingParams | null = JSON.parse(
            localStorage.getItem('trackingParams') || 'null'
        );
        const submissionData: CreateUserInput = {
            ...releventFormData,
            directAffiliateSignup: affiliateForm,
            referrer,
            userPlan: Plan.FREE,
            ...(trackingParams && { trackingParams }),
        };

        try {
            const { data, error } = await createUser({
                input: submissionData,
            });

            if (error || !data) {
                const errorText = error?.message || 'Could not create user';
                setError(`HTTP error: ${errorText}`);
                return;
            }

            const { data: authData, error: authError } = await authenticateUser({
                input: { email: submissionData.email, password: submissionData.password },
            });

            if (authError || !authData) {
                const errorText =
                    authError?.message ||
                    'Account created but could not authenticate - please try logging in manually.';
                setError(`HTTP error: ${errorText}`);
                return;
            }

            const userPlan = mappedPlan(data.createUser.userPlan);
            const subscriptionStatus = mappedSubscriptionStatus(
                data.createUser.subscriptionStatusId
            );
            const { __typename, subscriptionStatusId, ...accData } = data.createUser;
            const accountData = {
                ...accData,
                email: submissionData.email,
                userPlan,
                subscriptionStatus,
                token: authData.authenticateUser.token,
            };
            setAccount(accountData);
            navigate(redirectUrl);
        } catch (err: any) {
            setError(err.message);
        }
    };

    return { error, loading: loadingCreation || loadingAuth, createAccount };
};
