import React from 'react';
import ScriptInstallation from '../../../components/account/Dashboard/ScriptInstallation/ScriptInstallation';
import { withPage } from '../../../components/layout/Page/Page';
import { scriptSetupMeta } from '../../misc/MetaTags';
import styles from '../../../css/default.module.scss';
import { AccountProvider } from '../../../contexts/AccountContext/AccountContext';

const ScriptSetup = () => (
    <>
        <h1 className={styles.centralisedText}>Set up your tracking script</h1>
        <AccountProvider>
            <ScriptInstallation />
        </AccountProvider>
    </>
);

export default withPage(ScriptSetup)({ meta: scriptSetupMeta, innerSpacing: true });
