import React from 'react';
import styles from './styles.module.scss';
import { CustomComponentProps } from './types';

const BrowserImage = ({
    caption,
    captionJsx,
    imageSrc,
    imageAlt,
    cls = '',
    additionalJsx,
}: CustomComponentProps): JSX.Element => (
    <div className={`${styles.box} ${cls}`}>
        <div className={styles.browser}>
            <div className={styles.browserButtons}>
                <div className={styles.red}></div>
                <div className={styles.yellow}></div>
                <div className={styles.green}></div>
            </div>
            <img src={imageSrc} alt={imageAlt} />
        </div>
        <h4 className={styles.caption}>{caption || captionJsx}</h4>
        {additionalJsx && additionalJsx}
    </div>
);

export default BrowserImage;
