import { useState } from 'react';
import { EditWebsiteFn, UseEditWebsite } from './types';
import { useUpdateWebsiteMutation } from '../../../graphql/generated/schema';
import trimTrailingSlash from '../../../utils/HelperFunctions/trimTrailingSlash';

export const useEditWebsite: UseEditWebsite = (selectedRowId) => {
    const [error, setError] = useState<string | null>(null);
    const [success, setSuccess] = useState<boolean>(false);
    const [{ fetching: loading }, changeWebsite] = useUpdateWebsiteMutation();

    const editWebsite: EditWebsiteFn = async ({ name, url }) => {
        const cleanedUrl = trimTrailingSlash(url);

        try {
            const { data, error } = await changeWebsite({
                input: { name, url: cleanedUrl, websiteId: selectedRowId },
            });

            if (error || !data) {
                const errorText = error?.message || 'Could not update website';
                setSuccess(false);
                setError(`HTTP error: ${errorText}`);
                return;
            }

            setSuccess(true);
        } catch (err: any) {
            setSuccess(false);
            setError(err.message);
        }
    };

    return { loading, error, editWebsite, success };
};
