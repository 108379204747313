import React, { useState, useEffect } from 'react';
import styles from '../../../css/default.module.scss';
import customStyles from './styles.module.scss';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { yupResolver } from '@hookform/resolvers/yup';
import { SubmitHandler, useForm, FormProvider } from 'react-hook-form';
import { InputField, RadioOptions } from '../../default/Form/Form';
import { Loading, Success, Error } from '../../default/States/States';
import { FormInputs, ImprovementFormProps } from './types';
import { areasOfImprovementSchema } from '../../../schemas/FeedbackSchemas';
import { RADIO_PROPS } from './variables';
import { useImprovementFeedbackForm } from '../../../hooks/feedback/useImprovementFeedbackForm/useImprovementFeedbackForm';

const AreasOfImprovementPopup = () => {
    const [showPopup, setShowPopup] = useState<boolean>(false);
    const [exitIntent, setExitIntent] = useState<boolean>(false);

    useEffect(() => {
        const userClickedClose = sessionStorage.getItem('userClickedClose');

        if (!exitIntent && !userClickedClose) {
            const handleMouseLeave = (e: MouseEvent) => {
                if (e.clientY <= 0) {
                    setShowPopup(true);
                    setExitIntent(true);
                }
            };

            document.addEventListener('mouseleave', handleMouseLeave);

            return () => {
                document.removeEventListener('mouseleave', handleMouseLeave);
            };
        }
    }, [exitIntent]);

    const closePopup = () => {
        setShowPopup(false);
        setExitIntent(false);
        sessionStorage.setItem('userClickedClose', 'true');
    };

    return (
        <Modal
            isOpen={showPopup}
            onRequestClose={closePopup}
            className={styles.modal}
            contentLabel='Feedback Request'
            portalClassName={styles.modalOverlayWrap}
        >
            <button onClick={closePopup} className={styles.close} aria-label='close'>
                <FontAwesomeIcon icon={faTimes} />
            </button>
            <div>
                <h2>
                    <u>Please</u> Help Us! We value your opinion
                </h2>
                <h6>It'll only take a few seconds...</h6>
                <p>Do you have any honest feedback for us?</p>
                <ImprovementForm close={closePopup} />
            </div>
        </Modal>
    );
};

const ImprovementForm = ({ close }: ImprovementFormProps): JSX.Element => {
    const { loading, error, createFeedback, success } = useImprovementFeedbackForm();

    const handleSubmit: SubmitHandler<FormInputs> = async (formData) => {
        if (success) return;
        await createFeedback(formData);
    };

    const methods = useForm<FormInputs>({
        resolver: yupResolver(areasOfImprovementSchema),
        mode: 'onTouched',
        defaultValues: {
            comment: '',
            rating: undefined,
        },
    });

    const commentLength = methods.watch('comment').length;
    const remainingChars = 255 - commentLength;
    const exceededCharLength = remainingChars < 0;

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                    <div className={styles.formInner}>
                        <InputField
                            type='textarea'
                            title='comment'
                            alias='Areas of Improvement &amp; Suggestions'
                            placeholder='Enter ideas, suggestions, improvements, dislikes, comments, errors, anything'
                        />
                        <p
                            className={`${customStyles.remainingChars} ${
                                exceededCharLength ? customStyles.exceededLimit : ''
                            }`}
                        >
                            {remainingChars} characters remaining
                        </p>
                        <RadioOptions {...RADIO_PROPS} />
                    </div>
                    {loading ? (
                        <Loading />
                    ) : (
                        <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                            <button
                                type='submit'
                                className={`${styles.btnPrimary} ${success ? styles.disabled : ''}`}
                            >
                                Submit
                            </button>
                            <button onClick={close} className={styles.btnRed}>
                                {success ? 'Close' : 'Not Now'}
                            </button>
                        </div>
                    )}
                </form>
            </FormProvider>
            {error && <Error msg={error as string} marginTop={true} marginBottom={true} />}
            {success && <Success msg='Thank you!' marginTop={true} />}
        </>
    );
};

export default AreasOfImprovementPopup;
