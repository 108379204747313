import React, { FC } from 'react';
import { CodeBlockProps, InfoAreaWrapProps } from './types';
import styles from './styles.module.scss';

export const InfoAreaWrap: FC<InfoAreaWrapProps> = ({ children }) => (
    <div className={styles.infoAreaWrap}>{children}</div>
);

export const CodeBlock = ({
    title,
    code,
    customClass,
    marginTop,
    marginBottom,
    midWidth,
}: CodeBlockProps): JSX.Element | null => {
    const wrapperClass = `${styles.codeBlock} ${customClass || ''} ${
        marginTop ? styles.marginTop : ''
    } ${marginBottom ? styles.marginBottom : ''} ${midWidth ? styles.midWidth : ''}`;

    return (
        <div className={wrapperClass}>
            <h3>{title}</h3>
            <pre>
                <code>{code || 'none'}</code>
            </pre>
        </div>
    );
};
