import React from 'react';
import defaultStyles from '../../../../../../css/default.module.scss';
import { withPage } from '../../../../../../components/layout/Page/Page';
import { testCreatedMeta } from '../../../../../misc/MetaTags';
import { useParams, Params, Link } from 'react-router-dom';

const CreateTestSuccess = () => {
    const { testId } = useParams<Params<string>>();

    return (
        <>
            <h1 className={defaultStyles.centralisedText}>Test Successfully Created</h1>
            <h2>What next?</h2>
            <ol>
                <li>
                    Ensure the <Link to='/support/script-setup'>tracking script</Link> is installed
                    on every page of your site (or at the very least the test and redirected-to
                    pages)
                </li>
                <li>
                    Preview each variant to ensure they display or redirect correctly. You can view
                    them on the test page
                </li>
            </ol>
            <p>
                Note: the test is currently{' '}
                <b>
                    <u>paused</u>
                </b>{' '}
                - you can turn it on on the test page.
            </p>

            <Link to={`/user/tests/${testId}`} className={defaultStyles.btnPrimary}>
                View Test
            </Link>
        </>
    );
};

export default withPage(CreateTestSuccess)({ meta: testCreatedMeta, innerSpacing: true });
