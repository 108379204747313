import { useMemo } from 'react';
import { Params, useParams } from 'react-router-dom';
import { useAffiliateSessionMetrics } from '../../../../hooks/affiliates/useAffiliateSessionMetrics/useAffiliateSessionMetrics';
import { useAffiliateSessions } from '../../../../hooks/affiliates/useAffiliateSessions/useAffiliateSessions';
import { DEFAULT_ITEMS_PER_PAGE } from '../../../../utils/global-variables';
import { Paginator } from '../../../default/Paginator/Paginator';
import { StatBoxes } from '../../../default/StatBoxes/StatBoxes';
import { StatBox } from '../../../default/StatBoxes/types';
import { Loading, Error, NoneFound } from '../../../default/States/States';
import { DynamicTable } from '../../../default/Table/Table';

export const AffiliateSessionsTable = () => {
    const itemsPerPage: number = DEFAULT_ITEMS_PER_PAGE;
    const params = useParams<Params<string>>();
    const currentPage: number = Number(params.currentPage) || 1;
    const skip: number = (currentPage - 1) * itemsPerPage;
    const { sessionCount, sessions, loading, error } = useAffiliateSessions({
        limit: itemsPerPage,
        skip,
    });
    const headers: string[] = useMemo<string[]>(() => ['date', 'time'], []);
    const maxTableWidth: number = 300;

    if (loading) return <Loading />;
    if (error) return <Error msg={error.message} />;
    if (!sessions?.length) return <NoneFound msg='No sessions found' />;

    return (
        <>
            <DynamicTable data={sessions} headers={headers} maxWidth={maxTableWidth} />
            <Paginator
                currentPage={currentPage}
                itemCount={sessionCount}
                itemsPerPage={itemsPerPage}
                path='/user/profile/affiliate/sessions'
                maxWidth={maxTableWidth}
            />
        </>
    );
};

export const AffiliateSessionMetrics = () => {
    const { hasData, total, lastWeek, lastMonth, lastQuarter, lastYear, loading, error } =
        useAffiliateSessionMetrics();
    const statBoxes = useMemo<StatBox[]>(
        () => [
            {
                title: 'Total Sessions',
                number: total,
            },
            {
                title: 'Sessions Last Week',
                number: lastWeek,
            },
            {
                title: 'Sessions Last Month',
                number: lastMonth,
            },
            {
                title: 'Sessions Last Quarter',
                number: lastQuarter,
            },
            {
                title: 'Sessions Last Year',
                number: lastYear,
            },
        ],
        [total, lastWeek, lastMonth, lastQuarter, lastYear]
    );

    if (loading) return <Loading />;
    if (error) return <Error msg={error.message} />;
    if (!hasData) return null;

    return (
        <>
            <h3>Session Stats</h3>
            <StatBoxes boxes={statBoxes} marginBottom={true} />
        </>
    );
};
