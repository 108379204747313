import React, { useMemo } from 'react';
import { Params, useParams } from 'react-router-dom';
import { useGetReferralList } from '../../../../hooks/affiliates/useGetReferralList/useGetReferralList';
import {
    useReferralMetricTotals,
    useReferralMetricsDetailed,
} from '../../../../hooks/affiliates/useReferralMetrics/useReferralMetrics';
import { DEFAULT_ITEMS_PER_PAGE } from '../../../../utils/global-variables';
import { Paginator } from '../../../default/Paginator/Paginator';
import { StatBoxes } from '../../../default/StatBoxes/StatBoxes';
import { StatBox } from '../../../default/StatBoxes/types';
import { Loading, Error, NoneFound, Info } from '../../../default/States/States';
import { DynamicTable } from '../../../default/Table/Table';

export const ReferralList = () => {
    const itemsPerPage: number = DEFAULT_ITEMS_PER_PAGE;
    const params: Params<string> = useParams();
    const currentPage: number = Number(params.currentPage) || 1;
    const skip: number = (currentPage - 1) * itemsPerPage;
    const { referralsCount, referrals, loading, error } = useGetReferralList({
        limit: itemsPerPage,
        skip,
    });
    const headers: string[] = useMemo<string[]>(
        () => ['Date', 'Current Plan', 'Conversion Date', 'Paid Months', 'Lifetime Value'],
        []
    );

    if (loading) return <Loading />;
    if (error) return <Error msg={error} />;
    if (!referrals?.length) return <NoneFound msg='No referrals found' />;

    return (
        <>
            <h3>Referrals</h3>
            <DynamicTable data={referrals} headers={headers} />
            <Paginator
                currentPage={currentPage}
                itemCount={referralsCount}
                itemsPerPage={itemsPerPage}
                path='/user/profile/affiliate/referral-list'
            />
            <Info msg='Note: sometimes a customer may revert back from a paid to a free plan, which will affect the stats.' />
        </>
    );
};

export const ReferralPerformanceMetricTotals = () => {
    const { metricTotals, loading, error } = useReferralMetricTotals();
    const { freeReferrals, payingReferrals, totalReferrals } = metricTotals || {};
    const statBoxes = useMemo<StatBox[]>(
        () => [
            {
                title: 'Free Referrals',
                number: freeReferrals || 0,
            },
            {
                title: 'Paying Referrals',
                number: payingReferrals || 0,
            },
            {
                title: 'Total Referrals',
                number: totalReferrals || 0,
            },
        ],
        [freeReferrals, payingReferrals, totalReferrals]
    );

    if (loading) return <Loading />;
    if (error) return <Error msg={error} />;
    if (!freeReferrals && !payingReferrals) return null;

    return (
        <>
            <h3>Totals</h3>
            <StatBoxes boxes={statBoxes} marginBottom={true} />
        </>
    );
};

export const ReferralPerformanceMetricsDetailed = () => {
    const { hasData, freeMetrics, payingMetrics, loading, error } = useReferralMetricsDetailed();
    const { freeLastWeek, freeLastMonth, freeLastQuarter, freeLastYear } = freeMetrics || {};
    const { payingLastWeek, payingLastMonth, payingLastQuarter, payingLastYear } =
        payingMetrics || {};
    const freeStatBoxes = useMemo<StatBox[]>(
        () => [
            {
                title: 'Free Last Week',
                number: freeLastWeek || 0,
            },
            {
                title: 'Free Last Month',
                number: freeLastMonth || 0,
            },
            {
                title: 'Free Last Quarter',
                number: freeLastQuarter || 0,
            },
            {
                title: 'Free Last Year',
                number: freeLastYear || 0,
            },
        ],
        [freeLastWeek, freeLastMonth, freeLastQuarter, freeLastYear]
    );
    const payingStatBoxes = useMemo<StatBox[]>(
        () => [
            {
                title: 'Paying Last Week',
                number: payingLastWeek || 0,
            },
            {
                title: 'Paying Last Month',
                number: payingLastMonth || 0,
            },
            {
                title: 'Paying Last Quarter',
                number: payingLastQuarter || 0,
            },
            {
                title: 'Paying Last Year',
                number: payingLastYear || 0,
            },
        ],
        [payingLastWeek, payingLastMonth, payingLastQuarter, payingLastYear]
    );

    if (loading) return <Loading />;
    if (error) return <Error msg={error} />;
    if (!hasData) return null;

    return (
        <>
            <h3>Free Referrals</h3>
            <StatBoxes boxes={freeStatBoxes} marginBottom={true} />
            <h3>Paying Referrals</h3>
            <StatBoxes boxes={payingStatBoxes} marginBottom={true} />
        </>
    );
};
