import React, { useContext } from 'react';
import { yupResolver } from '@hookform/resolvers/yup';
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form';
import { editWebsiteSchema } from '../../../../schemas/WebsiteSchema';
import { InputField } from '../../../default/Form/Form';
import { Loading, Error, Success } from '../../../default/States/States';
import { EditWebsiteFormInputs, EditWebsiteFormProps } from './types';
import { useEditWebsite } from '../../../../hooks/websites/useEditWebsite/useEditWebsite';
import styles from '../../../../css/default.module.scss';
import { AccountContext } from '../../../../contexts/AccountContext/AccountContext';
import { AccountContextType } from '../../../../contexts/AccountContext/types';

const EditWebsiteForm = ({
    selectedRow,
    refetchWebsites,
    setModalIsOpen,
}: EditWebsiteFormProps): JSX.Element => {
    const { loading, error, editWebsite, success } = useEditWebsite(selectedRow?.id);
    const { setSelectedWebsite } = useContext<AccountContextType>(AccountContext);
    const handleSubmit: SubmitHandler<EditWebsiteFormInputs> = async (formData) => {
        await editWebsite(formData);
        setSelectedWebsite(undefined);
        refetchWebsites();
    };
    const methods = useForm<EditWebsiteFormInputs>({
        resolver: yupResolver(editWebsiteSchema),
        mode: 'onTouched',
        defaultValues: {
            name: selectedRow?.name,
            url: selectedRow?.url,
        },
    });

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmit)}>
                    <div className={styles.formInner}>
                        <InputField
                            type='text'
                            title='name'
                            placeholder='e.g. Homepage CTR Test 1'
                        />
                        <InputField
                            type='text'
                            title='url'
                            placeholder='e.g. https://www.example.com'
                        />
                    </div>
                    {loading ? (
                        <Loading />
                    ) : (
                        <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                            <button type='submit' className={styles.btnPrimary}>
                                Submit
                            </button>
                            <button
                                onClick={() => setModalIsOpen(false)}
                                className={styles.btnDark}
                            >
                                Cancel
                            </button>
                        </div>
                    )}
                </form>
            </FormProvider>
            {error && <Error msg={error} marginTop={true} />}
            {success && <Success msg='Website changed successfully' marginTop={true} />}
        </>
    );
};

export default EditWebsiteForm;
