import * as Yup from 'yup';
import { SITE_URL } from '../utils/global-variables';

// const URL_REGEX: RegExp = /https?:\/\/(www\.)?[-a-zA-Z0-9_]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9_.//]*)/;
const URL_REGEX: RegExp = /^(https?:\/\/)[^\s/$.?#].[^\s]*$/;
const HAS_QUERY_OR_PARAM_REGEX: RegExp = /[?&=]/;
const HAS_FORBIDDEN_CHARS_REGEX: RegExp = /[<>,\\|[\]{}#%!@£$*()^$£@!±'"]/;

export const editWebsiteSchema = Yup.object().shape({
    name: Yup.string()
        .required('Enter a name')
        // .matches(/^[a-zA-Z0-9]+$/, 'The name can only contain letters and numbers'),
        .matches(/^[\w\s]+$/, 'The name can only contain letters, numbers, and spaces'),
    url: Yup.string()
        .required('Enter a URL')
        .matches(URL_REGEX, `Enter a valid URL with a HTTP or HTTPS prefix (e.g. ${SITE_URL})`)
        .test(
            'no-query-or-param',
            "URL cannot contain '?', '&' or '=' symbols",
            (value: any) => !HAS_QUERY_OR_PARAM_REGEX.test(value)
        )
        .test(
            'no-forbidden-chars',
            'URL cannot contain forbidden characters',
            (value: any) => !HAS_FORBIDDEN_CHARS_REGEX.test(value)
        ),
});
