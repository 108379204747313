import React from 'react';
import styles from '../../../css/default.module.scss';
import Modal from 'react-modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
// import { SignupForm } from '../SignupForm/SignupForm';
import { CreateAccountModalProps } from './types';
import { CreateAccountForm } from '../../account/CreateAccount/CreateAccount';

export const CreateAccountModal = ({
    modalIsOpen,
    setIsOpen,
}: CreateAccountModalProps): JSX.Element => {
    const closeModal = () => {
        setIsOpen(false);
    };

    return (
        <Modal
            isOpen={modalIsOpen}
            onRequestClose={closeModal}
            className={styles.modal}
            contentLabel='Create Account'
            portalClassName={styles.modalOverlayWrap} // cannot use overlayClassName
        >
            <button onClick={closeModal} className={styles.close} aria-label='close'>
                <FontAwesomeIcon icon={faTimes} />
            </button>
            <div>
                <h2>Create Free Account to Download</h2>
                <p>Complete the form below:</p>
                <CreateAccountForm affiliateForm={false} />
            </div>
        </Modal>
    );
};
