import React from 'react';
import styles from './styles.module.scss';
import WarningBox from '../../../../../../components/default/Informational/WarningBox/WarningBox';

const DissuasionMessage = () => (
    <div className={styles.container}>
        <p>
            We understand that you're considering downgrading from either our Beginner or Pro plan
            to our Free plan. We want to provide you with a clear understanding of the benefits
            you'll be losing if you proceed with this downgrade.
        </p>

        <WarningBox
            title='Limited Testing Opportunities'
            description="With the Free plan, you'll be limited to 2 free tests, and only 1 test per month if you've run out of the free test quota. This means fewer opportunities to optimize your website's performance and user experience."
        />

        <WarningBox
            title='Reduced Testing Flexibility'
            description='The Free plan allows you to run only one active test at a time. This limitation may significantly impact your ability to perform timely A/B tests and hinder your ability to make data-driven decisions.'
        />

        <WarningBox
            title='Branding on Test Pages'
            description='Downgrading to the Free plan will result in the presence of our branding on your test pages. This could dilute your brand identity and professionalism in front of your audience.'
        />

        <WarningBox
            title='Single Website Support'
            description="Our Free plan supports testing on only one website. If you operate multiple websites or have expansion plans, you'll lose the flexibility to test and optimize each one individually."
        />

        <WarningBox
            title='Limited Test Traffic'
            description='The Free plan restricts the maximum number of sessions per test to 400. This limitation could prevent you from gathering sufficient data to draw statistically significant conclusions from your tests.'
        />

        <WarningBox
            title='Limited Variants'
            description="With the Free plan, you'll be limited to only 1 variant per test. This lack of flexibility may prevent you from exploring more sophisticated and nuanced test scenarios."
        />

        <p>
            By downgrading to the Free plan, you'll be forfeiting these valuable features and
            benefits available in our Beginner and Pro plans. We designed these plans to empower you
            with comprehensive testing capabilities, more test options, and enhanced support to help
            you achieve your optimization goals effectively.
        </p>

        <p>
            Before making your decision, we encourage you to assess your testing requirements,
            business objectives, and growth ambitions. Remember, you can always upgrade back to the
            Beginner or Pro plan to regain access to these exclusive features whenever you feel the
            need for more advanced testing capabilities and broader support.
        </p>

        <p>
            If you have any questions or need further assistance in determining the most suitable
            plan for your needs, please don't hesitate to reach out to our customer support team.
            We're here to help you make the best choice for your success. Thank you for considering
            our services, and we hope you continue to thrive with our platform.
        </p>
    </div>
);

export default DissuasionMessage;
