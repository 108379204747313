import React, { memo, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import defaultStyles from '../../../../../css/default.module.scss';
import { Loading, Error, Success } from '../../../../default/States/States';
import { DeleteWebsiteProps } from './types';
import { useDeleteWebsiteMutation } from '../../../../../graphql/generated/schema';

const DeleteWebsite = memo(
    ({ selectedRow, closeModal, refetchWebsites }: DeleteWebsiteProps): JSX.Element => {
        /*
        note: something strange happens with this that doesn't happen with DeletePage
        - had to use deletionCompleted state to prevent an infinite loop
        - after deletion, the component re-renders multiple times, presumably because it runs infinitely until line 'setDeletionCompleted(true)' executes
        - can be fixed by adding 'refetchWebsites()' to end of deleteWebsite function and removing the useEffect, but then the modal won't close after deletion
        - not a big issue but may be worth investigating later
    */

        const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
        const [deletionCompleted, setDeletionCompleted] = useState<boolean>(false);
        const [{ data, fetching: loading, error }, deleteWebsiteFn] = useDeleteWebsiteMutation();

        const deleteWebsite = async () => {
            await deleteWebsiteFn({
                id: selectedRow?.id as string,
            });
            setShowConfirmation(false);
        };

        useEffect(() => {
            if (data?.deleteWebsite?.success) {
                const timeout = setTimeout(() => {
                    closeModal();
                }, 3000);

                if (!deletionCompleted) {
                    refetchWebsites();
                    setDeletionCompleted(true);
                }

                return () => {
                    clearTimeout(timeout);
                };
            }
        }, [data, closeModal, refetchWebsites, deletionCompleted]);

        return (
            <div className={styles.container}>
                <button onClick={() => setShowConfirmation(true)} className={styles.deleteWebsite}>
                    Delete Website
                </button>
                {showConfirmation && (
                    <div className={styles.confirmation}>
                        <h3>Are you sure you want to delete this website?</h3>
                        {loading ? (
                            <Loading centralised={true} />
                        ) : (
                            <div
                                className={`${defaultStyles.buttonsContainer} ${defaultStyles.spaced}`}
                            >
                                <button
                                    type='submit'
                                    className={defaultStyles.btnRed}
                                    onClick={deleteWebsite}
                                >
                                    Yes
                                </button>
                                <button
                                    onClick={() => setShowConfirmation(false)}
                                    className={defaultStyles.btnGrey}
                                >
                                    No
                                </button>
                            </div>
                        )}
                    </div>
                )}
                {error && <Error msg={error.message} marginTop={true} />}
                {data?.deleteWebsite?.success && (
                    <Success msg='Website deleted successfully' marginTop={true} />
                )}
            </div>
        );
    }
);

export default DeleteWebsite;
