import React from 'react';
import { AffiliateCommissionsTable } from '../../../../../components/account/affiliates/AffiliateCommissions/AffiliateCommissions';
import { withPage } from '../../../../../components/layout/Page/Page';
import { affiliateCommissionsMeta } from '../../../../misc/MetaTags';
import defaultStyles from '../../../../../css/default.module.scss';
import { Back } from '../../../../../components/default/Navigation/Navigation';

export const AffiliateCommissions = () => (
    <>
        <h1 className={defaultStyles.centralisedText}>Commissions</h1>
        <h2>History</h2>
        <p>View all commissions you've generated.</p>
        <AffiliateCommissionsTable />
        <Back to='/user/profile/affiliate' text='Back to Affiliate Profile' />
    </>
);

export default withPage(AffiliateCommissions)({
    meta: affiliateCommissionsMeta,
    innerSpacing: true,
});
