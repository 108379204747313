export const STAGE_FIELDS = new Map<number, string[]>([
    [1, ['name', 'goalId', 'websiteId', 'pageId']],
    [2, ['variants']],
    [3, ['variants']], // this is correct too
    [4, ['maxSessions', 'targeting']],
]);

export enum STAGE_TITLES {
    'Initial Details' = 1,
    'Variants' = 2,
    'Traffic Allocation' = 3,
    'Targeting Options' = 4,
}

export const FINAL_STAGE = Array.from(STAGE_FIELDS.keys()).pop() || 0;
