import {
    faScroll,
    faVial,
    faClock,
    faTrophy,
    faRedo,
    faFile,
    faTrafficLight,
    faDesktop,
    faLayerGroup,
    faPoundSign,
    faPaintBrush,
    faEllipsisH,
    faPenAlt,
    faFunnelDollar,
    faMouse,
    faCreditCard,
    faRecycle,
    faHandPointRight,
} from '@fortawesome/free-solid-svg-icons';
import styles from './styles.module.scss';

export const AB_BENEFITS = [
    'Sales',
    'Sign-ups',
    'User Experience',
    'Price Setting',
    'Paid Ads',
    'Content Effectiveness',
    'Design and Layouts',
    'Promotional Offers',
    'Device Performance',
    'Page Loading Speeds',
    'Personalization and Segmentation',
    'Marketing Campaigns',
    'Product Offerings',
    'Lead Generation',
    'Conversions',
    'Bounce Rates',
    'Navigation',
    'Much More',
];

export const PROCESS_STEPS = [
    {
        icon: { icon: faScroll },
        title: 'Install Our Tracking Script',
        description: 'Once only',
        ariaLabel: 'test script',
    },
    {
        icon: { icon: faVial },
        title: (
            <>
                <span className={styles.primaryColour}>Create</span> an A/B Test
            </>
        ),
        description: 'Enter a page URL or code',
        ariaLabel: 'test vial',
    },
    {
        icon: { icon: faClock },
        title: (
            <>
                <span className={styles.primaryColour}>Wait</span> for the Results
            </>
        ),
        ariaLabel: 'clock',
    },
    {
        icon: { icon: faTrophy },
        title: (
            <>
                <span className={styles.primaryColour}>Implement</span> the Winning Variant
            </>
        ),
        ariaLabel: 'test winner',
    },
    {
        icon: { icon: faRedo },
        title: <span className={styles.primaryColour}>Repeat</span>,
        ariaLabel: 'test repeat',
    },
];

export const TEST_CREATION_STEPS = [
    {
        icon: { icon: faFile },
        title: (
            <>
                Select a <span className={styles.primaryColour}>page</span> you want to test
            </>
        ),
        ariaLabel: 'page',
    },
    {
        icon: { icon: faLayerGroup },
        title: (
            <>
                Create test <span className={styles.primaryColour}>variants</span>
            </>
        ),
        description: 'Enter another page URL or code',
        ariaLabel: 'variants',
    },
    {
        icon: { icon: faTrafficLight },
        title: (
            <>
                Set <span className={styles.primaryColour}>traffic</span> allocation
            </>
        ),
        ariaLabel: 'traffic',
    },
    {
        icon: { icon: faDesktop },
        title: (
            <>
                Set target <span className={styles.primaryColour}>devices</span> &amp; maximum
                sessions
            </>
        ),
        ariaLabel: 'desktop',
    },
];

export const HOMEPAGE_LIST_BOXES = [
    {
        icon: faPenAlt,
        label: 'pen',
        title: 'Forms',
        list: ['Fields', 'Length', 'Required Inputs'],
    },
    {
        icon: faFunnelDollar,
        label: 'funnel',
        title: 'Marketing Campaigns',
        list: ['Discounts', 'Promotions', 'Urgency Offers'],
    },
    {
        icon: faPoundSign,
        label: 'currency',
        title: 'Pricing',
        list: ['Amount', 'Model', 'Upsells', 'Cross-sells'],
    },
    {
        icon: faEllipsisH,
        label: 'ellipsis',
        title: 'Content Effectiveness',
        list: ['Product Images', 'Descriptions', 'Videos'],
    },
    {
        icon: faPaintBrush,
        label: 'paint brush',
        title: 'Styles',
        list: ['Layouts', 'Colors', 'Fonts'],
    },
    {
        icon: faHandPointRight,
        label: 'pointing',
        title: 'Calls-to-action',
        list: ['Options', 'Placement', 'Style'],
    },
    {
        icon: faCreditCard,
        label: 'credit card',
        title: 'Checkout',
        list: ['Payment Options', 'Abandonment Incentives', 'Trust Logos'],
    },
    {
        icon: faRecycle,
        label: 'recycling symbol',
        title: 'Traffic Capture',
        list: ['Exit Pop-ups', 'Lead Magnets'],
    },
    {
        icon: faMouse,
        label: 'mouse',
        title: 'Engagement',
        list: ['Clicks', 'Time-on-page', 'Bounces'],
    },
];
