import React from 'react';
import styles from './styles.module.scss';

const AnimatedAbTest = ({ cls = '' }: { cls?: string }) => (
    <div className={`${styles.outerWrap} ${cls}`}>
        <div className={styles.innerWrap}>
            <div className={`${styles.formContainer} ${styles.a}`}>
                <div className={styles.formOuter}>
                    <form className={styles.form}>
                        <h4>Variant A</h4>
                        <input type='text' />
                        <input type='email' />
                        <button type='submit'>Download</button>
                    </form>
                </div>
                <p className={`${styles.conversionRate} ${styles.a}`}>
                    <span className={styles.conversionText}>Conversion Rate:</span>{' '}
                    <span className={styles.amount}></span>
                </p>
                <div className={styles.checkmarkContainer}>
                    <svg
                        className={styles.checkmark}
                        xmlns='http://www.w3.org/2000/svg'
                        viewBox='0 0 52 52'
                    >
                        <circle
                            className={styles.checkmarkCircle}
                            cx='26'
                            cy='26'
                            r='25'
                            fill='none'
                        />
                        <path
                            className={styles.checkmarkCheck}
                            fill='none'
                            d='M14.1 27.2l7.1 7.2 16.7-16.8'
                        />
                    </svg>
                </div>
            </div>
            <div className={`${styles.formContainer} ${styles.b}`}>
                <div className={styles.formOuter}>
                    <form className={styles.form}>
                        <h4>Variant B</h4>
                        <input type='text' />
                        <input type='email' />
                        <button type='submit'>Submit</button>
                    </form>
                </div>
                <p className={`${styles.conversionRate} ${styles.b}`}>
                    <span className={styles.conversionText}>Conversion Rate:</span>{' '}
                    <span className={styles.amount}></span>
                </p>
            </div>
        </div>
    </div>
);

export default AnimatedAbTest;
