import React from 'react';
import { Section } from '../../../../components/default/Areas/Areas';
import { withPage } from '../../../../components/layout/Page/Page';
import { testCreationGuideMeta } from '../../../misc/MetaTags';
import { CodeBlock } from '../../../../components/default/Cards/Cards';
import { Link } from 'react-router-dom';
import { Info, Warning } from '../../../../components/default/States/States';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../styles.module.scss';
import defaultStyles from '../../../../css/default.module.scss';
import { Back } from '../../../../components/default/Navigation/Navigation';
import ListWithArrows from '../../../../components/default/ListWithArrows/ListWithArrows';
import { TEST_EXAMPLE_LINK_ITEMS } from '../../variables';
import { VideoModal } from '../../../../components/default/VideoModal/VideoModal';

const TestCreationGuide = () => {
    const formHtmlBefore = `
        <form action="/submit_endpoint" method="post">
        <label for="email">Enter your email:</label>
        <input type="email" id="email" name="email">
        <input type="submit" value="Submit">
        </form>
    `;
    const formHtmlAfter = `
        <form data-ab="cro-simple" action="/submit_endpoint" method="post">
        <label for="email">Enter your email:</label>
        <input type="email" id="email" name="email">
        <input type="submit" value="Submit">
        </form>
    `;
    const buttonHtmlBefore = `<button>Submit</button> <button class="btn-primary" disabled>Submit</button>`;
    const buttonHtmlAfter = `<button data-ab='cro-simple'>Submit</button> <button data-ab='cro-simple' class="btn-primary" disabled>Submit</button>`;
    const linkHtmlBefore = `<a href='#'>Link</a> <a href="#" target="_blank">Link</a>`;
    const linkHtmlAfter = `<a data-ab='cro-simple' href='#'>Link</a> <a data-ab='cro-simple' href="#" target="_blank">Link</a>`;

    return (
        <>
            <Contents />

            <VideoModal />

            <Section patterned={true}>
                <h2 id='tracking'>Tracking</h2>
                <Info
                    msg="Tracking Summary (TL;DR): if you want to track a single element (such as a form, button or link), you may want to (not compulsory) add a data-ab='cro-simple' attribute to the element's HTML tag (to enable custom tracking), otherwise do nothing (to enable standard tracking)."
                    marginBottom={true}
                />
                <h3 id='automatic-tracking'>Standard Tracking</h3>
                <p>
                    The <Link to='/support/script-setup'>tracking script</Link> that you embed on
                    your website automatically tracks the following average metrics for the control
                    and each variant:
                </p>
                <ul>
                    <li>Form submits</li>
                    <li>Time on page</li>
                    <li>Scrolled down percentage</li>
                    <li>Page clicks</li>
                    <li>Bounce rate</li>
                </ul>
                <p>These metrics will be tracked without any further installation steps.</p>
                <h3 id='custom-tracking'>Custom Tracking</h3>
                <h4 id='specific-element-tracking'>Tracking a Specific Element</h4>
                <p>
                    Oftentimes you may only be interested in tracking a specific element on the page
                    (such as a button, link or form) rather than the averages of multiple elements.
                    To track just one element, all you need to do is add a 'data-ab' attribute with
                    a value of 'cro-simple' to the html element you want to track. If you do not,
                    the standard tracking will be enabled instead. You need to add this attribute to
                    the main test page and any redirected-to pages (and not remove it from any
                    variants).
                </p>
                <Info
                    msg='This is important yet simple for getting the best results from your tests.'
                    marginBottom={true}
                />
                <Warning
                    marginBottom={true}
                    msg="You must add the data-ab='cro-simple' attribute to the same element on the control page and any redirected-to pages, otherwise the data will be mismatched and the test results invalidated."
                />
                <h4 id='buttons-and-links'>Buttons and Links</h4>
                <p>
                    Adding the{' '}
                    <span className={defaultStyles.highlightedText}>data-ab='cro-simple'</span>{' '}
                    attribute to a button or anchor tag (i.e. a link) will only track that element.
                    In addition to the standard tracking metrics, it will also track clicks and
                    hovers on that element.
                </p>
                <p>
                    Adding the attribute to buttons is very straightforward. All you need to do is
                    change the HTML as follows:
                </p>
                <CodeBlock
                    title='HTML buttons before - without tracking attributes'
                    code={buttonHtmlBefore}
                    marginBottom={true}
                />
                <CodeBlock
                    title='HTML buttons after - with tracking attributes'
                    code={buttonHtmlAfter}
                    marginBottom={true}
                />
                <p>Likewise, adding the attribute to only track a link is just as easy:</p>
                <CodeBlock
                    title='HTML links before - without tracking attributes'
                    code={linkHtmlBefore}
                    marginBottom={true}
                />
                <CodeBlock
                    title='HTML links after - with tracking attributes'
                    code={linkHtmlAfter}
                    marginBottom={true}
                />
                <p>
                    The attribute must be placed on the{' '}
                    <span className={defaultStyles.highlightedText}>a</span> or{' '}
                    <span className={defaultStyles.highlightedText}>button</span> tag itself.
                </p>
                <Info
                    msg="You should only add the `data-ab='cro-simple'` attribute to one element on your page. The above code snippets are just examples that illustrate how different HTML elements could be changed."
                    marginBottom={true}
                />
                <h4 id='forms'>Forms</h4>
                <p>
                    You can also add the attribute to a form to focus tracking on that element only.
                    In addition to the standard tracking metrics, it will also track time spent
                    using and clicks and hovers on the form.
                </p>
                <p>
                    Adding the attribute is again straightforward and can be applied to the HTML the
                    same way as with a button or link:
                </p>
                <CodeBlock
                    title='HTML Form before - without tracking attribute'
                    code={formHtmlBefore}
                    marginBottom={true}
                />
                <CodeBlock
                    title='HTML Form after - with tracking attribute'
                    code={formHtmlAfter}
                    marginBottom={true}
                />
                <p>
                    The attribute must be placed on the{' '}
                    <span className={defaultStyles.highlightedText}>form</span> tag itself.
                </p>
                <Warning msg="When implementing custom tracking, you can only track one element at a time. If you have multiple elements with a `data-ab='cro-simple'` attribute, only one element will be tracked - the first that's closest to the opening <body> tag." />
            </Section>

            <Section patterned={true}>
                <h2 id='creating-a-variant'>Creating a Variant</h2>
                <Info
                    msg='Variant Summary (TL;DR): when creating a variant, you can either redirect to another page or inject code (CSS and/or JavaScript) onto the page, but not both.'
                    marginBottom={true}
                />
                <p>
                    A variant is a changed or altered version of a web page. Let's say you have a
                    website page that you want to improve. You create a different version of that
                    same page, maybe changing the color of a button or rearranging the layout. This
                    altered version is called a variant.
                </p>
                <p>
                    When creating a variant, you can either redirect to another page or inject code
                    (CSS and/or JavaScript) onto the page, but not both. It is up to you how you
                    would like each variant to display. From the central page being tested (that is,
                    not the redirected-to pages), traffic is then split and sent to different
                    variants, according to how you define your traffic allocation.
                </p>
                <h3 id='redirects'>Redirects</h3>
                <p>
                    If you have another page that you want to use as a variant to test against, you
                    can enter the URL of that page to set it as a variant. Then, whenever a visitor
                    lands on the main page and this variant is calculated to show, it will redirect
                    the user to this page. Tracking only occurs on the redirected-to page if a user
                    is redirected from the main test page onto the redirected-to page; if a user
                    arrives at the redirected-to page any other way, tracking will not occur.
                </p>
                <Info
                    msg='This option is often preferred by non-coders, especially those who use a website builder or similar to create another page.'
                    marginBottom={true}
                />
                <h3 id='code-insertion'>Inserting Code</h3>
                <p>
                    For those who know a basic or higher amount of code, you can set a variant up
                    such that it injects CSS and/or JavaScript onto the page whenever this variant
                    is calculated to show.
                </p>
                <Info
                    msg='You can also preview a variant after you have created a test to ensure it behaves correctly before starting it.'
                    marginBottom={true}
                />
                <Warning msg='Do not alter the HTML of a page that is currently being tested - doing so may invalidate your test results.' />
            </Section>

            <Section patterned={true}>
                <h2 id='examples'>Test Creation Examples</h2>
                <p>See below for examples of creating tests:</p>
                <ListWithArrows items={TEST_EXAMPLE_LINK_ITEMS} />
                <Back to='/support' text='Support Hub' />
            </Section>
        </>
    );
};

const Contents = () => (
    <Section patterned={true}>
        <h1>How to Create a Test</h1>
        <h2>Contents:</h2>
        <ul className={styles.outerList}>
            <li>
                <a href='#tracking'>
                    <FontAwesomeIcon
                        icon={faAngleRight}
                        aria-label='right arrow'
                        className={styles.icon}
                    />{' '}
                    <span className={styles.label}>Tracking</span>
                </a>
            </li>
            <ul>
                <li>
                    <a href='#automatic-tracking'>
                        <FontAwesomeIcon
                            icon={faAngleRight}
                            aria-label='right arrow'
                            className={styles.icon}
                        />{' '}
                        <span className={styles.label}>Standard Tracking</span>
                    </a>
                </li>
                <li>
                    <a href='#custom-tracking'>
                        <FontAwesomeIcon
                            icon={faAngleRight}
                            aria-label='right arrow'
                            className={styles.icon}
                        />{' '}
                        <span className={styles.label}>Custom Tracking</span>
                    </a>
                    <ul>
                        <li>
                            <a href='#specific-element-tracking'>
                                <FontAwesomeIcon
                                    icon={faAngleRight}
                                    aria-label='right arrow'
                                    className={styles.icon}
                                />{' '}
                                <span className={styles.label}>Specific Elements</span>
                            </a>
                        </li>
                        <li>
                            <a href='#buttons-and-links'>
                                <FontAwesomeIcon
                                    icon={faAngleRight}
                                    aria-label='right arrow'
                                    className={styles.icon}
                                />{' '}
                                <span className={styles.label}>Buttons and Links</span>
                            </a>
                        </li>
                        <li>
                            <a href='#forms'>
                                <FontAwesomeIcon
                                    icon={faAngleRight}
                                    aria-label='right arrow'
                                    className={styles.icon}
                                />{' '}
                                <span className={styles.label}>Forms</span>
                            </a>
                        </li>
                    </ul>
                </li>
            </ul>
            <li>
                <a href='#creating-a-variant'>
                    <FontAwesomeIcon
                        icon={faAngleRight}
                        aria-label='right arrow'
                        className={styles.icon}
                    />{' '}
                    <span className={styles.label}>Creating a Variant</span>
                </a>
                <ul>
                    <li>
                        <a href='#redirects'>
                            <FontAwesomeIcon
                                icon={faAngleRight}
                                aria-label='right arrow'
                                className={styles.icon}
                            />{' '}
                            <span className={styles.label}>Redirects</span>
                        </a>
                    </li>
                    <li>
                        <a href='#code-insertion'>
                            <FontAwesomeIcon
                                icon={faAngleRight}
                                aria-label='right arrow'
                                className={styles.icon}
                            />{' '}
                            <span className={styles.label}>Inserting Code</span>
                        </a>
                    </li>
                </ul>
            </li>
            <li>
                <a href='#examples'>
                    <FontAwesomeIcon
                        icon={faAngleRight}
                        aria-label='right arrow'
                        className={styles.icon}
                    />{' '}
                    <span className={styles.label}>Test Creation Examples</span>
                </a>
            </li>
        </ul>
    </Section>
);

export default withPage(TestCreationGuide)({ meta: testCreationGuideMeta, fullWidth: true });
