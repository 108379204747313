export const Description = () => (
    <>
        <p>
            If you don't, a lower percentage of your visitors will buy from you - it's that simple.
        </p>
        <p>
            Try the <u>forever free</u> version and ditch it if it fails! Or do nothing and accept
            average conversions at best...
        </p>
    </>
);
