import { MetaTags } from './types';

export const homepageMeta: MetaTags = {
    title: 'Easy A/B Testing with Conversion Rate Optimisation Software',
    description:
        'Create your own simple A/B tests using the CRO Simple platform. Easy for businesses to get started to optimize conversions effortlessly and affordably.',
};
export const aboutMeta: MetaTags = {
    title: 'About CRO Simple | Simple and Affordable DIY A/B Tests',
    description:
        'Discover how CRO Simple simplifies A/B testing, empowering website owners with an intuitive platform to create their own A/B tests to improve conversion rates.',
};
export const blogMeta: MetaTags = {
    title: `CRO Simple Blog | A/B Testing Tips, Guides, Advice, How-to's and More`,
    description:
        'Explore the CRO Simple Blog for expert tips, guides, and advice on A/B testing. Discover how to optimize your website with how-to articles and the latest in conversion rate optimization.',
};
export const paymentInfoMeta: MetaTags = {
    title: 'Secure Payment Information for CRO Simple',
    description:
        'Trustworthy payment procedures at CRO Simple. Ensuring the security of your payment details. Explore our pricing plans for our affordable A/B testing software.',
};
export const contactMeta: MetaTags = {
    title: 'Contact CRO Simple for A/B testing Solutions',
    description:
        'Reach out to CRO Simple for inquiries or support. Professional assistance for your A/B testing needs. Discover how we empower businesses with our user-friendly platform.',
};
export const loginMeta: MetaTags = {
    title: 'CRO Simple Login',
    description:
        'Access your CRO Simple account. Streamlined login process for our A/B testing software. Begin optimizing conversions effortlessly with our platform.',
};
export const newPasswordMeta: MetaTags = {
    title: 'Create a New Password | CRO Simple Account',
    description:
        'Securely set up a new password for your CRO Simple account. Simple steps to ensure account security. Get back to optimizing your website with ease.',
};
export const resetPasswordMeta: MetaTags = {
    title: 'Reset Your Password | CRO Simple Account',
    description:
        'Effortlessly reset your CRO Simple account password. Hassle-free steps for account recovery. Resume your A/B testing journey promptly.',
};
export const newPasswordSuccessMeta: MetaTags = {
    title: 'Password Reset Success | CRO Simple',
    description:
        'Your password reset was successful. Get back to optimizing conversions with CRO Simple. Simplify A/B testing for your business.',
};
export const createAccountMeta: MetaTags = {
    title: 'Sign Up to Create Simple A/B Tests | CRO Simple',
    description:
        'Join CRO Simple and unlock the power of A/B testing for your website. Create your account hassle-free and start optimizing conversions affordably.',
};
export const userDashboardMeta: MetaTags = {
    title: 'Your Dashboard for A/B testing | CRO Simple',
    description:
        'Access your personalized dashboard at CRO Simple. Manage and monitor your A/B tests effortlessly to enhance your website’s performance.',
};
export const accountCreatedSuccessfully: MetaTags = {
    title: 'Account Created Successfully - Get Started Now | CRO Simple',
    description: 'You have successfully created your account. Act now to get testing.',
};

export const userWebsitesMeta: MetaTags = {
    title: 'Manage Your Websites | CRO Simple A/B testing',
    description:
        'Effortlessly manage all your websites for A/B testing at CRO Simple. Optimize conversions across multiple sites with ease.',
};

export const userTestMeta: MetaTags = {
    title: 'View Individual Test | CRO Simple A/B testing',
    description:
        'Analyze and optimize individual tests with ease using CRO Simple. Gain insights to enhance your website’s performance effortlessly.',
};

export const userTestsMeta: MetaTags = {
    title: 'Your A/B tests | CRO Simple A/B testing',
    description:
        'Explore and manage all your A/B tests conveniently at CRO Simple. Drive conversions and engagement effectively with our user-friendly platform.',
};

export const testVariantMeta: MetaTags = {
    title: 'Analyze Test Variants | CRO Simple A/B testing',
    description:
        'Dive into individual test variants effortlessly with CRO Simple. Gain insights and optimize your website’s performance seamlessly.',
};

export const createTestMeta: MetaTags = {
    title: 'Start New A/B test | CRO Simple A/B testing',
    description:
        'Initiate new A/B tests effortlessly with CRO Simple. Begin optimizing conversions and engagement for your website effortlessly.',
};

export const testCreatedMeta: MetaTags = {
    title: 'Test Created Successfully | CRO Simple A/B testing',
    description:
        'Celebrate your success! Your A/B test has been created at CRO Simple. Start optimizing conversions hassle-free.',
};

export const testResultsMeta: MetaTags = {
    title: 'View Test Results | CRO Simple A/B testing',
    description:
        'Analyze your A/B test results conveniently at CRO Simple. Gain insights and optimize your website’s performance effortlessly.',
};

export const userPagesMeta: MetaTags = {
    title: 'Manage Your Pages | CRO Simple A/B testing',
    description:
        'Effortlessly manage your website pages for A/B testing at CRO Simple. Optimize conversions across multiple pages with ease.',
};

export const userProfileMeta: MetaTags = {
    title: 'Your Profile | CRO Simple A/B testing',
    description:
        'Manage your profile at CRO Simple. Personalize your experience and optimize conversions effortlessly with our user-friendly platform.',
};

export const userAvatarGoalsMeta: MetaTags = {
    title: 'Set Your Goals | CRO Simple A/B testing',
    description:
        'Define your goals for A/B testing success at CRO Simple. Tailor your strategy and drive conversions effectively.',
};

export const userAvatarBusinessMeta: MetaTags = {
    title: 'Optimize Business | CRO Simple A/B testing',
    description:
        'Enhance your business performance with CRO Simple. Utilize A/B testing to drive conversions and engagement efficiently.',
};

export const userAvatarDemographicsMeta: MetaTags = {
    title: 'Analyze Demographics | CRO Simple A/B testing',
    description:
        'Explore demographics for enhanced A/B testing insights at CRO Simple. Optimize conversions based on audience segmentation.',
};
export const userAvatarPreviousExperienceMeta: MetaTags = {
    title: 'Leverage Experience | CRO Simple A/B testing',
    description:
        'Utilize past experiences for A/B testing success at CRO Simple. Optimize conversions based on valuable insights.',
};

export const scriptSetupMeta: MetaTags = {
    title: 'Configure Script Easily | CRO Simple A/B testing',
    description:
        'Effortlessly set up scripts with CRO Simple. Follow our script setup guide for seamless integration and optimization of your A/B tests.',
};

export const scriptVerificationMeta: MetaTags = {
    title: 'Verify Your Script | CRO Simple A/B testing',
    description:
        'Ensure script accuracy with CRO Simple. Use our script verification tools for confidence in your A/B testing setup.',
};

export const userPlanMeta: MetaTags = {
    title: 'Your A/B testing Plan | CRO Simple',
    description:
        'Explore and manage your A/B testing plan with CRO Simple. Optimize conversions and engagement with our flexible user plans.',
};

export const cancelPlanMeta: MetaTags = {
    title: 'Cancel Your A/B testing Plan | CRO Simple',
    description:
        'Cancel your A/B testing plan hassle-free with CRO Simple. Follow our easy process to manage your subscription.',
};

export const changePlanMeta: MetaTags = {
    title: 'Change Your A/B testing Plan | CRO Simple',
    description:
        'Modify your A/B testing plan effortlessly with CRO Simple. Upgrade or downgrade to suit your optimization needs.',
};

export const cancelPlanSuccessMeta: MetaTags = {
    title: 'Plan Cancelled Successfully | CRO Simple A/B testing',
    description:
        'Celebrate your success! Your A/B testing plan has been cancelled at CRO Simple. Explore other options for optimization.',
};

export const avatarProfileSuccessMeta: MetaTags = {
    title: 'Avatar Profile Created Successfully | CRO Simple A/B testing',
    description:
        'Congratulations! Your avatar profile has been created successfully at CRO Simple. Personalize your A/B testing experience.',
};

export const changePlanSuccessMeta: MetaTags = {
    title: 'Plan Changed Successfully | CRO Simple A/B testing',
    description:
        'Celebrate your success! Your A/B testing plan has been changed at CRO Simple. Explore new features and options for optimization.',
};

export const paymentFailedMeta: MetaTags = {
    title: 'Payment Failed | CRO Simple A/B testing',
    description:
        'Oops! Your payment has failed at CRO Simple. Please review and try again to continue your A/B testing subscription.',
};

export const paymentSuccessMeta: MetaTags = {
    title: 'Payment Success | CRO Simple A/B testing',
    description:
        'Success! Your payment is confirmed at CRO Simple. Enjoy uninterrupted access to premium A/B testing features.',
};

export const paymentMethodUpdateSuccessMeta: MetaTags = {
    title: 'Payment Method Updated Successfully | CRO Simple A/B testing',
    description:
        'Your payment method has been updated successfully at CRO Simple. Continue optimizing your website with confidence.',
};

export const paymentMethodUpdateCancelledMeta: MetaTags = {
    title: 'Payment Method Update Cancelled | CRO Simple A/B testing',
    description:
        'Your payment method update has been cancelled at CRO Simple. Review your preferences and try again if needed.',
};

export const editAccountMeta: MetaTags = {
    title: 'Edit Your Account | CRO Simple A/B testing',
    description:
        'Customize your account details effortlessly at CRO Simple. Personalize your experience and optimize conversions hassle-free.',
};

export const styleGuideMeta: MetaTags = {
    title: 'Style Guide | CRO Simple A/B testing',
    description:
        'Explore the default styles of various elements with the CRO Simple Style Guide. Learn about our platform’s design principles.',
};

export const pageNotFoundMeta: MetaTags = {
    title: 'Page Not Found (404) | CRO Simple A/B testing',
    description:
        'Oops! The page you tried to load could not be found at CRO Simple. Navigate back or explore our other features.',
};

export const pageErrorMeta: MetaTags = {
    title: 'Page Error | CRO Simple A/B testing',
    description:
        'Something went wrong at CRO Simple. Our team is on it! Navigate back or contact support for assistance.',
};

export const supportMeta: MetaTags = {
    title: 'Get Support | CRO Simple A/B testing',
    description:
        'Explore support options at CRO Simple. Our team is here to assist you with any questions or issues regarding A/B testing.',
};

export const testCreationGuideMeta: MetaTags = {
    title: 'A/B Test Creation Guide | CRO Simple A/B testing',
    description:
        'Learn how to create A/B tests the right way with CRO Simple. Follow our comprehensive guide for successful optimization.',
};

export const testExampleProductImageEffectivenessMeta: MetaTags = {
    title: 'Product Image Effectiveness A/B Test Example',
    description: 'Discover how to create a product image test by using our A/B testing platform.',
};

export const createFormTestMeta: MetaTags = {
    title: 'Landing Page Form A/B Test Example',
    description:
        'Discover how to create a landing page form test by using our A/B testing platform.',
};

export const privacyMeta: MetaTags = {
    title: 'Privacy Policy | CRO Simple A/B testing',
    description:
        'Review our privacy policy at CRO Simple. Understand how we handle your data while optimizing conversions through A/B testing.',
};

export const cookiesMeta: MetaTags = {
    title: 'Cookie Policy | CRO Simple A/B testing',
    description:
        'Learn about our cookie policy at CRO Simple. Understand how cookies enhance your A/B testing experience and user engagement.',
};

export const termsMeta: MetaTags = {
    title: 'Terms & Conditions | CRO Simple A/B testing',
    description:
        'Review the terms and conditions at CRO Simple. Understand the guidelines for using our platform for effective A/B testing.',
};

export const affiliateProgramMeta: MetaTags = {
    title: 'A/B Testing Affiliate Program | CRO Simple',
    description:
        'Sell our products to receive lifetime recurring commissions. Our affiliate program explained.',
};

export const partnershipsMeta: MetaTags = {
    title: 'CRO Partnership Program | CRO Simple',
    description:
        'Provide CRO or A/B testing services? Partner with us to receive ongoing commissions for anyone you refer to us.',
};

export const affiliateFaqMeta: MetaTags = {
    title: 'FAQs About Our Affiliate Program | CRO Simple',
    description:
        'Get answers to commonly asked questions about our affiliate program at CRO Simple. Learn how to maximize your affiliate journey with us!',
};

export const affiliateDashboardMeta: MetaTags = {
    title: 'Efficient Affiliate Dashboard | Monitor Conversions | CRO Simple',
    description:
        'Access your intuitive affiliate dashboard at CRO Simple to track and optimize conversions easily. Simplify your testing process today!',
};

export const referralListMeta: MetaTags = {
    title: 'Referral List for Affiliates | CRO Simple',
    description:
        'Check your affiliate referral list at CRO Simple to track your successes and optimize your marketing efforts. Start boosting conversions!',
};

export const affiliatePaymentsMeta: MetaTags = {
    title: 'Swift Affiliate Payments | Get Rewarded | CRO Simple',
    description:
        'Receive quick and reliable affiliate payments at CRO Simple. Join us and get rewarded for your contribution to conversion optimization!',
};

export const affiliateCommissionsMeta: MetaTags = {
    title: 'Earn Commissions | Boost Conversions | CRO Simple',
    description:
        'Maximize your earnings through affiliate commissions at CRO Simple. Start optimizing conversions and see your rewards grow!',
};

export const affiliateBalanceMeta: MetaTags = {
    title: 'Manage Affiliate Balance | CRO Simple',
    description:
        'Effortlessly manage your affiliate balance with ease at CRO Simple. Streamline your earnings and optimize conversions hassle-free!',
};

export const affiliateSessionsMeta: MetaTags = {
    title: 'Monitor Affiliate Sessions | CRO Simple',
    description:
        'Keep track of your affiliate sessions at CRO Simple to optimize your marketing strategies and drive better conversion rates. Start now!',
};

export const createAffiliateAccountMeta: MetaTags = {
    title: 'Simple Affiliate Account Creation | Join CRO Simple',
    description:
        'Create your affiliate account hassle-free with CRO Simple. Start optimizing conversions and boosting earnings today!',
};

export const affiliateLoginMeta: MetaTags = {
    title: 'Login to Your Affiliate Account | CRO Simple',
    description:
        'Access your affiliate account securely at CRO Simple. Begin optimizing conversions and maximizing your earnings effortlessly!',
};

export const pricingMeta: MetaTags = {
    title: 'Free and Paid A/B Testing Pricing Plans | CRO Simple',
    description:
        'Explore our free and affordable pricing plans at CRO Simple for user-friendly A/B testing. Start optimizing conversions today!',
};

export const servicesMeta: MetaTags = {
    title: 'Custom A/B Testing Services | CRO Simple',
    description:
        'Want technical assistance with A/B testing? Explore our professional A/B testing services, from basic setup to custom development.',
};

export const quickWinsMeta: MetaTags = {
    title: 'A/B Testing Quick Wins Guide',
    description:
        'Download your free guide to discover easy ways to improve your web pages with simple A/B tests.',
};

export const landingPageTemplateMeta: MetaTags = {
    title: 'HTML, CSS and JavaScript Landing Page Template Code Download',
    description:
        'Download this simple landing page template and amend it for use in your own projects. Includes the full HTML, CSS and JavaScript code.',
};

export const paidAdLandingPage: MetaTags = {
    title: 'Simple A/B Testing Platform to Optimise Websites',
    description:
        "Want a simple way of improving your website's conversion rate? Our A/B testing tool allows anyone to create simple A/B tests regardless of their technical ability.",
};

export const paidAdAdvertisersLandingPage: MetaTags = {
    title: 'Improve Paid Ad Conversion Rates via A/B Testing',
    description:
        'Want to improve your paid ad conversion rates? Use A/B testing to test different parts of your landing pages to discover how to improve your CPA and ROAS.',
};

export const paidAdEcommerceLandingPage: MetaTags = {
    title: 'Boost Ecommerce Conversion Rates via A/B Testing',
    description:
        'Want to improve your eCommerce store conversion rates? Use A/B testing to test different parts of your pages to discover how to improve conversions.',
};

export const newNewsletterSubscriber: MetaTags = {
    title: 'New Subsciber',
    description: 'Congratulations on your subscription. You can now access your free content.',
};

export const blogTemplate: MetaTags = {
    title: '@todo',
    description: '@todo',
};

export const wordpressAndWoocommerceAbTesting: MetaTags = {
    title: 'WordPress and WooCommerce A/B Split Testing - Pluginless',
    description:
        'Ditch the plugin and just use a simple A/B testing platform to create tests for your WordPress with WooCommerce store.',
};

export const improveProductPageConversionsMeta: MetaTags = {
    title: 'Product Page Conversion Rate Optimization Ideas for A/B Testing',
    description:
        'Discover simple changes you can make to your product pages to improve conversion rates, then test which changes perform best with A/B testing to increase sales.',
};

export const abTestingHeadlinesMeta: MetaTags = {
    title: 'A Comprehensive Guide to A/B Testing Headlines | CRO Simple',
    description:
        'Discover the benefits, key elements, and best practices of A/B testing headlines. Learn how our A/B testing platform can help optimize your headlines for maximum engagement and conversions.',
};

export const abTestingMultipleVariantsMeta: MetaTags = {
    title: 'A/B Testing Multiple Variants with Multivariate Testing | CRO Simple',
    description:
        'Discover how A/B testing multiple variants can unlock deeper insights, faster optimizations, and significant improvements in your digital strategies.',
};

export const abTestingToolFree: MetaTags = {
    title: 'Free A/B Testing Tool for Websites | Split and Multivariate Testing',
    description: `The CRO Simple A/B testing platform can be used for free to make simple A/B tests for websites. It's based on simplicity and ease of use to all.`,
};

export const improveFormConversionsMeta: MetaTags = {
    title: 'Improve Form Conversion Rates with A/B Testing | CRO Simple',
    description: `Discover simple changes you can make to your forms to increase conversion rates, then test which changes perform best with A/B testing to boost submissions and reduce abandonment.`,
};
