import BrowserImage from '../../../../../../../../components/default/ImageContainers/BrowserImage/BrowserImage';
import controlImage from '../../../../../../../support/Testing/Examples/ProductImageEffectiveness/control.png';
import variantOneImage from '../../../../../../../support/Testing/Examples/ProductImageEffectiveness/variant-one.png';
import styles from './styles.module.scss';

const ImageOneCaption = () => (
    <>
        Original - Conversion Rate:{' '}
        <span className={`${styles.conversionRate} ${styles.lower}`}>14%</span>
    </>
);

const ImageTwoCaption = () => (
    <>
        Variant - Conversion Rate:{' '}
        <span className={`${styles.conversionRate} ${styles.higher}`}>22%</span>
    </>
);

const Checkbox = () => (
    <div className={styles.checkmarkContainer}>
        <svg className={styles.checkmark} xmlns='http://www.w3.org/2000/svg' viewBox='0 0 52 52'>
            <circle className={styles.checkmarkCircle} cx='26' cy='26' r='25' fill='none' />
            <path className={styles.checkmarkCheck} fill='none' d='M14.1 27.2l7.1 7.2 16.7-16.8' />
        </svg>
    </div>
);

const Cross = () => (
    <div className={styles.crossContainerOuter}>
        <svg
            xmlns='http://www.w3.org/2000/svg'
            width='56'
            height='56'
            viewBox='0 0 48 48'
            className={styles.crossContainer}
        >
            <path
                fill='#f44336'
                d='M24,2C12.317,2,2,12.317,2,24s10.317,22,22,22s22-10.317,22-22S35.683,2,24,2z'
            ></path>
            <path
                fill='#fff'
                d='M29.657,15.515l2.828,2.828L18.344,32.484l-2.828-2.828L29.657,15.515z'
            ></path>
            <path
                fill='#fff'
                d='M32.485,29.656l-2.828,2.828L15.515,18.343l2.828-2.828L32.485,29.656z'
            ></path>
        </svg>
    </div>
);

export const ProductImageComparison = () => (
    <div className={styles.container}>
        <BrowserImage
            captionJsx={<ImageTwoCaption />}
            imageAlt='variant'
            imageSrc={variantOneImage}
            cls={styles.secondImageContainer}
            additionalJsx={<Checkbox />}
        />
        <BrowserImage
            captionJsx={<ImageOneCaption />}
            imageAlt='control'
            imageSrc={controlImage}
            cls={styles.firstImageContainer}
            additionalJsx={<Cross />}
        />
    </div>
);
