import React from 'react';
import styles from '../../styles.module.scss';
import defaultStyles from '../../../../../css/default.module.scss';
import { Section } from '../../../../../components/default/Areas/Areas';
import { withPage } from '../../../../../components/layout/Page/Page';
import { createFormTestMeta } from '../../../../misc/MetaTags';
// import InitialTestDetailsImage from './initial-test-details.png';
import Contents from '../../../../../components/layout/Contents/Contents';
import { FORM_TEST_CREATION_CONTENTS } from './variables';
import { Back } from '../../../../../components/default/Navigation/Navigation';
import { Link } from 'react-router-dom';
import { WarningWithChildren } from '../../../../../components/default/States/States';
import controlImage from './control.png';
import variantOneImage from './variant-one.png';
import variantTwoImage from './variant-two.png';
import BrowserImage from '../../../../../components/default/ImageContainers/BrowserImage/BrowserImage';
import { VideoModal } from '../../../../../components/default/VideoModal/VideoModal';

const FormTestCreation = () => (
    <>
        <Contents
            title='How to Create a Form Test to Increase Sign-ups'
            sections={FORM_TEST_CREATION_CONTENTS}
        />

        <VideoModal
            title='Video Tutorial'
            description='Watch our in-depth video explaining how to create a form A/B test.'
            videoEmbedUrl='https://www.youtube.com/embed/zPUccqZDn1U?si=smxgtyq13C5w8SvD'
        />

        <Section patterned={true}>
            <h2 id='overview'>Overview</h2>
            <p>
                In this test, we are going to test three different forms on a landing page to
                discover which one has the highest conversion rate - and by conversion rate, we will
                measure how many average times the form has been submitted. We will also measure
                other useful metrics, such as the average form completion time.
            </p>
            <div className={styles.variantsOverview}>
                <BrowserImage
                    caption='Control (original)'
                    imageAlt='control'
                    imageSrc={controlImage}
                />
                <BrowserImage caption='Variant A' imageAlt='variant a' imageSrc={variantOneImage} />
                <BrowserImage caption='Variant B' imageAlt='variant b' imageSrc={variantTwoImage} />
            </div>
            <p>
                Our test will identify which field options have the highest sign-up rate. Our
                control and variants will contain the following:
            </p>
            <p>
                <b>Control:</b> forename, email and gender fields;
                <br />
                <b>Variant A:</b> forename and email fields only; and
                <br />
                <b>Variant B:</b> email field only.
                <br />
            </p>
            <p>
                The goal: <b>increase sign-ups</b>. Without testing each form, we won't know which
                one converts the best and will lose out on lost sign-ups. It would of course be more
                beneficial to include more fields since we could potentially use this information
                for marketing purposes, but we don't know the impact of these extra fields on the
                sign-up rate.
            </p>
        </Section>

        <Section patterned={true}>
            <p className={styles.step}>Step 1</p>
            <h2 id='create-test-page'>Create a Page for Testing</h2>
            <p>
                Begin by choosing a webpage that you want to test. Just ensure it is exposed on the
                internet via a web URL.
            </p>
            <p>In this example, our test page will be https://www.example.com/free-ebook.</p>
        </Section>

        <Section patterned={true}>
            <p className={styles.step}>Step 2</p>
            <h2 id='initial-test-details'>Enter Initial Test Details</h2>
            <p>
                On our platform, create a new test and enter the initial test details: the test
                name, description, goal, website and page.
            </p>
        </Section>

        <Section patterned={true}>
            <p className={styles.step}>Step 3</p>
            <h2 id='tracking-approach'>Choose Tracking Approach</h2>
            <p>
                While this step is optional, in some cases we're only interested in tracking a
                specific element rather than generic metrics. Since we want to track the form
                sign-up rate, tracking the number of form submits of the specific form on the page
                would be more suitable than standard tracking. Therefore, in this scenario it would
                be recommended to add the{' '}
                <span className={defaultStyles.highlightedText}>data-ab='cro-simple'</span>{' '}
                attribute to the form to initiate custom tracking of that element.
            </p>
            <p>
                You can of course skip this step and standard tracking will be initiated, which will
                still include tracking average form submits but of all forms on the page rather than
                the specific form we want to test.
            </p>
            <p>
                For more information, read the{' '}
                <Link to='/support/test-creation/guide#tracking'>tracking guide</Link>.
            </p>
        </Section>

        <Section patterned={true}>
            <p className={styles.step}>Step 4</p>
            <h2 id='variant-approach'>Create Variants</h2>
            <p>
                For this test, we can create each variant by using a different page URL, where each
                page will display a different form.
            </p>
            <p>
                Firstly on your website, create a new page for each variant where each page shows a
                different form. Now all you need to do is create a redirect variant for each page
                URL you want to test. For example, you could use pages like these:
            </p>
            <ul>
                <li>Control: https://www.example.com/free-ebook - the original unchanged page</li>
                <li>
                    Variant 1: https://www.example.com/free-ebook-a - the same page but with a
                    different form
                </li>
                <li>
                    Variant 2: https://www.example.com/free-ebook-b - the same page but with another
                    different form
                </li>
            </ul>
            <p>You can create a redirect variant on the test creation form.</p>
            {/* <p>@todo: add the image here ________</p> */}
            <WarningWithChildren marginTop={true} marginBottom={true}>
                If choosing custom tracking, you need to add the{' '}
                <span className={defaultStyles.highlightedText}>data-ab='cro-simple'</span>{' '}
                attribute to the form on <em>every</em> redirect variant's page, otherwise there
                will be a tracking data mismatch.
            </WarningWithChildren>
        </Section>

        <Section patterned={true}>
            <p className={styles.step}>Step 5</p>
            <h2 id='traffic-allocation'>Set Traffic Allocation</h2>
            <p>
                Set the percentage of traffic that will see each variant. Setting up an equal
                distribution is a common practice.
            </p>
        </Section>

        <Section patterned={true}>
            <p className={styles.step}>Step 6</p>
            <h2 id='targeting'>Set Targeting Options</h2>
            <p>
                Set the target devices that the test will show on, along with the maximum visitor
                sessions.
            </p>
        </Section>

        <Section patterned={true}>
            <h2 id='completion'>That's Everything</h2>
            <p>
                You can start the test whenever you like, view the results upon completion and then
                implement the winner.
            </p>
            <p>
                The result: <b>more sign-ups over time</b>.
            </p>
        </Section>

        <Section patterned={true}>
            <h2 id='ideas'>Form Testing Ideas</h2>
            <p>
                Need some ideas of what you could test on a form to increase conversions? Head over
                to our detailed{' '}
                <Link to='/blog/improve-form-conversions-with-ab-testing'>
                    form conversion testing ideas
                </Link>{' '}
                guide.
            </p>
            <Back to='/support' text='Support Hub' block={true} />
        </Section>
    </>
);

export default withPage(FormTestCreation)({
    meta: createFormTestMeta,
    fullWidth: true,
});
