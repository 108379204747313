import React, { useState } from 'react';
import styles from './styles.module.scss';
import { HeroArea, Section } from '../../../../../../components/default/Areas/Areas';
import GetStarted from '../../../../../../components/default/GetStarted/GetStarted';
import BenefitBox from '../../../../../../components/default/Informational/BenefitBox/BenefitBox';
import { VideoModal } from '../../../../../../components/default/VideoModal/VideoModal';
import { withPage } from '../../../../../../components/layout/Page/Page';
import useQuoraCustomTrackingEvent from '../../../../../../hooks/tracking/useQuoraCustomTrackingEvent/useQuoraCustomTrackingEvent';
import { paidAdAdvertisersLandingPage } from '../../../../../misc/MetaTags';
import AnimatedAbTest from '../../../../../other/Homepage/AnimatedAbTest/AnimatedAbTest';
import ProcessSteps from '../../../../../../components/default/ProcessSteps/ProcessSteps';
import { PROCESS_STEPS } from './variables';
import ListBoxes from '../../../../../../components/default/ListBoxes/ListBoxes';
import { HOMEPAGE_LIST_BOXES } from '../../../../../other/Homepage/variables';
import { FreeGuide } from '../../../../../../components/default/FreeGuide/FreeGuide';

/*
    HOW TO CHANGE THE H1 TEXT VIA CSS ONLY (FOR A/B TESTING MYSELF...)

<style>
        h1 {
            color: transparent !important;
            font-size: 0 !important;
        }
        h1::before {
            content: 'Run Paid Ads? Slash Your CPA with Simple A/B Tests';
            color: #043263;
            font-size: 5rem !important;
        }
        @media (max-width: 600px) {
            h1::before {
                font-size: 3rem !important;
            }
        }
    </style>
*/

const Description = () => (
    <>
        <p>
            If you don't, your CPA will be higher, conversion rates will be lower, target audiences
            can't be as scalable and your competition may be better able to outbid you.
        </p>
        <p>
            Try the <u>forever free</u> version and ditch it if it fails! Or do nothing and accept
            average conversions at best...
        </p>
    </>
);

const PaidAdAdvertisersLandingPageOne = () => {
    const [videoModalOpen, setVideoModalOpen] = useState<boolean>(false);

    useQuoraCustomTrackingEvent('GenerateLead');

    return (
        <>
            <HeroArea
                title='Run Paid Ads? Supercharge Conversions with Simple A/B Tests'
                // description="If you don't, your CPA will be higher, conversion rates will be lower, target audiences can't be as scalable and your competition may be better able to outbid you."
                descriptionJsx={<Description />}
                buttonText='Try Free Version'
                buttonLink='/pricing'
                buttonTwoText='Read More'
                buttonTwoLink='#what-is-ab-testing'
                leftText={true}
                // additionalContent={<AnimatedAbTest cls={styles.animation} />}
                additionalContent={<AnimatedAbTest />}
                extraImagePadding={true}
                clsOuter={styles.heroOuter}
                clsInner={styles.heroInner}
            />

            <FreeGuide inverse={true} />

            <Section id='what-is-ab-testing' patterned={true}>
                <h2>What is A/B Testing?</h2>
                <p>
                    A/B testing is just showing different elements to users to see what converts
                    best. Examples would be:
                </p>
                <p>
                    <ul>
                        <li>Showing different form fields to test user sign-ups</li>
                        <li>Showing different product images to test sales</li>
                        <li>Displaying different layouts or styles to see what converts best</li>
                        <li>Displaying different text content for optimum conversions</li>
                    </ul>
                </p>
                <p>
                    Traffic is split between each variant and results tracked. There are ultimately
                    limitless aspects of a page that can be tested.
                </p>
            </Section>

            <Section patterned={true}>
                <h2>What You Should Do</h2>
                <p>Improving your conversion rate for paid ads with A/B testing is simple:</p>
                <ProcessSteps steps={PROCESS_STEPS} cls={styles.processSteps} />
                <p>
                    No complex setup, no agencies and no convolutions. Take full control by yourself
                    - do whatever you want.
                </p>
            </Section>

            <Section patterned={true} clsInner={styles.benefitsContainer}>
                <h2>Benefits of A/B Testing with Paid Advertising</h2>
                <div className={styles.benefits}>
                    <BenefitBox
                        title='Improve Conversion Rates'
                        description='Testing can increase the likelihood that visitors will complete desired actions, such as making a purchase or signing up for a newsletter.'
                    />
                    <BenefitBox
                        title='Optimise Ad Spend'
                        description='Higher conversions for the same amount of ad spend mean improved CPA and ROAS, thus making campaigns more cost-effective.'
                    />
                    <BenefitBox
                        title='Low Effort, Big Results'
                        description='Even simple tests can yield big results, often 3-5x ROI, which are very easy to implement.'
                    />
                    <BenefitBox
                        title='Scalability'
                        description='Enhanced efficiency allows you to target broader audiences because the improved ROAS can accommodate more users.'
                    />
                    <BenefitBox
                        title='Beat Competitors'
                        description="Improved ad performance means more auctions won, thus reducing your competitors' abilities to acquire customers."
                    />
                    <BenefitBox
                        title='SEO'
                        description='By improving elements like bounce rates, page loading speeds, and other key metrics, you can improve search engine rankings.'
                    />
                    <BenefitBox
                        title='Better Understand Marketing Channels'
                        description='Understand what works best in each channel, thus enable more targeted and effective campaigns.'
                    />
                    <BenefitBox
                        title='Understand Audience Preferences'
                        description='Uncover valuable insights into what your target audience responds to best to improve success rates for future campaigns.'
                    />
                </div>
            </Section>

            <Section patterned={true}>
                <h2>What You Should Test</h2>
                <p>
                    There are many significant parts of a website that should be optimised. Here are
                    a few examples:
                </p>
                <ListBoxes items={HOMEPAGE_LIST_BOXES} design={2} />
                <p>
                    If you don't optimise your website, you're <u>leaving money on the table</u>.
                </p>
            </Section>

            <VideoModal
                customOpenState={{ isCustomOpen: videoModalOpen, setCustomOpen: setVideoModalOpen }}
            />

            <GetStarted />
        </>
    );
};

export default withPage(PaidAdAdvertisersLandingPageOne)({
    meta: paidAdAdvertisersLandingPage,
    fullWidth: true,
    clsPage: styles['paid-advertisers-landing-page-one'],
    hasFooterLinks: false,
    noIndex: true,
    lightBackground: true,
});
