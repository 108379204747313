import { useMemo } from 'react';
import { useAffiliateSessionsQuery } from '../../../graphql/generated/schema';
import { formatDate, formatTime } from '../../../utils/HelperFunctions/HelperFunctions';
import { RefinedSession, UseAffiliateSessions } from './types';

export const useAffiliateSessions: UseAffiliateSessions = (options) => {
    const [limit, skip] = [options?.limit || 0, options?.skip || 0];
    const [{ data, fetching: loading, error }] = useAffiliateSessionsQuery({
        variables: {
            limit,
            skip,
        },
    });
    const refinedSessions = useMemo<RefinedSession[] | undefined>(
        () =>
            data?.affiliateSessions?.sessions?.map(({ dateTime, id }) => {
                const numberedDate = Number(dateTime);
                const formattedDateCreated = formatDate(numberedDate);
                const formattedTime = formatTime(numberedDate);
                return { date: formattedDateCreated, time: formattedTime, id };
            }),
        [data]
    );

    return {
        sessionCount: data?.affiliateSessions?.total || 0,
        sessions: refinedSessions,
        loading,
        error,
    };
};
