import { useState } from 'react';
import { useCreateUserFeedbackMutation } from '../../../graphql/generated/schema';
import { CreateFeedbackFn, UseImprovementFeedbackForm } from './types';

export const useImprovementFeedbackForm: UseImprovementFeedbackForm = () => {
    const [error, setError] = useState<string>('');
    const [success, setSuccess] = useState<boolean>(false);
    const [{ fetching: loading }, makeFeedback] = useCreateUserFeedbackMutation();

    const createFeedback: CreateFeedbackFn = async ({ comment, rating }) => {
        try {
            const { data, error } = await makeFeedback({
                input: { comment, rating },
            });

            if (error || !data) {
                const errorText = error?.message || 'Could not create feedback';
                setSuccess(false);
                setError(`HTTP error: ${errorText}`);
                return;
            }

            setError('');
            setSuccess(true);
        } catch (err: any) {
            setSuccess(false);
            setError(err.message);
        }
    };

    return { loading, error, createFeedback, success };
};
