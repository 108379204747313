import { useCallback, useEffect } from 'react';
import { useAffiliateSessionMutation } from '../../../graphql/generated/schema';
import { deleteCookies, getCookie } from '../../../utils/HelperFunctions/HelperFunctions';

// sets affiliate cookies
export const useAffiliates: () => void = () => {
    const [, createAffiliateSession] = useAffiliateSessionMutation();

    const createSession = useCallback(
        async (cookie: string): Promise<void> => {
            const { error } = await createAffiliateSession({
                referrerId: cookie,
            });

            if (error) {
                console.error(error); // don't need try-catch as not doing anything after any errors
                return;
            }

            console.log('CRO Simple: affiliate referral session created');
        },
        [createAffiliateSession]
    );

    useEffect(() => {
        const urlParams: URLSearchParams = new URLSearchParams(window.location.search);
        let referrerCookie: string | null = getCookie('referrer');
        const referrerParam: string | null = urlParams.get('referrer');

        if (referrerParam && !referrerCookie) {
            // set referrer cookie
            const expiryDate: Date = new Date(new Date().setFullYear(new Date().getFullYear() + 1)); // 1 year from now
            document.cookie = `referrer=${referrerParam}; expires=${expiryDate.toUTCString()};`;
            referrerCookie = getCookie('referrer');
        }

        // if referrer cookie already exists
        if (referrerCookie) {
            const accountReferrerId: string | null = getCookie('accountId');

            if (referrerCookie === accountReferrerId) {
                // so they can't refer themselves
                deleteCookies(['referrer']);
                return;
            }

            createSession(referrerCookie);
            return; // to only credit initial referrer
        }
    }, [createSession]);
};
