import React from 'react';
import { Link } from 'react-router-dom';
import { withPage } from '../../components/layout/Page/Page';
import { blogMeta } from '../misc/MetaTags';
import { HeroArea } from '../../components/default/Areas/Areas';
import { articles } from './articles';
import { ArticlesProps } from './types';
import blogStyles from './styles.module.scss';
import defaultStyles from '../../css/default.module.scss';

const Blog = () => (
    <>
        <HeroArea
            title='CRO Simple Blog'
            description='Expert tips, guides, and advice on A/B testing. Discover how to optimize your website with how-to articles and more in conversion rate optimization.'
            tall={false}
        />
        <Articles articles={articles} />
    </>
);

const Articles = ({ articles }: ArticlesProps): JSX.Element => (
    <div className={blogStyles.articlesWrap}>
        {articles.map((article) => (
            <article
                key={article.title}
                className={`${defaultStyles.infoContainer} ${blogStyles.article}`}
            >
                <img src={article.image.src} alt={article.image.alt} className={blogStyles.image} />
                <div className={blogStyles.contentWrap}>
                    <h2>{article.title}</h2>
                    <p>{article.description}</p>
                    <Link to={article.link} className={defaultStyles.btnPrimary}>
                        Read
                    </Link>
                </div>
            </article>
        ))}
    </div>
);

export default withPage(Blog)({ meta: blogMeta });
