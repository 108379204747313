import React from 'react';
import { Link } from 'react-router-dom';
import { HeroArea, Section } from '../../../components/default/Areas/Areas';
import { partnershipsMeta } from '../../misc/MetaTags';
import defaultStyles from '../../../css/default.module.scss';
import styles from './styles.module.scss';
import { withPage } from '../../../components/layout/Page/Page';
import { faCheck, faHandshake } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { VideoModal } from '../../../components/default/VideoModal/VideoModal';

const Partnerships = () => (
    <>
        <HeroArea
            title='Official Partnerships'
            description='Do you offer CRO or A/B testing services? Implement them using our platform for your clients and receive recurring 25% affiliate commissions.'
            buttonText='Learn More'
            buttonLink='#learn-more'
            leftText={true}
            additionalContent={
                <FontAwesomeIcon
                    icon={faHandshake}
                    aria-label='handshake'
                    className={styles.handshakeIcon}
                />
            }
            clsOuter={styles.heroOuter}
            clsInner={styles.heroInner}
            clsBtn={defaultStyles.btnDark}
            extraBottomSpace={true}
        />

        <Section id='learn-more' patterned={true}>
            <h2>Partner with us</h2>
            <p>
                If you offer CRO or A/B testing services, why not consider partnering with us? It
                will be mutually and significantly beneficial for both of us and your clients. You
                could introduce our platform to your clients and receive recurring lifetime
                commissions for their use of our product - even if it is just a mention.
            </p>
            <p>
                Why create the A/B test solutions with free software and not receive continuous
                commissions?
            </p>
        </Section>

        <Section patterned={true} clsOuter={styles.benefitsWrap}>
            <h2>Benefits</h2>

            <h3>
                <FontAwesomeIcon icon={faCheck} aria-label='tick' className={styles.tickIcon} />
                Continuous Commissions
            </h3>
            <p>
                Recurring ongoing commissions: earn 25% commissions on every payment made by clients
                you refer
            </p>

            <h3>
                <FontAwesomeIcon icon={faCheck} aria-label='tick' className={styles.tickIcon} />{' '}
                Faster and Easier Implementation
            </h3>
            <p>
                Make implementing A/B tests easier and faster for you when making them for your
                clients. Our product only requires installing one script tag once, and then either
                adding code (CSS/JS) or comparing against another page URL when creating a A/B
                tests.
            </p>

            <h3>
                <FontAwesomeIcon icon={faCheck} aria-label='tick' className={styles.tickIcon} />{' '}
                They can Upgrade Later if Undecided
            </h3>
            <p>
                We don't know what A/B testing tools you currently use, but why implement something
                free for your clients when you could receive continuous automated commissions by
                using our product instead? There's still a free version if they are undecided about
                paying and they can upgrade to a paid version later if they decide to.
            </p>

            <h3>
                <FontAwesomeIcon icon={faCheck} aria-label='tick' className={styles.tickIcon} />{' '}
                Upsell Opportunity for Little Effort
            </h3>
            <p>
                If you do not want to use our app, you could just suggest our product as an upsell.
                It may suit your some of your clients' needs, which they may value.
            </p>
        </Section>

        <Section patterned={true}>
            <h2>How we can help</h2>
            <p>
                We can create a custom page that recognises you as an official partner and with a
                backlink to your website, affiliate session cookie (so whenever anyone visits the
                URL and creates an account, you will be credited as the affiliate) and an account
                creation link.
            </p>
        </Section>

        <Section patterned={true}>
            <h2>Our Suggestions</h2>
            <p>Either:</p>
            <ol>
                <li>
                    Create an account for your client using the link on your official partner page
                    we create for you (i.e. so you are affiliated) and provide them with the login
                    credentials - or ask them to visit your affiliate link and create an account
                    themselves
                </li>
                <li>
                    Install the tracking script on their website (it's just a script that goes in
                    the head tag - a very easy and quick implementation)
                </li>
                <li>Create tests for them using our software</li>
            </ol>
            <p>
                Or if you prefer to use other tools, you could consider just recommending our tool
                using an affiliate link so that if they ever pay then you'll receive continuous
                commissions - you could suggest that they could just give it a try.
            </p>
        </Section>

        <VideoModal
            title='How our product works'
            description="Watch the 99 second video of how easy it is to install the tracking script and create an A/B test. Your clients should be able to use our software and create A/B tests on their own after you finish implementing their tests for them, meaning they may continually use our product that you'll receive continuous commissions for."
        />

        <Section patterned={true}>
            <h2>How to get started</h2>
            <p>
                To get started, just register as an affiliate to receive an affiliate link. You can
                also <Link to='/contact'>contact us</Link> if you have any questions.
            </p>
            <Link to='/affiliates' className={defaultStyles.btnPrimary}>
                See Affiliate Program
            </Link>
        </Section>
    </>
);

export default withPage(Partnerships)({
    meta: partnershipsMeta,
    fullWidth: true,
});
