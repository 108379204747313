import { useMemo } from 'react';
import { faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { UseGetWebsites, RefinedWebsite } from './types';
import { useWebsitesQuery } from '../../../graphql/generated/schema';

// keep as .tsx as it needs the icon (in JSX)
export const useGetWebsites: UseGetWebsites = (options) => {
    const { limit = 10, skip = 0 } = options || {};
    const [{ data, fetching: loading, error }, refetchWebsites] = useWebsitesQuery({
        variables: {
            limit,
            skip,
        },
    });
    const refinedWebsites = useMemo<RefinedWebsite[] | undefined>(
        () =>
            data?.websites?.websites.map(({ id, name, url }) => ({
                id,
                name,
                url,
                icon: <FontAwesomeIcon icon={faPen} aria-label='edit' />,
            })),
        [data]
    );

    return {
        websiteCount: data?.websites?.total || 0,
        websites: refinedWebsites,
        loading,
        error,
        refetchWebsites,
    };
};
