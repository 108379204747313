import { DocumentNode } from 'graphql';
import { useCallback, useEffect, useState } from 'react';
import { useClient } from 'urql';
import {
    AffiliatePerformanceMetricsDetailedDocument,
    AffiliatePerformanceMetricsFree,
    AffiliatePerformanceMetricsPro,
    AffiliatePerformanceMetricsTotals,
    AffiliatePerformanceMetricTotalsDocument,
} from '../../../graphql/generated/schema';
import {
    UseGetReferralMetrics,
    UseReferralMetricsDetailed,
    UseReferralMetricTotals,
} from './types';

export const useReferralMetricTotals: UseReferralMetricTotals = () => {
    const [metricTotals, setMetricTotals] = useState<AffiliatePerformanceMetricsTotals | null>(
        null
    );
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const getMetrics = useGetReferralMetrics();

    const handleMetrics = useCallback(async () => {
        try {
            setLoading(true);
            const getMetricsRes = await getMetrics(AffiliatePerformanceMetricTotalsDocument);
            const metricsData = getMetricsRes?.data?.affiliatePerformanceMetrics?.totals;
            if (!metricsData) throw new Error('Could not get affiliate performance metric totals');
            setMetricTotals(metricsData);
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, [getMetrics]);

    useEffect(() => {
        handleMetrics();
    }, [handleMetrics]);

    return { metricTotals, loading, error };
};

export const useReferralMetricsDetailed: UseReferralMetricsDetailed = () => {
    const [freeMetrics, setFreeMetrics] = useState<AffiliatePerformanceMetricsFree | null>(null);
    const [payingMetrics, setPayingMetrics] = useState<AffiliatePerformanceMetricsPro | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string>('');
    const [hasData, setHasData] = useState<boolean>(false);
    const getMetrics = useGetReferralMetrics();

    const handleMetrics = useCallback(async () => {
        try {
            setLoading(true);
            const getMetricsRes = await getMetrics(AffiliatePerformanceMetricsDetailedDocument);
            const { free, paying } = getMetricsRes?.data?.affiliatePerformanceMetrics;
            const { __typename, ...freeData } = free;
            const { __typename: __typenamePro, ...payingData } = paying;
            if (!freeData || !payingData)
                throw new Error('Could not get detailed affiliate performance metrics');
            setFreeMetrics(freeData);
            setPayingMetrics(payingData);
            const allDataCombined = { ...freeData, ...payingData };
            setHasData(!Object.values(allDataCombined).every((value) => value === 0));
        } catch (error: any) {
            setError(error.message);
        } finally {
            setLoading(false);
        }
    }, [getMetrics]);

    useEffect(() => {
        handleMetrics();
    }, [handleMetrics]);

    return { hasData, freeMetrics, payingMetrics, loading, error };
};

const useGetReferralMetrics: UseGetReferralMetrics = () => {
    const client = useClient();
    return useCallback(
        //@ts-ignore
        (query: DocumentNode) => client.query(query).toPromise(),
        [client]
    );
};
