import {
    faVial,
    faTrophy,
    faRedo,
    faClock,
    faSearchDollar,
} from '@fortawesome/free-solid-svg-icons';
import styles from './styles.module.scss';

export const PROCESS_STEPS = [
    {
        icon: { icon: faVial },
        title: (
            <>
                <span className={styles.primaryColour}>Create</span> an A/B Test on a Product Page
            </>
        ),
        description: 'Enter a page URL or code',
        ariaLabel: 'test vial',
    },
    {
        icon: { icon: faClock },
        title: (
            <>
                <span className={styles.primaryColour}>Wait</span> for the Results
            </>
        ),
        ariaLabel: 'clock',
    },
    {
        icon: { icon: faSearchDollar },
        title: (
            <>
                <span className={styles.primaryColour}>Compare</span> Purchases
            </>
        ),
        description: 'Original vs variant',
        ariaLabel: 'compare',
    },
    {
        icon: { icon: faTrophy },
        title: (
            <>
                <span className={styles.primaryColour}>Implement</span> the highest converting
                variant
            </>
        ),
        ariaLabel: 'test winner',
    },
    {
        icon: { icon: faRedo },
        title: <span className={styles.primaryColour}>Repeat</span>,
        ariaLabel: 'test repeat',
    },
];
