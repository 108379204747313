import React, { createContext, FC, useState } from 'react';
import { AccountContextType, AccountProviderProps, WebsiteWithId } from './types';
import { SortedWebsites } from '../../hooks/pages/useSelectWebsites/types';

export const AccountContext = createContext<AccountContextType>({
    selectedWebsite: undefined,
    setSelectedWebsite: () => {},
    websites: undefined,
    setWebsites: () => {},
});

export const AccountProvider: FC<AccountProviderProps> = ({ children }) => {
    const [selectedWebsite, setSelectedWebsite] = useState<WebsiteWithId | undefined>(undefined);
    const [websites, setWebsites] = useState<SortedWebsites>(undefined);

    return (
        <AccountContext.Provider
            value={{
                selectedWebsite,
                setSelectedWebsite,
                websites,
                setWebsites,
            }}
        >
            {children}
        </AccountContext.Provider>
    );
};
