import { convertToCurrency, formatDate } from '../../../utils/HelperFunctions/HelperFunctions';
import {
    UnrefinedAffiliateTransactions,
    RefinedAffiliateTransactions,
} from '../useAffiliateTransactions/types';
import { RefinedAffiliatePayments } from './types';

export const refineAffiliateTransactions = (
    transactions: UnrefinedAffiliateTransactions | undefined
): RefinedAffiliateTransactions | undefined => {
    if (!transactions) return;
    return transactions.map(
        ({
            __typename,
            id,
            dateTime,
            amount,
            balance,
            ...transactionDetails
        }: UnrefinedAffiliateTransactions) => {
            const formattedDate: string = formatDate(Number(dateTime));
            const qualified: string = transactionDetails?.qualified?.toString() ?? '';
            const refinedAmount: string = convertToCurrency(amount) as string;
            const refinedBalance: string | undefined = convertToCurrency(balance);
            return {
                id,
                date: formattedDate,
                refinedAmount,
                ...transactionDetails,
                qualified,
                ...(refinedBalance && { refinedBalance }),
            };
        }
    );
};

// do not merge with the refineAffiliateTransactions function above, otherwise uses extra bandwidth; keep separate
export const refineAffiliatePayments = (
    transactions: UnrefinedAffiliateTransactions | undefined
): RefinedAffiliatePayments | undefined => {
    if (!transactions) return;
    return transactions.map(({ id, dateTime: date, amount }: UnrefinedAffiliateTransactions) => {
        const formattedDate: string = formatDate(Number(date));
        const refinedAmount: string = convertToCurrency(amount) as string;
        return { id, date: formattedDate, refinedAmount };
    });
};
