import React, { memo, useEffect, useState } from 'react';
import styles from './styles.module.scss';
import defaultStyles from '../../../../../css/default.module.scss';
import { useDeletePageMutation } from '../../../../../graphql/generated/schema';
import { DeletePageProps } from './types';
import { Loading, Error, Success } from '../../../../default/States/States';

const DeletePage = memo(
    ({ selectedRow, closeModal, refetchPages }: DeletePageProps): JSX.Element => {
        const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
        const [{ data, fetching: loading, error }, deletePageFn] = useDeletePageMutation();

        const deletePage = async () => {
            await deletePageFn({
                id: selectedRow?.id as string,
            });
            setShowConfirmation(false);
        };

        useEffect(() => {
            if (data?.deletePage?.success) {
                const timeout = setTimeout(() => {
                    closeModal();
                }, 3000);

                refetchPages();

                return () => {
                    clearTimeout(timeout);
                };
            }
        }, [data, closeModal, refetchPages]);

        return (
            <div className={styles.container}>
                <button onClick={() => setShowConfirmation(true)} className={styles.deletePage}>
                    Delete Page
                </button>
                {showConfirmation && (
                    <div className={styles.confirmation}>
                        <h3>Are you sure you want to delete this page?</h3>
                        {loading ? (
                            <Loading centralised={true} />
                        ) : (
                            <div
                                className={`${defaultStyles.buttonsContainer} ${defaultStyles.spaced}`}
                            >
                                <button
                                    type='submit'
                                    className={defaultStyles.btnRed}
                                    onClick={deletePage}
                                >
                                    Yes
                                </button>
                                <button
                                    onClick={() => setShowConfirmation(false)}
                                    className={defaultStyles.btnGrey}
                                >
                                    No
                                </button>
                            </div>
                        )}
                    </div>
                )}
                {error && <Error msg={error.message} marginTop={true} />}
                {data?.deletePage?.success && (
                    <Success msg='Page deleted successfully' marginTop={true} />
                )}
            </div>
        );
    }
);

export default DeletePage;
