import React, { useCallback } from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../../../../css/default.module.scss';
import EditPageForm from '../EditPageForm/EditPageForm';
import { EditPageModalProps } from './types';
import Modal from 'react-modal';
import DeletePage from './DeletePage/DeletePage';

const EditPageModal = ({
    selectedRow,
    modalIsOpen,
    setModalIsOpen,
    refetchPages,
}: EditPageModalProps): JSX.Element | null => {
    const closeModal = useCallback(() => {
        setModalIsOpen(false);
    }, [setModalIsOpen]);

    if (!selectedRow) return null;

    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className={styles.modal}
                contentLabel='Edit Page'
                portalClassName={styles.modalOverlayWrap} // cannot use overlayClassName
            >
                <button onClick={closeModal} className={styles.close} aria-label='close'>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <div>
                    <h2>Edit Page</h2>
                    <EditPageForm
                        selectedRow={selectedRow}
                        refetchPages={refetchPages}
                        setModalIsOpen={setModalIsOpen}
                    />
                    <DeletePage
                        selectedRow={selectedRow}
                        closeModal={closeModal}
                        refetchPages={refetchPages}
                    />
                </div>
            </Modal>
        </>
    );
};

export default EditPageModal;
