import React, { FC } from 'react';
import {
    FormProvider,
    SubmitHandler,
    useForm,
    useFormContext,
    useFormState,
} from 'react-hook-form';
import styles from '../../../css/default.module.scss';
import {
    InputFieldProps,
    SelectFieldProps,
    FieldContainerProps,
    BelowFormLinksProps,
    InputFieldWithoutLabelProps,
    CheckboxFieldsProps,
    RadioFieldProps,
    RadioOptionsProps,
    SelectFieldWithOptionsProps,
    DateFieldProps,
    CodeFieldProps,
} from './types';
import { extractNestedParts } from '../../../utils/HelperFunctions/HelperFunctions';
import { TAGS } from './variables';
import { ErrorText, Loading, Success, Error } from '../States/States';
import { yupResolver } from '@hookform/resolvers/yup';
import { exampleSchema } from '../../../schemas/ExampleSchema';
import CodeEditor from '../CodeEditor/CodeEditor';

// note: title is case sensitive, so may not register properly if capitalisation is not congruent with Yup schema fields
// onChange // do NOT re-add this as it can cause problems, e.g. when pressing enter to submit

export const InputFieldWithoutLabel = ({
    title,
    type,
    placeholder,
    disabled = false,
    value,
    customId,
    onPaste,
}: InputFieldWithoutLabelProps): JSX.Element => {
    const { register } = useFormContext();
    const Tag: any = TAGS[type];

    return (
        <Tag
            type={type}
            id={customId || title}
            {...register(title)}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onPaste={onPaste}
        />
    );
};

export const InputField = ({
    title,
    type,
    cls,
    placeholder,
    disabled = false,
    alias,
    caption,
    value,
    hasColon = true,
    onPaste,
}: InputFieldProps): JSX.Element => (
    <FieldContainer title={title} cls={cls} alias={alias} caption={caption} hasColon={hasColon}>
        <InputFieldWithoutLabel
            title={title}
            type={type}
            placeholder={placeholder}
            disabled={disabled}
            value={value}
            onPaste={onPaste}
        />
    </FieldContainer>
);

export const DateField = ({ title, cls, alias, hasColon = true }: DateFieldProps): JSX.Element => {
    const { register } = useFormContext();

    return (
        <FieldContainer title={title} cls={cls} alias={alias} hasColon={hasColon}>
            <input type='date' id={title} {...register(title, { required: true })} />
        </FieldContainer>
    );
};

export const SelectField: FC<SelectFieldProps> = ({
    title,
    cls,
    disabled = false,
    children,
    alias,
    hasColon = true,
}) => {
    const { register } = useFormContext();

    return (
        <FieldContainer title={title} cls={cls} alias={alias} hasColon={hasColon}>
            <select id={title} {...register(title)} disabled={disabled}>
                {children}
            </select>
        </FieldContainer>
    );
};

export const SelectFieldWithOptions = ({
    title,
    cls,
    disabled = false,
    alias,
    options,
    hasColon = true,
}: SelectFieldWithOptionsProps): JSX.Element => (
    <SelectField title={title} cls={cls} disabled={disabled} alias={alias} hasColon={hasColon}>
        <option value=''>-- Select --</option>
        {options.map(({ value, name }) => (
            <option key={value} value={value}>
                {name}
            </option>
        ))}
    </SelectField>
);

export const RadioField = ({ value, title, group, cls, alias }: RadioFieldProps): JSX.Element => {
    const { register } = useFormContext();

    return (
        <FieldContainer title={title} cls={cls} alias={alias} hasColon={false}>
            <input type='radio' id={title} value={value} {...register(group)} />
        </FieldContainer>
    );
};

export const RadioOptions = ({ options, label, group }: RadioOptionsProps): JSX.Element => {
    const { errors } = useFormState();
    const errorMsg = errors?.[group]?.message;

    return (
        <div className={styles.radioGroup}>
            <label className={styles.main}>{label}</label>
            {options.map(({ value, title }) => (
                <RadioField
                    key={title}
                    title={title}
                    value={value}
                    group={group}
                    cls={`${styles.radioField} ${styles.specificity}`}
                />
            ))}
            {errorMsg && (
                <span className={`${styles.required} ${styles.block}`}>{errorMsg as string}</span>
            )}
        </div>
    );
};

export const CheckboxFields = ({
    title,
    values,
    cls = '',
    alias,
    caption,
}: CheckboxFieldsProps): JSX.Element | null => {
    const { errors } = useFormState();
    const errorMsg = errors?.[title]?.message;

    if (!values) return null;

    return (
        <div className={`${styles.inputContainer} ${styles.checkboxContainer} ${cls}`}>
            <h3 className={styles.title}>{alias || title}</h3>
            {values.map((value, i) => (
                <label key={value}>
                    <InputFieldWithoutLabel
                        type='checkbox'
                        title={title}
                        value={value}
                        customId={`${title}[${i}]`}
                    />{' '}
                    <span className={styles.option}>{value}</span>
                </label>
            ))}
            {caption && <span className={styles.caption}>{caption}</span>}
            {errorMsg && <ErrorText msg={errorMsg as string} />}
        </div>
    );
};

const FieldContainer = ({
    title,
    cls = '',
    children,
    alias,
    caption,
    hasColon = true,
}: FieldContainerProps): JSX.Element => {
    const { errors } = useFormState();
    const errorMsg = (() => {
        if (title.includes('].')) {
            // e.g. if title is 'variants[0].description'
            const [prefix, index, postfix] = extractNestedParts(title) as [string, number, string];
            // @ts-ignore @todo
            return errors?.[prefix]?.[index]?.[postfix]?.message;
        }

        return errors?.[title]?.message;
    })();

    return (
        <div className={`${styles.inputContainer} ${cls}`}>
            <label htmlFor={title}>
                {alias ?? title}
                {hasColon && ':'}
            </label>
            {children}
            {caption && <span className={styles.caption}>{caption}</span>}
            {errorMsg && <span className={styles.required}>{errorMsg}</span>}
        </div>
    );
};

export const BelowFormLinks: FC<BelowFormLinksProps> = ({ children }) => (
    <p className={styles.belowFormLink}>{children}</p>
);

type FormInputs = any;
// wrap form in 'memo'?
// note: if it doesn't submit, check that the schema matches the form fields...
export const FormTemplate = () => {
    // const { loading, error, submitForm, success } = useSubmitForm();
    const loading = false; // temp
    const error = false; // temp
    const success = false; // temp
    // const submitForm = async (args: any) => {
    //     console.log('submit');
    // }; // temp - replace submitForm with useSubmitForm if necessary
    const handleSubmitFn: SubmitHandler<FormInputs> = (formData) => {
        console.log('handle submit', formData);
        // await submitForm(formData);
    };
    const methods = useForm<FormInputs>({
        resolver: yupResolver(exampleSchema),
        mode: 'onTouched',
        defaultValues: {
            name: '',
        },
    });

    return (
        <>
            <FormProvider {...methods}>
                <form onSubmit={methods.handleSubmit(handleSubmitFn)}>
                    <div className={styles.formInner}>
                        <InputField type='text' title='name' placeholder='e.g. Homepage' />
                    </div>
                    {loading ? (
                        <Loading />
                    ) : (
                        <div className={`${styles.buttonsContainer} ${styles.spaced}`}>
                            <button type='submit' className={styles.btnPrimary}>
                                Submit
                            </button>
                            <button
                                onClick={() => {
                                    console.log('cancel');
                                }}
                                // onClick={() => setModalIsOpen(false)}
                                className={styles.btnDark}
                            >
                                Cancel
                            </button>
                        </div>
                    )}
                </form>
            </FormProvider>
            {error && <Error msg={error as string} marginTop={true} />}
            {success && <Success msg='Form submitted successfully' marginTop={true} />}
        </>
    );
};

export const CodeField = ({
    title,
    alias,
    hasColon = true,
    language,
    onChange,
    cls = '',
    defaultValue,
}: CodeFieldProps): JSX.Element => (
    <FieldContainer title={title} cls={cls} alias={alias} hasColon={hasColon}>
        <CodeEditor
            defaultValue={defaultValue}
            language={language}
            onChange={onChange}
            cls={styles.inputContainer}
        />
    </FieldContainer>
);
