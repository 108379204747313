import React, { useCallback, useMemo, useState } from 'react';
import { userWebsitesMeta } from '../../../misc/MetaTags';
import { withPage } from '../../../../components/layout/Page/Page';
import { Loading, Error, NoneFound, Info } from '../../../../components/default/States/States';
import { DynamicTable } from '../../../../components/default/Table/Table';
import { Paginator } from '../../../../components/default/Paginator/Paginator';
import { useParams, Params } from 'react-router-dom';
import { DEFAULT_ITEMS_PER_PAGE } from '../../../../utils/global-variables';
import EditWebsiteModal from '../../../../components/account/Websites/EditWebsiteModal/EditWebsiteModal';
import { RefinedWebsite } from '../../../../hooks/websites/useGetWebsites/types';
import { useGetWebsites } from '../../../../hooks/websites/useGetWebsites/useGetWebsites';
import defaultStyles from '../../../../css/default.module.scss';
import styles from './styles.module.scss';
import CreateWebsiteModal from '../../../../components/account/Websites/CreateWebsiteModal/CreateWebsiteModal';
import { Back } from '../../../../components/default/Navigation/Navigation';

const Websites = () => {
    const itemsPerPage: number = DEFAULT_ITEMS_PER_PAGE;
    const params = useParams<Params<string>>();
    const currentPage: number = Number(params.currentPage) || 1;
    const skip: number = (currentPage - 1) * itemsPerPage;
    const headers: string[] = useMemo<string[]>(() => ['name', 'url', ''], []);
    const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
    const [selectedRow, setSelectedRow] = useState<RefinedWebsite | null>(null);
    const { websiteCount, websites, loading, error, refetchWebsites } = useGetWebsites({
        limit: itemsPerPage,
        skip,
    });
    const onClick = useCallback<(newRow: RefinedWebsite) => void>((newRow) => {
        setModalIsOpen(true);
        setSelectedRow(newRow);
    }, []);

    if (loading) return <Loading entirePage={true} />;
    if (error) return <Error msg={error.message} />;

    return (
        <>
            <h1 className={defaultStyles.centralisedText}>Websites</h1>
            {websites && websites?.length ? (
                <>
                    <DynamicTable
                        clsInner={styles.websitesTable}
                        data={websites}
                        headers={headers}
                        onClick={onClick}
                    />
                    <Paginator
                        currentPage={currentPage}
                        itemCount={websiteCount}
                        itemsPerPage={itemsPerPage}
                        path='/user/websites'
                    />
                    <Info
                        msg='You can only use a website with or without a subdomain (e.g. www.example.com vs example.com) - A/B testing will only work on one and not the other.'
                        marginBottom={true}
                    />
                    <EditWebsiteModal
                        selectedRow={selectedRow}
                        modalIsOpen={modalIsOpen}
                        setModalIsOpen={setModalIsOpen}
                        refetchWebsites={refetchWebsites}
                    />
                </>
            ) : (
                <NoneFound msg='No websites found' centralised={true} />
            )}
            <CreateWebsiteModal refetchWebsites={refetchWebsites} />
            <Back to='/user' text='Back to Dashboard' />
        </>
    );
};

export default withPage(Websites)({ meta: userWebsitesMeta, innerSpacing: true });
