import * as Yup from 'yup';
import { emailSchema } from './AccountSchemas';

export const signupFormSchema = Yup.object().shape({
    firstName: Yup.string()
        .required('Enter a name')
        .matches(/^[\w\s]+$/, 'The name can only contain letters, numbers, and spaces'),
    email: emailSchema,
    newsletter: Yup.array()
        .of(Yup.string().oneOf(['*Includes newsletter signup']))
        .min(1, 'Signup is required')
        .required('Signup is required'),
});
