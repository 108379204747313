import React, { Dispatch, useState } from 'react';
import styles from '../../../../QuickWins/styles.module.scss';
import QuickWinsImage from '../../../../../../images/ebook-ab-sm.png';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Section } from '../../../../../../components/default/Areas/Areas';
import { withPage } from '../../../../../../components/layout/Page/Page';
import { paidAdEcommerceLandingPage } from '../../../../../misc/MetaTags';
import { CreateAccountModal } from '../../../../../../components/default/CreateAccountModal/CreateAccountModal';
import useQuoraCustomTrackingEvent from '../../../../../../hooks/tracking/useQuoraCustomTrackingEvent/useQuoraCustomTrackingEvent';

const BENEFITS = [
    'Quick to Start',
    'Easy to Implement',
    'Takes Little Time',
    'Low Effort, Large Results',
    'CMS-friendly (e.g. Shopify, WooCommerce, etc)',
    'Beginner-friendly (minimal knowledge required)',
    'Forever Free Version Availble',
];

const PaidAdEcommerceLandingPageTwo = () => {
    const [modalIsOpen, setIsOpen] = useState<boolean>(false);

    useQuoraCustomTrackingEvent('GenerateLead');

    return (
        <>
            <Section
                clsOuter={styles.quickWinsOuter}
                clsInner={`${styles.quickWinsInner} ${styles.specificity}`}
                tag='section'
            >
                <img src={QuickWinsImage} alt='A/B Test Easy Wins Guide' />
                <BodyText setIsOpen={setIsOpen} />
            </Section>
            <CreateAccountModal modalIsOpen={modalIsOpen} setIsOpen={setIsOpen} />
        </>
    );
};

const BodyText = ({ setIsOpen }: { setIsOpen: Dispatch<boolean> }): JSX.Element => (
    <div className={`${styles.benefitsContainer} ${styles.infoContainer}`}>
        <h1>The Free eCommerce Experiments Guide</h1>
        <h2>Let simple experiments take your conversions to new heights</h2>
        <p>
            Discover <u>proven</u> low-effort, high-result changes you can make to boost conversion
            rates.
        </p>
        <p>
            Start with our <u>forever free</u> version.
        </p>
        {/* <p>If you don't, your conversion rate will be average at best.</p> */}
        {/* <p>
            Get started for free in under 10 minutes - just create an account (1 minute), install
            the tracking script (2 minutes), and create a simple test (5 minutes) - start with the
            forever free version first.
        </p> */}
        <ul>
            {BENEFITS.map((benefit: string) => (
                <li key={benefit}>
                    <FontAwesomeIcon icon={faCheck} aria-label='tick' className={styles.check} />{' '}
                    {benefit}
                </li>
            ))}
        </ul>
        <button data-ab='cro-simple' className={styles.btnPrimary} onClick={() => setIsOpen(true)}>
            Download
        </button>
    </div>
);

export default withPage(PaidAdEcommerceLandingPageTwo)({
    meta: paidAdEcommerceLandingPage,
    fullWidth: true,
    noindex: true,
    hasFooterLinks: false,
    lightBackground: true,
});
