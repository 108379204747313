import React from 'react';
import {
    AffiliateSessionMetrics,
    AffiliateSessionsTable,
} from '../../../../../components/account/affiliates/AffiliateSessions/AffiliateSessions';
import { withPage } from '../../../../../components/layout/Page/Page';
import { affiliateSessionsMeta } from '../../../../misc/MetaTags';
import defaultStyles from '../../../../../css/default.module.scss';
import { Back } from '../../../../../components/default/Navigation/Navigation';

export const AffiliateSessions = () => (
    <>
        <h1 className={defaultStyles.centralisedText}>Sessions</h1>
        <p>
            A session is when someone who you have referred (before anyone else) visits our site. It
            does not count the number of pages they view. If they return without clicking on your
            referral link, it will still count as a session.
        </p>
        <AffiliateSessionMetrics />
        <AffiliateSessionsTable />
        <Back to='/user/profile/affiliate' text='Back to Affiliate Profile' />
    </>
);

export default withPage(AffiliateSessions)({ meta: affiliateSessionsMeta, innerSpacing: true });
