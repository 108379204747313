import React, { useMemo } from 'react';
import { useTestVariantsQuery } from '../../../../graphql/generated/schema';
import { Loading, Error, NoneFound } from '../../../default/States/States';
import { useParams, Params, Link } from 'react-router-dom';
import { DynamicTable } from '../../../default/Table/Table';
import styles from '../../../../css/default.module.scss';
import { TestVariantsTableProps } from './types';
import customStyles from './style.module.scss';

const TestVariantsTable = ({ pageUrl }: TestVariantsTableProps): JSX.Element => {
    const { testId } = useParams<Params<string>>();
    const [{ data, fetching: loading, error }] = useTestVariantsQuery({
        variables: {
            testId: testId as string,
        },
    });
    const variants = useMemo(
        () =>
            data?.testVariants
                .filter((variant) => !variant.isControl)
                .map((variant) => {
                    const { id, name, trafficAllocation, variantNo, hasRedirect } = variant;
                    const previewUrl = `${pageUrl}?preview=true&testId=${testId}&variantNo=${variantNo}`;

                    return {
                        id,
                        name,
                        trafficAllocation: `${trafficAllocation}%`,
                        variantNo,
                        hasRedirect: hasRedirect.toString(),
                        preview: (
                            <a
                                href={previewUrl}
                                target='_blank'
                                rel='noreferrer'
                                className={styles.btnDark}
                            >
                                Preview
                            </a>
                        ),
                        view: (
                            <Link
                                to={`/user/tests/${testId}/variant/${id}`}
                                className={styles.btnPrimary}
                            >
                                Details
                            </Link>
                        ),
                    };
                }),
        [data?.testVariants, testId, pageUrl]
    );

    const headers = useMemo(
        () => ['Name', 'Traffic Allocation', 'Variant No', 'Is Redirect', 'Preview', 'Details'],
        []
    );

    if (loading) return <Loading centralised={true} />;
    if (error) return <Error msg={error.message} />;
    if (!variants?.length)
        return <NoneFound msg={`No variants found for test with id ${testId}`} />;

    // @todo: Add a 'create test variant' button or link if no variants exist

    return (
        <>
            <DynamicTable data={variants} headers={headers} />
            <p className={customStyles.caption}>
                You must install the tracking script to be able to preview a variant.
            </p>
        </>
    );
};

export default TestVariantsTable;
