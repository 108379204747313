import React, { useEffect, useMemo, useState } from 'react';
import defaultStyles from '../../../../../../css/default.module.scss';
import styles from './styles.module.scss';
import { testResultsMeta } from '../../../../../misc/MetaTags';
import { withPage } from '../../../../../../components/layout/Page/Page';
import { useParams, Params, Link, useNavigate } from 'react-router-dom';
import { Back } from '../../../../../../components/default/Navigation/Navigation';
import { Loading, NoneFound, Success } from '../../../../../../components/default/States/States';
import PageError from '../../../../../other/errors/PageError.tsx/PageError';
import {
    ButtonData,
    FormData,
    useDeleteTestDataForTestMutation,
    useTestPerformanceDataQuery,
} from '../../../../../../graphql/generated/schema';
import { DynamicTable } from '../../../../../../components/default/Table/Table';
import {
    millisecondsToSeconds,
    oneDP,
} from '../../../../../../utils/HelperFunctions/HelperFunctions';
import { ResultsDataProps } from './types';
import getTrackingType from '../../../../../../utils/HelperFunctions/getTrackingType';
import { BUTTON_HEADERS, FORM_HEADERS, STANDARD_HEADERS } from './variables';
import { TrackingType } from '../../../../../../utils/HelperFunctions/getTrackingType/variables';
import { HeroArea } from '../../../../../../components/default/Areas/Areas';
import DeleteTestResults from '../../../../../../components/account/Test/TestResults/DeleteTestResults/DeleteTestResults';

const TestResults = () => {
    const { testId } = useParams<Params<string>>();
    const [trackingType, setTrackingType] = useState<TrackingType | null>(null);
    const [headers, setHeaders] = useState<string[] | null>(null);
    const [dataMismatch, setDataMismatch] = useState<boolean>(false);
    const [{ data, fetching: loading, error }] = useTestPerformanceDataQuery({
        variables: {
            testId: testId as string,
        },
    });
    const [
        {
            data: resetTestResultsData,
            fetching: loadingResetTestResults,
            error: resetTestResultsError,
        },
        DeleteTestResultsFn,
    ] = useDeleteTestDataForTestMutation();
    const navigate = useNavigate();

    const DeleteTestResults = () =>
        DeleteTestResultsFn({
            testId: testId as string,
        });

    const results = useMemo(
        () =>
            data?.testPerformanceData
                .sort((a, b) => a.variantNo - b.variantNo)
                .map((variant) => {
                    const {
                        avgClicks,
                        avgFormSubmits,
                        avgScrolledDownPercentage,
                        medianTimeOnPage,
                        bounceRate,
                        variantId,
                        variantNo,
                        sessions,
                    } = variant || {};

                    const isTrackingBtn = Boolean(variant.buttonData);
                    const isTrackingForm = Boolean(variant.formData);

                    return {
                        id: variantId,
                        variant: variantNo || 'Control',
                        ...(avgFormSubmits !== null && {
                            avgFormSubmits: oneDP(avgFormSubmits as number),
                        }),
                        medianTimeOnPage: millisecondsToSeconds(medianTimeOnPage) + ' sec',
                        avgScrolledDownPercentage: avgScrolledDownPercentage + '%',
                        avgClicks: oneDP(avgClicks),
                        bounceRate: bounceRate + '%',
                        ...(isTrackingBtn && {
                            avgBtnClicks: oneDP(
                                (variant.buttonData as ButtonData).avgClicks as number
                            ),
                            avgBtnHovers: oneDP(
                                (variant.buttonData as ButtonData).avgHovers as number
                            ),
                        }),
                        ...(isTrackingForm && {
                            avgFormClicks: oneDP(
                                (variant.formData as FormData).avgClicks as number
                            ),
                            avgFormHovers: oneDP(
                                (variant.formData as FormData).avgHovers as number
                            ),
                            submissionRate: (variant.formData as FormData).submissionRate + '%',
                            medianTimeSpent:
                                millisecondsToSeconds(
                                    (variant.formData as FormData).medianTimeSpent as number
                                ) + ' sec',
                        }),
                        sessions,
                        view: (
                            <Link
                                to={`/user/tests/${testId}/variant/${variantId}`}
                                className={defaultStyles.btnPrimary}
                            >
                                View
                            </Link>
                        ),
                    };
                }),
        [data?.testPerformanceData, testId]
    );

    useEffect(() => {
        if (!results) return;

        const calculatedTrackingType = getTrackingType(results);
        setTrackingType(calculatedTrackingType);

        if (calculatedTrackingType === TrackingType.BUTTON) {
            setHeaders(BUTTON_HEADERS);
            return;
        }

        if (calculatedTrackingType === TrackingType.FORM) {
            setHeaders(FORM_HEADERS);
            return;
        }

        if (calculatedTrackingType === TrackingType.HAS_ERROR) {
            setDataMismatch(true);
            return;
        }

        setHeaders(STANDARD_HEADERS);
    }, [results]);

    useEffect(() => {
        if (resetTestResultsData?.deleteTestDataForTest?.success) {
            setDataMismatch(false);
            const timeoutId = setTimeout(() => {
                navigate(`/user/tests/${testId}`);
            }, 3000);

            // Cleanup function to clear the timeout if the component unmounts before the delay
            return () => clearTimeout(timeoutId);
        }
    }, [resetTestResultsData?.deleteTestDataForTest?.success, navigate, testId]);

    if (resetTestResultsData?.deleteTestDataForTest?.success)
        return (
            <>
                <Success
                    msg='Test results reset successfully. Now redirecting...'
                    marginTop={true}
                />
                <Loading centralised={true} marginTop={true} />
            </>
        );

    if (loadingResetTestResults) {
        return <Loading centralised={true} entirePage={true} />;
    }

    if (resetTestResultsError) {
        return (
            <PageError
                msg={`Please contact customer support and take a screenshot of this error: ${resetTestResultsError?.message}`}
            />
        );
    }

    if (dataMismatch) {
        return (
            <HeroArea
                title='Test Data Mismatch'
                description="We're sorry, but it looks like your test data is invalid. This has likely happened because one of your variants or control was set up with standard tracking while another variant or control was set up with custom tracking (i.e. using the data-ab='cro-simple' attribute) - in other words, one of the associated test pages likely had an element with a data-ab='cro-simple' attribute, while another associate test page likely did not. You should either remove this attribute from all elements on all associated test pages, or ensure all associated test pages have an element with this attribute - and then reset your test data to remove mismatching data and then resume this test (or create it again). Note that all variants and the control must have either custom tracking or standard tracking, and when custom tracking is enabled, only one element should have the data-ab='cro-simple' attribute and it should be consistent across all associated test pages (i.e. the main test page and any redirected-to pages). For more information, see our test creation guide."
                buttonText='Reset Test Data'
                buttonOnClick={DeleteTestResults}
                buttonTwoText='Test Creation Guide'
                buttonTwoLink='/support/test-creation/guide'
                withBackButton={true}
            />
        );
    }

    if (loading || !trackingType || !headers?.length) {
        return <Loading centralised={true} entirePage={true} />;
    }

    if (error || !data?.testPerformanceData) return <PageError msg={error?.message} />;

    /*
        @todo later:
        - display a comparison of top performing variant (or any the user chooses) against the control
        - export options? (e.g. CSV, PDF, etc)
        - a chart that visualizes the results
        - display whether it's in progress or finished (and what % in progress)
        - mobile vs desktop vs tablet performance data?
    */

    return (
        <>
            <h1 className={defaultStyles.centralisedText}>Test Results</h1>
            {!results?.length ? (
                <NoneFound
                    msg="There's no data yet. You have likely only recently started this test, so you'll need to wait for some test results."
                    centralised={true}
                />
            ) : (
                <ResultsData results={results} headers={headers} trackingType={trackingType} />
            )}
            <Back to={`/user/tests/${testId}`} text='Back to Test Details' />
        </>
    );
};

const ResultsData = ({ results, headers, trackingType }: ResultsDataProps): JSX.Element => {
    const heading = `${
        trackingType !== TrackingType.STANDARD ? 'Custom ' : ''
    }${trackingType} tracking`;

    return (
        <>
            <h3 className={styles.trackingType}>{heading}</h3>
            <p>
                View the test results of each variant compared to the control to identify any
                improvements. The results show the averages per session.
            </p>
            <DynamicTable data={results} headers={headers} />
            <DeleteTestResults />
        </>
    );
};

export default withPage(TestResults)({
    meta: testResultsMeta,
    fullWidth: true,
    clsInner: defaultStyles.innerContainerPadding,
    innerSpacing: true,
});
