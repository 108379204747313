import React from 'react';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styles from '../../../../css/default.module.scss';
import { EditWebsiteModalProps } from './types';
import Modal from 'react-modal';
import EditWebsiteForm from '../EditWebsiteForm/EditWebsiteForm';
import DeleteWebsite from './DeleteWebsite/DeleteWebsite';

const EditWebsiteModal = ({
    selectedRow,
    modalIsOpen,
    setModalIsOpen,
    refetchWebsites,
}: EditWebsiteModalProps): JSX.Element | null => {
    const closeModal = () => {
        setModalIsOpen(false);
    };

    if (!selectedRow) return null;

    return (
        <>
            <Modal
                isOpen={modalIsOpen}
                onRequestClose={closeModal}
                className={styles.modal}
                contentLabel='Edit Website'
                portalClassName={styles.modalOverlayWrap} // cannot use overlayClassName
            >
                <button onClick={closeModal} className={styles.close} aria-label='close'>
                    <FontAwesomeIcon icon={faTimes} />
                </button>
                <div>
                    <h2>Edit Website</h2>
                    <EditWebsiteForm
                        selectedRow={selectedRow}
                        refetchWebsites={refetchWebsites}
                        setModalIsOpen={setModalIsOpen}
                    />
                    <DeleteWebsite
                        selectedRow={selectedRow}
                        closeModal={closeModal}
                        refetchWebsites={refetchWebsites}
                    />
                </div>
            </Modal>
        </>
    );
};

export default EditWebsiteModal;
