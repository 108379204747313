import { useIsDirectAffiliateSignupQuery } from '../../../graphql/generated/schema';
import { UseIsDirectAffiliateSignup } from './types';

export const useIsDirectAffiliateSignup: UseIsDirectAffiliateSignup = () => {
    const [{ data, error, fetching: loading }] = useIsDirectAffiliateSignupQuery();
    return {
        isDirectAffiliateSignup: data?.me?.directAffiliateSignup,
        loading,
        error,
    };
};
