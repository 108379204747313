import React from 'react';
import styles from './styles.module.scss';
import WarningBox from '../../../../../../components/default/Informational/WarningBox/WarningBox';
import { MAX_VARIANTS } from '../../../../../../utils/global-variables';

const BeginnerPlanDissuasionMessage = () => (
    <div className={styles.container}>
        <p>
            Before downgrading, just be aware of the restrictions that will be imposed on your
            account.
        </p>

        <WarningBox
            title='Reduced Variants - Less Freedom to Experiment'
            description={`With the Beginner Plan, you'll only be able to experiment with a maximum of ${MAX_VARIANTS.BEGINNER} variants per test. But with the Pro Plan, you can try out as many variations as you want, giving you more chances to find what really works.`}
        />

        <WarningBox
            title='One Test at a Time'
            description='Going for the Beginner Plan means you can only carry out one test at a time. If you want to move faster and make multiple tests together, the Pro Plan lets you do that, helping you learn and optimise quicker.'
        />

        <WarningBox
            title='Reduced Data Accuracy with Limited Test Sessions'
            description='The Beginner Plan puts a tighter cap on the sessions per test – at 4000. This means reduced accuracy of test results. With the Pro Plan, you get up to 10000 sessions per test, making the results more accurate to find out what users really want.'
        />

        <WarningBox
            title='One Website Only'
            description="If you've got more than one website or want to expand, the Beginner Plan might hold you back. The Pro Plan doesn't limit you as you can make tests on every website you manage to maximize results."
        />

        <p>
            If you still want to downgrade with the aforementioned restrictions, you can continue
            below:
        </p>
    </div>
);

export default BeginnerPlanDissuasionMessage;
