import React, { useMemo } from 'react';
import { Params, useParams } from 'react-router-dom';
import { useAffiliatePayments } from '../../../../hooks/affiliates/useAffiliatePayments/useAffiliatePayments';
import { DEFAULT_ITEMS_PER_PAGE } from '../../../../utils/global-variables';
import { Paginator } from '../../../default/Paginator/Paginator';
import { Loading, Error, NoneFound } from '../../../default/States/States';
import { DynamicTable } from '../../../default/Table/Table';

export const AffiliatePaymentsTable = () => {
    const itemsPerPage: number = DEFAULT_ITEMS_PER_PAGE;
    const params: Params<string> = useParams();
    const currentPage: number = Number(params.currentPage) || 1;
    const skip: number = (currentPage - 1) * itemsPerPage;
    const { paymentsCount, payments, loading, error } = useAffiliatePayments({
        limit: itemsPerPage,
        skip,
    });
    const headers: string[] = useMemo<string[]>(() => ['Date', 'Amount'], []);
    const maxTableWidth: number = 300;

    if (loading) return <Loading />;
    if (error) return <Error msg={error.message} />;
    if (!payments?.length) return <NoneFound msg='No payments found' />;

    return (
        <>
            <DynamicTable data={payments} headers={headers} maxWidth={maxTableWidth} />
            <Paginator
                currentPage={currentPage}
                itemCount={paymentsCount}
                itemsPerPage={itemsPerPage}
                path='/user/profile/affiliate/payments'
                maxWidth={maxTableWidth}
            />
        </>
    );
};
