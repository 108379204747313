import { useContext } from 'react';
import { UserContext } from '../../../../contexts/UserContext/UserContext';
import { UserContextType } from '../../../../contexts/UserContext/types';
import InArrears from '../../../account/payments/statuses/InArrears/InArrears';
import Restricted from '../../../account/payments/statuses/Restricted/Restricted';
import CardNearingExpiry from '../../../account/payments/statuses/CardNearingExpiry/CardNearingExpiry';
import styles from './styles.module.scss';

export const UserStatus = () => {
    const { user } = useContext<UserContextType>(UserContext);
    const { userPlan } = user || {};
    const hasPaidPlan = userPlan === 'BEGINNER' || userPlan === 'PRO';

    if (!user || !hasPaidPlan) return null;

    const { subscriptionStatus, cardNearingExpiry } = user;
    const isInArrears = subscriptionStatus === 'IN_ARREARS';
    const isRestricted = subscriptionStatus === 'RESTRICTED';

    return (
        <div className={styles.container}>
            {isInArrears && <InArrears />}
            {isRestricted && <Restricted />}
            {cardNearingExpiry && <CardNearingExpiry />}
        </div>
    );
};
