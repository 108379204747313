import React from 'react';
import { Link } from 'react-router-dom';
import { HeroArea, Section } from '../../../components/default/Areas/Areas';
import { affiliateProgramMeta } from '../../misc/MetaTags';
import defaultStyles from '../../../css/default.module.scss';
import styles from './styles.module.scss';
import { withPage } from '../../../components/layout/Page/Page';
import { DEFAULT_AFFILIATE_RATE } from '../../../utils/global-variables';
import RotatingDollar from './RotatingDollar/RotatingDollar';

const AffiliateProgram = () => (
    <>
        <HeroArea
            title='Our Affiliate Program'
            description={`Earn money with us by selling our software to earn substantial ${DEFAULT_AFFILIATE_RATE} customer lifetime commissions.`}
            buttonText='Learn More'
            buttonLink='#learn-more'
            buttonTwoText='Affiliate Login'
            buttonTwoLink='/affiliates/login'
            leftText={true}
            additionalContent={<RotatingDollar />}
            clsOuter={styles.heroOuter}
            clsInner={styles.heroInner}
            clsBtn={defaultStyles.btnDark}
            clsBtnTwo={defaultStyles.btnWhite}
            extraBottomSpace={true}
        />

        <Section patterned={true}>
            <h2>Becoming an affiliate</h2>
            <p>
                You can register as an affiliate using the link below, or if you are an existing
                user of our product then we will have automatically made you an affiliate - just see
                your affiliate dashboard after logging into your account to see the details.
            </p>
            <Link to='/affiliates/register' className={defaultStyles.btnPrimary}>
                Register
            </Link>
        </Section>

        <Section patterned={true}>
            <h2>How it works</h2>
            <ol>
                <li>You send prospective customers to our website</li>
                <li>Some of them convert to paying customers</li>
                <li>You get a significant cut of the money</li>
                <li>
                    You get {DEFAULT_AFFILIATE_RATE} of the money they pay - for <em>every</em>{' '}
                    payment they ever make
                </li>
            </ol>
        </Section>

        <Section id='learn-more' patterned={true}>
            <h2>Information</h2>
            <p>
                Interested in becoming an affiliate but want to learn more? See our{' '}
                <Link to='/affiliates/faq'>FAQs</Link>.
            </p>
            <p>
                Or do you already offer CRO or A/B testing (or similar) services? Learn about{' '}
                <Link to='/partnerships'>Partnerships</Link>.
            </p>
        </Section>
    </>
);

export default withPage(AffiliateProgram)({
    meta: affiliateProgramMeta,
    fullWidth: true,
});
