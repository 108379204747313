import React from 'react';
import { VariantImplementationProps } from './types';
import { CodeBlock, InfoAreaWrap } from '../../../default/Cards/Cards';

const VariantImplementation = ({
    trafficAllocation,
    cssCode,
    jsCode,
    pageRedirectUrl,
}: VariantImplementationProps): JSX.Element => (
    <InfoAreaWrap>
        <h2>Implementation:</h2>
        <p>
            <strong>Traffic Allocation:</strong> {trafficAllocation}%
        </p>
        <p>
            <strong>Page Redirect:</strong> {pageRedirectUrl || 'none'}
        </p>
        <CodeBlock title='CSS Code' code={cssCode} />
        <CodeBlock title='JavaScript Code' code={jsCode} />
    </InfoAreaWrap>
);

export default VariantImplementation;
